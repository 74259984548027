import { Component, OnInit, Input } from '@angular/core';
import { IconButtonTypeEnum } from '../shared/models/enums/icon-button-type-enum';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {

  public iconType: any;
  public iconTypeMap : {[index: number]: {src: string, svgClass: string}}

  @Input() iconButtonType;
  @Input() text;
  @Input() tooltipText;
   
  constructor() {this.iconType = IconButtonTypeEnum }
  
  ngOnInit(): void {
    this.iconTypeMap = {
      [IconButtonTypeEnum.moderation]: {src: '/assets/icons/moderation.svg', svgClass: 'moderation'}
    }
  }

}
