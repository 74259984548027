import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { OptionItem, WorkExperienceFilterModel } from '@hrra/common-models' 
import { Range } from '@hrra/core';

@Component({
  selector: 'hrra-work-experience',
  templateUrl: './work-experience.component.html',
  styleUrls: ['./work-experience.component.scss'],
})
export class WorkExperienceComponent implements OnInit, ControlValueAccessor, OnChanges {
  
  onChange = (value) => {};
  onTouched = () => {};

  workExperienceForm: FormGroup;


  @Input() experienceFilter: WorkExperienceFilterModel;

  @Output() onExperienceFilter: EventEmitter<WorkExperienceFilterModel> = new EventEmitter<WorkExperienceFilterModel>();

  constructor(private fb: FormBuilder) {}

	ngOnInit(): void {
    this.workExperienceForm = this.fb.group({
      workExperienceFrom: new FormControl(undefined),
      workExperienceTo: new FormControl(undefined),
      withoutWorkExperience: new FormControl(undefined),
      anyExperience: new FormControl(undefined)
    })
  }

  isFormReseted(filter: WorkExperienceFilterModel){
    return !filter.anyExperience && !filter.withoutWorkExperience && filter.experienceRange.from == null && filter.experienceRange.to == null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['experienceFilter']){  
      if(this.workExperienceForm?.controls['withoutWorkExperience'].value == undefined){
        this.workExperienceForm?.controls['withoutWorkExperience'].setValue(this.experienceFilter.withoutWorkExperience);
      }
      if(this.workExperienceForm?.controls['anyExperience'].value == undefined){
        this.workExperienceForm?.controls['anyExperience'].setValue(this.experienceFilter.anyExperience);
      }

      if(this.workExperienceForm?.controls['workExperienceFrom'].value == undefined && this.experienceFilter.experienceRange != undefined){
        this.workExperienceForm?.controls['workExperienceFrom'].setValue(this.experienceFilter.experienceRange.from);
      }
      if(this.workExperienceForm?.controls['workExperienceTo'].value == undefined && this.experienceFilter.experienceRange != undefined){
        this.workExperienceForm?.controls['workExperienceTo'].setValue(this.experienceFilter.experienceRange.to);
      }

      const currentFilter = changes['experienceFilter'].currentValue;
      
      if(this.isFormReseted(currentFilter) && this.workExperienceForm != null){
        this.workExperienceForm.reset();
        
        this.workExperienceForm.controls.workExperienceFrom.enable();
        this.workExperienceForm.controls.workExperienceTo.enable();
      }
    }
  }

  writeValue(value: any) {}

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }
  
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  onWorkExperienceFilter(){
    const isDisabledExperienceYears = this.workExperienceForm.controls.withoutWorkExperience.value == true || this.workExperienceForm.controls.anyExperience.value == true;

    if(isDisabledExperienceYears){
      this.workExperienceForm.controls.workExperienceFrom.reset();
      this.workExperienceForm.controls.workExperienceTo.reset();
      this.workExperienceForm.controls.workExperienceFrom.disable();
      this.workExperienceForm.controls.workExperienceTo.disable();
    }else{
      this.workExperienceForm.controls.workExperienceFrom.enable();
      this.workExperienceForm.controls.workExperienceTo.enable();
    }

    let experienceFilter: WorkExperienceFilterModel = {
      experienceRange: {
        from: this.workExperienceForm.controls.workExperienceFrom.value,
        to: this.workExperienceForm.controls.workExperienceTo.value
      },

      withoutWorkExperience: this.workExperienceForm.controls.withoutWorkExperience.value,
      anyExperience: this.workExperienceForm.controls.anyExperience.value
    }

    this.onExperienceFilter.emit(experienceFilter);
  }
}
