import { Injectable } from '@angular/core';
import { LocationsViewModel, TreeSelectModel } from '@hrra/common-models';
import { ApiResponse, SmartHttpService, UrlConfigService } from '@hrra/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlConfig, UrlConfigContainer } from 'src/app/core/url-config';

@Injectable({
  providedIn: 'root'
})
export class JobseekerLocationService {

  getLocations(locationParentIds: string[]): Observable<ApiResponse<LocationsViewModel[], {}>> {
    let data = {
      parentLocationIds: locationParentIds,
    }
    return this.smartHttp.get<LocationsViewModel[]>(this.urlService.urlConfig.api.data.location.get, data);    
  }

  getTreeSelectLocations(): Observable<ApiResponse<TreeSelectModel<string>[], {}>> {  
    return this.smartHttp.get<TreeSelectModel<string>[]>(this.urlService.urlConfig.api.data.location.getLocationTree, {}).pipe(
      map(d =>{
        return JSON.parse(JSON.stringify(d))
      }
    ));    
  }

  constructor(private urlService: UrlConfigService<UrlConfigContainer>, private smartHttp: SmartHttpService){}

}
