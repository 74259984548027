import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducers } from './reducer';
import { EffectsModule } from '@ngrx/effects';
import { SearchPageEffects } from './search-page';
import { AnnouncementsEffects } from './effects';
import { AnnouncementDetailsEffects } from './ann-details-page';
import { SearchFieldEffects } from './front-page/search-field/effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('announcement', reducers),
    EffectsModule.forFeature([
      AnnouncementsEffects,
      SearchPageEffects.AnnouncementSearchEffects,
      AnnouncementDetailsEffects.AnnouncementDetailsEffects,
      SearchFieldEffects,
    ]),
  ],
})
export class AnnouncementStoreModule {}
