import { Inject, Injectable, InjectionToken } from '@angular/core';
import * as pino from 'pino';
import { EnvService } from '../env.service';
import { EXTERNAL_LOGGER, LogginService } from './logging-provider';

@Injectable({
  providedIn: 'root'
})
export class ClientLoggingService extends LogginService {

  _logger: pino.Logger;

  get logger() {
    return this._logger;
  }


  constructor(private env: EnvService) {
    super();
    this._logger = pino(<pino.LoggerOptions>{ name: 'angular-app', level: env.logLevel, prettyPrint: {
      translateTime: "SYS:standard"
    } });
  }
}


// export interface TestService {
//   serviceName: string;
// }


// export class ServiceA{

//   serviceName: string;

//   constructor(){
//     console.log('AAAAAAAAAA');
//     this.serviceName = "A";
//   }

// }


// export class ServiceB {

//   serviceName: string;

//   constructor(){
//     console.log('BBBBBBBBBBBB');
//     this.serviceName = "B";
//   }
// }