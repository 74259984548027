import { Injectable, Optional } from '@angular/core';
import { throwError as observableThrowError, Observable, throwError } from 'rxjs';
import { map, catchError, timeout } from 'rxjs/operators';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { ApiResponse, ApiError, RawApiResponse } from './api-response';
import { LocalDataStorageService } from './local-data-storage.service';
import { TransferState } from '@angular/platform-browser';
import * as P from 'pino';
import { LogginService } from './logging/logging-provider';
import { SsrService } from './ssr.service';

@Injectable({
  providedIn: 'root',
})
export class SmartHttpService {
  SERVER_TIMEOUT = 5000;
  BROWSER_TIMEOUT = 60000;

  constructor(
    private http: HttpClient,
    private storage: LocalDataStorageService,
    @Optional() private trasferState: TransferState,
    private loggingService: LogginService,
    private ssrService: SsrService
  ) {
    this._logger = this.loggingService.logger.child({
      source: 'smart-http-service',
    });
  }

  private _logger: P.Logger;
  //TODO add auth headers and localization params (language)

  get logger() {
    return this._logger;
  }

  public get<TData, TError = {}>(
    url: string,
    params: { [index: string]: any },
    withCredentials = true
  ): Observable<ApiResponse<TData, TError>> {
    this.logger.debug({ url: url, params: params }, 'Http request');

    if (url.startsWith('EMPTY_URL_PLACEHOLDER')) {
      this.logger.fatal(
        { url: url, params: params },
        'API URL placeholder not replaced'
      );
    }

    if (this.trasferState !== null) {
      this.logger.trace(
        { state: JSON.parse(this.trasferState.toJson()) },
        'TRANSFER STATE'
      );
    } else {
      this.logger.trace({}, 'Transfer state provider not configured');
    }

    let searchParams = new HttpParams();

    for (let p in params) {
      if (params.hasOwnProperty(p) && params[p]) {
        if (params[p] instanceof Array) {
          params[p].forEach((item) => {
            searchParams = searchParams.append(p, item);
          });
        } else {
          searchParams = searchParams.append(p, params[p]);
        }
      }
    }

    const requestTimeout = this.ssrService.isPlatformServer() ? 5000 : 60000;

    return this.getRaw<ApiResponse<TData, TError>>(
      url,
      null,
      searchParams,
      withCredentials
    ).pipe(
      timeout(requestTimeout),
      map((d) => this.extractData<TData, TError>(d)),
      catchError((err) => {
        let processedError = this.processRawError(err);
        return observableThrowError(processedError);
      })
    );
  }

  public post<TData, TError = {}>(
    url: string,
    data: any,
    withCredentials = true
  ): Observable<ApiResponse<TData, TError>> {
    let body = JSON.stringify(data);
    let headers = new HttpHeaders({ 'Content-type': 'application/json' });

    const requestTimeout = this.ssrService.isPlatformServer()
      ? this.SERVER_TIMEOUT
      : this.BROWSER_TIMEOUT;

    return this.postRaw<ApiResponse<TData, TError>>(
      url,
      body,
      headers,
      null,
      withCredentials
    ).pipe(
      timeout(requestTimeout),
      map((d) => this.extractData<TData, TError>(d)),
      catchError((err) => {
        let processedError = this.processRawError(err);
        return observableThrowError(processedError);
      })
    );
  }

  public sendBeacon(url: string, data: FormData): boolean {
    return navigator.sendBeacon(url, data);
  }

  public postWithParams<TData, TError = {}>(
    url: string,
    params: { [index: string]: any },
    withCredentials = true
  ): Observable<ApiResponse<TData, TError>> {
    let searchParams = new HttpParams();

    for (let p in params) {
      if (params.hasOwnProperty(p) && params[p]) {
        if (params[p] instanceof Array) {
          params[p].forEach((item) => {
            searchParams = searchParams.append(p, item);
          });
        } else {
          searchParams = searchParams.append(p, params[p]);
        }
      }
    }

    const requestTimeout = this.ssrService.isPlatformServer()
      ? this.SERVER_TIMEOUT
      : this.BROWSER_TIMEOUT;

    return this.postRaw<ApiResponse<TData, TError>>(
      url,
      null,
      null,
      searchParams,
      withCredentials
    ).pipe(
      timeout(requestTimeout),
      map((d) => this.extractData<TData, TError>(d)),
      catchError((err) => {
        let processedError = this.processRawError(err);
        return observableThrowError(processedError);
      })
    );
  }

  public postWithFile<TData, TError = {}>(
    url: string,
    data: FormData,
    withCredentials = true
  ): Observable<ApiResponse<TData, TError>> {
    let headers = new HttpHeaders();

    return this.postRaw<ApiResponse<TData, TError>>(
      url,
      data,
      headers,
      null,
      withCredentials
    ).pipe(
      map((d) => this.extractData<TData, TError>(d)),
      catchError((err) => {
        let processedError = this.processRawError(err);
        return observableThrowError(processedError);
      })
    );
  }

  private processRawError<TData, TError>(
    rawError: any
  ): ApiResponse<TData, TError> {
    let result = <ApiResponse<TData, TError>>{
      success: false,
      data: null,
      error: <ApiError<TError>>{},
    };

    if (rawError instanceof HttpErrorResponse) {
      let error = <HttpErrorResponse>rawError;

      result.status = error.status;
      result.statusText = error.statusText;

      result.error.isServerError = error.status == 500;
      result.error.isUnauthorized = error.status == 401;
      result.error.isPermissionDenied = error.status == 403;
      result.error.isClientError = error.status == 400 || error.status == 404;
      result.error.isConnectionFailure = error.status == 0;

      //TODO: result.error.isSessionExpired = error.status == 440;

      if (this.isJson(error)) {
        let er = error.error;
        result.error.errorCode = er.error.errorCode;
        result.error.errorMessage = er.error.errorMessage;
        result.error.errorReferenceId = er.error.errorReferenceId;
        result.error.errorDetails = er.error.errorDetails;
        result.error.additionalInfo = er.error.additionalInfo;
      }

      this.logger.error(
        {
          status: error.status,
          statusText: error.statusText,
          url: error.url,
          message: error.message,
        },
        'ERROR in http request'
      );
    } else {
      result.status = 0; //connection error
      this.logger.error('Uknown error in http request');
      this.logger.error(rawError);
    }

    return result;
  }

  private extractData<TData, TError>(
    response: HttpResponse<RawApiResponse<TData>>
  ): ApiResponse<TData, TError> {
    this.logger.debug('Success response from server');
    let result = <ApiResponse<TData, TError>>{
      success: true,
      error: null,
      status: response.status,
      statusText: response.statusText,
      data: null,
    };

    if (this.hasContent(response)) {
      if (this.isJson(response)) {
        result.data = this.preprocessResponseData(response.body.data);
      } else {
        throw new Error('Invalid response contents type');
      }
    }

    return result;
  }

  private preprocessResponseData<TData>(data: TData) {
    /*
        Modifying angular http client response object (reference returned by http client) when using angular universal (ssr)
        and http transfer state causes issues: browser receives already modified object via http transfer state and same changes
        are applied again.
        This happens because when running on server, modifications are applied to original object returned by http client, 
        then used by http transfer state to send to browser as a cached response.
        As a result same changes are applied two times, for example adding some item to array property in response object
        gives us array with that item appended twice (once appended when running on server and once when running in browser).
        As a workaround when running on server we clone original response object before modifying it, to leave original object
        untouched, so that http transfer state can use it as a cached response.
        */
    if (this.ssrService.isPlatformServer()) {
      return JSON.parse(JSON.stringify(data));
    } else if (this.ssrService.isPlatformBrowser()) {
      return data;
    } else {
      throw new Error('Unsupported platform');
    }
  }

  private isJson<TData, TError>(
    response: HttpResponse<RawApiResponse<TData>> | HttpErrorResponse
  ) {
    let contentType = response.headers.get('content-type');
    return (
      contentType &&
      (contentType.indexOf('application/json') !== -1 ||
        contentType.indexOf('application/problem+json') !== -1)
    );
  }

  private hasContent<TData>(
    response: HttpResponse<RawApiResponse<TData>> | HttpErrorResponse
  ) {
    return response.status != 204; //TODO enum for http statuses
  }

  public getRaw<T>(
    url: string,
    headers?: HttpHeaders,
    params?: HttpParams,
    withCredentials = false
  ): Observable<HttpResponse<T>> {
    if (headers === undefined || headers === null) {
      headers = new HttpHeaders();
    }

    if (this.storage.hasAccessToken()) {
      headers = headers.append(
        'Authorization',
        `Bearer ${this.storage.getAccessToken()}`
      );
    }

    if (this.storage.isUiLanguageSet()) {
      headers = headers.append('Accept-Language', this.storage.getUiCulture());
    }

    this.logger.debug({ actualUrl: url }, 'ACTUAL URL: ' + url);

    return this.http.get<T>(url, {
      headers: headers,
      observe: 'response',
      params: params,
      responseType: 'json',
      withCredentials: withCredentials,
    });
  }

  public postRaw<T>(
    url: string,
    body: any,
    headers?: HttpHeaders,
    params?: HttpParams,
    withCredentials = false
  ): Observable<HttpResponse<T>> {
    if (headers === undefined || headers === null) {
      headers = new HttpHeaders();
    }

    if (this.storage.hasAccessToken()) {
      headers = headers.append(
        'Authorization',
        `Bearer ${this.storage.getAccessToken()}`
      );
    }

    if (this.storage.isUiLanguageSet()) {
      headers = headers.append('Accept-Language', this.storage.getUiCulture());
    }

    return this.http.post<T>(url, body, {
      headers: headers,
      observe: 'response',
      params: params,
      responseType: 'json',
      withCredentials: withCredentials,
    });
  }
}
