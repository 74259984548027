import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AuthEffects, SnackBarEffects, FavoritesEffects } from './effects';
import { CvBoxStoreModule } from './cv-box-store';
import { AnnouncementStoreModule } from './announcement-store';
import { CustomerStoreModule } from './customer-store';
import { SharedModule } from '../shared/shared.module';
import { CurrentWebsiteEffects } from './effects';
import { lookupReducer, currentInfoReducer, lookupReducerMy, favoritesReducer } from './reducer';
import { JobseekerStoreModule } from './jobseeker-store';
import { SubscriberStoreModule } from './subscriber-store/subscriber-store.module';
import { InfoPageStoreModule } from './info-pages-store/info-pages-store.module';
import { BannerStoreModule } from './banner-store/banner.module';
import { routerReducer } from '@ngrx/router-store';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot({
      lookup: lookupReducer, 
      currentInfo: currentInfoReducer,
      lookupMy: lookupReducerMy,
      favorites: favoritesReducer,
      router: routerReducer
    }),//TODO MERGE reducers action reducer map
    EffectsModule.forRoot([CurrentWebsiteEffects, AuthEffects, FavoritesEffects, SnackBarEffects]),
    CvBoxStoreModule,
    AnnouncementStoreModule,
    CustomerStoreModule,
    JobseekerStoreModule,
    SubscriberStoreModule,
    InfoPageStoreModule,
    SharedModule,
    BannerStoreModule
  ]
})
export class RootStoreModule { }
