<div class="container" [ngClass] = "{'container--without-large-size':  pageName == page.SearchResultsPage || pageName == page.CustomerPublicPage,
                                    'container--with-large-size':  pageName != page.SearchResultsPage && pageName != page.CustomerPublicPage}" 
     *ngIf="{timeZone: timeZone$ | async} as data"
     [ngStyle]="{'background': (announcement.isWithBranding && announcement.brandingAnnouncementTileTypeId === brandingAnnouncementTileType.TileWithSpecialDesign) ? announcement.announcementBrandingListColor : 'white'}">
     <div class="content" [ngClass] = "{'ann-title--without-large-size' : pageName != page.CustomerPublicPage  && pageName != page.SearchResultsPage,
                       'ann-tile': true, 'ann-tile--main-page': pageName == page.FrontPage,
                       'list-branding-with-special-design': announcement.isWithBranding && announcement.brandingAnnouncementTileTypeId === brandingAnnouncementTileType.TileWithSpecialDesign}">
               
        <div *ngIf="announcement.isWithBranding" class="list-branding" 
             [ngClass]="{'list-branding-with-ribbon': announcement.isWithBranding && announcement.brandingAnnouncementTileTypeId === brandingAnnouncementTileType.TileWithRibbon}"
             [ngStyle]="((announcement.isWithBranding && announcement.brandingAnnouncementTileTypeId === brandingAnnouncementTileType.TileWithRibbon) ? {'background': announcement.announcementBrandingListColor} : {})">
        </div>

        <a [routerLink]="['/announcement', announcement.announcementId, announcement.urlFriendlyTitle]" class="title" [ngClass] = "{'title-link--without-large-size' : pageName == page.SearchResultsPage || pageName == page.CustomerPublicPage}">
            <ng-container [ngTemplateOutlet]="AnnouncementLinkDetails" [ngTemplateOutletContext]="{ $implicit: announcement }">
            </ng-container>
        </a>
        
        <div class="location">
            <div class="location__container" [ngClass] = "{'location--without-large-size' : pageName == page.SearchResultsPage || pageName == page.CustomerPublicPage}">
                <hrra-announcement-location [locations]="announcement?.extendedLocations"></hrra-announcement-location>  
            </div>
        </div>
        
        <ng-template #AnnouncementLinkDetails>
            <img *ngIf="(announcement?.isWithFrame && (announcement.frameType == announcementFrameType.FramePlus || announcement.frameType == announcementFrameType.FrameFlame)) || announcement?.isWithBauble" 
                 class="title__frame-icon" 
                 src="{{announcement.isWithBauble ? ('assets/icons/decorations/' + announcementBaubleType[announcement.baubleType] + '.png') : 
                       ('assets/img/common/' + announcementFrameType[announcement.frameType] + '.svg')}}">

            <div [ngClass]="{'title__frame title__frame--flame':announcement?.isWithFrame && announcement.frameType == announcementFrameType.FrameFlame,
                           'title--bold-desktop' : pageName == page.CustomerPublicPage  || pageName == page.SearchResultsPage,
                           'title__frame title__frame--plus':announcement?.isWithFrame && announcement.frameType == announcementFrameType.FramePlus,
                           'title--without-large-size' : pageName != page.CustomerPublicPage  && pageName != page.SearchResultsPage,
                           'title__text' : true}">
                {{announcement.title}}
                <div class="additional-info additional-info-desktop">
                    <img *ngIf="announcement.isSalaryAvailable" class="additional-info__gel-icon" src="assets/img/common/currency-icon.svg" alt="">
                    <img *ngIf="announcement.isContactPhoneAvailable" class="additional-info__phone-icon" src="assets/img/common/phone-icon.svg" alt="">
                    <svg-icon title="{{'label.WorkFromHome' | translate}}" *ngIf="announcement?.isWorkFromHome" class="additional-info__home-icon" src="/assets/icons/home.svg" class="home-icon"></svg-icon>
                </div> 
            </div>
        </ng-template>
        
       <div class="company" [ngClass] = "{'company--without-large-size' : pageName == page.SearchResultsPage || pageName == page.CustomerPublicPage}">
           <a *ngIf="!announcement.isAnonymous; else elseBlock" [routerLink]="['/customer', announcement.customerId, announcement.urlFriendlyCustomerName]" class="company__title">
              <div>{{announcement.customerName}}</div>
           </a>
           <ng-template #elseBlock>
              <div class="company__title">{{announcement.customerName}}</div>
           </ng-template>
            
            <div class="company__additional-info">
                <img *ngIf="announcement.isVerified" title="{{'label.Verified' | translate}}" class="company__additional-info-Verified-icon company__additional-info-icon" src="assets/img/common/verification-icon.svg">
                <img  *ngIf="announcement.isWithCvBox" class="company__additional-info-cvbox-icon company__additional-info-icon" src="assets/img/common/cv-box-company.svg">
            </div>
       </div>
        <div *ngIf="announcement.isWithLogo" [ngClass] = "{'company-logo--without-large-size' : pageName != page.CustomerPublicPage && pageName != page.SearchResultsPage,
                           'company-logo' : true}">
            <a *ngIf="!announcement.isAnonymous; else elseBlockLogo" class="company-logo__link" [routerLink]="['/customer', announcement.customerId, announcement.urlFriendlyCustomerName]">
                <img class="company-logo__img" src="{{announcement.logoFilename}}">
            </a>
            <ng-template #elseBlockLogo>
                <img class="company-logo__img" src="{{announcement.logoFilename}}">
            </ng-template>
        </div>

        <div class="united-content">
            <div class="date">
                <div class="ann-type ann-type--desktop">{{announcementListSectionMap[announcement.listingSection] | translate}}</div>
    
                <span [ngClass] = "{'date--today' : announcement.isPublishedToday}">{{announcement.isPublishedToday ? ('label.Today' | translate)  : (announcement.renewalDate || announcement.publishDate) | date:'d MMM':data.timeZone:currentLang}} - </span>
                <span [ngClass] = "{'date--today' : announcement.isDeadLineToday}">{{announcement.isDeadLineToday ? ('label.Today' | translate)  : announcement.deadlineDate | date:'d MMM':data.timeZone:currentLang}}</span>
           
                <div class="additional-info additional-info-mobile">
                    <img *ngIf="announcement.isSalaryAvailable" class="additional-info__gel-icon" src="assets/img/common/currency-icon.svg" alt="">
                    <img *ngIf="announcement.isContactPhoneAvailable" class="additional-info__phone-icon" src="assets/img/common/phone-icon.svg" alt="">
                    <svg-icon title="{{'label.WorkFromHome' | translate}}" *ngIf="announcement?.isWorkFromHome" class="additional-info__home-icon" src="/assets/icons/home.svg" class="home-icon"></svg-icon>
                </div> 
            </div>

            <img class="ann-heart-icon" (click)="onToggleFavorite()" [src]="announcement.isFavorite ? 'assets/img/common/fav-icon-filled.svg' : 'assets/img/common/fav-icon.svg' " alt="">
        </div>

        <button *ngIf="announcement.applicationMethod != applicationMethodType.EmailPhoneAddress && userType !== userTypeEnum.Internal" [routerLink]="['/announcement', announcement.announcementId, announcement.urlFriendlyTitle]" 
                [ngClass]="{'send-cv-button--without-large-size' : pageName != page.CustomerPublicPage  && pageName != page.SearchResultsPage,
                            'send-cv-button' : true}">
                {{'label.SendCv' | translate}}
        </button>

        <div class="ann-type ann-type--responsive">{{announcementListSectionMap[announcement.listingSection] | translate}}</div>
    </div>
</div>
