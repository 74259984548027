import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner-insert-script',
  templateUrl: './banner-insert-script.component.html',
  styleUrls: ['./banner-insert-script.component.scss']
})
export class BannerInsertScriptComponent implements OnInit {

  @Input() banner: string;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => {
        try {
            (window['adsbygoogle'] = window['adsbygoogle'] || []).push({
                overlays: {bottom: true}
            });
        } catch (e) {
            console.error(e);
        }
    }, 0);
  }
}
