import { JobseekerRegistrationState } from "./state";
import { createImmerReducer } from 'ngrx-immer/store';
import * as JobseekerRegistrationActions from './actions';
import { on, Action } from '@ngrx/store';

let initialState: JobseekerRegistrationState = {
    loading: false,
    registrationFailureDetails: null,
    regsitrationRequestFailed: false,
    emailResendToken: null,
    activationEmailResendMaxAttemptsReached: false,
    emailVerificationLoading: false,
    emailVerificationComplete: false,
    emailVerificationError: false,
    loadingSeoData: false,
    metaData: null
}

const reducer = createImmerReducer(
    initialState,
    on(JobseekerRegistrationActions.registerJobseeker, state =>{
        state.loading = true;
        state.regsitrationRequestFailed = false;
        state.registrationFailureDetails = null;
        return state;
    }),
    on(JobseekerRegistrationActions.registerJobseekerSuccess, (state, {payload}) =>{
        state.loading = false;
        state.emailResendToken = payload.emailVerificationResendToken;
        return state;
    }),
    on(JobseekerRegistrationActions.registerJobseekerFail, (state, {payload}) =>{
        state.loading = false;
        state.regsitrationRequestFailed = true;
        state.registrationFailureDetails = payload;
        return state;
    }),
    on(JobseekerRegistrationActions.verifyJobseekerEmail, (state) => {
        state.emailVerificationLoading = true;

        return state;
    }),
    on(JobseekerRegistrationActions.verifyJobseekerEmailSuccess, (state) => {
        state.emailVerificationLoading = false;
        state.emailVerificationComplete = true;
        return state;
    }),
    on(JobseekerRegistrationActions.verifyJobseekerEmailFail, (state) => {
        state.emailVerificationLoading = false;
        state.emailVerificationError = true;
        return state;
    }),
    on(JobseekerRegistrationActions.getCvRegistrationPageSeoData, (state) => {
        state.loadingSeoData = true;
        return state;
    }),
    on(JobseekerRegistrationActions.getCvRegistrationPageSeoDataSuccess, (state, { payload }) => {
        state.loadingSeoData = false;
        state.metaData = payload
        return state;
    }),
    on(JobseekerRegistrationActions.getCvRegistrationPageSeoDataFail, (state, { payload }) => {
        state.loadingSeoData = false;
        return state;
    }),
);

export function jobseekerRegistrationReducer(state: JobseekerRegistrationState | undefined, action: Action) {
    return reducer(state, action);
}
