import { AnnouncementDetailsState } from "./state";
import * as AnnouncementDetailsActions from './actions';
import { on, Action} from '@ngrx/store';
import { createImmerReducer } from 'ngrx-immer/store';
import { PageNameEnum } from "src/app/shared/models/enums/page-names-enum";
import * as RootActions from '../../actions';

let initialState: AnnouncementDetailsState = {
    announcementDetails: null,
    currentNvaigationIds: null,
    loading: false,
    loadingNavigationIds: false,
    start: null,
    announcementFailureDetails: null,
    metaData: null,
    bannerList: null,
    bannerListLoading: false,

    previouseAnnouncementId: null,
    isDetailsOpened: false,
    isSimilarAnnouncement: false,
    firstOpenedAnnouncementSimilarIds: null,

    applyLoading: false,
    applyFailureDetails: null,
    viewAnnouncementLoading: false,

    isCvBoxSliderVisible: false
}

const reducer = createImmerReducer(
    initialState,
    on(AnnouncementDetailsActions.loadAnnouncementDetails, state => {
        state.loading = true;
        return state;
    }),
    on(AnnouncementDetailsActions.loadAnnouncementDetailsSuccess, (state, {payload}) => {
        state.announcementDetails = payload.announcement;
        state.metaData = payload.metaData;
        state.loading = false;
        if(state.isDetailsOpened && !state.isSimilarAnnouncement){
            state.firstOpenedAnnouncementSimilarIds = payload.announcement.similarAnnouncements.map(e => {return {announcementId:e.announcementId,announcementTitle:e.title}});
            state.previouseAnnouncementId = state.announcementDetails?.announcementId; //set ann id until the first similar ann is opened..
        }
        if(state.previouseAnnouncementId == payload.announcement.announcementId){
            state.isSimilarAnnouncement = false;
        }
        return state;
    }),
    on(AnnouncementDetailsActions.loadAnnouncementDetailsFail, (state, {payload}) => {
        state.announcementFailureDetails = payload;
        state.loading = false;
        return state;
    }),
    on(AnnouncementDetailsActions.loadNavigationIds, (state, {payload}) => {
        state.loadingNavigationIds = true;
        return state;
    }),
    on(AnnouncementDetailsActions.loadNavigationIdsSuccess, (state, {payload}) => {
        state.start = payload.start;
        state.currentNvaigationIds = payload?.announcementIdsWithTitles;
        // state.loading = false;
        return state;
    }),
    on(AnnouncementDetailsActions.loadNavigationIdsFail, (state, {payload}) => {
        state.loading = false;
        return state;
    }),
    on(RootActions.loadLastListingPage, (state, { payload }) => {
        if(payload == PageNameEnum.AnnouncementDetails){
            state.isDetailsOpened = true;
        }
        else{
            state.isDetailsOpened = false;
            state.isSimilarAnnouncement = false;
            state.previouseAnnouncementId = null;
            state.firstOpenedAnnouncementSimilarIds = null;
        }        
        return state;
    }),
    on(AnnouncementDetailsActions.setIsSimilarAnnouncement, state => {
        state.isSimilarAnnouncement = true;
        return state;
    }),
    on(AnnouncementDetailsActions.getAnnDetailsPageBannerList, (state, { payload }) => {
        state.bannerListLoading = true;
        return state;
    }),
    on(AnnouncementDetailsActions.getAnnDetailsPageBannerListSuccess, (state, { payload }) => {
        state.bannerListLoading = false;
        state.bannerList = payload;
        return state;
    }),
    on(AnnouncementDetailsActions.getAnnDetailsPageBannerListFailed, state => {
        state.bannerListLoading = false;
        return state;
    }),
    on(AnnouncementDetailsActions.apply, (state, {payload}) => {
        state.applyLoading = true;
        return state;
    }),
    on(AnnouncementDetailsActions.applySuccess, (state, {payload}) => {
        state.applyLoading = false;
        state.announcementDetails = payload.announcement.announcement
        return state;
    }),
    on(AnnouncementDetailsActions.applyFailed, (state, {payload}) => {
        state.applyFailureDetails = payload;
        state.applyLoading = false;
        return state;
    }),
    on(AnnouncementDetailsActions.viewAnnouncement, (state, { payload }) => {
        state.viewAnnouncementLoading = true;
        return state;
    }),
    on(AnnouncementDetailsActions.viewAnnouncementSuccess, state => {
        state.viewAnnouncementLoading = false;
        return state;
    }),
    on(AnnouncementDetailsActions.ViewAnnouncementFailed, (state, { payload }) => {
        state.viewAnnouncementLoading = false;
        return state;
    }),
    on(AnnouncementDetailsActions.showCvBoxSlider, (state, { payload }) => {
        state.isCvBoxSliderVisible = payload;
        return state;
    })
);

export function announcementDetailsReducer(state: AnnouncementDetailsState | undefined, action: Action) {
    return reducer(state, action);
}
