<div class="container">
    <div class="item" *ngIf="currentPage!=1" (click)="navigateToPage(currentPage-1)">
        <svg-icon class="chevron chevron--left" src="/assets/icons/chevron-left.svg" [svgClass]="'chevron'"></svg-icon>
    </div>
    <div class="paging-container">
        <div [ngClass]="{'item--current' : item.isCurrent,
                         'item' : true}"
                         *ngFor="let item of pagingNavItems"
                         (click)="navigateToPage(item.page)">
            {{ item.isElipsis ? '...' : item.page}}
        </div>
    </div>
    <div class="item"  *ngIf="currentPage!=pageCount" (click)="navigateToPage(currentPage+1)">
        <svg-icon class="chevron--left" src="assets/icons/chevron-right.svg"  [svgClass]="'chevron'"></svg-icon>
    </div>
</div>