import { Injectable } from '@angular/core';
import { SmartHttpService } from '@hrra/core';
import { UrlConfigService } from '@hrra/core';
import { JobseekerPrivacy } from '../models/jobseeker-privacy-model';
import { UrlConfig, UrlConfigContainer } from 'src/app/core/url-config';
import { BasicOptionItem } from '@hrra/common-models';

@Injectable({
  providedIn: 'root'
})
export class JobseekerSettingsService {

  public getJobseekerPrivacy(){
    return this.smartHttp.get<JobseekerPrivacyViewModel>(this.urlService.urlConfig.api.data.jobseekers.privacy, {});
  }

  public updateJobseekerPrivacy(privacyId: number){
    let data: JobseekerPrivacyViewModel = {
      privacyId: privacyId
    }
    return this.smartHttp.post<JobseekerPrivacyViewModel>(this.urlService.urlConfig.api.data.jobseekers.privacy, data);
  }

  public changeJobseekerPassword(pass: JobseekerChangePasswordViewModel) {
    return this.smartHttp.post<JobseekerPrivacyViewModel>(this.urlService.urlConfig.api.data.jobseekers.changePassword, pass);
  }

  public getJobseekerBlacklist(name: string){
    return this.smartHttp.get<JobseekerBlackListViewModel>(this.urlService.urlConfig.api.data.jobseekers.blacklist, { name });
  }

  public updateJobseekerBlacklist(data: JobseekerBlacklistUpdateViewModel){
    return this.smartHttp.post<JobseekerBlackListViewModel>(this.urlService.urlConfig.api.data.jobseekers.blacklist, data);
  }

  constructor(private smartHttp: SmartHttpService, private urlService: UrlConfigService<UrlConfigContainer>) { }
}


export interface JobseekerPrivacyViewModel{
  privacyId: number;
}

export interface JobseekerChangePasswordViewModel {
  currentPassword: String;
  newPassword: String;
  repeatNewPassword: String;
}

export interface JobseekerBlackListViewModel{
  jobseekerIgnoredCustomerList: BasicOptionItem[];
  customerList: BasicOptionItem[];
}

export interface JobseekerBlacklistUpdateViewModel{
  ignoredCustomerList: number[];
}
