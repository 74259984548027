import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { ViewAnnouncementModel } from '../announcement/shared/services/announcement.service';

@Injectable({
  providedIn: 'root'
})
export class CustomLocalDataStorageService {

  constructor(private storage: LocalStorageService) { }

  public storeFavorites(announcementIds: number[]){
    this.storage.store(CustomDataStorageKey.Favorites, announcementIds);
  }

  public getFavorites(){
    return this.storage.retrieve(CustomDataStorageKey.Favorites);
  }

  public storeAnnouncementViews(announcementViews: ViewAnnouncementModel[]){
    this.storage.store(CustomDataStorageKey.AnnouncementViews, announcementViews);
  }

  public getAnnouncementViews(){
    return this.storage.retrieve(CustomDataStorageKey.AnnouncementViews);
  }
}


export enum CustomDataStorageKey{
  Favorites = 'favorites',
  AnnouncementViews = 'announcementViews'
}