import { createAction, props } from "@ngrx/store";
import { Banner } from "src/app/banner/shared/models/banner-model";
import { ApiError } from "@hrra/core";
import { ActionPayload } from "@hrra/core";

export enum Actions {
    InsertBannerClick = '[banner] Insert banner click',
    InsertBannerClickSuccess = '[banner]  Insert banner click success',
    InsertBannerClickFailed = '[banner]  Insert banner click failed',

    InsertBannerView = '[banner] Insert banner view',
    InsertBannerViewSuccess = '[banner]  Insert banner view success',
    InsertBannerViewFailed = '[banner]  Insert banner view failed',
}


export const insertBannerClick = createAction(
    Actions.InsertBannerClick,
    props<ActionPayload<{ bannerId: number, providerWebsiteId:number, pageId: number, targetId:number}>>()
);
export const insertBannerClickSuccess = createAction(
    Actions.InsertBannerClickSuccess
);
export const insertBannerClickFailed = createAction(
    Actions.InsertBannerClickFailed,
    props<ActionPayload<ApiError>>()
);


export const insertBannerView = createAction(
    Actions.InsertBannerView,
    props<ActionPayload<{ bannerId: number[] }>>()
);
export const insertBannerViewSuccess = createAction(
    Actions.InsertBannerViewSuccess
);
export const insertBannerViewFailed = createAction(
    Actions.InsertBannerViewFailed,
    props<ActionPayload<ApiError>>()
);