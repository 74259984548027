import { Action, createReducer, on, State } from '@ngrx/store';
import { createImmerReducer, immerOn } from 'ngrx-immer/store';
import { SearchState, FilterSectionState } from './state';
import * as Actions from './actions'
//import { LookupState } from '../state';


const tplTop: FilterSectionState = {
    isExpanded: false,
    allItemsVisible: false
};

const tplAll: FilterSectionState = {
    isExpanded: false,
    allItemsVisible: true
};

const initialState: SearchState = {
    filterStatsLoading: false,
    filterStats: undefined,
    matchedAnnouncementCount: 0,
    announcements: [],
    pageLinkCount: 5,
    loading: false,

    filter: {
        localityIds: [],
        categoryIds: [],
        announcementTypeId: undefined,
        publishDateRangeOptionId: 0,
        experienceRangeOptionIds: [], //TODO: remove
        // experienceFilterOptions: undefined,
        experienceRange: {
            from: null,
            to: null
        },
        withoutWorkExperience: false,
        anyExperience: false,
        isWorkFromHome: false,
        query: undefined,
      
        salaryRangeOptionId: 0,
        onlySelectedSalary: false,

        currentPage: 1
    },
    submittedFilter: {
        localityIds: [],
        categoryIds: [],
        announcementTypeId: undefined,
        publishDateRangeOptionId: 0,
        experienceRangeOptionIds: [], //TODO: remove
        // experienceFilterOptions: undefined,
        isWorkFromHome: false,
        experienceRange: {
            from: null,
            to: null
        },
        withoutWorkExperience: undefined,
        anyExperience: undefined,
        query: undefined,
        
        salaryRangeOptionId: 0,
        onlySelectedSalary: false,

        currentPage: 1
    },
    uiState: {
        isExpanded: false,
        location: { ...tplTop},
        category: { ...tplTop, expandedCategoryIds: []},
        announcementType: { ...tplAll},
        publishDate: { ...tplAll},
        experience: { ...tplAll},
        salary: { ...tplAll}
    },

    metaData: null,
    bannerList: null,
    bannerListLoading: false
}




const _searchReducer = createImmerReducer(
    initialState,
    on(Actions.loadFilterStats, (state, action) => {
        state.filterStatsLoading = true;
        return state;
    }),
    on(Actions.loadFilterStatsComplete, (state, { payload }) => {
        state.filterStatsLoading = false;
        state.filterStats = payload;
        return state;
    }),

    //search

    on(Actions.announcementSearch, (state, { payload }) => {
        state.loading = true;
        return state;
    }),

    on(Actions.searchComplete, (state, { payload}) => {
        state.announcements = payload.announcements.items;
        state.matchedAnnouncementCount = payload.announcements.totalCount;
        state.uiState.isExpanded = false;
        state.loading = false;
        state.metaData = payload.metaData;

        return state;
    }),

    on(Actions.searchFail, (state, { payload }) => {
        state.loading = false;

        return state;
    }),
    
    on(Actions.updateSubmittedFilter, state => {
        state.submittedFilter = state.filter;  
        return state;
    }),

    //Locations

    on(Actions.toggleShowAllLocations, (state, action) => {
        //console.log('enter show all reducer');
        state.uiState.location.allItemsVisible = !state.uiState.location.allItemsVisible;
        return state;
    }),

    on(Actions.toggleExpandLocationFilter, (state, action) => {
        state.uiState.location.isExpanded = !state.uiState.location.isExpanded;

        return state;
    }),

    on(Actions.updateLocationFilter, (state, { payload }) => {
        state.filter.localityIds = state.filter.localityIds.filter(c => c != payload.localityId);

        if(payload.checked){
            state.filter.localityIds.push(payload.localityId);
        }

        return state;
    }),

    //Categories

    on(Actions.toggleShowAllCategories, (state, action) => {
        //console.log('enter show all reducer');
        state.uiState.category.allItemsVisible = !state.uiState.category.allItemsVisible;
        return state;
    }),

    on(Actions.toggleExpandCategoryFilter, (state, action) => {
        state.uiState.category.isExpanded = !state.uiState.category.isExpanded;

        return state;
    }),

    on(Actions.toggleExpandHeadCategory, (state, { payload }) => {
        let categoryId = payload.categoryId;
        let expandedCategoryIds = state.uiState.category.expandedCategoryIds;
        
        if(expandedCategoryIds.some(c => c == categoryId)){
            state.uiState.category.expandedCategoryIds = expandedCategoryIds.filter(c => c != categoryId);
        }
        else {
            state.uiState.category.expandedCategoryIds.push(categoryId);
        }

        return state;
    }),

    on(Actions.updateCategoryilter, (state, { payload }) => {
        state.filter.categoryIds = payload.categoryIds;
        // state.filter.categoryIds = state.filter.categoryIds.filter(c => c != payload.categoryId);

        // if(payload.checked){
        //     state.filter.categoryIds.push(payload.categoryId);
        // }

        return state;
    }),

    on(Actions.updateLocalityFilter, (state, {payload}) => {
        state.filter.localityIds = payload.localityIds;
        return state
    }),

    // Work From Home 

    on(Actions.updateWorkFromHomeFilter, (state, {payload}) => {
        state.filter.isWorkFromHome = payload.isWorkFromHome
        return state
    }),

    //Salary

    on(Actions.toggleExpandSalaryFilter, (state, action) => {
        state.uiState.salary.isExpanded = !state.uiState.salary.isExpanded;

        return state;
    }),

    on(Actions.updateSalaryFilter, (state, { payload }) => {
        state.filter.salaryRangeOptionId = payload.salaryRangeOptionId;

        return state;
    }),

    on(Actions.updateOnlySelectedSalaryFilter, (state, { payload }) => {
        state.filter.onlySelectedSalary = payload;

        return state;
    }),

    //Work experience

    on(Actions.toggleExpandExperienceFilter, (state, action) => {
        state.uiState.experience.isExpanded = !state.uiState.experience.isExpanded;

        return state;
    }),

    on(Actions.updateExperienceFilter, (state, { payload }) => {

        if(state.filter.experienceRange !== undefined){
            state.filter.experienceRange.from = payload.experienceFilter.experienceRange.from;
            state.filter.experienceRange.to = payload.experienceFilter.experienceRange.to;
        }

        state.filter.withoutWorkExperience = payload.experienceFilter.withoutWorkExperience;
        state.filter.anyExperience = payload.experienceFilter.anyExperience;

        // state.filter.experienceRangeOptionIds = state.filter.experienceRangeOptionIds.filter(c => c != payload.experienceRangeOptionId);

        // if(payload.checked){
        //     state.filter.experienceRangeOptionIds.push(payload.experienceRangeOptionId);
        // }

        return state;
    }),

    //Announcement type

    on(Actions.toggleExpandAnnouncementTypeFilter, (state, action) => {
        state.uiState.announcementType.isExpanded = !state.uiState.announcementType.isExpanded;

        return state;
    }),

    on(Actions.updateAnnouncementTypeFilter, (state, { payload }) => {
        state.filter.announcementTypeId = payload.announcementTypeId;

        return state;
    }),

    //Publish date

    on(Actions.toggleExpandPublishDateFilter, (state, action) => {
        state.uiState.publishDate.isExpanded = !state.uiState.publishDate.isExpanded;

        return state;
    }),

    on(Actions.updatePublishDateFilter, (state, { payload }) => {
        state.filter.publishDateRangeOptionId = payload.publishDateRangeOptionId;

        return state;
    }),

    //keyword

    on(Actions.updateKeywordFilter, (state, { payload }) => {
        state.filter.query = payload.query;

        return state;
    }),

    //Full filter
    
    on(Actions.updateFilter, (state, { payload }) => {
        state.filter = Object.assign({}, initialState.filter, payload);
        state.submittedFilter = Object.assign({}, initialState.filter, payload);

        return state;
    }),

    on(Actions.resetFilter, (state, action) => {
        state.filter = initialState.filter;
        //uiState ?

        return state;
    }),

    on(Actions.toggleExpandFilter, (state, action) => {
        state.uiState.isExpanded = !state.uiState.isExpanded;
        return state;
    }),

    //bammers
    on(Actions.getSearchPageBannerList, (state, {payload}) => {
        state.bannerListLoading = true;
        return state;
    }),
    on(Actions.getSearchPageBannerListSuccess, (state, {payload}) => {
        state.bannerList = payload;
        state.bannerListLoading = false;
        return state;
    }),
    on(Actions.getSearchPageBannerListFailed, (state, {payload}) => {
        state.bannerListLoading = false;
        return state;
    }),
);


export function announcementReducer(state: SearchState | undefined, action: Action) {
    //console.log('announcement reducer');
    return _searchReducer(state, action);
}
