import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogginService } from './logging-provider';
import { ServerLoggingService } from './server-logging.service';
import { ClientLoggingService } from './client-logging.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class LoggingModule {
  static forServer(): ModuleWithProviders<LoggingModule>{
    return {
      ngModule: LoggingModule,
      providers: [
        { provide: LogginService, useClass: ServerLoggingService}
      ]
    }
  }

  static forBrowser(): ModuleWithProviders<LoggingModule>{
    return {
      ngModule: LoggingModule,
      providers: [
        { provide: LogginService, useClass: ClientLoggingService}
      ]
    }
  }
}
