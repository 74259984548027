import { Injectable } from '@angular/core';
import { SmartHttpService } from '@hrra/core';
import { UrlConfigService } from '@hrra/core';
import { UrlConfig, UrlConfigContainer } from 'src/app/core/url-config';
import { CvBoxApplication } from 'src/app/shared/models/cv-box-application-model';
import { JobseekerOwnApplicationListRecord } from 'src/app/shared/models/jobseeker-application-model';

@Injectable({
  providedIn: 'root'
})
export class JobseekerAppliesService {
  public getApplicationList(page: number, pageSize: number){
    return this.smartHttp.get<ApplicationViewModel>(this.urlService.urlConfig.api.data.jobseekers.applications, {page: page, pageSize: pageSize});
  }

  public getCvBoxApplicationList(page: number, pageSize: number){
    return this.smartHttp.get<CvBoxApplicationViewModel>(this.urlService.urlConfig.api.data.jobseekers.cvBoxApplications, {page: page, pageSize: pageSize});
  }

  constructor(private smartHttp: SmartHttpService, private urlService: UrlConfigService<UrlConfigContainer>) { }
}


export interface ApplicationViewModel{
  applications: JobseekerOwnApplicationListRecord[];
  pageSize: number;
  totalRecords: number;
} 

export interface CvBoxApplicationViewModel{
  applications: CvBoxApplication[];
  pageSize: number;
  totalRecords: number;
} 