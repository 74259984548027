<div class="container">
    <div *ngFor="let location of locations;  let last = last;">
        <!-- <div class="content" [ngStyle]="{'margin-bottom': !last ? '0.5rem' : '0px'}"> -->
        <div class="content">
            <div class="location-icon">
                <svg-icon class="icon" [src]="location.isWithInCompleteLocation ? '/assets/icons/location-hidden.svg' : '/assets/icons/location.svg'"></svg-icon>
            </div>
    
            <div class="location-items">
                <ng-container *ngFor="let item of location.announcementExtendedLocationParts, let last=last">
                    <span class="locaion-item">{{item.name}}{{last ? '' : ', '}} </span>
                </ng-container>
            </div>
        </div>
    </div>
</div>

