import { Injectable } from '@angular/core';

declare var FB: any;

@Injectable({
  providedIn: 'root'
})
export class FacebookService {

  isParsed = false;
  timeoutHandle: any;

  init(version='v12.0'){
    FB.init({
      //appId            : appId,
      autoLogAppEvents : true,
      xfbml            : false,
      version          : version
    });
  }

  parse(){
    if(!this.isParsed){
      FB.XFBML.parse();
      this.isParsed = true;
      return true;
    }

    return false;
  }

  showCustomerChatDialog(){

    let delay = this.parse();

    if(delay){
      this.timeoutHandle = setTimeout(() => FB.CustomerChat.show(), 5000);
    }
    else {
      FB.CustomerChat.show(true);
    }

  }

  hideCustomerChat(){
    FB.CustomerChat.hide();
    if(this.timeoutHandle != null){
      clearTimeout(this.timeoutHandle);
      this.timeoutHandle = null;
    }
  }

  constructor() { }
}
