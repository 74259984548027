import { Action, on } from '@ngrx/store';
import { createImmerReducer } from 'ngrx-immer/store';
import { CategoryPickerState } from "./state";
import * as Actions from './actions';


let initialState =  <CategoryPickerState> {
    expandedCategoryIds: [],
    query: null,
    selectedCategoryIds: []
};

const reducer = createImmerReducer(initialState,
    on(Actions.toggleExpandHeadCategory, (state, { payload }) => {
        let categoryId = payload.categoryId;
        let expandedCategoryIds = state.expandedCategoryIds;
        
        if(expandedCategoryIds.some(c => c == categoryId)){
            state.expandedCategoryIds = expandedCategoryIds.filter(c => c != categoryId);
        }
        else {
            state.expandedCategoryIds.push(categoryId);
        }

        return state;
    }),

    on(Actions.selectCategory, (state, { payload }) => {
        state.selectedCategoryIds = state.selectedCategoryIds.filter(c => c != payload.categoryId);
        state.selectedCategoryIds.push(payload.categoryId);

        return state;
    }),

    on(Actions.unselectCategory, (state, { payload }) => {
        state.selectedCategoryIds = state.selectedCategoryIds.filter(c => c != payload.categoryId);

        return state;
    }),

    on(Actions.removeCategory, (state, { payload }) => {
        state.selectedCategoryIds = state.selectedCategoryIds.filter(c => c != payload.categoryId);

        return state;
    }),

    on(Actions.filterCategories, (state, { payload }) => {
        state.query = payload.query;

        return state;
    }),

    on(Actions.resetCategoryFilter, (state, _) => {
        state.query = '';

        return state;
    }),

    on(Actions.loadCategories, (state, { payload }) => {
        state.selectedCategoryIds = payload.categoryIds;

        return state;
    })
);


export function categoryPickerReducer(state: CategoryPickerState | undefined, action: Action){
    return reducer(state, action);
}