export enum AnnouncementModerationAttributeEnum {
  CustomerName = 1,
  Type = 2,
  Title = 3,
  Title2 = 4,
  Description = 5,
  Description2 = 6,
  IsAnonymous = 7,
  IsWithLogo = 8,
  OccupationCategory = 9,
  ContactPerson = 10,
  HideContactPerson = 11,
  EmploymentForm = 12,
  BusinessTravel = 13,
  DurationFrom = 14,
  DurationTo = 15,
  SalaryFrom = 16,
  SalaryTo = 17,
  HideSalary = 18,
  RequiredLanguage = 19,
  WorkExperienceFrom = 20,
  WorkExperienceTo = 21,
  EducationLevel = 22,
  DrivingLicence = 23,
  Logo_Main = 24,
  Logo_Thumbnail1 = 25,
  Attachment = 26,
  ApplicationMethod = 27,
  Application_Email = 28,
  Application_IsEmailSameAsContactPerson = 29,
  Application_PhoneNumber = 30,
  Application_ApplicationUrl = 31,
  Application_ApplicationAddress = 32,
  PublishDate = 33,
  DeadlineDate = 34,
  IsWithBonus = 35,
  IsSuitableForStudent = 36,
  IsWorkFromHome = 37,
  Bonus = 38,
  WorkExperienceType = 39,
  ExtendedLocations = 40,
  Address_City = 41,
}

export enum JobseekerModerationAttributeEnum {
  Name = 1,
  Surname = 2,
  Email = 3,
  PhoneNumber = 4,
  PhoneNumber2 = 5,
  Gender = 6,
  DateOfBirth = 7,
  Locality = 8,
  ExtendedLocationId = 9,
  Address = 10,
  LegalAddress = 12,
  DrivingLicense = 13,
  IsVehicleOwner = 14,
  VehicleType = 15,
  Photo = 16,
  WorkExperience = 17,
  Education = 18,
  Language = 19,
  Attachment = 20,
  IsWorkExperienceFilled = 21,
}

export enum CustomerModerationAttributeEnum {
  Name = 1,
  Name2 = 2,
  Logo = 3,
  LogoThumbnail = 4,
  Industry = 5,
  NumberOfEmployees = 6,
  City = 7,
  Website = 8,
  PublicEmail = 9,
  Description = 10,
}

export enum BrandingModerationAttributeEnum {
  AnnouncementDetailsDesktopBgImage = 1,
  AnnouncementDetailsMobileBgImage = 2,
  AnnouncementDetailsBgColor = 3,
  AnnouncementTileColor = 4,
  CustomerDetailsBgColor = 5,
  CoverImage = 6,
}
