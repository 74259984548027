<form [formGroup]="salaryForm" #ngForm="ngForm" class="form">
    <div class="header">{{'label.SalaryRange' | translate}}</div>
    
    <p-dropdown
        #dropdown
        [(ngModel)]="selectedSalaryOption"
        [dataKey]="'key'"
        [optionValue]="'key'"
        [styleClass]="'salaryOptionDropdown'"
        [options]="salaryOptions" 
        formControlName="salaryRangeOption" 
        [panelStyleClass]="'dropdown-panel'"
        optionLabel="name" 
        [showClear]="selectedSalaryOption != 0"
        (onChange)="onChangeSalaryRangeOption($event.value)"
        [placeholder]="''"
        [appendTo]="'body'">
        <ng-template let-item pTemplate="item">
            <span class="dropdown-item">{{ item.name }}</span>
        </ng-template>
        <ng-template pTemplate="dropdownicon">
            <svg-icon src="/assets/icons/arrow-down.svg" [svgStyle]="{'display':'flex', 'justify-content':'center', 'align-items':'center'}"></svg-icon>
        </ng-template>
    </p-dropdown>

    <div class="checkbox-container">
        <input (change)="onOnlySelectedSalary()" formControlName="onlySelectedSalary" type="checkbox">
        <label class="label">{{'label.OnlyWithTheIndicatedSalary' | translate}}</label>
        <svg-icon title="{{'text.SalaryFilterInfo' | translate}}" [src]="'assets/icons/info.svg'" ></svg-icon>
    </div>
</form>