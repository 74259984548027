import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { SmartHttpService } from '@hrra/core';
import { UrlConfigService } from '@hrra/core';
import { TranslitService } from 'src/app/shared/services/translit.service';
import { CustomerListViewModel } from '../models/customer-list-view-model';
import { Customer } from '../models/customer-model';
import { UrlConfig, UrlConfigContainer } from 'src/app/core/url-config';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  public getCustomer(id: number){
    return this.smartHttp.get<Customer>(this.urlService.format(this.urlService.urlConfig.api.data.customer.get, {id: id}), {})
    .pipe(map(c => {
      c.data.announcementList.forEach(a => {
        a.urlFriendlyTitle = this.translit.getUrlFriendlyString(a.title);
        a.urlFriendlyCustomerName = this.translit.getUrlFriendlyString(a.customerName);
      });

      return c;
    }));
  }

  public getCustomerList(page: number, pageSize: number){
    return this.smartHttp.get<CustomerListViewModel>(this.urlService.urlConfig.api.data.customer.getList, {page: page, pageSize: pageSize})
    .pipe(map(c => {
      c.data.companies.forEach(a => {
        a.urlFriendlyCustomerName = this.translit.getUrlFriendlyString(a.name);
      })

      return c;
    }));
  }

  public applyCvBoxFromCustomer(id: number) {
    return this.smartHttp.post<Customer>(this.urlService.urlConfig.api.data.customer.cvBoxApply, id);
  }

  constructor(private smartHttp: SmartHttpService, private urlService: UrlConfigService<UrlConfigContainer>, private translit: TranslitService) { }
}
