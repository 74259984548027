import { Attribute, Component, Input, OnInit } from '@angular/core';
import { SsrService } from 'src/app/core/ssr.service';
import { ErrorTypeEnum } from '@hrra/ui';

@Component({
  selector: 'app-not-existing-page',
  templateUrl: './not-existing-page.component.html',
  styleUrls: ['./not-existing-page.component.scss']
})
export class NotExistingPageComponent implements OnInit {


  @Input() hideDefaultMessage: boolean;
  @Input() errorType: ErrorTypeEnum;

  constructor(private ssr: SsrService) {
    this.errorType = ErrorTypeEnum.NotFound;
  }

  ngOnInit(): void {
    if(this.errorType == ErrorTypeEnum.NotFound)
    {
      this.ssr.setStatusNotFound();
    }
    else if(this.errorType == ErrorTypeEnum.NotAvailable){
      this.ssr.setStatusNotAvailable();
    }
  }
  
}
