import { Injectable } from '@angular/core';
import { User } from '@hrra/common-models';
import { LocalDataStorageService } from '@hrra/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserData } from 'src/app/core/auth-result';
import { AuthService } from 'src/app/core/auth.service';
import { selectUser } from 'src/app/root-store/selectors';
import { AppState } from 'src/app/root-store/state';
import { v4 as uuid} from 'uuid'
@Injectable({
  providedIn: 'root',
})
export class UniqueGuidService {
  public currentUser$: Observable<User> = this.store.select(selectUser);

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private storage: LocalDataStorageService
  ) {}

  generateUniqueGuid(): void {

    let uniqueGuide = uuid()

    this.storage.storeAnonymousGuid(uniqueGuide)

  }

  getUniqueGuid(): any {
    return this.storage.getAnonymousGuid()
  }
}
