import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AnnouncementService,
  ViewAnnouncementModel,
} from 'src/app/announcement/shared/services/announcement.service';
import * as sharedAnnouncementService from 'src/app/shared/services/announcement.service';
import * as AnnouncementDetailsActions from './actions';
import {
  mergeMap,
  catchError,
  map,
  tap,
  withLatestFrom,
  filter,
  switchMap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { TranslitService } from 'src/app/shared/services/translit.service';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../state';
import { SsrService } from 'src/app/core/ssr.service';
import { selectAnnViewCountIgnorePeriod, selectCurrentWebsite } from '../../selectors';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { BannerService } from 'src/app/banner/shared/services/banner.service';
import { AuthService } from 'src/app/core/auth.service';
import { AnnouncementViewEvent } from 'src/app/announcement/shared/models/announcement-details-model';

@Injectable()
export class AnnouncementDetailsEffects{

    loadAnnouncementDetails$ = createEffect(() => this.actions$.pipe(
        ofType(AnnouncementDetailsActions.loadAnnouncementDetails),
        mergeMap(action => this.announcementService.getAnnouncement(action.payload.announcementId)
        .pipe(
            map(response => AnnouncementDetailsActions.loadAnnouncementDetailsSuccess({payload: response.data})),
            tap(d => {
                if(!d.payload.announcement.expired && (action.payload.urlTitle == null || action.payload.urlTitle != this.translit.getUrlFriendlyString(d.payload.announcement.title))){

                    if(action.payload.nav != null){
                        this.router.navigate(['/announcement', d.payload.announcement.announcementId, this.translit.getUrlFriendlyString(d.payload.announcement.title)], { queryParams: { nav: action.payload.nav }});
                    }
                    else {
                        this.router.navigate(['/announcement', d.payload.announcement.announcementId, this.translit.getUrlFriendlyString(d.payload.announcement.title)]);
                    }
                }
            }),
            filter(
              (d) =>
                !(
                  !d.payload.announcement.expired &&
                  (action.payload.urlTitle == null ||
                    action.payload.urlTitle !=
                      this.translit.getUrlFriendlyString(
                        d.payload.announcement.title
                      ))
                )
            ),
            catchError((response) =>
              of(
                AnnouncementDetailsActions.loadAnnouncementDetailsFail({
                  payload: response.error,
                })
              )
            )
          )
      )
    )
  );

    loadAnnouncementDetailsSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AnnouncementDetailsActions.loadAnnouncementDetailsSuccess),
        filter(d  => this.ssrService.isPlatformBrowser()),
        switchMap(action => this.store.select(selectCurrentWebsite).pipe(
          filter((providerId) => providerId != null),
          map(providerId => ({ action, providerId }))
      )),
        map(({action,providerId}) => AnnouncementDetailsActions.sendAnnouncementViewToAnalytic({payload: {announcement: action.payload.announcement, providerWebsiteId:providerId.providerWebsiteId}}))
    ));

    sendAnnouncementViewToAnalytic$ = createEffect(() => this.actions$.pipe(
      ofType(AnnouncementDetailsActions.sendAnnouncementViewToAnalytic),
      mergeMap(action => of(action).pipe(
        tap((x: any) => {
          let announcementAnalytic: AnnouncementViewEvent = {
            announcementId: x.payload.announcement.announcementId,
            announcementStatId: x.payload.announcement.announcementStatId,
            announcementRenewalStatId: x.payload.announcement.announcementRenewalStatId,
            providerWebsiteId: x.payload.providerWebsiteId,
            userId: this.authService.getUserId(),
            isAnonymous: this.authService.isSignedIn(),
            isUnique: this.announcementService.isAnnouncementViewCountable(x.payload.announcement.announcementId)
          }
          return this.announcementService.sendAnnouncementViewToAnalytic(announcementAnalytic)
        }),
        map((ann:any) => AnnouncementDetailsActions.viewAnnouncement({payload: {announcementId: ann.payload.announcement.announcementId, isUniqueView: this.announcementService.isAnnouncementViewCountable(ann.payload.announcement.announcementId)}}),
        catchError(response => of(AnnouncementDetailsActions.sendAnnouncementViewToAnalyticFail({payload: response.error})))
      ))
      
    )     
  ))

  viewAnnouncement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnnouncementDetailsActions.viewAnnouncement),
      mergeMap((action) => 
        this.announcementService.announcementView(action.payload).pipe(
          map(() => AnnouncementDetailsActions.viewAnnouncementSuccess()),
          withLatestFrom(
            this.store.pipe(select(selectAnnViewCountIgnorePeriod))
          ),
          tap(([d, ignorePeriod]) => {
            var currentDate = new Date();
            let data: ViewAnnouncementModel = {
              announcementId: action.payload.announcementId,
              expireDate: new Date(
                currentDate.getTime() + ignorePeriod * 60000
              ),
            };
            this.announcementService.addAnnouncementView(data);
          }),
          map(([d, ignorePeriod]) => d),
          catchError((response) =>
            of(
              AnnouncementDetailsActions.ViewAnnouncementFailed({
                payload: response.error,
              })
            )
          )
        )
      )
    )
  );

  searchAnnouncements$ = createEffect(() =>
    this.actions$.pipe(
        ofType(AnnouncementDetailsActions.loadNavigationIds),
        mergeMap(action => this.sharedAnnouncementService.searchAnnouncements(action.payload.filter)
        .pipe(
            map(response => AnnouncementDetailsActions.loadNavigationIdsSuccess({ payload: {announcementIdsWithTitles: response.data.announcements.items.map(e => {return {announcementId:e.announcementId,announcementTitle:e.title}}), start: action.payload.start} })),
            catchError(response => of(AnnouncementDetailsActions.loadNavigationIdsFail({ payload: response.error })))
        ))
    ));

  getAnnDetailsPageBannerList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnnouncementDetailsActions.getAnnDetailsPageBannerList),
      mergeMap((action) =>
        this.bannerService
          .getBannerList(action.payload.targetId, action.payload.bannerHeadId)
          .pipe(
            map((response) =>
              AnnouncementDetailsActions.getAnnDetailsPageBannerListSuccess({
                payload: response.data,
              })
            ),
            catchError((error) =>
              of(
                AnnouncementDetailsActions.getAnnDetailsPageBannerListFailed({
                  payload: error.error,
                })
              )
            )
          )
      )
    )
  );
  apply$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnnouncementDetailsActions.apply),
      mergeMap((action) =>
        this.announcementService.apply(action.payload.announcementId).pipe(
          map((response) =>
            AnnouncementDetailsActions.applySuccess({
              payload: { announcement: response.data },
            })
          ),
          catchError((response) =>
            of(
              AnnouncementDetailsActions.applyFailed({
                payload: response.error,
              })
            )
          )
        )
      )
    )
  );

  applyFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AnnouncementDetailsActions.applyFailed),
        tap((d) => {
          this.dialogService.getApplyFailedDialog(d.payload.errorCode);
        })
      ),
    { dispatch: false }
  );


    constructor(private actions$: Actions, 
                private announcementService: AnnouncementService, 
                private sharedAnnouncementService: sharedAnnouncementService.AnnouncementService, 
                private translit: TranslitService, 
                private router: Router,
                private bannerService: BannerService,
                private store: Store<AppState>,
                private ssrService: SsrService,
                private dialogService: DialogService,
                private authService: AuthService,
                private translate: TranslateService){}
}
