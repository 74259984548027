import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TreeNode } from 'primeng/api';
import { TreeSelect } from 'primeng/treeselect';
import { LanguageService } from '../shared/services/language.service';

@Component({
  selector: 'hrra-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TreeSelectComponent
    },
]
})
export class TreeSelectComponent implements OnInit, ControlValueAccessor {
	onChange = (value) => {};
  	onTouched = () => {};

  
  	@ViewChild('treeSelect')
  	treeSelect!: TreeSelect;

  	@Input() treeNodes: any;

	@Input() isLarge!: boolean;

	public onStartUpdateTree: boolean = true;

	@Input() 
	get selectedNodes(): any {return this._selectedNodes;}
	set selectedNodes(values: any[]) {
		if(this.onStartUpdateTree && values.length > 0){
			//console.log("setting value: %o", values);
			this.onStartUpdateTree = !this.onStartUpdateTree;
			setTimeout(() => {
				this._selectedNodes = values;
				this.updateTree(values);
			}, 0);
		}else if(values.length == 0){
			//console.log("setting empty value: %o", values);
				this.onStartUpdateTree  = false;
				setTimeout(() => {
					this._selectedNodes = values;
					this.updateTree(values);
				}, 0);
			}
	}
	
	private _selectedNodes: number[] = [];
	

	@Input("NotFound") notFoundTextMessage: string = 'label.NotFound';
	@Input("PlaceholderText") placeHolderTextMessage: string = 'label.SelectItem';
	@Input("SelectItemCountText") selectedItemCountText: string = 'label.SelectItemCount'
	@Input('ScrollHeight') scrollHeight = '15rem';


	@Output("updateTreeValue") updateTreeSelectValue: EventEmitter<number[]>;

  	public itemCount: number = 0;
	public isDropdownIconDown: boolean;
  	public hideAfterUnSelect: boolean;

	public treeSelectControl : FormControl = new FormControl([]);
	
	ngOnInit(): void {}

	closeDropdown(){
		this.treeSelect.overlayVisible = false;
		this.isDropdownIconDown = true;
		this.treeSelect.cd.markForCheck();
	}

  	constructor() {
		this.updateTreeSelectValue = new EventEmitter<number[]>();
    	this.isDropdownIconDown = true;
    	this.hideAfterUnSelect = false;
  	}

	updateTree(values: any[]){
		this.writeValue(values);
		this.updatePartialSelections(this.treeNodes, [], values.map((c:number) => c.toString()));
	}

	writeValue(nodeIds: number[]): void {
		const treeDictionary = this.getTreeDictionary(this.treeNodes);
		let selectedTreeNodes: any = [];
				
		nodeIds.forEach((id: any) => {
			if(id in treeDictionary){
				selectedTreeNodes.push(treeDictionary[id]);

			}
		});
		this.treeSelectControl.patchValue(selectedTreeNodes);
		this.updateItemCount(false);
	}

	updatePartialSelections(tree: TreeNode[], checkedNodes: TreeNode[], keys: string[]){
		let count = tree.length;
		for (const node of tree) {
			node.expanded = false;
			if (keys.includes(node.key) || checkedNodes.includes(node.parent)) {
				checkedNodes.push(node);	
				count--;
		  	}
		  	if (node.children){
				this.updatePartialSelections(node.children, checkedNodes, keys);
			}
		}
		if (tree.length > 0 && tree[0].parent) {
			tree[0].parent.partialSelected = (count > 0 && count != tree.length);
		}
	}
	

	getTreeDictionary(tree: any){
		const treeDictionary : { [index: number]: any } = {};
		
		let visited = new Set<string>();
		let queue = [];
		
		for(let i = 0 ; i < tree.length ; i++){
			queue.push(tree[i]);
		}

		while(queue.length > 0){
			let node = queue[0];
			visited.add(node.key);
			queue.shift();

			treeDictionary[node.key] = node;

			if(node.children == null) continue;

			node.children.forEach((child: any) => {
				if(!visited.has(child.key)){
					queue.push(child);
				}
			});
		}
		return treeDictionary;

	}
	
	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}
	  
	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}
	
	setDisabledState?(isDisabled: boolean): void {}

  	countLeaves(nodeObject: any, emitEvent: boolean = true): number {
    	let treeLeaves = 0;
		let selectedNodeIds: any = [];
		
		if (nodeObject == null) { 
			if(emitEvent) { 
				this.updateTreeSelectValue.emit(selectedNodeIds);
			}
			return treeLeaves;
		}
    	else { Object.keys(nodeObject).forEach((key) => {
      		const item = nodeObject[key];
      		if (item.children === null) {
				selectedNodeIds.push(item.key);
        		treeLeaves += 1;
      		}
    	})};

		if(emitEvent){
			this.updateTreeSelectValue.emit(selectedNodeIds);
		}
		
		this.onChange(selectedNodeIds);

    	return treeLeaves;
  	}
 
   	updateItemCount(emitEvent = true) {
		this.itemCount = this.countLeaves(this.treeSelectControl.value, emitEvent);
   	}
 
   	onClear() {
     	this.updateItemCount();
   	}
 
   	onReset() {
		this.treeSelectControl.reset();
    	this.updateItemCount();
   	}
 
   	onApprove() {
     	this.treeSelect.overlayVisible = false;
     	this.isDropdownIconDown = !this.isDropdownIconDown;
   	}
 
   	onSelectNode(event: any){
		this.updateItemCount();

   	}
 
   	onUnSelectNode(event: any){
     	this.hideAfterUnSelect = true;
     	this.updateItemCount();
   	}
 
   	onHide(event: any) {
     	if (event === undefined && this.hideAfterUnSelect) {
       		this.treeSelect.overlayVisible = true;
       		this.hideAfterUnSelect = !this.hideAfterUnSelect;
     	}else{
       		this.isDropdownIconDown = !this.isDropdownIconDown;
     	}
   	}
 
   	onShow(event: any){
     	this.isDropdownIconDown = !this.isDropdownIconDown;
   	}
}