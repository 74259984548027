import { HRRAExceptionCode, ResetPasswordViewModel } from "@hrra/common-models";
import { createSelector } from '@ngrx/store';
import { selectJobseekerStoreState } from '../jobseeker-profile/selectors';

export const selectUserRegistrationNotFound = createSelector(
    selectJobseekerStoreState,
    (e) => {
      return e.resetPassword.resetPasswordFailureDetails?.errorCode == HRRAExceptionCode.UserRegistrationNotFound;
    }
);

export const selectIsResetSuccessed = createSelector(
    selectJobseekerStoreState,
    (e) => {
      return e.resetPassword.isResetSuccessed;
    }
);

export const selectPasswordResetViewModel = createSelector(
  selectJobseekerStoreState,
  e => {
    let result: ResetPasswordViewModel = <any>{};

    result.email = e.resetPassword.resetPasswordModel?.email;
    result.reCaptchaToken = e.resetPassword.resetPasswordModel?.reCaptchaToken;
    result.forceReload = true;

    return result;
  }
);