<div class="container">

    <!-- <app-layout-header></app-layout-header> -->
    <app-header></app-header>
    
    <div class="content">
        <router-outlet></router-outlet>
    </div>

    <app-layout-footer></app-layout-footer>
</div>
