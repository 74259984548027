<form [formGroup]="workFromHomeForm" #ngForm="ngForm" class="form">
  <div class="checkbox-container">
    <input
      (change)="onWorkFromHomeChange()"
      formControlName="workFromHome"
      type="checkbox"
    />
    <label class="label">{{ 'label.WorkFromHome' | translate }}</label>
    <div class="svgClass">
        <svg-icon src="/assets/icons/home.svg" [svgStyle]="{ 'height.rem':0.9 ,'margin-left.rem':0.62,'fill':'#FF6500'  }"></svg-icon>
    </div>
  </div>
</form>
