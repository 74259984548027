import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { catchError, debounceTime, map, mergeMap, tap } from 'rxjs/operators';
import { JobseekerSettingsService } from 'src/app/jobseeker/shared/services/jobseeker-settings.service';
import * as JobseekerSettingsActions from './actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { displaySnackBarMessage } from 'src/app/root-store/actions';
import { createErrorMessage, createSuccessMessage, CustomSnackbarService } from '@hrra/ui';
import { of } from 'rxjs';


@Injectable()
export class JobseekerSettingsEffects{

    getJobseekerPrivacy$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerSettingsActions.loadJobseekerPrivacy),
        mergeMap(() => this.jobseekerSettingsService.getJobseekerPrivacy()
        .pipe(
            map(response => JobseekerSettingsActions.loadJobseekerPrivacySuccess({payload: response.data})),
            catchError(response => of(JobseekerSettingsActions.loadJobseekerPrivacyFail({payload: response.error})))
        ))
    ));

    updateJobseekerPrivacy$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerSettingsActions.updateJobseekerPrivacy),
        mergeMap((action) => this.jobseekerSettingsService.updateJobseekerPrivacy(action.payload.privacyId)
        .pipe(
            map(response => JobseekerSettingsActions.updateJobseekerPrivacySuccess({payload: response.data})),
            catchError(response => of(JobseekerSettingsActions.updateJobseekerPrivacyFail({payload: response.error})))
        ))
    ));

    updateJobseekerPrivacySuccess$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerSettingsActions.updateJobseekerPrivacySuccess),
        tap(() => this.snackBarService.open(this.translate.instant('message.SaveMessage'), null, { duration: 2000, panelClass: ['snackbar-success']})
        )
    ), { dispatch: false });

  changeJobseekerPassword$ = createEffect(() => this.actions$.pipe(
    ofType(JobseekerSettingsActions.changeJobseekerPassword),
    mergeMap((action) => this.jobseekerSettingsService.changeJobseekerPassword(action.payload)
      .pipe(
        map(() => JobseekerSettingsActions.changeJobseekerPasswordSuccess()),
        catchError(response => of(JobseekerSettingsActions.changeJobseekerPasswordFail({ payload: response.error })))
      ))
  ));

  changeJobseekerPasswordSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(JobseekerSettingsActions.changeJobseekerPasswordSuccess),
    tap(() => this.customSnackBarService.openSnackbar(createSuccessMessage())
    )
  ), { dispatch: false });

  loadJobseekerBlacklist$ = createEffect(() => this.actions$.pipe(
    ofType(JobseekerSettingsActions.loadJobseekerBlacklist),
    debounceTime(500),
    mergeMap((action) => this.jobseekerSettingsService.getJobseekerBlacklist(action.payload.name)
      .pipe(
        map(response => JobseekerSettingsActions.loadJobseekerBlackListSuccess({ payload: response.data })),
        catchError(response => of(JobseekerSettingsActions.loadJobseekerBlacklistFail({ payload: response.error })))
      ))
  ));

  updateJobseekerBlacklist$ = createEffect(() => this.actions$.pipe(
    ofType(JobseekerSettingsActions.updateJobseekerBlacklist),
    mergeMap((action) => this.jobseekerSettingsService.updateJobseekerBlacklist(action.payload)
      .pipe(
        map(action => JobseekerSettingsActions.updateJobseekerBlacklistSuccess({payload: action.data})),
        catchError(response => of(JobseekerSettingsActions.updateJobseekerBlacklistFail({ payload: response.error })))
      ))
   ));

  updateJobseekerBlacklistSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(JobseekerSettingsActions.updateJobseekerBlacklistSuccess),
    tap(() => this.store.dispatch(displaySnackBarMessage({payload: createSuccessMessage()})))
      ),
    { dispatch: false }
  );

  updateJobseekerBlacklistFail$ = createEffect(() => this.actions$.pipe(
    ofType(JobseekerSettingsActions.updateJobseekerBlacklistFail),
    tap(() => this.store.dispatch(displaySnackBarMessage({payload: createErrorMessage()})))
      ),
    { dispatch: false }
  );

    constructor(private store: Store<AppState>, 
      private actions$: Actions, 
      private jobseekerSettingsService: JobseekerSettingsService, 
      private snackBarService: MatSnackBar, 
      private customSnackBarService: CustomSnackbarService,
      private translate: TranslateService){}
}