<!-- <mat-radio-group (change)="onChangeAnnuncementTypeFilter($event)" [ngModel]="(salaryFilter$ | async).announcementTypeId">
    <div *ngFor="let annType of announcementTypeOptions$ | async" style="margin-top: 0.625rem;">
        <mat-radio-button [value]="annType.announcementTypeId">{{annType.name}}</mat-radio-button>
    </div>
</mat-radio-group> -->
<div *ngIf="salaryFilter$ | async as data" class="container">
    <p-dropdown [options]="announcementTypeOptions$ | async"
    [dataKey]="data.announcementTypeId"
    [ngModel]="data.announcementTypeId"
    (onChange)="onChangeAnnuncementTypeFilter($event.value.announcementTypeId)"
    [styleClass]="'ann-type-dropdown'"
    [panelStyleClass]="'dropdown-panel'"
    optionLabel="name"
    [showClear]="false"
    [appendTo]="'body'"
    autoWidth="false">
    <ng-template let-item pTemplate="item">
        <span class="dropdown-item">{{ item.name }}</span>
    </ng-template>
    <ng-template pTemplate="dropdownicon">
        <div class="dropdwon-icon">
            <svg-icon src="/assets/icons/arrow-down.svg" [svgClass]="'arrow-class'"></svg-icon>
        </div>
    </ng-template>
</p-dropdown>
</div>
