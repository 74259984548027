import { HRRAExceptionCode } from "@hrra/common-models";
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { displayPages } from 'src/app/shared/helpers/paging-helper';
import { selectCvBoxCustomerMap } from '../announcement-store/selectors';
import { selectCurrentWebsiteNameForSeo, selectCustomersNumberOfItemsOnPage, selectFavoriteAnnouncementsMap } from '../selectors';
import { CustomerStoreState } from './state';
import { Announcement } from "src/app/announcement/shared/models/announcement-model";

export const getCustomerState = createFeatureSelector<CustomerStoreState>('customer');

export const selectCustomer = createSelector(
    getCustomerState,
    (state: CustomerStoreState) => state.customer
);

export const selectIsCustomerLoading = createSelector(
    getCustomerState,
    c => c.loading || c.cvBoxApplyLoading
);

export const selectIsCustomerLoaded = createSelector(
  getCustomerState,
  c => c.isCustomerLoaded
);

export const selectCustomerMetaData = createSelector(
    getCustomerState,
    selectCurrentWebsiteNameForSeo,
    (c, w) => {
        return {
            website: w,
            metaData: c.customer?.metaData
        };
    }
);

export const selectCustomerAnnouncements = createSelector(
    getCustomerState,
    selectFavoriteAnnouncementsMap,
    selectCvBoxCustomerMap,
    (c, fav, cm) => {
        let announcements = c.customer?.announcementList.map(c => Object.assign({}, c, { isFavorite: fav[c.announcementId] === true, isWithCvBox: cm[c.customerId] === true }));
     
        return announcements;
    }
);

export const isCustomerDescriptionLong = createSelector(
    getCustomerState,
    c => c.customer?.description?.length > 500
);

export const isWithFullDescription = createSelector(
    getCustomerState,
    c => c.isWithFullDescription
);

export const selectCustomerDescription = createSelector(
    getCustomerState,
    isCustomerDescriptionLong,
    isWithFullDescription,
    (c, ld, fd) => {
        if(c.customer?.description == null) return '';

        if(ld && !fd){
            return c.customer.description.substring(0, Math.min(500, c.customer.description.length)) + "...";
        }
        else{
            return c.customer.description;
        }
    }
);

export const selectCompanies = createSelector(
    getCustomerState,
    c => c.customerListPageState.customerList?.companies
);

export const selectCustomerListPageCount = createSelector(
  getCustomerState,
  selectCustomersNumberOfItemsOnPage,
  (c, p) => {
    let result = Math.max(
      1,
      Math.ceil(c.customerListPageState.customerList?.totalRecords / p)
    );
  
    return result;
  });

  export const selectCustomerCount = createSelector(
    getCustomerState,
    c => c.customerListPageState.customerList?.totalRecords
  );

  export const selectCurrentPage = createSelector(
    getCustomerState,
    c => c.customerListPageState.currentPage
  );

  export const selectDisplayPages = createSelector(
    getCustomerState,
    selectCustomerListPageCount,
    (s, pageCount) => {

      let current = s.customerListPageState.currentPage;
      let pageLinkCount = s.customerListPageState.pageLinkCount;

      return displayPages(current, pageLinkCount, pageCount);
    }
  );

export const selectCustomerListPageMetaData = createSelector(
  getCustomerState,
  selectCurrentWebsiteNameForSeo,
  (c, w) => {
    return {
        website: w,
        metaData: c.customerListPageState.customerList?.metaData
    };
});

export const selectCustomerListPageIsLoading = createSelector(
  getCustomerState,
  c => c.customerListPageState.loading
);

export const selectCustomerPageDoesNotExist = createSelector(
  getCustomerState,
  c => c.customerPageFailureDetails?.errorCode == HRRAExceptionCode.CustomerDoesNotExist || c.customerPageFailureDetails?.errorCode == HRRAExceptionCode.CustomerIsNotActive
);
