<div *ngIf="{initialData: initialData$ | async,
             locations: locations$ | async,
             publishDateOptions: publishDateOptions$ | async,
             todayPublishDateOption: todayPublishDateOption$ | async,
             headCategories: headCategories$ | async,
             customerPortalUrl: customerPortalUrl$ | async,
             categoryTree: categoryTree$ | async,
             localityTree: localityTree$ | async} as data">

  <form [formGroup]="searchForm" class="container" *ngIf="data.categoryTree.length > 0">
    <ul class="ann-type-list">
      <li (click)="onAnnouncementType(announcementTypes.Vacancy)" class="ann-type-list__item">
        <span class="ann-type-list__hashtag">#</span>
        <span class="ann-type-list__text">{{ "label.Vacancies" | translate }}</span>
      </li>
      <li (click)="onAnnouncementType(announcementTypes.Training)" class="ann-type-list__item">
        <span class="ann-type-list__hashtag">#</span>
        <span class="ann-type-list__text">{{ "label.Trainings" | translate }}</span>
      </li>
      <li (click)="onAnnouncementType(announcementTypes.Tender)" class="ann-type-list__item">
        <span class="ann-type-list__hashtag">#</span>
        <span class="ann-type-list__text">{{ "label.Tenders" | translate }}</span>
      </li>
      <li (click)="onTodayFilter(data.todayPublishDateOption, data.publishDateOptions)" class="list__item-today--desktop">
        <span>{{ "label.Today" | translate }} </span>
        <span class="list__item-today--count">{{ data.initialData?.todayAnnouncementCount }}</span>
      </li>
    </ul>
    <ul class="list product-count-list">
      <li class="list__item product-count-list__item product-count-list__item--vacancy">
        <a class="product-count-list__link" [routerLink]="['./search-posting']">
          <span>{{ data.initialData?.announcementCount }} </span>
          <span>{{ "label.Announcement" | translate: { count: data.initialData?.vacancyCount } }}</span>
        </a>
      </li>
      <li class="list__item product-count-list__item product-count-list__item--company">
        <a class="product-count-list__link" [routerLink]="['/companies']">
          <span>{{ data.initialData?.companyCount }} </span>
          <span>{{ "label.Company" | translate: { count: data.initialData?.companyCount }  }}</span>
        </a>
      </li>
      <li class="list__item product-count-list__item  product-count-list__item--cv">
        <a class="product-count-list__link" href="{{ data.customerPortalUrl }}" target="_blank">
          <span>{{ data.initialData?.cvCount }} </span>
          <span>{{ "label.CV" | translate }}</span>
        </a>
      </li>
    </ul>
  
    <ul class="list list--news">
      <!--TODO: uncomment when 'new' faeature is done-->
      <!-- <li class="list__item list__item--new">
        <span>{{ "label.New" | translate }} </span>
        <span>({{ data.initialData?.newAnnouncementCount }})</span>
      </li> -->
      <li (click)="onTodayFilter(data.todayPublishDateOption, data.publishDateOptions)" class="list__item list__item--today">
        <span>{{ "label.Today" | translate }} </span>
        <span class="list__item--today-count">{{ data.initialData?.todayAnnouncementCount }}</span>
      </li>
    </ul>

    <div class="search-field">
      <input id="lbl-search" type="text" class="search-field__input" formControlName="searchKeyword" placeholder="({{ 'label.AnnouncementSearchExampleText' | translate }})"/>
      <label for="lbl-search" class="search-field__label">
          <svg-icon src="/assets/icons/search.svg" [svgClass]="'search-field__icon'"></svg-icon>
      </label>
    </div>

    <div class="categories">
      <!--<mat-form-field floatLabel="never" class="dropdown__mat-form-field">
        <mat-label>{{ 'label.Categories' | translate }}</mat-label>
        <mat-select disableOptionCentering [formControl]="categories" multiple panelClass="search-panel-multiple-select">
          <mat-select-trigger class="dropdown__trigger">
              {{ 'label.SelectedCategoryCount' | translate: { count: categories.value?.length } }}
          </mat-select-trigger>
          <mat-option *ngFor="let headCategory of data.headCategories" [value]="headCategory">
            {{ headCategory.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>-->
      <hrra-tree-select 
        [treeNodes]="data.categoryTree" 
        [PlaceholderText]="'label.Categories'"
        [isLarge]="false"
        [SelectItemCountText]="'label.SelectedCategoryCount'"
        [formControl]="categories">
      </hrra-tree-select>
    </div>

 
  
    <div class="localities">
      <!--<mat-form-field floatLabel="never" class="dropdown__mat-form-field">
        <mat-label>{{ 'label.City' | translate }}</mat-label>
        <mat-select disableOptionCentering [formControl]="locations" multiple panelClass="search-panel-multiple-select">
          <mat-select-trigger class="dropdown__trigger">
            {{ 'label.SelectedCityCount' | translate: { count: locations.value?.length } }}
          </mat-select-trigger>
          <mat-option *ngFor="let location of data.locations" [value]="location">
            {{ location.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>-->

      <hrra-location-tree-select
        [PlaceholderText]="'label.Location'"
        [isLarge]="false"
        [SelectItemCountText]="'label.SelectedCityCount'"
        [formControl]="locations">
      </hrra-location-tree-select>

    </div>

    <div class="search-btn">
      <button class="search-btn__item" (click)="onSearchButtonClick()">
        <span>{{ "label.Search" | translate }} </span>
        <span class="search-btn__item--mobile">({{ data.initialData?.announcementCount }} {{ "label.Announcement" | translate: { count: data.initialData?.vacancyCount } }})</span> 
      </button>
    </div>

    <div class="search-advanced">
      <a [routerLink]="['./search-posting']" class="search-advanced__link">
        {{ "label.AdvancedSearch" | translate }}
      </a>
    </div>
  </form>
</div>
