import { Injectable } from '@angular/core';
import { CustomLocalDataStorageService } from 'src/app/core/local-data-storage.service';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {

  public getFavorites(): number[] {
    return this.storage.getFavorites() ?? [];
  }

  public addToFavorites(announcementId: number){
    let announcementIds = this.getFavorites();

    let newAnnouncementIds = [ ... announcementIds.filter(p => p != announcementId), announcementId ];

    this.storage.storeFavorites(newAnnouncementIds);

    return newAnnouncementIds;
  }

  public removeFromFavorites(announcementId: number){
    let announcementIds = this.getFavorites();

    let newAnnouncementIds = announcementIds.filter(p => p != announcementId);

    this.storage.storeFavorites(newAnnouncementIds);

    return newAnnouncementIds;
  }

  public refreshFavorites(announcementIds: number[]){
    this.storage.storeFavorites(announcementIds);
  }

  constructor(private storage: CustomLocalDataStorageService) { }
}
