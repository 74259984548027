import { Component, OnInit } from '@angular/core';
import { theme } from 'src/environments/theme';
import { AppState } from '../root-store/state';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LookupService } from '../shared/services/lookup.service';
import { ProviderWebsite } from '../shared/models/provider-website';
import { SystemConfig } from '../shared/models/system-config-model';
import { selectCurrentLanguage, selectCurrentWebsite, selectCurrentWebsiteDisplayName, selectCustomerPortalUrl, selectPartnerSitesForFooter, selectSystemConfigs } from 'src/app/root-store/selectors';
import { PartnerSitesModel } from '../shared/models/partner-sites-mode';

@Component({
  selector: 'app-layout-footer',

  templateUrl: `./${theme.name}/layout-footer.component.html`,
  styleUrls: [`./${theme.name}/layout-footer.component.scss`]
})
export class LayoutFooterComponent implements OnInit {

  date = Date.now();

  //TODO: Enum
  facebookLinkKey: string;
  linkedLinkKey: string;


  partnerSitesForFooter$: Observable<PartnerSitesModel[]>;
  website$: Observable<ProviderWebsite>;
  language$: Observable<string>;
  systemConfigs$: Observable<SystemConfig>;
  websiteDisplayName$: Observable<string>;
  customerPortalUrl$: Observable<string>;
  currentLanguage$: Observable<string>;

  constructor(private store: Store<AppState>, private service: LookupService){
   }

  ngOnInit(): void {
    this.partnerSitesForFooter$ = this.store.pipe(select(selectPartnerSitesForFooter));
    this.website$ = this.store.pipe(select(selectCurrentWebsite));
    this.language$ = this.store.pipe(select(selectCurrentLanguage));
    this.systemConfigs$ = this.store.pipe(select(selectSystemConfigs));
    this.websiteDisplayName$ = this.store.pipe(select(selectCurrentWebsiteDisplayName));
    this.customerPortalUrl$ = this.store.pipe(select(selectCustomerPortalUrl));
    this.currentLanguage$ = this.store.pipe(select(selectCurrentLanguage));
  }
}