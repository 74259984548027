<header class="header">
    <nav class="navigation">
        <ul class="navigation-list">
            <li class="list-item logo">
                <a *ngIf="!isActive('/')" class="logo__link" [routerLink]="['/']">
                    <img class="logo__img" src="assets/img/common/logo.svg" alt="" />
                </a>
                <a *ngIf="isActive('/')" class="logo__link" [href]="'/'">
                    <img class="logo__img" src="assets/img/common/logo.svg" alt="" />
                </a>
            </li>

            <li *ngIf="!isBurgerMenuOpen" class="sign-in sign-in--small-size">
                <a [routerLink]="['/jobseeker/sign-in']">{{ 'label.SignIn' | translate }}</a>
            </li>

            <ul class="header-items header-items--small-size">
                <li class="favorites favorites--mobile">
                    <a [routerLink]="['/announcement/favorites']">
                        <div class="favorites--mobile__text">
                            <img class="favorites--mobile__icon" src="assets/img/common/heart-icon.svg" alt="" />
                            <div *ngIf="(favCount$ | async) > 0" class="favorites--mobile__count">{{ favCount$ | async }}</div>
                        </div>
                    </a>
                </li>
                <li class="search">
                    <a [routerLink]="['/search-posting']">
                        <img class="search__icon" src="assets/img/common/search-icon.svg" alt="" />
                    </a>
                </li>
                <li class="burger-menu">
                    <button class="burger-menu__btn" (click)="toggleBurgerMenu()">
                        <img class="burger-menu__icon" src="assets/img/header/burger-menu-icon.svg" alt="" />
                    </button>
                </li>
            </ul>

            <ul [ngClass]="{'burger-menu--opened': isBurgerMenuOpen}" class="header-items basic-list" appClickOutside (clickOutside)="closeDropdown()" menuClass="menu">
                <li class="basic-list__item favorites favorites--desktop">
                    <a class="favorites--desktop__link" [routerLink]="['/announcement/favorites']" menuNavItem>
                        <img class="favorites--desktop__icon" src="assets/img/common/heart-icon.svg" alt="" />
                        <div class="favorites--desktop__text">
                            <div>{{ 'label.Favorites' | translate }}</div>
                            <div *ngIf="(favCount$ | async) > 0" class="favorites--desktop__count">{{ favCount$ | async }}</div>
                        </div>
                    </a>
                </li>
                <li class="basic-list__item cv-box">
                    <a class="cv-box__link" [routerLink]="['/cvbox']" menuNavItem>
                        <img class="cv-box__icon" src="assets/img/cv-box/cv-box-icon.svg" alt="" />
                        <div class="cv-box__text">{{ 'label.CvBoxes' | translate }}</div>
                    </a>
                </li>
                <li class="basic-list__item subscription">
                    <a class="subscription__link" [routerLink]="['/subscriber/subscription']" menuNavItem>
                        <img class="subscription__icon" src="assets/img/header/subscription-icon.svg" alt="" />
                        <div class="subscription__text">{{ 'label.Subscribe' | translate }}</div>
                    </a>
                </li>
                <li class="basic-list__item registration">
                    <a [routerLink]="['/jobseeker/register']" menuNavItem>
                        <div class="registration__text">{{ 'label.CvRegistration' | translate }}</div>
                    </a>
                </li>
                <li class="basic-list__item sign-in--basic">
                    <a [routerLink]="['/jobseeker/sign-in']" menuNavItem>
                        <div class="sign-in__text">{{ 'label.SignIn' | translate }}</div>
                    </a>
                </li>
                <li class="basic-list__item">
                    <a href="{{(customerPortalUrl$ | async)}}/dashboard" target="_blank" menuNavItem>
                        <div class="customer__text">{{ 'label.ForEmployers' | translate }}</div>
                    </a>
                </li>
                <li class="basic-list__item language">
                    <div (click)="toggleLanguage()" class="language-icon" *ngIf="defaultLanguage !== null && alternativeLanguage !== null">
                        <button class="language-icon__item" [ngClass]="{'active': defaultLanguage == currentLanguage}">{{ ('label.lang-short-'+ defaultLanguage) | translate }}</button>
                        <button class="language-icon__item" [ngClass]="{'active': alternativeLanguage == currentLanguage}">{{ ('label.lang-short-'+ alternativeLanguage) | translate }}</button>
                    </div>
                </li>
            </ul>
        </ul>
    </nav>
</header>