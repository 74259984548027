<ng-container *ngIf="{
    systemConfig: systemConfigs$ | async,
    website: website$ | async,
    partnerSitesForFooter: partnerSitesForFooter$ | async,
    websiteDisplayName: websiteDisplayName$ | async,
    customerPortalUrl: customerPortalUrl$ | async
} as data">
    <footer class="footer">
        <div class="container">
            <div class="info-list info-list--general">
                <img class="logo" src="assets/img/footer/logo.svg" alt="">
                <p class="copyright">© {{(website$ | async)?.launchYear}} - {{date | date: 'yyy'}}. {{(website$ | async)?.displayName}}</p>
                <div id="top-ge" class="top-ge"></div>
            </div>
            <div class="info-list info-list--jobseekers">
                <p class="info-list__title">
                    {{'label.FooterJobseekersTitle' | translate}}
                </p> 
                <ul>
                    <li class="info-list__item">
                        <a [routerLink]="['./jobseeker/register']">
                            {{'label.CvRegistration' | translate}}
                        </a>
                    </li>
                    <li class="info-list__item">
                        <a [routerLink]="['./info/','frequently-asked-questions-'+data.websiteDisplayName]">
                            {{'label.FrequentlyAskedQuestions' | translate}}
                        </a>
                    </li>
                    <li class="info-list__item">
                        <a [routerLink]="['/companies']">
                            {{'label.Companies' | translate}}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="info-list info-list--companies">
                <p class="info-list__title">{{'label.FooterCompaniesTitle' | translate}}:</p>
                <ul>
                    <li class="info-list__item">
                        <a href="{{data.customerPortalUrl}}/customer" target="_blank">
                            {{'label.AddAnnouncement' | translate}}
                        </a>
                    </li>
                    <li class="info-list__item">
                        <a href="{{data.customerPortalUrl}}/register/customer" target="_blank">
                            {{'label.CompanyRegistration' | translate}}
                        </a>
                    </li>
                    <li class="info-list__item">
                        <a [routerLink]="['./info/','services-'+data.websiteDisplayName]">
                            {{'label.ProductsAndPrices' | translate}}
                        </a>
                    </li>
                    <li class="info-list__item">
                        <a [routerLink]="['./info/','outsource-'+data.websiteDisplayName]">
                            {{'label.Outsourcing' | translate}}/{{'label.Outstaffing' | translate}}
                        </a>
                    </li>
                    <li class="info-list__item">
                        <a href="./assets/documents/WEB_Standard_Service_Terms_{{ currentLanguage$ | async }}.pdf" target="_blank">
                            {{'label.StandardServiceTerms' | translate}}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="info-list info-list--info">
                <p class="info-list__title">{{'label.FooterInformationTitle' | translate}}:</p>
                <ul>
                    <li class="info-list__item">
                        <a [routerLink]="['./info/','contact-us-'+data.websiteDisplayName]">
                            {{'label.ContactUsNow' | translate}}
                        </a>
                    </li>
                    <li class="info-list__item">
                        <a [routerLink]="['./info/','about-'+data.websiteDisplayName]">
                            {{'label.AboutOurCompany' | translate}}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="social-media">
                <a href="{{data.systemConfig?.common.linkedInLink}}" target="_blank">
                    <img class="social-media__logo" src="assets/img/footer/social-media-in.svg" alt="">
                </a>
                <a href="{{data.systemConfig?.common.facebookLink}}" target="_blank">
                    <img class="social-media__logo" src="assets/img/footer/social-media-fb.svg" alt="">
                </a>
                <a href="{{data.systemConfig?.common.instagramLink}}" target="_blank">
                    <img class="social-media__logo" src="assets/img/footer/social-media-inst.svg" alt="">
                </a>
                <a href="{{data.systemConfig?.common.twitterLink}}" target="_blank">
                    <img class="social-media__logo" src="assets/img/footer/social-media-twitter.svg" alt="">
                </a>
            </div>
            <div class="partner-sites">
                <div *ngFor="let item of data.partnerSitesForFooter" class="partner-sites__item">
                    <a [href]="item.link" target="_blank">{{item.name}}</a>
                </div>
            </div>
        </div>
    </footer>
</ng-container>