import { createAction, props } from '@ngrx/store';
import { Banner } from 'src/app/banner/shared/models/banner-model';
import { ApiError } from '@hrra/core';
import { CvBoxPageViewModel } from 'src/app/cv-box/shared/services/cv-box.service';
import { ActionPayload } from "@hrra/core";

export enum CvBoxPageActionsTypes{
    loadCvBoxes = '[Cv-box page] Load cv-boxes',
    loadCvBoxesSuccess = '[Cv-box page] Load cv-boxes success',
    loadCvBoxesFail = '[Cv-box page] Load cv-boxes fail',

    SearchCvBox = '[Cv-box page] Search cv-box',

    GetCvBoxPageBannerList = '[Cv-box page] Get cv box banner list',
    GetCvBoxPageBannerListSuccess = '[Cv-box page] Get cv box banner list success',
    GetCvBoxPageBannerListFailed = '[Cv-box page] Get cv box banner list faled',
}


export const loadCvBoxes = createAction(
   CvBoxPageActionsTypes.loadCvBoxes
 );
 
 export const loadCvBoxesSuccess = createAction(
   CvBoxPageActionsTypes.loadCvBoxesSuccess,
   props<ActionPayload<CvBoxPageViewModel>>()
   );
 
 export const loadCvBoxesFail = createAction(
   CvBoxPageActionsTypes.loadCvBoxesFail,
   props<ActionPayload<ApiError>>()
   );

 export const SearchCvBox = createAction(
   CvBoxPageActionsTypes.SearchCvBox,
   props<ActionPayload<string>>()
 );

 //banners
export const getCvBoxPageBannerList = createAction(
    CvBoxPageActionsTypes.GetCvBoxPageBannerList,
    props<ActionPayload<{ targetId: number, bannerHeadId: number, }>>()
);
export const getCvBoxPageBannerListSuccess = createAction(
    CvBoxPageActionsTypes.GetCvBoxPageBannerListSuccess,
    props<ActionPayload<Banner[]>>()
);
export const getCvBoxPageBannerListFailed = createAction(
    CvBoxPageActionsTypes.GetCvBoxPageBannerListFailed,
    props<ActionPayload<ApiError>>()
);
