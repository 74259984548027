import { Params } from "@angular/router";
import { OptionItem } from "@hrra/common-models";
import { Range } from '@hrra/core';
import { AnnouncementFilter } from "src/app/shared/models/announcement-filter";

export function convertFilterToQueryParams(filter: AnnouncementFilter, salaryOptions: OptionItem<Range<number>>[], publishDateOptions: OptionItem<Range<number>>[]): Params{
    let result = <Params> {};
  
    if(filter.query !== undefined){
      result[AnnouncementFilterQueryParams.Query] = filter.query;
    }
  
    if(filter.announcementTypeId !== undefined){
      result[AnnouncementFilterQueryParams.AnnouncementType] = filter.announcementTypeId;
    }
  
    if(filter.localityIds !== undefined && filter.localityIds.length>0){
      result[AnnouncementFilterQueryParams.Locality] = JSON.stringify(filter.localityIds);
    }
  
    if(filter.categoryIds !== undefined && filter.categoryIds.length>0){
      result[AnnouncementFilterQueryParams.Categories] = JSON.stringify(filter.categoryIds);
    }
  
    if(filter.salaryRangeOptionId !== 0 && salaryOptions[filter.salaryRangeOptionId].value !== undefined){
      result[AnnouncementFilterQueryParams.Salary] = filter.salaryRangeOptionId;
    }
  
    if(filter.publishDateRangeOptionId !== 0 && publishDateOptions[filter.publishDateRangeOptionId].value !== undefined){
      result[AnnouncementFilterQueryParams.PublishDate] = filter.publishDateRangeOptionId;
    }
  
    if(filter.experienceRangeOptionIds !== undefined && filter.experienceRangeOptionIds.length > 0){
      result[AnnouncementFilterQueryParams.Experience] = JSON.stringify(filter.experienceRangeOptionIds);
    }
  
    if(filter.currentPage !== undefined){
      result[AnnouncementFilterQueryParams.Page] = filter.currentPage;
    }
  
    result['cc'] = Math.floor(Math.random()*1000);
  
    return result;
}


export enum AnnouncementFilterQueryParams{
    AnnouncementType='t',
    Categories='c',
    Experience='e',
    Locality='l',
    PublishDate='p',
    Query='q',
    Salary='s',
    Page='pg'
  }