import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SharedModule } from '../shared/shared.module';
import { TestDashboardComponent } from './test-dashboard/test-dashboard.component';
import { JobseekerErrorMessageComponent } from './jobseeker-error-message/jobseeker-error-message.component';
import { NotExistingPageComponent } from './not-existing-page/not-existing-page.component';
import { UiModule } from '@hrra/ui';
import { RouterModule } from '@angular/router';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';

@NgModule({
  declarations: [
    TestDashboardComponent, JobseekerErrorMessageComponent,
    NotExistingPageComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AngularSvgIconModule,
    UiModule,
    RouterModule,
    MatDialogModule,
  ],
  exports: [
    JobseekerErrorMessageComponent,
    NotExistingPageComponent
  ]
})
export class HrUiModule { }
