import { createAction, props } from '@ngrx/store';
import { ApiError } from '@hrra/core';
import { CvBox } from 'src/app/cv-box/shared/models/cv-box-model';
import { CvBoxPageViewModel } from 'src/app/cv-box/shared/services/cv-box.service';
import { ActionPayload } from "@hrra/core";

export enum FrontPageCvBoxActionsTypes{
    loadFrontPageCvBoxes = '[Front page] Load cv-boxes',
    loadFrontPageCvBoxesSuccess = '[Front page] Load cv-boxes success',
    loadFrontPageCvBoxesFail = '[Front page] Load cv-boxes fail',
}


export const loadFrontPageCvBoxes = createAction(
   FrontPageCvBoxActionsTypes.loadFrontPageCvBoxes
 );
 
 export const loadFrontPageCvBoxesSuccess = createAction(
   FrontPageCvBoxActionsTypes.loadFrontPageCvBoxesSuccess,
   props<ActionPayload<CvBoxPageViewModel>>()
 );
 
 export const loadFrontPageCvBoxesFail = createAction(
   FrontPageCvBoxActionsTypes.loadFrontPageCvBoxesFail,
   props<ActionPayload<ApiError>>()
 );
