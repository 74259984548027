import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { changeLanguage, logout } from 'src/app/root-store/actions';
import { selectFavoriteAnnouncementsCount } from 'src/app/root-store/selectors';
import { AppState } from 'src/app/root-store/state';
import { User } from 'src/app/user/shared/models/user-model';

@Component({
  selector: 'app-header-jobseeker',
  templateUrl: './header-jobseeker.component.html',
  styleUrls: ['./header-jobseeker.component.scss']
})
export class HeaderJobseekerComponent implements OnInit {

  @Input() isSignedIn: boolean;
  @Input() user: User;
  @Input() currentLanguage: string;
  @Input() alternativeLanguage: string;
  @Input() defaultLanguage: string

  
  isBurgerMenuOpen = false;
  isProfileOpen = false;

  favCount$: Observable<number>;

  public isActive(url: string){
    return this.router.isActive(url, { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'});
  }

  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit(): void {
    this.favCount$ = this.store.pipe(select(selectFavoriteAnnouncementsCount));
  }

  closeDropdown(){
    if(this.isBurgerMenuOpen){}
    this.isBurgerMenuOpen = false;
    
    if(this.isProfileOpen)
    this.isProfileOpen = false;
 }

  toggleLanguage() {
    let lang = this.currentLanguage == this.defaultLanguage ? this.alternativeLanguage : this.defaultLanguage;
    this.store.dispatch(changeLanguage({ payload: { languageId: lang}}));
  }

  toggleBurgerMenu() {
    this.isBurgerMenuOpen = !this.isBurgerMenuOpen;

    if(this.isBurgerMenuOpen){
      this.isProfileOpen = true;
    }

    else{
      this.isProfileOpen = false;
    }
  }

  toggleProfile() {
    this.isProfileOpen = !this.isProfileOpen;
  }

  onSignOutClick() {
    this.store.dispatch(logout());
  }
}