import { Component, Inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, CurrentInfoState } from 'src/app/root-store/state';
import {
  AnalyticViewBanner,
  Banner,
} from 'src/app/banner/shared/models/banner-model';
import { BannerPositionEnum } from 'src/app/banner/shared/models/enums/banner-position-enum';
import { BannerTargetEnum } from 'src/app/banner/shared/models/enums/banner-target-enum';
import { BannerTypeEnum } from 'src/app/banner/shared/models/enums/banner-type-enum';
import { insertBannerClick } from 'src/app/root-store/banner-store/actions';
import { Observable } from 'rxjs';
import { ProviderWebsite } from '@hrra/common-models';
import {
  ProviderWebsiteUrlMap,
  selectCurrentWebsite,
} from 'src/app/root-store/selectors';
import { AuthService } from 'src/app/core/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalDataStorageService } from '@hrra/core';
import { BannerService } from '../shared/services/banner.service';
import { BannerHeaderPositionId } from 'src/app/shared/models/enums/banner-header-position-id-enum';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  @Input() banner: Banner;
  @Input() pageId: BannerHeaderPositionId;

  providerWebsite$: Observable<ProviderWebsite>;

  bannerType: any;
  targetId: any;
  position: any;
  userId: string;

  constructor(
    private store: Store<AppState>,
    private bannerService: BannerService
  ) {
    this.bannerType = BannerTypeEnum;
    this.targetId = BannerTargetEnum;
    this.position = BannerPositionEnum;
  }

  insertBannerClick(bannerId: number, providerWebsiteId: number, targetId:number) {
    this.store.dispatch(
      insertBannerClick({
        payload: { bannerId: bannerId, targetId:targetId, providerWebsiteId: providerWebsiteId, pageId:this.pageId },
      })
    );
  }

  ngOnInit(): void {
    this.providerWebsite$ = this.store.select(selectCurrentWebsite);
  }
}
