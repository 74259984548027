import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from 'src/app/root-store/state';
import { AnnouncementStoreState } from '../../state';
import { selectAnnouncementState } from '../../search-page/selectors';


export const selectSearchFieldStateData = createSelector(
  selectAnnouncementState,
  (s) => s.searchFieldState.websiteStats
);
