import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {

  @Output() clickOutside = new EventEmitter<void>();
  @Input() menuClass: string;

  constructor(private elementRef: ElementRef) { }

  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    let element = this.elementRef.nativeElement;
    let clickedInside = element.contains(target);

    let attributes: any = Array.from(element.querySelectorAll('[menuNavItem]'));
    let isClickedOnRouterLink = attributes.some((e) => e.contains(target));

    let className = target.className.baseVal != undefined ? target.className.baseVal : target.className;
    
    if((clickedInside && isClickedOnRouterLink) || (!clickedInside && !className.includes(this.menuClass))){
      this.clickOutside.emit();
     }
  }
}
