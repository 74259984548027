<div *ngIf="{ locationTree: locationTree$ | async, itemCount: itemCount$ | async, overlayVisible: overlayVisible$ | async, loading: loading$ | async} as data">
    <p-treeSelect
        #treeSelect
        [containerStyleClass]="isLarge ? 'tree-select-searchPage': 'tree-select'"
        [formControl]="treeSelectControl"
        [options]="data.locationTree"
        [metaKeySelection]="true"
        selectionMode="checkbox"
        placeholder="Select Item"
        [scrollHeight]="scrollHeight"
        [filter]="true"
        [showClear]="true"
        [emptyMessage]="notFoundTextMessage | translate"
        [resetFilterOnHide]="true"
        [filterInputAutoFocus]="false"
        
        (onShow)="onShow($event)"
        (onHide)="onHide($event)"
        (onNodeSelect)="onSelectNode($event)"
        (onNodeUnselect)="onUnSelectNode($event)"
        
        [appendTo]="'body'">

        <!-- (onNodeSelect)="onSelectNode($event)"
        (onNodeUnselect)="onUnSelectNode($event)"
        (onClear)="onClear()"
        (onHide)="onHide($event)"
        (onShow)="onShow($event)" -->
    
        <ng-template pTemplate="triggericon">
            <span class="pi pi-angle-up" *ngIf="data.overlayVisible"></span>
            <span class="pi pi-angle-down" *ngIf="!data.overlayVisible"></span>
        </ng-template>

        <ng-template pTemplate="itemloadingicon">
            <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
        </ng-template>
        <ng-template pTemplate="empty">
            <div *ngIf="data.loading" style="text-align: center; height: 7rem;" >
                <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
            </div>
        </ng-template>

        <ng-template pTemplate="itemtogglericon" let-open>
            <svg-icon *ngIf="open" src="/assets/icons/arrow-up.svg" class="arrow-icon"></svg-icon>
            <svg-icon *ngIf="!open" src="/assets/icons/arrow-down.svg" class="arrow-icon"></svg-icon>
        </ng-template>

        <ng-template pTemplate="closeicon">
            <svg-icon src="/assets/icons/close.svg" class="close-icon"></svg-icon>
        </ng-template>

        <ng-template pTemplate="filtericon">
            <svg-icon src="/assets/icons/search-small.svg" class="search-icon"></svg-icon>
        </ng-template>

        <ng-template pTemplate="value" let-node>
            <span *ngIf="data.itemCount == 0" [ngClass]="isLarge ? 'tree-select-value-searchPage tree-select-value-searchPage__grey':'tree-select-value tree-select-value__grey'">{{ placeHolderTextMessage | translate }}</span>
            <span *ngIf="data.itemCount > 0" [ngClass]="isLarge ? 'tree-select-value-searchPage tree-select-value-searchPage__primary-green': 'tree-select-value tree-select-value__primary-green'">{{ selectedItemCountText | translate : {count: data.itemCount} }}</span>
        </ng-template>

        <ng-template pTemplate="footer">
            <div class="footer">
                <button class="footer__reset-button" (click)="onReset()">{{ 'label.ClearFilter' | translate }}</button>
                <button class="footer__approve-button" (click)="onApprove()">{{ 'label.Approve' | translate }}</button>
            </div>
        </ng-template>
    </p-treeSelect>
</div>