import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CvBoxItemComponent } from './cv-box-item/cv-box-item.component';
import { CvBoxPageComponent } from './cv-box-page/cv-box-page.component';
import { SharedModule } from '../shared/shared.module';
import { CvBoxRoutingModule } from './cv-box-routing.module';
import { FormsModule } from '@angular/forms';
import { HrUiModule } from '../hr-ui/hr-ui.module';
import { UiModule } from '@hrra/ui';
import { BannerModule } from '../banner/banner.module';



@NgModule({
  declarations: [CvBoxItemComponent, CvBoxPageComponent],
  imports: [
    CommonModule,
    SharedModule,
    CvBoxRoutingModule,
    FormsModule,
    HrUiModule,
    UiModule,
    BannerModule
  ],
  exports: [
    CvBoxItemComponent, CvBoxPageComponent
  ]
})
export class CvBoxModule { }
