import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { CustomerService } from 'src/app/customer/shared/services/customer.service';
import * as customerPublicPageActions from './public-page-actions';
import { mergeMap, catchError, map, exhaustMap, tap, withLatestFrom, filter, take } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { TranslitService } from 'src/app/shared/services/translit.service';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../state';
import { selectCustomersNumberOfItemsOnPage } from '../selectors';

@Injectable()
export class CustomerEffects{

    loadCustomer$ = createEffect(() => this.actions$.pipe(
        ofType(customerPublicPageActions.loadCustomer),
        mergeMap(action => this.customerService.getCustomer(action.payload.customerId)
            .pipe(
                map(customer => customerPublicPageActions.loadCustomerSuccess({payload: customer.data})),
                tap(d => {
                    if(action.payload.urlTitle == null || action.payload.urlTitle != this.translit.getUrlFriendlyString(d.payload.name)){
                       this.router.navigate(['/customer', d.payload.customerId, this.translit.getUrlFriendlyString(d.payload.name)]);
                    }
                }),
                catchError(response => of(customerPublicPageActions.loadCustomerFail({payload: response.error})))
            ))
    ));


    loadCustomerList$ = createEffect(() => 
    combineLatest(
    [
        this.actions$.pipe(
            ofType(customerPublicPageActions.loadCustomerList)
        ),
        this.store.pipe(
            select(selectCustomersNumberOfItemsOnPage), 
            filter(c => c !== undefined),
            take(1)
        )
    ]).pipe(
        mergeMap(([action, pageSize]) => this.customerService.getCustomerList(action.payload.page, pageSize)
          .pipe(
              map(action => customerPublicPageActions.loadCustomerListSuccess({payload: action.data})),
              catchError(response => of(customerPublicPageActions.loadCustomerListFail({payload: response.error})))
          ))
        )
    );

    cvBoxApply$ = createEffect(() => this.actions$.pipe(
        ofType(customerPublicPageActions.cvBoxApply),
        mergeMap(action => this.customerService.applyCvBoxFromCustomer(action.payload.customerId)
        .pipe(
            map(response => customerPublicPageActions.cvBoxApplySuccess({payload: { customer: response.data }})),
            catchError(response => of(customerPublicPageActions.cvBoxApplyFailed({payload: response.error})))
        ))
    ));

    applyFailed$ = createEffect(() => this.actions$.pipe(
        ofType(customerPublicPageActions.cvBoxApplyFailed),
        tap(d => {
            this.dialogService.getApplyFailedDialog(d.payload.errorCode);
        })
    ), { dispatch: false })

    constructor(private actions$: Actions, 
                private customerService: CustomerService, 
                private translit: TranslitService,
                private router: Router,
                private dialogService: DialogService,
                private translate: TranslateService,
                private store: Store<AppState>){}
}