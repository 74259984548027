import { Injectable } from '@angular/core';
import { AnnouncementFilter, AnnouncementFullFilter } from '../models/announcement-filter';
import { QueryParams } from '../models/query-params';
import { FilterStats } from 'src/app/root-store/announcement-store/search-page/state';
import { Observable, of } from 'rxjs';
import { AppState, LookupState } from 'src/app/root-store/state';
import { Params, ParamMap } from '@angular/router';
import { SmartHttpService } from '@hrra/core';
import { UrlConfigService } from '@hrra/core';
import { Announcement } from 'src/app/announcement/shared/models/announcement-model';
import { ApiResponse } from '@hrra/core';
import { map } from 'rxjs/operators';
import { TranslitService } from './translit.service';
import { MetaDataModel } from '../models/meta-model';
import { UrlConfig, UrlConfigContainer } from 'src/app/core/url-config';
import { Banner } from '../../banner/shared/models/banner-model';
import { announcementDeadlineTodayHelper, announcementPublishTodayHelper } from '../helpers/announcement-publish-date-helper';
import { OptionItem } from '@hrra/common-models';
import { PagingWrapper, Range } from '@hrra/core';

let mockFilterStats: FilterStats = {
  location: {
    1: 20,
    2: 30,
    3: 0,
    4: 10,
    5: 1,
    6: 3,
    7: 0
  },
  category: {
    1: 2,
    2: 1,
    3: 1,
    4: 10,
    5: 10,
    6: 0
  }
};


// let mockSearchResults: PagingWrapper<Announcement> = {
//   items: [
//     {
//       announcementId: 1,
//       title: 'ann 1'
//     },
//     {
//       announcementId: 2,
//       title: 'ann 1'
//     },
//     {
//       announcementId: 3,
//       title: 'ann 1'
//     },
//     {
//       announcementId: 4,
//       title: 'ann 1'
//     },
//     {
//       announcementId: 1,
//       title: 'ann 1'
//     },
//     {
//       announcementId: 2,
//       title: 'ann 1'
//     },
//     {
//       announcementId: 3,
//       title: 'ann 1'
//     },
//     {
//       announcementId: 4,
//       title: 'ann 1'
//     },
//     {
//       announcementId: 1,
//       title: 'ann 1'
//     },
//     {
//       announcementId: 2,
//       title: 'ann 1'
//     },
//     {
//       announcementId: 3,
//       title: 'ann 1'
//     },
//     {
//       announcementId: 4,
//       title: 'ann 1'
//     },
//     {
//       announcementId: 1,
//       title: 'ann 1'
//     },
//     {
//       announcementId: 2,
//       title: 'ann 1'
//     },
//     {
//       announcementId: 3,
//       title: 'ann 1'
//     },
//     {
//       announcementId: 4,
//       title: 'ann 1'
//     },
//     {
//       announcementId: 1,
//       title: 'ann 1'
//     },
//     {
//       announcementId: 2,
//       title: 'ann 1'
//     },
//     {
//       announcementId: 3,
//       title: 'ann 1'
//     },
//     {
//       announcementId: 4,
//       title: 'ann 1'
//     },
//     {
//       announcementId: 1,
//       title: 'ann 1'
//     },
//     {
//       announcementId: 2,
//       title: 'ann 1'
//     },
//     {
//       announcementId: 3,
//       title: 'ann 1'
//     },
//     {
//       announcementId: 4,
//       title: 'ann 1'
//     },

//   ],

//   totalCount: 30
// };


@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {

  public convertQueryParamsToFilter(queryParams: ParamMap): AnnouncementFilter{
    let result = <AnnouncementFilter>{};

    if(queryParams.has(AnnouncementFilterQueryParams.AnnouncementType)){
      result.announcementTypeId = +queryParams.get(AnnouncementFilterQueryParams.AnnouncementType);
    }

    if(queryParams.has(AnnouncementFilterQueryParams.Categories)){
      result.categoryIds = JSON.parse(queryParams.get(AnnouncementFilterQueryParams.Categories));
    }


    let experienceRange: Range<number> = {
      from: null,
      to: null
    };
    if(queryParams.has(AnnouncementFilterQueryParams.ExperienceFrom)){
      if(experienceRange != undefined){
        experienceRange.from = JSON.parse(queryParams.get(AnnouncementFilterQueryParams.ExperienceFrom));
      }
    }
    if(queryParams.has(AnnouncementFilterQueryParams.ExperienceTo)){
      if(experienceRange != undefined){
        experienceRange.to = JSON.parse(queryParams.get(AnnouncementFilterQueryParams.ExperienceTo));
      }
    }
    result.experienceRange = experienceRange;


    if(queryParams.has(AnnouncementFilterQueryParams.WithoutWorkExperience)){
      result.withoutWorkExperience = JSON.parse(queryParams.get(AnnouncementFilterQueryParams.WithoutWorkExperience));
    }

    if(queryParams.has(AnnouncementFilterQueryParams.AnyExperience)){
      result.anyExperience = JSON.parse(queryParams.get(AnnouncementFilterQueryParams.AnyExperience));
    }


    if(queryParams.has(AnnouncementFilterQueryParams.Locality)){
      result.localityIds = JSON.parse(queryParams.get(AnnouncementFilterQueryParams.Locality));
    }

    if(queryParams.has(AnnouncementFilterQueryParams.PublishDate)){
      result.publishDateRangeOptionId = +queryParams.get(AnnouncementFilterQueryParams.PublishDate);
    }

    if(queryParams.has(AnnouncementFilterQueryParams.Query)){
      result.query = queryParams.get(AnnouncementFilterQueryParams.Query);
    }

    if(queryParams.has(AnnouncementFilterQueryParams.Salary)){
      result.salaryRangeOptionId = +queryParams.get(AnnouncementFilterQueryParams.Salary);
    }

    if(queryParams.has(AnnouncementFilterQueryParams.OnlySelectedSalary)){
      result.onlySelectedSalary = JSON.parse(queryParams.get(AnnouncementFilterQueryParams.OnlySelectedSalary));
    }

    if(queryParams.has(AnnouncementFilterQueryParams.IsWorkFromHome)){
      result.isWorkFromHome = JSON.parse(queryParams.get(AnnouncementFilterQueryParams.IsWorkFromHome))
    }

    if(queryParams.has(AnnouncementFilterQueryParams.Page)){
      result.currentPage = +queryParams.get(AnnouncementFilterQueryParams.Page);
    }
    else {
      result.currentPage = 1;
    }

    return result;
  }

  public convertFilterToFullFilter(filter: AnnouncementFilter, lookup: LookupState, pageSize: number): AnnouncementFullFilter{
    let result = <AnnouncementFullFilter>{};
    
    if(filter.query !== undefined){
      result.query = filter.query;
    }

    if(filter.announcementTypeId !== undefined){
      result.announcementTypeId = filter.announcementTypeId;
    }

    if(filter.localityIds !== undefined && filter.localityIds.length>0){
      result.localityIds = filter.localityIds;
    }

    if(filter.categoryIds !== undefined && filter.categoryIds.length>0){
      result.categoryIds = filter.categoryIds;
    }

    if(filter.salaryRangeOptionId !== undefined && lookup.salaryFilterOptions[filter.salaryRangeOptionId].value !== undefined){
      let salary = lookup.salaryFilterOptions[filter.salaryRangeOptionId]
      result.salary = {
        from: salary.value.from,
        to: salary.value.to
      };
    }

    if(filter.onlySelectedSalary !== undefined){
      result.onlySelectedSalary = filter.onlySelectedSalary;
    }

    if(filter.isWorkFromHome !== undefined){
      result.isWorkFromHome = filter.isWorkFromHome
    }

    if(filter.publishDateRangeOptionId !== undefined && lookup.publishDateFilterOptions[filter.publishDateRangeOptionId].value !== undefined){
      let publishDate = lookup.publishDateFilterOptions[filter.publishDateRangeOptionId];
      result.publishDate = {
        from: publishDate.value.from,
        to: publishDate.value.to
      };
    }

    if(filter.experienceRange !== undefined){
      // let experienceOptions = filter.experienceRangeOptionIds.map(c => lookup.workExperienceFilterOptions[c]);
      result.experienceFilterOptions = {
        from: filter.experienceRange.from,
        to: filter.experienceRange.to
      };
    }

    if(filter.withoutWorkExperience !== undefined){
      result.withoutWorkExperience = filter.withoutWorkExperience;
    }

    if(filter.anyExperience !== undefined){
      result.anyExperience = filter.anyExperience;
    }

    //let currentPage = filter.currentPage || 1;
    let itemsPerPage = pageSize;// state.lookup.configs.uxSettings.websiteSettings.frontPageNumberOfItemsOnPage;

    result.start = (filter.currentPage-1) * itemsPerPage;
    result.limit = itemsPerPage;

    return result;
  }


  public convertQuickFilterToQueryParams(filter: AnnouncementFilter){
    let result = <Params> {};

    if(filter.query !== undefined){
      result[AnnouncementFilterQueryParams.Query] = filter.query;
    }

    if(filter.announcementTypeId !== undefined){
      result[AnnouncementFilterQueryParams.AnnouncementType] = filter.announcementTypeId;
    }

    if(filter.localityIds !== undefined && filter.localityIds.length>0){
      result[AnnouncementFilterQueryParams.Locality] = JSON.stringify(filter.localityIds);
    }

    if(filter.categoryIds !== undefined && filter.categoryIds.length>0){
      result[AnnouncementFilterQueryParams.Categories] = JSON.stringify(filter.categoryIds);
    }

    return result
  }

  public convertPublishDateOptionsToQueryParams(filter: AnnouncementFilter, publishDateOptions: OptionItem<Range<number>>[]){
    let result = <Params> {};

    if(filter.publishDateRangeOptionId !== undefined && publishDateOptions[filter.publishDateRangeOptionId].value !== undefined){
      result[AnnouncementFilterQueryParams.PublishDate] = filter.publishDateRangeOptionId;
    }

    return result
  }

  public convertLocalityOptionToQueryParams(filter: AnnouncementFilter){
    let result = <Params> {};

    if(filter.localityIds !== undefined && filter.localityIds.length > 0){
      result[AnnouncementFilterQueryParams.Locality] = JSON.stringify(filter.localityIds);
    }

    return result
  }

  public convertFilterToQueryParams(filter: AnnouncementFilter, salaryOptions: OptionItem<Range<number>>[], publishDateOptions: OptionItem<Range<number>>[]): Params{
    let result = <Params> {};

    if(filter.query !== undefined){
      result[AnnouncementFilterQueryParams.Query] = filter.query;
    }

    if(filter.announcementTypeId !== undefined){
      result[AnnouncementFilterQueryParams.AnnouncementType] = filter.announcementTypeId;
    }

    if(filter.localityIds !== undefined && filter.localityIds.length>0){
      result[AnnouncementFilterQueryParams.Locality] = JSON.stringify(filter.localityIds);
    }

    if(filter.categoryIds !== undefined && filter.categoryIds.length>0){
      result[AnnouncementFilterQueryParams.Categories] = JSON.stringify(filter.categoryIds);
    }

    if(filter.salaryRangeOptionId !== undefined && salaryOptions[filter.salaryRangeOptionId] !== undefined){
      result[AnnouncementFilterQueryParams.Salary] = filter.salaryRangeOptionId;
    }

    if(filter.onlySelectedSalary !== undefined){
      result[AnnouncementFilterQueryParams.OnlySelectedSalary] = filter.onlySelectedSalary;
    }

    if(filter.isWorkFromHome !== undefined){
      result[AnnouncementFilterQueryParams.IsWorkFromHome] = filter.isWorkFromHome
    }

    if(filter.publishDateRangeOptionId !== undefined && publishDateOptions[filter.publishDateRangeOptionId].value !== undefined){
      result[AnnouncementFilterQueryParams.PublishDate] = filter.publishDateRangeOptionId;
    }
    
    if(filter.experienceRange !== undefined){
      result[AnnouncementFilterQueryParams.ExperienceFrom] = JSON.stringify(filter.experienceRange.from);
    }
    
    if(filter.experienceRange !== undefined){
      result[AnnouncementFilterQueryParams.ExperienceTo] = JSON.stringify(filter.experienceRange.to);
    }
    

    if(filter.withoutWorkExperience !== undefined){
      result[AnnouncementFilterQueryParams.WithoutWorkExperience] = filter.withoutWorkExperience;
    }

    if(filter.anyExperience !== undefined){
      result[AnnouncementFilterQueryParams.AnyExperience] = filter.anyExperience;
    }

    if(filter.currentPage !== undefined){
      result[AnnouncementFilterQueryParams.Page] = filter.currentPage;
    }

    result['cc'] = Math.floor(Math.random()*1000);

    return result;
  }


  public getFilterStats(): Observable<FilterStats>
  {
    return of(mockFilterStats);
  }

  public searchAnnouncements(filter: AnnouncementFullFilter): Observable<ApiResponse<SearchResultModel>>{
    let data = {};

    if(filter.query !== undefined){
      data['Query'] = filter.query;
    }

    if(filter.announcementTypeId !== undefined){
      data['AnnouncementTypeId'] = filter.announcementTypeId;
    }

    if(filter.localityIds !== undefined && filter.localityIds.length > 0){
      data['ExtendedLocationCodes'] = filter.localityIds;
    }

    if(filter.categoryIds !== undefined && filter.categoryIds.length > 0){
      data['CategoryIds'] = filter.categoryIds;
    }

    // if(filter.experience !== undefined && filter.experience.length > 0){
    //   data['Experience'] = filter.experience;
    // }
    // if(filter.experienceFilterOptions?.experienceRange !== undefined){
    //   data['Experience'] = filter.experienceFilterOptions.experienceRange;
    // }

    // if(filter.experienceFilterOptions?.experienceRange !== undefined && filter.experienceFilterOptions?.experienceRange.from !== null){
    //   data['ExperienceFrom'] = filter.experienceFilterOptions.experienceRange.from;
    // }

    // if(filter.experienceFilterOptions?.experienceRange !== undefined && filter.experienceFilterOptions?.experienceRange.to !== null){
    //   data['ExperienceTo'] = filter.experienceFilterOptions.experienceRange.to;
    // }

    if(filter.experienceFilterOptions !== undefined){
      data['WorkExperience'] = filter.experienceFilterOptions;
    }

    if(filter.withoutWorkExperience !== undefined){
      data['WithoutWorkExperience'] = filter.withoutWorkExperience;
    }

    if(filter.anyExperience !== undefined){
      data['AnyExperience'] = filter.anyExperience;
    }

    if(filter.salary !== undefined){
      data['Salary'] = filter.salary;
    }

    if(filter.onlySelectedSalary !== undefined){
      data['OnlySelectedSalary'] = filter.onlySelectedSalary;
    }

    if(filter.publishDate !== undefined){
      data['PublishDate'] = filter.publishDate;
    }

    if(filter.start !== undefined){
      data['Start'] = filter.start;
    }

    if(filter.limit !== undefined){
      data['Limit'] = filter.limit;
    }
    if(filter.isWorkFromHome !== undefined){
      data['IsWorkFromHome'] = filter.isWorkFromHome
    }

    return this.http.post<SearchResultModel>(this.urlService.urlConfig.api.data.announcements.search, data).pipe(map(c => {
      let currentDate = new Date();

      c.data.announcements.items.forEach(a => {
        a.urlFriendlyTitle = this.translit.getUrlFriendlyString(a.title);
        a.urlFriendlyCustomerName = this.translit.getUrlFriendlyString(a.customerName);
        a.isPublishedToday = announcementPublishTodayHelper(a.renewalDate, a.publishDate, currentDate);
        a.isDeadLineToday = announcementDeadlineTodayHelper(a.deadlineDate, currentDate);
      });
      return c;
    }));
  }

  constructor(private urlService: UrlConfigService<UrlConfigContainer>, private http: SmartHttpService, private translit: TranslitService) { }
}

export class AnnouncementHelpers
{
  public static convertFilterToQueryParams(filter: AnnouncementFilter, salaryOptions: OptionItem<Range<number>>[], publishDateOptions: OptionItem<Range<number>>[]): Params{
    let result = <Params> {};

    if(filter.query !== undefined){
      result[AnnouncementFilterQueryParams.Query] = filter.query;
    }

    if(filter.announcementTypeId !== undefined){
      result[AnnouncementFilterQueryParams.AnnouncementType] = filter.announcementTypeId;
    }

    if(filter.localityIds !== undefined && filter.localityIds.length>0){
      result[AnnouncementFilterQueryParams.Locality] = JSON.stringify(filter.localityIds);
    }

    if(filter.categoryIds !== undefined && filter.categoryIds.length>0){
      result[AnnouncementFilterQueryParams.Categories] = JSON.stringify(filter.categoryIds);
    }

    if(filter.salaryRangeOptionId !== undefined && salaryOptions[filter.salaryRangeOptionId]?.value !== undefined){
      result[AnnouncementFilterQueryParams.Salary] = filter.salaryRangeOptionId;
    }

    if(filter.onlySelectedSalary !== undefined){
      result[AnnouncementFilterQueryParams.OnlySelectedSalary] = filter.onlySelectedSalary;
    }

    if(filter.isWorkFromHome !== undefined){
      result[AnnouncementFilterQueryParams.IsWorkFromHome] = filter.isWorkFromHome;
    }

    if(filter.publishDateRangeOptionId !== undefined && publishDateOptions[filter.publishDateRangeOptionId]?.value !== undefined){
      result[AnnouncementFilterQueryParams.PublishDate] = filter.publishDateRangeOptionId;
    }

    if(filter.experienceRange !== undefined){
      result[AnnouncementFilterQueryParams.ExperienceFrom] = JSON.stringify(filter.experienceRange.from);
      result[AnnouncementFilterQueryParams.ExperienceTo] = JSON.stringify(filter.experienceRange.to);
    }
    
    // if(filter.experienceRange !== undefined && filter.experienceRange.from != null){
    //   result[AnnouncementFilterQueryParams.ExperienceFrom] = filter.experienceRange.from;
    // }

    // if(filter.experienceRange !== undefined && filter.experienceRange.to != null){
    //   result[AnnouncementFilterQueryParams.ExperienceTo] = filter.experienceRange.to;
    // }
    
    if(filter.withoutWorkExperience !== undefined){
      result[AnnouncementFilterQueryParams.WithoutWorkExperience] = JSON.stringify(filter.withoutWorkExperience);
    }

    if(filter.anyExperience !== undefined){
      result[AnnouncementFilterQueryParams.AnyExperience] = JSON.stringify(filter.anyExperience);
    }


    if(filter.currentPage !== undefined){
      result[AnnouncementFilterQueryParams.Page] = filter.currentPage;
    }

    result['cc'] = Math.floor(Math.random()*1000);

    return result;
  }
}

enum AnnouncementFilterQueryParams{
  AnnouncementType='t',
  Categories='c',
  // Experience='e',
  ExperienceFrom='ef',
  ExperienceTo='et',
  WithoutWorkExperience='we',
  AnyExperience='ee',
  Locality='l',
  PublishDate='p',
  Query='q',

  IsWorkFromHome = 'w',

  Salary='s',
  OnlySelectedSalary='os',

  Page='pg'
}

export interface SearchResultModel{
  announcements: PagingWrapper<Announcement>;
  metaData: MetaDataModel;
}
