export interface LocationListViewModel{
    locationId: string;
    name: string;
    type: LocationTypeEnum;
}

export interface LocationsViewModel{
    parentLocationId: string;
    locations: LocationListViewModel[];
}
  
export enum LocationStatusEnum
{
    Active = 1,
    Inactive = 2
}

export enum LocationTypeEnum
{
    Root = 1,
    Undefined = 2,
    Other = 3,
    Country = 4,
    State = 5,
    City = 6,
    District = 7,
    Street = 8,
    Abroad = 9,
    Wildcard = 10
}
