import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
//import { PagingNavItem } from 'src/app/root-store/announcement-store/search-page/state';

@Component({
  selector: 'app-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss']
})
export class PagingComponent implements OnInit {

  @Input() currentPage: number;
  @Input() pagingNavItems: PagingNavItem[];
  @Input() pageCount: number;

  @Output() onPageNav: EventEmitter<number> = new EventEmitter<number>();

  public navigateToPage(page: number){
    if(page!=-1){
      this.onPageNav.emit(page);
    }
  }
  
  constructor() { }

  ngOnInit(): void {
  }

}

export interface PagingNavItem{
  page: number;
  isElipsis: boolean;
  isCurrent: boolean;
}
