import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TreeNode } from 'primeng/api';
import { TreeSelect } from 'primeng/treeselect';
import { LocationTreeSelectStore, TreeInputUpdateCommand } from '../shared/store/location-tree-select-store';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription, asapScheduler, asyncScheduler, combineLatest, combineLatestWith, delay, filter, map, observeOn, startWith, take, takeUntil, tap, withLatestFrom } from 'rxjs';
import { TreeSelectModel } from '@hrra/common-models';
import { RouteReuseStrategy } from '@angular/router';

@Component({
	selector: 'hrra-location-tree-select',
	templateUrl: './location-tree-select.component.html',
	styleUrls: ['./location-tree-select.component.scss'],
	providers: [
		LocationTreeSelectStore,
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: LocationTreeSelectComponent
		},
	]
})
export class LocationTreeSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
	onChange = (value) => { };
	onTouched = () => { };

	@ViewChild('treeSelect')
	treeSelect!: TreeSelect;

	private treeInputUpdates$ = new ReplaySubject<TreeInputUpdateCommand>();
	private treeUpdates$: Observable<string[]>;
	private treeLoadCommand$ = new ReplaySubject<void>();

	private _sub: Subscription;
	private _treeLoadSub: Subscription;
	private _updateSub: Subscription;

	@Input()
	get selectedNodes(): any { return this._selectedNodes; }
	set selectedNodes(values: string[]) {

		console.log(`set location values: ${values}`);

		this.updateValue(values);
	}

	updateValue(values: string[]){
		let currentValueEmpty = this.treeSelectControl.value == undefined || this.treeSelectControl.value == null || this.treeSelectControl.value.length == 0;

		let initialValueLoadRequired = this.treeSelectControl.pristine && currentValueEmpty && values.length>0;
		let resetValueRequired = !currentValueEmpty && this.treeSelectControl.value.length > 0 && values.length == 0;
		let valueUpdateMaybeRequired = false;

		if(!initialValueLoadRequired && !resetValueRequired && !currentValueEmpty && this.treeSelectControl.value.length > 0 && values.length > 0 ){
			let existingValues = this.treeSelectControl.value.map(c => c.data);
			//let extractedValues = this.extract()
			//if compression is not enabled simple comparison is enough, otherwiese we additionally need to check this later when entire tree is availabel to extract values
			valueUpdateMaybeRequired = this.compressValues || !this.areEqual(existingValues, values);
		}
		

		console.log("tree select control value: %o", this.treeSelectControl.value);


		if(initialValueLoadRequired || resetValueRequired || valueUpdateMaybeRequired){

			console.log('loading tree');
			this.treeLoadCommand$.next();

			console.log("setting value: %o", values);
			this._selectedNodes = values;

			this.treeInputUpdates$.next({ newValues: values, existingValues: (this.treeSelectControl.value || []).map(c => c.data), additionalCheckRequired: this.compressValues });
		}
	}


	private _selectedNodes: string[] = [];


	@Input("NotFound") notFoundTextMessage: string = 'label.NotFound';
	@Input("PlaceholderText") placeHolderTextMessage: string = 'label.SelectItem';
	@Input("SelectItemCountText") selectedItemCountText: string = 'label.SelectItemCount'
	@Input('ScrollHeight') scrollHeight = '15rem';

	@Input() compressValues = true;
	@Input() enableVirtualScroll = false;
	@Input() virtualScrollItemSize = 33;
	@Input() virtualScrollHeight = '200px';
	@Input() isLarge!: boolean;


	@Output("updateTreeValue") updateTreeSelectValue: EventEmitter<string[]>;

	@Output() onCompleteEdit: EventEmitter<string[]>;

	public overlayVisible$: Observable<boolean>;

	//public hideAfterUnSelect: boolean;

	public treeSelectControl: FormControl<TreeSelectModel<string>[]> = new FormControl<TreeSelectModel<string>[]>([]);

	public locationTree$!: Observable<TreeSelectModel<string>[]>;

	public itemCount$!: Observable<number>;

	public loading$: Observable<boolean> | undefined;

	constructor(private store: LocationTreeSelectStore) {
		this.updateTreeSelectValue = new EventEmitter<string[]>();
		this.onCompleteEdit = new EventEmitter<string[]>();
		//this.hideAfterUnSelect = false;
	}

	ngOnDestroy(): void {
		console.log('destroy');
		this._sub.unsubscribe();
		this._treeLoadSub.unsubscribe();
		this._updateSub.unsubscribe();
	}

	ngOnInit(): void {

		console.log('on init');
		//this.store.loadLocationTree();
		this.locationTree$ = this.store.locationTree$;
		this.loading$ = this.store.loading$;
		this.overlayVisible$ = this.store.overlayVisible$;

		this.itemCount$ = this.treeSelectControl.valueChanges.pipe(
			withLatestFrom(this.locationTree$.pipe(
				filter(c => c.length > 0),
			)),
			map(([value, tree]) => {
				console.log('recalculating items');
				return (value || []).filter(c => c.leaf).length;
			})
		)
		.pipe(startWith(0));

		this._updateSub = this.treeSelectControl.valueChanges.pipe(
			withLatestFrom(this.locationTree$.pipe(
				filter(c => c.length > 0),
			)),
			map(([c, t]) => {

				let map = new Map<string, TreeSelectModel<string>>();
				this.getTreeMap(t, map);

				let result = this.fixInvalidParents(c, map);

				let selectedSet = new Set(result.map(cc => cc.data));

				if(result.length != (c || []).length)
					this.fixPartialSelections(t, selectedSet);

				// let updatedCommand: TreeInputUpdateCommand = {
				// 	additionalCheckRequired: command.additionalCheckRequired,
				// 	existingValues: command.existingValues,
				// 	newValues: result.map(c => c.data)
				// };

				return { c: result, t };

				return {c, t};
			}),
			map(({c, t}) =>{
				return this.compressValues ? [this.compress(c, t), t] : [c, t];
			}),
			tap(([value, tree]) => {
				let values = (value || []).map(c => c.data);
				this.updateTreeSelectValue.emit(values);
				console.log("form values: %o", values);
				this.onChange(values);
			})
		).subscribe(c =>{
			console.log("sent update %o", c)
		});

		this._treeLoadSub = this.treeLoadCommand$.pipe(
			takeUntil(
				this.locationTree$.pipe(
					filter(c => c.length > 0),
					tap(c => {
						console.log("data: %o", c);
					})
				)
			)
		).subscribe(() => {
			console.log('actually loading tree');
			this.store.loadLocationTree();
		});


		this.treeUpdates$ = this.treeInputUpdates$.pipe(
			combineLatestWith(
				this.locationTree$.pipe(
					filter(c => c.length > 0)
				)
			),
			map(([command, tree])=> {

				return { command, tree };
				// let map = new Map<string, TreeSelectModel<string>>();
				// this.getTreeMap(tree, map);

				// let result = this.fixInvalidParents(command.newValues.map(c => map.get(c)!), map);

				// let selectedSet = new Set(result.map(c => c.data));

				// if(result.length != command.newValues.length)
				// 	this.fixPartialSelections(tree, selectedSet);

				// let updatedCommand: TreeInputUpdateCommand = {
				// 	additionalCheckRequired: command.additionalCheckRequired,
				// 	existingValues: command.existingValues,
				// 	newValues: result.map(c => c.data)
				// };

				// return { command: updatedCommand, tree: tree};
			}),
			filter(({command, tree}) => {
				if(!command.additionalCheckRequired)
					return true;

				let extractedValues = this.extract(command.newValues, tree);
				return !this.areEqual(extractedValues, command.existingValues)
			}),
			map(({command, tree}) => {
				return this.compressValues ? this.extract(command.newValues, tree) : command.newValues;
			})
		);

		this._sub = this.treeUpdates$
		.subscribe({ next: c => {
			asyncScheduler.schedule(() => {
				let map = new Map<string, TreeSelectModel<string>>();
				this.getTreeMap(<any>this.treeSelect.options || [], map);
				console.log("incoming values: %o", c);
				let nodes = c.map(cc => map.get(cc));
				console.log("processed to nodes: %o", nodes);
				this.treeSelectControl.setValue(nodes);
				let selectedSet = new Set<string>(c);
				this.fixPartialSelections(<any>this.treeSelect.options, selectedSet);
				console.log("updated value: %o", nodes);
			});
		}});
	}

	areEqual(a: string[], b: string[]){
		let left = new Set(a);
		let right = new Set(b);

		return left.size == right.size && (new Set([...left, ...right])).size == left.size;
	}

	closeDropdown() {
		this.treeSelect.hide();
		// this.treeSelect.cd.markForCheck();
	}

	writeValue(nodeIds: string[]): void {
		// let treeNodes = this.treeSelect?.options;
		// let treeDictionary = this.getTreeDictionary(treeNodes);
		// let selectedTreeNodes: any = [];

		// nodeIds.forEach((id: string) => {
		// 	if (id in treeDictionary) {
		// 		selectedTreeNodes.push(treeDictionary[Number(id)]);
		// 	}
		// });

		// this.treeSelectControl.patchValue(selectedTreeNodes);
		// this.updateItemCount(false);

		this.updateValue(nodeIds);
		
	}

	fixInvalidParents(selectedNodes: TreeSelectModel<string>[], map: Map<string, TreeSelectModel<string>>)
	{
		let originalSelectionMap = new Map<string, TreeSelectModel<string>>();
		(selectedNodes || []).forEach(cc => {
			originalSelectionMap.set(cc.data, cc);
		});

		console.log("fixing parents, original value: %o", selectedNodes);
		let selectedNodeSet = new Set((selectedNodes || []).map(c => c.data));
		let result = (selectedNodes || []).map(cc => map.get(cc.data)).filter(c => {
			if(c.leaf)
				return true;
			let parentInvalid = c.children.some(cc => !selectedNodeSet.has(cc.data)) 
				|| c.children.some(cc => !selectedNodeSet.has(cc.data));
			return !parentInvalid;
		});

		console.log("fixing parent, fixed nodes: %o", result);

		return result;
	}

	fixPartialSelections(nodes: TreeSelectModel<string>[], selectedNodes: Set<string>){

		for(let node of nodes){
			let hasChildren = !node.leaf && node.children !== undefined && node.children !== null && node.children.length > 0;
			
			node.partialSelected = hasChildren && this.fixPartialSelections(node.children, selectedNodes);
			node.expanded = false;
		}

		let currentSelection = nodes.filter(c => selectedNodes.has(c.data));
		let incompleteSelection = currentSelection.length > 0 && currentSelection.length < nodes.length;
		let partialSelectedChild = nodes.some(c => c.partialSelected);

		return incompleteSelection || partialSelectedChild;
	}

	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	setDisabledState?(isDisabled: boolean): void { }

	onClear() {

	}

	onReset() {
		this.treeSelectControl.reset();
	}

	onApprove() {
		this.onCompleteEdit.emit((this.treeSelectControl.value || []).map(c => c.data));
		this.closeDropdown();
	}

	onSelectNode(event: any) {
		//this.updateTreeSelectValue.emit((this.treeSelectControl.value || []).map(c => c.data));
		//this.updateItemCount();
	}

	onUnSelectNode(event: any) {
		//this.updateTreeSelectValue.emit((this.treeSelectControl.value || []).map(c => c.data));
		//this.hideAfterUnSelect = true;
		//this.updateItemCount();
	}

	onHide(event: any) {
		this.store.updateOverlayVisible(false);
	}

	onShow(event: any) {
		this.treeLoadCommand$.next();
		this.store.updateOverlayVisible(true);
		//this.isDropdownIconDown = !this.isDropdownIconDown;
		// if (!this.enableVirtualScroll) {
		// 	this.treeSelect.treeViewChild!.virtualScroll = true;
		// 	this.treeSelect.treeViewChild!.scrollHeight = '200px';
		// 	this.treeSelect.treeViewChild!.virtualScrollItemSize = 33;
		// 	this.enableVirtualScroll = true;
		// }


		//TODO after udpate to 17 pass as input directly
		//This code is broken, when uncommenting causes bug: when selecting child, parent is not checked(selected)
		if(this.enableVirtualScroll){
			// this.treeSelect.treeViewChild!.virtualScroll = this.enableVirtualScroll;
			// this.treeSelect.treeViewChild!.scrollHeight = this.virtualScrollHeight;
			// this.treeSelect.treeViewChild!.virtualScrollItemSize = this.virtualScrollItemSize;
		}
	}

	compress(values: TreeSelectModel<string>[], tree: TreeSelectModel<string>[]){
		let map = new Map<string, TreeSelectModel<string>>();
		let depthMap = new Map<string, number>();
		this.getTreeMap(tree, map, depthMap);
		let selectedSet = new Set((values || []).map(c => c.data));
		//let exclusionSet = new Set<string>();
		let checkedParents = new Set<string>();

		let nonLeafs = (values || []).filter(c => !c.leaf).map(c => map.get(c.data)!);


		//TODO order nonLeafs by depth, first process those with less depth

		nonLeafs.sort((a, b) => depthMap.get(a.data)! - depthMap.get(b.data)!);


		while(nonLeafs.length>0){
			let node = nonLeafs[0];
			let items = this.getSubTreeNodes(node);
			let excludedSet = new Set(items);
			nonLeafs = nonLeafs.filter(c => !excludedSet.has(c.data) && c.data != node.data);
			items.forEach(c =>{
				selectedSet.delete(c)
			});
		}


		let result = [... selectedSet].map(c => map.get(c)!);

		console.log("compressed nodes: %o", result);

		return result;
	}

	getSubTreeNodes(node: TreeSelectModel<string>): string[]
	{
		let result = [];

		if(node.children === undefined || node.children === null || node.children.length == 0)
			return [];


		for(let child of node.children){
			result.push(child.data);
			let grandChildren = this.getSubTreeNodes(child);
			result.push(... grandChildren);
		}

		return result;
	}

	extract(values: string[], tree: TreeSelectModel<string>[]){
		let result = [];

		let localityIds = new Set<string>();
		let map = new Map<string, TreeSelectModel<string>>();
		this.getTreeMap(tree, map);

		for(let value of values){
			let node = map.get(value);
			result.push(value);
			let subtreeValues = this.getSubTreeNodes(node!);
			result.push(... subtreeValues);
		}

		console.log("extracted nodes: %o", result);

		return result;
	}


	getTreeMap(nodes: TreeSelectModel<string>[], map: Map<string, TreeSelectModel<string>>, depthMap: Map<string, number>=null, depth=0){
		for(let node of nodes){
			map.set(node.data, node);
			if(depthMap != null)
				depthMap.set(node.data, depth);
			if(node.children !== undefined && node.children !== null && node.children.length > 0){
				this.getTreeMap(node.children, map, depthMap, depth+1);
			}
		}
	}
}