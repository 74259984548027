import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AnnouncementType, AppState } from 'src/app/root-store/state';
import { Store, select } from '@ngrx/store';
import { selectAnnouncementTypeOptions } from 'src/app/root-store/selectors';
import { selectFilter } from 'src/app/root-store/announcement-store/search-page/selectors';
import { AnnouncementFilter } from 'src/app/shared/models/announcement-filter';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { updateAnnouncementTypeFilter } from 'src/app/root-store/announcement-store/search-page/actions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ann-type-filter',
  templateUrl: './ann-type-filter.component.html',
  styleUrls: ['./ann-type-filter.component.scss']
})
export class AnnTypeFilterComponent implements OnInit {

  public announcementTypeOptions$: Observable<AnnouncementType[]>;
  public salaryFilter$: Observable<AnnouncementFilter>;

  private _selectedTypeOption: number = 0;

  @Input()
  get selectedTypeOption(): number { return this._selectedTypeOption; }
  set selectedTypeOption(selectedTypeOption: any){
    /**
     * When click clear button, selectedSalaryOption value become OptionItem<Range<number>>{  }, other cases - option key
     */
    this._selectedTypeOption = typeof(selectedTypeOption) == "number" ? selectedTypeOption : selectedTypeOption?.key;
  }

  onChangeAnnuncementTypeFilter(event: number ){
    this.store.dispatch(updateAnnouncementTypeFilter( { payload: { announcementTypeId: event } }));
  }

  constructor(private store: Store<AppState>, private translate: TranslateService) { }

  ngOnInit(): void {
    this.announcementTypeOptions$ = this.store.pipe(select(selectAnnouncementTypeOptions));
    this.salaryFilter$ = this.store.pipe(select(selectFilter));
  }

}
