import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationMessage } from '../models/application-message';
import { MessageType } from '../models/enums/message-type-enum';


@Injectable({
  providedIn: 'root'
})
export class CustomSnackbarService {

  openSnackbar(message: ApplicationMessage){
    let messageText: string;
    let panelClass: string;

    if(message.type == MessageType.Success){
      messageText = this.translate.instant(message.summary);
      panelClass = 'snackbar-success';
    }

    else if (message.type == MessageType.Error){
      messageText = this.translate.instant(message.summary);
      panelClass = 'snackbar-fail';
    }


    this.snackBarService.open(messageText, null, { duration: message.displayTime, panelClass: [panelClass] })
  }

  constructor(private snackBarService: MatSnackBar, private translate: TranslateService) { }
}

export const createSuccessMessage = function(summary = 'message.SaveMessage', detail='', duration = 2000) {
  let succsessMessageSnackbar: ApplicationMessage = {
    type: MessageType.Success,
    summary: summary,
    detail: detail,
    sourceId: '',
    isSticky: false,
    displayTime: duration,
  }
  return succsessMessageSnackbar;
}

export const createErrorMessage = function(summary ='message.CommonServerErrorSummary', detail='', duration = 2000) {
  let errorMessageSnackbar: ApplicationMessage = {
    type: MessageType.Error,
    summary: summary,
    detail: detail,
    sourceId: '',
    isSticky: false,
    displayTime: duration,
  }
  return errorMessageSnackbar;
}

export const createMessage = function(type: MessageType, summary: string, detail = '', duration = 2000){
  let messageSnackbar: ApplicationMessage = {
    type: type,
    summary: summary,
    detail: detail,
    sourceId: '',
    isSticky: false,
    displayTime: duration,
  }
  return messageSnackbar;
}