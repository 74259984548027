import { Injectable } from '@angular/core';
import { SmartHttpService } from '@hrra/core';
import { UrlConfigService } from '@hrra/core';
import { UrlConfig, UrlConfigContainer } from 'src/app/core/url-config';
import { Config } from '../models/system-config-model';

@Injectable({
  providedIn: 'root'
})
export class SystemConfigService {

 public getConfigs(){
  return this.smartHttp.get<Config>(this.urlService.format(this.urlService.urlConfig.api.data.configs.get, {}), {});
}

  constructor(private smartHttp: SmartHttpService, private urlService: UrlConfigService<UrlConfigContainer>) { }

}
