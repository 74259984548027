import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { saveAs, FileSaverOptions } from 'file-saver';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {

  constructor(@Inject(DOCUMENT) private document: Document, private http: HttpClient) { }

  public downloadFile(url: string, fileName: string) {

    if (this.isFileSaverSupported()) {

      this.http.get(url, { responseType: 'blob'}).subscribe(d => {
        saveAs(<any>d, fileName)
      });     
    }

    else {
      this.downloadFileFallback(url, fileName);
    }
  }

  public isFileSaverSupported(): boolean {
    try {
      return !!new Blob();
    } catch (e) {
      return false;
    }
  }

  public downloadFileFallback(url: string, filename: string) {
    let link = this.document.createElement('a');
    link.href = url;
    link.download = filename;
    link.target = '_blank';
    link.click();
  }
}