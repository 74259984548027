import { Action, on } from '@ngrx/store';
import { createImmerReducer } from 'ngrx-immer/store';
import { JobseekerSettingsState } from './state';
import * as JobseekerSettingsActions from './actions';


let initialState: JobseekerSettingsState = {
    privacy: null,
    loadingPrivacy: false,
    selectedPrivacy: null,
    updateLoading: false,
    jobseekerChangePasswordFailureDetails: null,

    blacklist: {
        jobseekerBlackList: null,
        loadJobseekerBlacklist: false,
        jobseekerIgnoredCustomerCurrentList: null,
        jobseekerCustomerCurrentList: null
    }
}

const reducer = createImmerReducer(
    initialState,

    on(JobseekerSettingsActions.loadJobseekerPrivacy, state => {
        state.loadingPrivacy = true;
        return state;
    }),
    on(JobseekerSettingsActions.loadJobseekerPrivacySuccess, (state, { payload }) => {
        state.privacy = payload;
        state.selectedPrivacy = payload.privacyId;
        state.loadingPrivacy = false;
        return state;
    }),
    on(JobseekerSettingsActions.loadJobseekerPrivacyFail, (state, { payload }) => {
        state.loadingPrivacy = false;
        return state;
    }),
    on(JobseekerSettingsActions.changeJobseekerPrivacy, (state, { payload }) => {
        state.selectedPrivacy = payload.privacyId;
        return state;
    }),
    on(JobseekerSettingsActions.cancelChanges, state => {
        state.selectedPrivacy = state.privacy.privacyId;
        return state;
    }),
    on(JobseekerSettingsActions.updateJobseekerPrivacy, state => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerSettingsActions.updateJobseekerPrivacySuccess, (state, { payload }) => {
        state.updateLoading = false;
        state.privacy = payload;
        state.selectedPrivacy = payload.privacyId;
        return state;
    }),
    on(JobseekerSettingsActions.updateJobseekerPrivacyFail, (state, { payload }) => {
        state.updateLoading = false;
        return state;
    }),
    on(JobseekerSettingsActions.changeJobseekerPassword, state => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerSettingsActions.changeJobseekerPasswordSuccess, state => {
        state.updateLoading = false;
        state.jobseekerChangePasswordFailureDetails = null;
        return state;
    }),
    on(JobseekerSettingsActions.changeJobseekerPasswordFail, (state, { payload }) => {
        state.updateLoading = false;
        state.jobseekerChangePasswordFailureDetails = payload;
        return state;
    }),
    on(JobseekerSettingsActions.cancelJobseekerPasswordChanges, state => {
        state.jobseekerChangePasswordFailureDetails = null;
        return state;
    }),
    on(JobseekerSettingsActions.loadJobseekerBlacklist, state => {
        state.blacklist.loadJobseekerBlacklist = true;
        return state;
    }),
    on(JobseekerSettingsActions.loadJobseekerBlackListSuccess, (state, { payload }) => {
        state.blacklist.loadJobseekerBlacklist = false;
        state.blacklist.jobseekerBlackList = payload;
        state.blacklist.jobseekerIgnoredCustomerCurrentList = payload.jobseekerIgnoredCustomerList;

        state.blacklist.jobseekerCustomerCurrentList = payload.customerList.filter(
            (e) => {
                return !payload.jobseekerIgnoredCustomerList.map(c => c.value).includes(e.value);
        });
        return state;
    }),
    on(JobseekerSettingsActions.loadJobseekerBlacklistFail, (state, { payload }) => {
        state.blacklist.loadJobseekerBlacklist = false;
        return state;
    }),
    on(JobseekerSettingsActions.selectBlackListItem, (state, { payload }) => {
        state.blacklist.jobseekerIgnoredCustomerCurrentList.push(payload.customer);
        state.blacklist.jobseekerCustomerCurrentList = state.blacklist.jobseekerCustomerCurrentList.filter(e => e.value != payload.customer.value);

        return state;
    }),
    on(JobseekerSettingsActions.removeSelectedBlacklistItem, (state, { payload }) => {
        state.blacklist.jobseekerCustomerCurrentList.push(payload.customer);
        state.blacklist.jobseekerCustomerCurrentList = state.blacklist.jobseekerCustomerCurrentList.sort((a, b) => {
            return a.value < b.value ? -1 : a.value > b.value ? 1 : 0;
        });

        let index = state.blacklist.jobseekerIgnoredCustomerCurrentList.map(e => e.value).indexOf(payload.customer.value);
        state.blacklist.jobseekerIgnoredCustomerCurrentList.splice(index, 1);

        return state;
    }),
    on(JobseekerSettingsActions.cancelBlacklistChanges, state => {
        state.blacklist.jobseekerIgnoredCustomerCurrentList = state.blacklist.jobseekerBlackList.jobseekerIgnoredCustomerList;

        state.blacklist.jobseekerCustomerCurrentList = state.blacklist.jobseekerBlackList.customerList.filter(
            (e) => {
                return !state.blacklist.jobseekerBlackList.jobseekerIgnoredCustomerList.map(c => c.value).includes(e.value);
        });
        return state;
    }),
    on(JobseekerSettingsActions.updateJobseekerBlacklist, state => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerSettingsActions.updateJobseekerBlacklistSuccess, (state, { payload }) => {
        state.updateLoading = false;
        state.blacklist.jobseekerBlackList = payload;
        state.blacklist.jobseekerIgnoredCustomerCurrentList = payload.jobseekerIgnoredCustomerList;
        state.blacklist.jobseekerCustomerCurrentList = payload.customerList.filter(
            (e) => {
                return !payload.jobseekerIgnoredCustomerList.map(c => c.value).includes(e.value);
        });
        return state;
    }),
    on(JobseekerSettingsActions.updateJobseekerPrivacyFail, (state, { payload }) => {
        state.updateLoading = false;
        return state;
    })
)

export function jobseekerSettingsReducer(state: JobseekerSettingsState | undefined, action: Action) {
    return reducer(state, action);
}