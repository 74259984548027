import { Component, OnInit } from '@angular/core';
import { theme } from '../../environments/theme'
import { Store, select } from '@ngrx/store';
import { AppState } from '../root-store/state';
import { selectLocalities } from '../root-store/selectors';

@Component({
  selector: 'app-layout-main',
  templateUrl: `./${theme.name}/layout-main.component.html`,
  styleUrls: [`./${theme.name}/layout-main.component.scss`]
})
export class LayoutMainComponent implements OnInit {
  constructor(private store: Store<AppState>) {
  }

  ngOnInit(): void {
    // let jobseekerId = 1;

    // this.smartHttp.get<Jobseeker>(this.urlService.format(this.urlService.urlConfig.jobseekers.get, {id: jobseekerId}), {}).subscribe(d => {
    //   console.log(d);
    // }, err => {
    //   console.log(err);
    // });

    //this.store.pipe(select(selectLocalities))
  }

}


interface Jobseeker {
  jobseekerId: number;
  name: string;
  surname: string;
  email: string;
}