import { createAction, props } from '@ngrx/store';
import { ApiError } from '@hrra/core';
import { StaticPageViewModel } from 'src/app/shared/models/static-page-model';
import { ActionPayload } from "@hrra/core";

export enum Actions {
    LoadInfoPage = '[info page] Load info page',
    LoadInfoPageSuccess = '[info page] Load info page Success',
    LoadInfoPageFail = '[info page] Load info page fail'
}

export const loadInfoPage = createAction(
    Actions.LoadInfoPage,
    props<ActionPayload<{alias: string}>>()
);

export const loadInfoPageSuccess = createAction(
    Actions.LoadInfoPageSuccess,
    props<ActionPayload<StaticPageViewModel>>()
);

export const loadInfoPageFail = createAction(
    Actions.LoadInfoPageFail,
    props<ActionPayload<ApiError>>()
);