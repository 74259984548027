import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { JobseekerService } from 'src/app/jobseeker/shared/services/jobseeker.service';
import * as JobseekerSubscriptionActions from './actions';
import { mergeMap, catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { SubscriberService } from 'src/app/subscriber/shared/services/subsriber.service';

@Injectable()
export class JobseekerSubscriptionEffects{

    updateSubscription$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerSubscriptionActions.updateSubscription),
        mergeMap((action) => this.jobseekerService.updateSubscription(action.payload)
        .pipe(
            map(response => JobseekerSubscriptionActions.updateSubscriptionSuccess({payload: response.data})),
            tap(c => this.router.navigate(['/jobseeker/subscription'])),
            catchError(response => of(JobseekerSubscriptionActions.updateSubscriptionFail({payload: response.error})))
        ))
    ));

    loadSubscription$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerSubscriptionActions.loadSubscription),
        mergeMap((action) => this.jobseekerService.getSubscription()
        .pipe(
            map(response => JobseekerSubscriptionActions.loadSubscriptionSuccess({payload: response.data})),
            catchError(response => of(JobseekerSubscriptionActions.loadSubscriptionFail({payload: response.error})))
        ))
    ));
    
    constructor(private actions$: Actions, private jobseekerService: JobseekerService, private router: Router){}
}
