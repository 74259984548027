import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { JobseekerService } from 'src/app/jobseeker/shared/services/jobseeker.service';
import * as JobseekerRegistrationActions from './actions';
import { mergeMap, catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { MetaService } from 'src/app/shared/services/meta.service';

@Injectable()
export class JobseekerRegistrationEffects{

    registerJobseeker$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerRegistrationActions.registerJobseeker),
        mergeMap((action) => this.jobseekerSerivce.register(action.payload)
        .pipe(
            map(response => JobseekerRegistrationActions.registerJobseekerSuccess({payload: response.data})),
            catchError(response => of(JobseekerRegistrationActions.registerJobseekerFail({payload: response.error})))
        ))
    ));

    registerJobseekerSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerRegistrationActions.registerJobseekerSuccess),
        tap(() => this.router.navigate(['/jobseeker/registration-complete'])),
        catchError(response => of(JobseekerRegistrationActions.registerJobseekerFail({payload: response.error})))//todo remove bacuase it's not dispatched
    ), { dispatch: false});

    resendActivationEmail$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerRegistrationActions.resendActivationEmail),
        mergeMap((action) => this.jobseekerSerivce.resendVerificationEmail(action.payload.emailVerificationResendToken)
        .pipe(
            map(() => JobseekerRegistrationActions.resendActivationEmailSuccess()),
            catchError(response => of(JobseekerRegistrationActions.ResendActivationEmailFail({payload: response.error})))
        ))
    ));

    verifyJobseekerEmail$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerRegistrationActions.verifyJobseekerEmail),
        mergeMap((action) => this.jobseekerSerivce.verifyEmail(action.payload.verificationCode)
        .pipe(
            map(response => JobseekerRegistrationActions.verifyJobseekerEmailSuccess()),
            catchError(response => of(JobseekerRegistrationActions.verifyJobseekerEmailFail({payload: response.error})))
        ))
    ));

    getCvRegistrationPageSeoData$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerRegistrationActions.getCvRegistrationPageSeoData),
        mergeMap((action) => this.metaService.getCvRegistrationPageSeoData()
        .pipe(
            map(response => JobseekerRegistrationActions.getCvRegistrationPageSeoDataSuccess({payload: response.data})),
            catchError(response => of(JobseekerRegistrationActions.getCvRegistrationPageSeoDataFail({payload: response.error})))
        ))
    ));
    
    constructor(private actions$: Actions, private jobseekerSerivce: JobseekerService, private router: Router, private metaService: MetaService){}
}
