<a [routerLink]="['/customer', company.customerId, company.urlFriendlyCustomerName]"  class="container">
    <div class="logo">
        <img [src]="company.logoHref" class="logo__img">
    </div>
    <div class="title">
        <div class="title__text">{{company.name}}</div>
        <img *ngIf="company.isVerified" title="{{'label.Verified' | translate}}" class="title__verified" src="assets/img/common/verification-icon.svg">
    </div>
    <div class="additional-info">
        <div class="additional-info__container">
            <div class="additional-info__count">
                {{company.announcementTotal}}
            </div>
            <div *ngIf="company.hasActiveCvBox" class="additional-info__cvBox">
                <img class="additional-info__cvBox-icon" src="assets/img/common/cv-box-company.svg">
            </div>
        </div>
    </div>
</a>