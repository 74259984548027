import {
  createFeatureSelector,
  createSelector,
  createAction,
} from '@ngrx/store';
import { AppState, AnnouncementType } from '../../state';
import { AnnouncementStoreState } from '../state';
import {
  selectLocalities,
  selectHeadCategories,
  selectCategoriesMap,
  selectSalaryOptions,
  selectWorkExperienceOptions,
  selectChildCategories,
  selectAnnouncementTypes,
  selectPublishDateOptions,
  selectFavoriteAnnouncementsMap,
  selectCurrentWebsiteNameForSeo,
  selectSalaryOptionsNew,
  selectFrontPageNumberOfItemsOnPage,
  selectCategories
} from '../../selectors';
import { AnnouncementListingSectionEnum, Locality, OccupationCategory, OptionItem, TreeSelectModel, WorkExperienceFilterModel } from '@hrra/common-models';
import { AnnouncementHelpers } from 'src/app/shared/services/announcement.service';
import { BannerPositionEnum } from 'src/app/banner/shared/models/enums/banner-position-enum';
import { Banner } from 'src/app/banner/shared/models/banner-model';
import { selectCvBoxCustomerMap } from '../selectors';
import { PagingNavItem, RoutablePagingNavItem } from '@hrra/ui';
import { Params } from '@angular/router';
import { convertFilterToQueryParams } from 'src/app/announcement/shared/helpers/convert-filter-to-query-params';
import { Announcement } from 'src/app/announcement/shared/models/announcement-model';

export const selectAnnouncementState =
  createFeatureSelector<AnnouncementStoreState>('announcement');

export const selectSearchState = createSelector(
  selectAnnouncementState,
  (s) => s && s.search
);

export const selectFilter = createSelector(
  selectAnnouncementState,
  (s) => s.search.filter
);

// AnnouncementSections
export const selectPreviewAnnouncements = createSelector(
  selectAnnouncementState,
  selectFavoriteAnnouncementsMap,
  selectCvBoxCustomerMap,
  (s, fav, cm) => {
    let announcements = s.search.announcements.map((c) =>
      Object.assign({}, c, {
        isFavorite: fav[c.announcementId] === true,
        isWithCvBox: cm[c.customerId] === true,
      })
    );

    return announcements.filter(
      (a) => a.listingSection == AnnouncementListingSectionEnum.Preview
    );
  }
);

export const selectAnnouncements = createSelector(
  selectAnnouncementState,
  selectFavoriteAnnouncementsMap,
  selectCvBoxCustomerMap,
  (s, fav, cm) => {
    let announcements = s.search.announcements?.map((c) =>
      Object.assign({}, c, {
        isFavorite: fav[c.announcementId] === true,
        isWithCvBox: cm[c.customerId] === true,
      })
    );

    return announcements;
  }
);

export const selectMatchedAnnouncementCount = createSelector(
  selectAnnouncementState,
  (s) => s.search.matchedAnnouncementCount
);

export const selectIsExpanded = createSelector(
  selectAnnouncementState,
  (s) => s.search.uiState.isExpanded
);

//Locations

export const selectDisplayCities = createSelector(
  selectLocalities,
  selectSearchState,
  (localities, search) => {
    if (search.uiState.location.allItemsVisible) {
      return localities;
    } else {
      return localities
        .concat()
        .sort((a, b) => a.indexNumber - b.indexNumber)
        .slice(0, 5);
    }
  }
);

export const selectLocationUiState = createSelector(
  selectSearchState,
  (s) => s.uiState.location
);

export const selectShowAll = createSelector(
  selectLocationUiState,
  (s) => s.allItemsVisible
);

export const selectShowTopOnly = createSelector(
  selectLocationUiState,
  (s) => !s.allItemsVisible
);

export const selectLocationExpanded = createSelector(
  selectLocationUiState,
  (s) => s.isExpanded
);

export const selectLocationFilterActive = createSelector(
  selectFilter,
  (s) => s.localityIds.length > 0
);

export const selectLocationFilterLocationsActiveMap = createSelector(
  selectFilter,
  selectLocalities,
  (f, l) => {
    let result = {};
    l.forEach((c) => (result[c.localityId] = false));
    f.localityIds.forEach((c) => (result[c] = true));

    return result;
  }
);

// Categories

export const selectDisplayCategories = createSelector(
  selectHeadCategories,
  selectSearchState,
  (headCategories, search) => {
    if (search.uiState.category.allItemsVisible) {
      return headCategories;
    } else {
      return headCategories
        .concat()
        .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
        .slice(0, 5);
    }
  }
);

export const selectActiveCategoriesCountByHead = createSelector(
  selectCategoriesMap,
  selectFilter,
  (allCategories, filter) => {
    let result: { [index: number]: number } = {};

    filter.categoryIds.forEach((e) => {
      if (result[allCategories[e].headCategoryId] === undefined) {
        result[allCategories[e].headCategoryId] = 1;
      } else {
        result[allCategories[e].headCategoryId] =
          result[allCategories[e].headCategoryId] + 1;
      }
    });

    return result;
  }
);

export const selectCategoryUiState = createSelector(
  selectSearchState,
  (s) => s.uiState.category
);

export const selectShowAllCategories = createSelector(
  selectCategoryUiState,
  (s) => s.allItemsVisible
);

export const selectShowTopCategoriesOnly = createSelector(
  selectCategoryUiState,
  (s) => !s.allItemsVisible
);

export const selectCategoriesExpanded = createSelector(
  selectCategoryUiState,
  (s) => s.isExpanded
);

export const selectCategoryFilterActive = createSelector(
  selectFilter,
  (s) => s.categoryIds.length > 0
);

export const selectHeadCategoriesActiveFilterMap = createSelector(
  selectHeadCategories,
  selectCategoriesMap,
  selectFilter,
  (headCategories, allCategories, filter) => {
    let result: { [index: number]: boolean } = {};

    headCategories.forEach((c) => {
      result[c.categoryId] = false;
    });

    filter.categoryIds.forEach((c) => {
      result[allCategories[c].headCategoryId] = true;
    });

    return result;
  }
);

export const selectHeadCategoriesExpandedMap = createSelector(
  selectHeadCategories,
  selectCategoryUiState,
  (headCategories, uiState) => {
    let result: { [index: number]: boolean } = {};

    headCategories.forEach((c) => {
      result[c.categoryId] = false;
    });

    uiState.expandedCategoryIds.forEach((c) => {
      result[c] = true;
    });

    return result;
  }
);

export const selectCategoryFilterCategoriesActiveMap = createSelector(
  selectFilter,
  selectChildCategories,
  (f, o) => {
    let result = {};
    o.forEach((c) => (result[c.categoryId] = false));
    f.categoryIds.forEach((c) => (result[c] = true));

    return result;
  }
);

//Salary

export const selectSalaryUiState = createSelector(
  selectSearchState,
  (s) => s.uiState.salary
);

export const selectSalaryExpanded = createSelector(
  selectSalaryUiState,
  (s) => s.isExpanded
);

export const selectSalaryFilterActive = createSelector(
  selectFilter,
  selectSalaryOptions,
  (f, o) =>
    o[f.salaryRangeOptionId] && o[f.salaryRangeOptionId].value !== undefined
);

//Work experience

export const selectExperienceUiState = createSelector(
  selectSearchState,
  (s) => s.uiState.experience
);

export const selectExperienceExpanded = createSelector(
  selectExperienceUiState,
  (s) => s.isExpanded
);

export const selectExperienceFilterActive = createSelector(
  selectFilter,
  (s) => s.experienceRangeOptionIds.length > 0
);

export const selectExperienceFilterOptionsActiveMap = createSelector(
  selectFilter,
  selectWorkExperienceOptions,
  (f, o) => {
    let result = {};
    o.forEach((c) => (result[c.key] = false));
    f.experienceRangeOptionIds.forEach((c) => (result[c] = true));

    return result;
  }
);

export const selectExperienceFilter = createSelector(selectFilter, (e) => {
  let result: WorkExperienceFilterModel = {
    experienceRange: e.experienceRange,
    withoutWorkExperience: e.withoutWorkExperience,
    anyExperience: e.anyExperience,
  };

  return result;
});

//Announcement type

export const selectAnnouncementTypeUiState = createSelector(
  selectSearchState,
  (s) => s.uiState.announcementType
);

export const selectAnnouncementTypeExpanded = createSelector(
  selectAnnouncementTypeUiState,
  (s) => s.isExpanded
);

export const selectAnnouncementTypeFilterActive = createSelector(
  selectFilter,
  (s) => s.announcementTypeId !== undefined
);

//Publish date

export const selectPublishDateUiState = createSelector(
  selectSearchState,
  (s) => s.uiState.publishDate
);

export const selectPublishDateExpanded = createSelector(
  selectPublishDateUiState,
  (s) => s.isExpanded
);

export const selectPublishDateFilterActive = createSelector(
  selectFilter,
  selectPublishDateOptions,
  (f, o) =>
    o[f.publishDateRangeOptionId] &&
    o[f.publishDateRangeOptionId].value !== undefined
);

//filter

export const selectFilterExpanded = createSelector(
  selectSearchState,
  (s) => s.uiState.isExpanded
);

//paging

export const selectPageCount = createSelector(
  selectSearchState,
  selectFrontPageNumberOfItemsOnPage,
  (s, p) => {
    let result = Math.max(1, Math.ceil(s.matchedAnnouncementCount / p));

    return result;
  }
);

export const selectPreviuseSearchPageQueryParams = createSelector(
  selectSearchState,
  selectSalaryOptions('All'),
  selectPublishDateOptions,
  (s, so, po) => {
    let params: Params = convertFilterToQueryParams(s.submittedFilter, so, po);
    let route = { ...params, pg: s.filter.currentPage - 1 };

    return route;
  }
);

export const selectNextSearchPageQueryParams = createSelector(
  selectSearchState,
  selectSalaryOptions('All'),
  selectPublishDateOptions,
  (s, so, po) => {
    let params: Params = convertFilterToQueryParams(s.submittedFilter, so, po);
    let route = { ...params, pg: s.filter.currentPage + 1 };

    return route;
  }
);

export const selectDisplayPages = createSelector(
  selectSearchState,
  selectPageCount,
  selectSalaryOptions('All'),
  selectPublishDateOptions,
  (s, pageCount, so, po) => {
    let result: RoutablePagingNavItem[] = [];
    let halfNavCount = (s.pageLinkCount - 3) / 2; //nav lnks count before and after current page when it's in the middle and does nto interfer with forst and last page
    let fullNavCount = s.pageLinkCount - 2; //nav links count except first and last page
    let currentPage = s.filter.currentPage;

    let params: Params = convertFilterToQueryParams(s.submittedFilter, so, po);

    if (pageCount <= s.pageLinkCount) {
      //if page numebr is less tha totoal nav links just display all pages
      for (let i = 1; i <= pageCount; i++) {
        let route = { ...params, pg: i };
        result.push({
          page: i,
          isElipsis: false,
          isCurrent: i == currentPage,
          queryParams: route,
        });
      }
    } else {
      result.push({
        page: 1,
        isElipsis: false,
        isCurrent: currentPage == 1,
        queryParams: { ...params, pg: 1 },
      }); //display forst page

      if (currentPage == 1 || currentPage - halfNavCount <= 2) {
        //if current page's distance from first page is less then halfNvacount just display all links starting from 2 and then elispsi and last page
        for (let i = 1; i <= fullNavCount; i++) {
          let route = { ...params, pg: i + 1 };
          result.push({
            page: i + 1,
            isElipsis: false,
            isCurrent: i + 1 == currentPage,
            queryParams: route,
          });
        }
        result.push({
          page: -1,
          isElipsis: true,
          isCurrent: false,
          queryParams: null,
        });
        result.push({
          page: pageCount,
          isElipsis: false,
          isCurrent: pageCount == currentPage,
          queryParams: { ...params, pg: pageCount },
        });
      } else if (
        currentPage - halfNavCount > 2 &&
        currentPage + halfNavCount >= pageCount
      ) {
        //if current page's distance from last page is less than halfNavcount display all linkes starting from last page - fullNavCount -1, and then last page
        result.push({
          page: -1,
          isElipsis: true,
          isCurrent: false,
          queryParams: null,
        });

        for (let i = 0; i < fullNavCount; i++) {
          result.push({
            page: pageCount - fullNavCount + i,
            isElipsis: false,
            isCurrent: pageCount - fullNavCount + i == currentPage,
            queryParams: { ...params, pg: pageCount - fullNavCount + i },
          });
        }

        result.push({
          page: pageCount,
          isElipsis: false,
          isCurrent: pageCount == currentPage,
          queryParams: { ...params, pg: pageCount },
        });
      } else if (
        currentPage - halfNavCount > 2 &&
        currentPage + halfNavCount < pageCount
      ) {
        //if current page is in th emiddle display first page, elispsis, halfnavcoutn pages before current page, current page and half nav coutn after current page and finally last page
        result.push({
          page: -1,
          isElipsis: true,
          isCurrent: false,
          queryParams: null,
        });
        for (let i = 0; i < fullNavCount; i++) {
          result.push({
            page: currentPage - halfNavCount + i,
            isElipsis: false,
            isCurrent: currentPage - halfNavCount + i == currentPage,
            queryParams: { ...params, pg: currentPage - halfNavCount + i },
          });
        }

        if (currentPage - halfNavCount + fullNavCount - 1 < pageCount) {
          result.push({
            page: -1,
            isElipsis: true,
            isCurrent: false,
            queryParams: null,
          });
        }

        result.push({
          page: pageCount,
          isElipsis: false,
          isCurrent: pageCount == currentPage,
          queryParams: { ...params, pg: pageCount },
        });
      }
    }
    return result;
  }
);

export const selectQueryParams = createSelector(
  selectFilter,
  selectSalaryOptionsNew,
  selectPublishDateOptions,
  (f, so, po) => {
    let result = AnnouncementHelpers.convertFilterToQueryParams(f, so, po);
    return result;
  }
);

export const selectIsSearchResultLoading = createSelector(
  selectSearchState,
  (s) => s.loading
);

export const selectSearchResultMetaData = createSelector(
  selectSearchState,
  selectCurrentWebsiteNameForSeo,
  (s, w) => {
    return {
      website: w,
      metaData: s.metaData,
    };
  }
);

export const selectLocationCount = createSelector(
  selectFilter,
  (e) => e.localityIds.length
);

export const selectExperienceCount = createSelector(
  selectFilter,
  (e) => e.experienceRangeOptionIds.length
);

export const selectCategoryCount = createSelector(
  selectFilter,
  (e) => e.categoryIds.length
);

export const selectCategoryIds = createSelector(
  selectFilter,
  selectCategories,
  (e, c) => {
    if (e.categoryIds.length == 0) return [];

    const categories = c.filter((c) => !c.isHead);

    const headCategoryCount: { [index: number]: number } = {};
    const categoryToHeadCategory: { [index: number]: number } = {};

    categories.forEach((category: OccupationCategory) => {
      if (category.headCategoryId in headCategoryCount) {
        headCategoryCount[category.headCategoryId] += 1;
        categoryToHeadCategory[category.id] = category.headCategoryId;
      } else {
        headCategoryCount[category.headCategoryId] = 0;
      }
    });

    e.categoryIds.forEach((item) => {
      headCategoryCount[categoryToHeadCategory[item]] -= 1;
    });

    let appendedHeadCategory = [];

    for (let key in headCategoryCount) {
      if (headCategoryCount[key] == 0) {
        appendedHeadCategory.push(parseInt(key));
      }
    }
    return appendedHeadCategory.concat(e.categoryIds);
  }
);

export const selectLocalityIds = createSelector(
  selectFilter,
  (e) => { 
    if (e.localityIds.length == 0) return [];
    //console.log(e.localityIds)

    return e.localityIds;
  }
);




//Banners
export const selectSearchPageBanners = createSelector(
  selectSearchState,
  (e) => e?.bannerList
);

export const selectSearchPageBannerIds = createSelector(
  selectSearchState,
  (e) => {
    return {
      bannerIdList: e.bannerList?.map((v) => v.bannerId) || [],
      targetId: e.bannerList && e.bannerList.length > 0 && e.bannerList[0].targetId,
    };
  }
);

export const selectFrontPageBannerMap = createSelector(
  selectSearchPageBanners,
  (s) => {
    let map: { [index: number]: Banner } = {};
    s?.reduce((a, c) => {
      a[c.positionId] = c;
      return a;
    }, map);

    return map;
  }
);

export const selectSearchPageTopBanner = createSelector(
  selectFrontPageBannerMap,
  (s) => {
    return s[BannerPositionEnum.Search_results_Top];
  }
);

export const selectSearchPageLeftBanner = createSelector(
  selectFrontPageBannerMap,
  (s) => {
    return s[BannerPositionEnum.Search_results_Left_Sidebar];
  }
);

export const selectSearchPageListtBanner = createSelector(
  selectFrontPageBannerMap,
  (s) => {
    return s[BannerPositionEnum.Search_results_List];
  }
);