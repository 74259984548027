import { Component, OnInit } from '@angular/core';
import { theme } from 'src/environments/theme';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/root-store/state';
import { Observable } from 'rxjs';
import { WebsiteStatsModel } from '../shared/models/website-stats';
import { loadSearchFieldInitData } from 'src/app/root-store/announcement-store/front-page/search-field/search-field-actions';
import { selectSearchFieldStateData } from 'src/app/root-store/announcement-store/front-page/search-field/selectors';
import { Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { selectCategoriesTree, selectCustomerPortalUrl, selectHeadCategories, selectLocalities, selectPublishDateOptions, selectTodayPublishDateOption } from 'src/app/root-store/selectors';
import { AnnouncementFilter } from 'src/app/shared/models/announcement-filter';
import { AnnouncementService } from 'src/app/shared/services/announcement.service';
import { AnnouncementTypeEnum, Locality, OccupationCategory, OptionItem, TreeSelectModel } from '@hrra/common-models';
import { Range } from '@hrra/core';

@Component({
  selector: 'app-search-and-filter',
  templateUrl: `./${theme.name}/search-and-filter.component.html`,
  styleUrls: [`./${theme.name}/search-and-filter.component.scss`],
})
export class SearchAndFilterComponent implements OnInit {
  searchForm: UntypedFormGroup;
  initialData$: Observable<WebsiteStatsModel>;

  locations$: Observable<Locality[]>;
  todayPublishDateOption$: Observable<OptionItem<Range<number>>>;
  publishDateOptions$: Observable<OptionItem<Range<number>>[]>;
  headCategories$: Observable<OccupationCategory[]>;
  customerPortalUrl$: Observable<string>;

  public announcementTypes: any;

  locations = new UntypedFormControl([]);
  categories = new UntypedFormControl([]);

  public categoryTree$: Observable<TreeSelectModel<number>[]>;
  public localityTree$: Observable<TreeSelectModel<number>[]>;

  onSearchButtonClick() {
    let categories = this.categories.value;
    let locations = this.locations.value

    

    let searchData = <AnnouncementFilter>{
      query: this.searchForm.get('searchKeyword').value,
      localityIds: locations,
      categoryIds: categories
    };

    let q = this.announcementService.convertQuickFilterToQueryParams(searchData);

    this.router.navigate(['./search-posting'], {
      queryParams: q
    });
  }

  onAnnouncementType(typeId: number){
    let searchData = <AnnouncementFilter>{
      announcementTypeId: typeId
    };

    let q = this.announcementService.convertQuickFilterToQueryParams(searchData);

    this.router.navigate(['./search-posting'], {
      queryParams: q
    });
  }

  onTodayFilter(publishDateOption:  OptionItem<Range<number>>, publishDateOptions: OptionItem<Range<number>>[]){
    let searchData = <AnnouncementFilter>{
      publishDateRangeOptionId: publishDateOption?.key
    };

    let q = this.announcementService.convertPublishDateOptionsToQueryParams(searchData, publishDateOptions);

    this.router.navigate(['./search-posting'], {
      queryParams: q
    });
  }

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private announcementService: AnnouncementService
  ) {
    this.announcementTypes = AnnouncementTypeEnum

  }

  ngOnInit() {
    this.searchForm = new UntypedFormGroup({
      searchKeyword: new UntypedFormControl(null)
    })
    
    this.store.dispatch(loadSearchFieldInitData());

    this.initialData$ = this.store.pipe(select(selectSearchFieldStateData));
    this.locations$ = this.store.pipe(select(selectLocalities));
    this.todayPublishDateOption$ = this.store.pipe(select(selectTodayPublishDateOption));
    this.publishDateOptions$ = this.store.pipe(select(selectPublishDateOptions));
    this.headCategories$ = this.store.pipe(select(selectHeadCategories));
    this.customerPortalUrl$ = this.store.pipe(select(selectCustomerPortalUrl));

    this.categoryTree$ = this.store.pipe(select(selectCategoriesTree));
    // this.localityTree$ = this.store.pipe(select(selectLocalitiesTree))
  }
}
