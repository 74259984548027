import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { of } from 'rxjs';
import * as cvBoxSectionsActions from './actions/cv-box-page-actions';
import * as frontPageCvBoxActions from './actions/cv-box-front-page-actions';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { CvBoxService } from 'src/app/cv-box/shared/services/cv-box.service';
import { BannerService } from "src/app/banner/shared/services/banner.service";

@Injectable()
export class CvBoxSectionsEffects{

    loadCvBoxPageBoxes$ = createEffect(() => this.actions$.pipe(
        ofType(cvBoxSectionsActions.loadCvBoxes),
        mergeMap(action => this.cvBoxService.getCvBoxPageSections()
          .pipe(
              map(cvBoxSections => cvBoxSectionsActions.loadCvBoxesSuccess({payload: cvBoxSections.data})),
              catchError(response => of(cvBoxSectionsActions.loadCvBoxesFail({payload: response.error})))
          ))
        )
    );

    loadFrontPageCvBoxes$ = createEffect(() => this.actions$.pipe(
        ofType(frontPageCvBoxActions.loadFrontPageCvBoxes),
        mergeMap(() => this.cvBoxService.getCvBoxPageSections()
          .pipe(
              map(cvBoxSections => frontPageCvBoxActions.loadFrontPageCvBoxesSuccess({payload: cvBoxSections.data})),
              catchError(response => of(frontPageCvBoxActions.loadFrontPageCvBoxesFail({payload: response.error})))
          ))
        )
    );

    getCvBoxPageBannerList$ = createEffect(() => this.actions$.pipe(
        ofType(cvBoxSectionsActions.getCvBoxPageBannerList),
        mergeMap(action => this.bannerService.getBannerList(action.payload.targetId, action.payload.bannerHeadId)
        .pipe(
            map(response => cvBoxSectionsActions.getCvBoxPageBannerListSuccess({payload: response.data})),
            catchError(error => of(cvBoxSectionsActions.getCvBoxPageBannerListFailed({payload: error.error})))
        ))
    ));

    constructor(private actions$: Actions, private cvBoxService: CvBoxService, private bannerService: BannerService){}
}