import { createAction, props } from '@ngrx/store';
import { ApiError } from '@hrra/core';
import { JobseekerPrivacy } from 'src/app/jobseeker/shared/models/jobseeker-privacy-model';
import { JobseekerBlacklistUpdateViewModel, JobseekerBlackListViewModel, JobseekerChangePasswordViewModel, JobseekerPrivacyViewModel } from 'src/app/jobseeker/shared/services/jobseeker-settings.service';
import { ActionPayload } from "@hrra/core";
import { BasicOptionItem } from '@hrra/common-models';

export enum Actions{

    LoadJobseekerPrivacy = '[Jobseeker settings] Load jobseeker privacy',
    LoadJobseekerPrivacySuccess = '[Jobseeker settings] Load jobseeker privacy success',
    LoadJobseekerPrivacyFail = '[Jobseeker settings] Load jobseeker privacy fail',

    ChangeJobseekerPrivacy = '[Jobseeker settings] Change jobseeker privacy',
    CancelChanges = '[Jobseeker settings] Cancel jobseeker privacy changes',

    UpdateJobseekerPrivacy = '[Jobseeker settings] Update jobseeker privacy',
    UpdateJobseekerPrivacySuccess = '[Jobseeker settings] Update jobseeker privacy success',
    UpdateJobseekerPrivacyFail = '[Jobseeker settings] Update jobseeker privacy fail',

    ChangeJobseekerPassword = '[Jobseeker settings] Change jobseeker password',
    ChangeJobseekerPasswordSuccess = '[Jobseeker settings] Change jobseeker password success',
    ChangeJobseekerPasswordFail = '[Jobseeker settings] Change jobseeker password fail',
    
    CancelJobseekerPasswordChanges = '[Jobseeker settings] Cancel jobseeker password changes',

    LoadJobseekerBlacklist = '[Jobseeker settings] Load jobseeker blacklist',
    LoadJobseekerBlacklistSuccess = '[Jobseeker settings] Load jobseeker blacklist success',
    LoadJobseekerBlacklistFail = '[Jobseeker settings] Load jobseeker blacklist fail',

    SelectBlacklistItem = '[Jobseeker settings] Select blacklist item',
    RemoveSelectedBlacklistItem = '[Jobseeker settings] Remove selected blacklist item',
    CancelBlacklistChanges = '[Jobseeker settings] Cancel blacklist changes',

    UpdateJobseekerBlacklist = '[Jobseeker settings] Update jobseeker blacklist',
    UpdateJobseekerBlacklistSuccess = '[Jobseeker settings] Update jobseeker blacklist success',
    UpdateJobseekerBlacklistFail = '[Jobseeker settings] Update jobseeker blacklist fail'
}

export const loadJobseekerPrivacy = createAction(
    Actions.LoadJobseekerPrivacy
);

export const loadJobseekerPrivacySuccess = createAction(
    Actions.LoadJobseekerPrivacySuccess,
    props<ActionPayload<JobseekerPrivacyViewModel>>()
);

export const loadJobseekerPrivacyFail = createAction(
    Actions.LoadJobseekerPrivacyFail,
    props<ActionPayload<ApiError>>()
);

export const changeJobseekerPrivacy = createAction(
    Actions.ChangeJobseekerPrivacy,
    props<ActionPayload<{privacyId: number}>>()
);

export const cancelChanges = createAction(
    Actions.CancelChanges
);

export const updateJobseekerPrivacy = createAction(
    Actions.UpdateJobseekerPrivacy,
    props<ActionPayload<{privacyId: number}>>()
);

export const updateJobseekerPrivacySuccess = createAction(
    Actions.UpdateJobseekerPrivacySuccess,
    props<ActionPayload<JobseekerPrivacyViewModel>>()
);

export const updateJobseekerPrivacyFail = createAction(
    Actions.UpdateJobseekerPrivacyFail,
    props<ActionPayload<ApiError>>()
);

export const changeJobseekerPassword = createAction(
  Actions.ChangeJobseekerPassword,
  props<ActionPayload<JobseekerChangePasswordViewModel>>()
);

export const changeJobseekerPasswordSuccess = createAction(
  Actions.ChangeJobseekerPasswordSuccess
);

export const changeJobseekerPasswordFail = createAction(
  Actions.ChangeJobseekerPasswordFail,
  props<ActionPayload<ApiError>>()
);

export const cancelJobseekerPasswordChanges = createAction(
    Actions.CancelJobseekerPasswordChanges
);

export const loadJobseekerBlacklist = createAction(
  Actions.LoadJobseekerBlacklist,
  props<ActionPayload<{ name: string }>>()
);

export const loadJobseekerBlackListSuccess = createAction(
  Actions.LoadJobseekerBlacklistSuccess,
  props<ActionPayload<JobseekerBlackListViewModel>>()
);

export const loadJobseekerBlacklistFail = createAction(
  Actions.LoadJobseekerBlacklistFail,
  props<ActionPayload<ApiError>>()
);

export const selectBlackListItem = createAction(
  Actions.SelectBlacklistItem,
  props<ActionPayload<{ customer: BasicOptionItem }>>()
);

export const removeSelectedBlacklistItem = createAction(
  Actions.RemoveSelectedBlacklistItem,
  props<ActionPayload<{ customer: BasicOptionItem }>>()
);

export const cancelBlacklistChanges = createAction(
  Actions.CancelBlacklistChanges
);

export const updateJobseekerBlacklist = createAction(
  Actions.UpdateJobseekerBlacklist,
  props<ActionPayload<JobseekerBlacklistUpdateViewModel>>()
);

export const updateJobseekerBlacklistSuccess = createAction(
  Actions.UpdateJobseekerBlacklistSuccess,
  props<ActionPayload<JobseekerBlackListViewModel>>()
);

export const updateJobseekerBlacklistFail = createAction(
  Actions.UpdateJobseekerBlacklistFail,
  props<ActionPayload<ApiError>>()
)
