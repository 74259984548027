import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { JobseekerService } from 'src/app/jobseeker/shared/services/jobseeker.service';
import { AppState } from '../../state';
import * as ResetPasswordActions from './actions';


@Injectable()
export class JobseekerResetPasswordEffects{

    resetJobseekerResetPassword$ = createEffect(() => this.actions$.pipe(
        ofType(ResetPasswordActions.resetPassword),
        mergeMap((action) => this.jobseekerService.resetPassword(action.payload)
        .pipe(
            map(() => ResetPasswordActions.resetPasswordSuccess()),
            catchError(response => of(ResetPasswordActions.resetPasswordFail({ payload: response.error})))
        ))
    ));

    constructor(private actions$: Actions, private jobseekerService: JobseekerService){}
}