import *  as XRegExp from 'xregexp';

export class Patterns {
    static id = XRegExp.cache("^[1-9][0-9]{0,10}$");
    static amount = XRegExp.cache("^[1-9][0-9]{0,10}(\\.{[0-9]{0,2}})?$");
    static taxId = XRegExp.cache("^([0-9][0-9\\p{L}]{2,}|[\\p{L}][0-9][0-9\\p{L}]{1,}|[\\p{L}]{2}[0-9][0-9\\p{L}]*|[\\p{L}]{3,}[0-9][0-9\\p{L}]*)$");
    //Javascript Regexp
    static websiteNew = "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$";
    static website = XRegExp.cache("^[a-zA-Z0-9\\\.-:]+\\.[a-zA-Z0-9-]{2,4}$");
    static email = XRegExp.cache("^([\\w-]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([a-z]{2,16}(?:\\.[a-z]{2})?)$", "i");
    static emailNew = XRegExp.cache("^(([^<>()\\[\\]\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$");
    static containsEmail = XRegExp.cache("([\\w-]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([a-z]{2,6}(?:\\.[a-z]{2})?)", "i");
    static companyName = XRegExp.cache("[\\S]{2,}");
    static customerName = XRegExp.cache("[^\ ]{1,}");
    static personName = XRegExp.cache("^[\\s\\p{L}\\'\\-]+$");
    static address = XRegExp.cache("^(([\\p{L}][\\s\\p{L}0-9\\W][\\s\\p{L}0-9\\W]*)|([\\s\\p{L}0-9\\W]+[\\p{L}])|([\\s\\p{L}0-9\\W]+[\\p{L}][\\s\\p{L}0-9\\W]*))$");
    static jobseekerAddress = XRegExp.cache("[^\ ]{3,}");
    static legalName = XRegExp.cache("^(([\\p{L}][\\s\\p{L}0-9\\W][\\s\\p{L}0-9\\W]*)|([\\s\\p{L}0-9\\W]+[\\p{L}])|([\\s\\p{L}0-9\\W]+[\\p{L}][\\s\\p{L}0-9\\W]*))$");
    static phoneNumber = XRegExp.cache("^\\+?([0-9]+\\s?)*$");
    static mobilePhoneNumber = XRegExp.cache("^[0-9]{9,}$");
    static phoneNumberNew = XRegExp.cache("^([+\\(\\)\\[\\]]*[0-9][+\\(\\)\\[\\]\\s\\-]*){6,}$");
    static phoneNumberExtension = XRegExp.cache("^[0-9]{1,5}$");
    static postcode = XRegExp.cache("^([0-9][0-9A-Za-z]{2,}|[A-Za-z][0-9][0-9A-Za-z]{1,}|[A-Za-z]{2}[0-9][0-9A-Za-z]*|[A-Za-z]{3,}[0-9][0-9A-Za-z]*)$");

    static letter = XRegExp.cache("[\\p{L}]");
    static digit = XRegExp.cache("[0-9]");
    static specialCharacter = XRegExp.cache("[^0-9A-Za-z]");

    static numeric = /^[\d]*$/;
    static year = XRegExp.cache("^[0-9]{4}$");
    static textField = XRegExp.cache("[\\S]{3,}");

    static noNumbers = XRegExp.cache("^([^0-9]*)$");

    static jobseekerName = XRegExp.cache("^[\\s\\p{L}\\'\\-]+$");
}
