import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AnnouncementService } from 'src/app/announcement/shared/services/announcement.service';
import * as frontPageActions from './announcement-front-page-actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { BannerService } from 'src/app/banner/shared/services/banner.service';

export {
}

@Injectable()
export class AnnouncementsEffects{

    loadFrontPageAnnouncements$ = createEffect(() => this.actions$.pipe(
        ofType(frontPageActions.loadFrontPageAnnouncements),
        mergeMap(() => this.announcementService.getFrontPageModel()
        .pipe(
            map(announcements => frontPageActions.loadFrontPageAnnouncementsSuccess({payload: announcements.data})),
            catchError(error => of(frontPageActions.loadFrontPageAnnouncementsFail({payload: { error: error }})))
        )
       )
    ));

    loadFavoriteAnnouncements$ = createEffect(() => this.actions$.pipe(
        ofType(frontPageActions.loadFavoriteAnnouncements),
        mergeMap(() => this.announcementService.getFavoriteAnnouncements()
        .pipe(
            map(response => frontPageActions.loadFavoriteAnnouncementsSuccess({payload: response.data})),
            catchError(error => of(frontPageActions.loadFavoriteAnnouncementsFail({payload: { error: error }})))
        )
       )
    ));

    getMainPageBannerList$ = createEffect(() => this.actions$.pipe(
        ofType(frontPageActions.getFrontPageBannerList),
        mergeMap(action => this.bannerService.getBannerList(action.payload.targetId, action.payload.bannerHeadPositionId)
        .pipe(
            map(response => frontPageActions.getFrontPageBannerListSuccess({payload: response.data})),
            catchError(error => of(frontPageActions.getFrontPageBannerListFailed({payload: error.error})))
        ))
    ));

    constructor(private actions$: Actions, private announcementService: AnnouncementService, private bannerService: BannerService){}
}