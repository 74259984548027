<app-spinner [isLoading]="isLoading$ | async">
    <div *ngIf="{companies: companies$ | async,
                 customerCount: customerCount$ | async,
                 website: website$ | async} as data"  
         class="container">
        
        <div class="title">
             {{'label.Companies' | translate}}
        </div>

        <div class="description" [innerHTML]="'text.CompaniesPageDescription' | translate : {customerCount: data.customerCount, website: data.website?.displayName } | safeHtml"></div>
    
        <div class="companies">
            <app-customer-list-item *ngFor="let company of data.companies" [company] = "company"></app-customer-list-item>
        </div>
    

        <div *ngIf="data.companies?.length > 0">
            <ng-container *ngIf="{
                pageCount: pageCount$ | async,
                navItems: navItems$ | async,
                currentPage: currentPage$ | async
            } as data">
                <app-paging [currentPage]="data.currentPage" [pageCount]="data.pageCount" [pagingNavItems]="data.navItems" (onPageNav)="onPagignNavigation($event)"></app-paging>
            </ng-container>
        </div>
        
    </div>
</app-spinner>