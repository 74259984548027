import { BannerStoreState } from "./state";
import { Action, ActionReducerMap, on } from '@ngrx/store';
import * as Actions from './actions'
import { createImmerReducer } from 'ngrx-immer/store';

let initialState: BannerStoreState = {
    insertClickLoading: false,
    insertViewLoading: false
}


const _bannerReducer = createImmerReducer(
    initialState,
    on(Actions.insertBannerClick, (state, { payload }) => {
        state.insertClickLoading = true;
        return state;
    }),
    on(Actions.insertBannerClickSuccess, (state) => {
        state.insertClickLoading = false;
        return state;
    }),
    on(Actions.insertBannerClickFailed, (state, { payload }) => {
        state.insertClickLoading = false;
        return state;
    }),

    on(Actions.insertBannerView, (state, { payload }) => {
        state.insertViewLoading = true;
        return state;
    }),
    on(Actions.insertBannerViewSuccess, (state) => {
        state.insertViewLoading = false;
        return state;
    }),
    on(Actions.insertBannerViewFailed, (state, { payload }) => {
        state.insertViewLoading = false;
        return state;
    })
);


export function bannerReducer(state: BannerStoreState | undefined, action: Action) {
    return _bannerReducer(state, action);
}