<p-treeSelect
    #treeSelect
    [containerStyleClass]="isLarge ? 'tree-select--searchPage': 'tree-select'"
    [formControl]="treeSelectControl"
    [options]="treeNodes"
    [metaKeySelection]="true"
    selectionMode="checkbox"
    placeholder="Select Item"
    [scrollHeight]="scrollHeight"
    [filter]="true"
    [showClear]="true"
    [emptyMessage]="notFoundTextMessage | translate"
    [resetFilterOnHide]="true"
    [filterInputAutoFocus]="false"
    (onNodeSelect)="onSelectNode($event)"
    (onNodeUnselect)="onUnSelectNode($event)"
    (onClear)="onClear()"
    (onHide)="onHide($event)"
    (onShow)="onShow($event)"
    [appendTo]="'body'">
    
        <ng-template pTemplate="triggericon">
            <svg-icon *ngIf="!isDropdownIconDown" src="/assets/icons/arrow-up.svg" [svgClass]="'dropdown-icon-svg'"></svg-icon>
            <svg-icon *ngIf="isDropdownIconDown" src="/assets/icons/arrow-down.svg" [svgClass]="'dropdown-icon-svg'"></svg-icon>
        </ng-template>
        <ng-template pTemplate="itemtogglericon" let-open>
            <svg-icon *ngIf="open" src="/assets/icons/arrow-up.svg" class="arrow-icon"></svg-icon>
            <svg-icon *ngIf="!open" src="/assets/icons/arrow-down.svg" class="arrow-icon"></svg-icon>
        </ng-template>

        <ng-template pTemplate="closeicon">
            <svg-icon src="/assets/icons/close.svg" class="close-icon"></svg-icon>
        </ng-template>

        <ng-template pTemplate="filtericon">
            <svg-icon src="/assets/icons/search-small.svg" class="search-icon"></svg-icon>
        </ng-template>

        <ng-template pTemplate="value" let-node>
            <span *ngIf="itemCount == 0" [ngClass]="isLarge ? 'tree-select-value--searchPage tree-select-value--searchPage__grey':'tree-select-value tree-select-value__grey'">{{ placeHolderTextMessage | translate }}</span>
            <span *ngIf="itemCount > 0" [ngClass]="isLarge ? 'tree-select-value--searchPage tree-select-value--searchPage__primary-green': 'tree-select-value tree-select-value__primary-green'">{{ selectedItemCountText | translate : {count: itemCount} }}</span>
        </ng-template>

        <ng-template pTemplate="footer">
            <div class="footer">
                <button class="footer__reset-button" (click)="onReset()">{{ 'label.ClearFilter' | translate }}</button>
                <button class="footer__approve-button" (click)="onApprove()">{{ 'label.Approve' | translate }}</button>
            </div>
        </ng-template>
</p-treeSelect>