import { createSelector } from "@ngrx/store";
import { displayPages } from "src/app/shared/helpers/paging-helper";
import { selectGeneralDefaultNumberOfListItemsOnPage } from "../../selectors";
import { selectJobseekerStoreState } from "../jobseeker-profile/selectors";

export const selectJobseekerVacancyApplications = createSelector(
    selectJobseekerStoreState,
    s => s.applies.vacancyApplications.applicationModel?.applications
);

export const selectJobseekerCvBoxApplications = createSelector(
    selectJobseekerStoreState,
    s => s.applies.cvBoxApplications.applicationModel?.applications
);

export const selectAnnouncementAppliesCurrentPage = createSelector(
    selectJobseekerStoreState,
    s => s.applies.vacancyApplications.pageState.currentPage
);

export const selectCvBoxAppliesCurrentPage = createSelector(
    selectJobseekerStoreState,
    s => s.applies.cvBoxApplications.pageState.currentPage
);

export const selectVacancyApplicationsPageCount = createSelector(
    selectJobseekerStoreState,
    selectGeneralDefaultNumberOfListItemsOnPage,
    (s, p) => {
    let result = Math.max(
      1,
      Math.ceil(s.applies.vacancyApplications.applicationModel?.totalRecords / p)
    );
  
    return result;
});

export const selectCvBoxApplicationsPageCount = createSelector(
    selectJobseekerStoreState,
    selectGeneralDefaultNumberOfListItemsOnPage,
    (s, p) => {
    if(s.applies.cvBoxApplications.applicationModel == null || p == null) return 1;

    let result = Math.max(
      1,
      Math.ceil(s.applies.cvBoxApplications.applicationModel.totalRecords / p)
    );
  
    return result;
});

export const selectVacancyApplicationsDisplayPages = createSelector(
    selectVacancyApplicationsPageCount,
    selectJobseekerStoreState,
    (c, s) => {
        let current = s.applies.vacancyApplications.pageState.currentPage;
        let pageLinkCount = s.applies.vacancyApplications.pageState.pageLinkCount;
        let pageCount = c;

        return displayPages(current, pageLinkCount, pageCount);
});

export const selectCvBoxApplicationsDisplayPages = createSelector(
    selectCvBoxApplicationsPageCount,
    selectJobseekerStoreState,
    (c, s) => {
        let current = s.applies.cvBoxApplications.pageState.currentPage;
        let pageLinkCount = s.applies.cvBoxApplications.pageState.pageLinkCount;
        let pageCount = c;

        return displayPages(current, pageLinkCount, pageCount);
});

export const selectVacancyApplicationIsLoading = createSelector(
    selectJobseekerStoreState,
    s => s.applies.vacancyApplications.loading
);

export const selectCvBoxApplicationIsLoading = createSelector(
    selectJobseekerStoreState,
    s => s.applies.cvBoxApplications.loading
);