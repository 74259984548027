import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PagingNavItem } from '@hrra/ui';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { loadCustomerList } from 'src/app/root-store/customer-store/public-page-actions';
import { selectCompanies, selectCurrentPage, selectCustomerCount, selectCustomerListPageCount, selectCustomerListPageIsLoading, selectCustomerListPageMetaData, selectDisplayPages } from 'src/app/root-store/customer-store/selectors';
import { selectCurrentWebsite } from 'src/app/root-store/selectors';
import { AppState } from 'src/app/root-store/state';
import { ProviderWebsite } from 'src/app/shared/models/provider-website';
import { MetaService } from 'src/app/shared/services/meta.service';
import { CustomerListItem } from '../shared/models/customer-list-view-model';

@Component({
  selector: 'app-customer-list-page',
  templateUrl: './customer-list-page.component.html',
  styleUrls: ['./customer-list-page.component.scss']
})
export class CustomerListPageComponent implements OnInit {
  
  metaDataSubscription: Subscription;

  public companies$: Observable<CustomerListItem[]>;
  public pageCount$: Observable<number>;
  public navItems$: Observable<PagingNavItem[]>;
  public currentPage$: Observable<number>;
  public isLoading$: Observable<boolean>;
  public customerCount$: Observable<number>;
  public website$: Observable<ProviderWebsite>;

  constructor(private store: Store<AppState>, private metaService: MetaService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((p) => {
      let page: number =+p.get('page')

      if(p.has('page')){
        page =  +p.get('page')
      }
      else{
        page = 1;
      }
      this.store.dispatch(loadCustomerList({payload: {page: page}}));
    })

    this.companies$ = this.store.pipe(select(selectCompanies));
    this.pageCount$ = this.store.pipe(select(selectCustomerListPageCount));
    this.navItems$ = this.store.pipe(select(selectDisplayPages));
    this.currentPage$ = this.store.pipe(select(selectCurrentPage));
    this.isLoading$ = this.store.pipe(select(selectCustomerListPageIsLoading));
    this.customerCount$ = this.store.pipe(select(selectCustomerCount));
    this.website$ = this.store.pipe(select(selectCurrentWebsite));

    this.metaDataSubscription = this.store.pipe(select(selectCustomerListPageMetaData)).subscribe(
      (data) => {
        this.metaService.updateMetaData(data.website, data.metaData);
      }
    )
  }

  onPagignNavigation(page: number){
    this.router.navigate(['companies'], {
      queryParams: {'page': page}
    });
  }
}
