import { createAction, props } from '@ngrx/store';
import { ActionPayload } from "@hrra/core";


export enum Actions {
    LoadCategories = '[Category picker] load categories',

    ToggleExpandHeadCategory = '[Category picker] toggle expand head category',

    SelectCategory = '[Category picker] select category',
    UnselectCategory = '[Category picker] unselect category',
    RemoveCategory = '[Category picker] remove category',

    FilterCategories = '[Category picker] filter categories',

    ResetCategoryFilter = '[Category picker] reset category filter'

}

export const loadCategories = createAction(
    Actions.LoadCategories,
    props<ActionPayload<{ categoryIds: number[] }>>()
);

export const toggleExpandHeadCategory = createAction(
    Actions.ToggleExpandHeadCategory,
    props<ActionPayload<{ categoryId: number }>>()
);

export const selectCategory = createAction(
    Actions.SelectCategory,
    props<ActionPayload<{ categoryId: number}>>()
);

export const unselectCategory = createAction(
    Actions.UnselectCategory,
    props<ActionPayload<{ categoryId: number}>>()
);

export const removeCategory = createAction(
    Actions.RemoveCategory,
    props<ActionPayload<{ categoryId: number}>>()
);

export const filterCategories = createAction(
    Actions.FilterCategories,
    props<ActionPayload<{ query: string }>>()
);

export const resetCategoryFilter = createAction(
    Actions.ResetCategoryFilter
);