import { JobseekerResetPasswordState } from "./state";
import * as JobseekerResetPasswordActions from './actions';
import { Action, on } from '@ngrx/store';
import { createImmerReducer } from 'ngrx-immer/store';


let initialState: JobseekerResetPasswordState = {
    resetPasswordModel: null,
    loading: false,
    resetPasswordFailureDetails: null,
    isResetSuccessed: false
}

const reducer = createImmerReducer(
    initialState,

    on(JobseekerResetPasswordActions.resetPassword, (state, { payload }) => {
        state.resetPasswordModel = payload;
        state.loading = true;
        state.isResetSuccessed = false;
        return state;
    }),
    on(JobseekerResetPasswordActions.resetPasswordSuccess, state => {
        state.resetPasswordModel = null;
        state.loading = false;
        state.isResetSuccessed = true;
        state.resetPasswordFailureDetails = null;
        return state;
    }),
    on(JobseekerResetPasswordActions.resetPasswordFail, (state, { payload }) => {
        state.loading = false;
        state.resetPasswordFailureDetails = payload;
        state.isResetSuccessed = false;
        return state;
    }),
    on(JobseekerResetPasswordActions.clearResetPasswordPageInfo, state => {
        state.resetPasswordFailureDetails = null;
        state.isResetSuccessed = false;
        state.resetPasswordModel = null;
        return state;
    })
)

export function jobseekerResetPasswordReducer(state: JobseekerResetPasswordState | undefined, action: Action) {
    return reducer(state, action);
}