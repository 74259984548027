import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'hrra-work-from-home',
  templateUrl: './work-from-home.component.html',
  styleUrls: ['./work-from-home.component.scss'],
})
export class WorkFromHomeComponent implements OnInit, OnChanges, ControlValueAccessor{
  @Output() workFromHome: EventEmitter<boolean> = new EventEmitter<boolean>();


   public workFromHomeForm!: FormGroup

   onChange = (value) => {};
   onTouched = () => {};


   @Input() filter: any
   constructor(private fb:FormBuilder){}

   onWorkFromHomeChange(){
    this.workFromHome.emit(this.workFromHomeForm.controls.workFromHome.value);
   }

   writeValue(value: any) {}

   isFormReseted(filter: any){
    return !filter;
  }

   registerOnChange(onChange: any) {
     this.onChange = onChange;
   }
   
   registerOnTouched(onTouched: any) {
     this.onTouched = onTouched;
   }

   ngOnChanges(changes: SimpleChanges) {
    if (changes['filter'] ){
      if(this.workFromHomeForm?.controls['workFromHome'].value == undefined){
        this.workFromHomeForm?.controls['workFromHome'].setValue(this.filter.isWorkFromHome);
      }
      const currentFilter = changes.filter.currentValue.isWorkFromHome;
      if(this.isFormReseted(currentFilter) && this.workFromHomeForm != null){
        this.workFromHomeForm.reset();
      
      }
    }
  }

   ngOnInit(){
    this.workFromHomeForm = this.fb.group({
      workFromHome: new FormControl(undefined),
    })
   }
}
