import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerPublicPageComponent } from './customer-public-page/customer-public-page.component';
import { SharedModule } from '../shared/shared.module';
import { CustomerRoutingModule } from './customer-routing.module';
import { AnnouncementModule } from '../announcement/announcement.module';
import { HrUiModule } from '../hr-ui/hr-ui.module';
import { CustomerListItemComponent } from './customer-list-item/customer-list-item.component';
import { CustomerListPageComponent } from './customer-list-page/customer-list-page.component';
import { RouterModule } from '@angular/router';
import { UiModule } from '@hrra/ui';



@NgModule({
  declarations: [CustomerPublicPageComponent, CustomerListItemComponent, CustomerListPageComponent],
  imports: [
    CommonModule,
    SharedModule,
    AnnouncementModule,
    CustomerRoutingModule,
    HrUiModule,
    UiModule,
    RouterModule
    
  ],
  exports:[
    CustomerPublicPageComponent
  ]
})
export class CustomerModule { }
