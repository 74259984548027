<mat-dialog-content>{{data.message | translate}}</mat-dialog-content>
<mat-dialog-actions>
    <button class="button button--yes" [mat-dialog-close] ="true">
        {{'label.Close' | translate}}
    </button>
    <button class="button button--router">
        <a [routerLink]="[data.routerLink]">
            {{data.routerText | translate}}
        </a>
    </button>
</mat-dialog-actions>