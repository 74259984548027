import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as SearchFieldActions from './search-field-actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthService } from '../../../../core/auth.service';
import { AnnouncementService } from '../../../../announcement/shared/services/announcement.service';



@Injectable()
export class SearchFieldEffects {

  loadSearchFieldInitData$ = createEffect(() => this.actions$.pipe(
    ofType(SearchFieldActions.loadSearchFieldInitData),
    mergeMap(a => this.announcementService.getSearchFieldInitialData()
      .pipe(
        map(c => SearchFieldActions.loadSearchFieldInitDataSuccess({ payload: c.data })),
        catchError(err => of(SearchFieldActions.loadSearchFieldInitDataFail({ payload: err })))
      ))
  ));

  constructor(
    private actions$: Actions,
    private announcementService: AnnouncementService,
    private authService: AuthService
  ) { }
}
