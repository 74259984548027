import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { customerReducer } from './reducer';
import { EffectsModule } from '@ngrx/effects';
import { CustomerEffects } from './effects';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('customer', customerReducer),
    EffectsModule.forFeature([CustomerEffects])
  ]
})
export class CustomerStoreModule { }
