import { Action, ActionReducerMap, on } from '@ngrx/store';
import { createImmerReducer } from 'ngrx-immer/store';
import * as infoPageActions from './actions';
import { InfoPageStoreState } from './state';

let initialState: InfoPageStoreState = {
    resource: null,
    loading: null
}

const reducer = createImmerReducer(
    initialState,
    on(infoPageActions.loadInfoPage, state => {
        state.loading = true;
        return state;
    }),
    on(infoPageActions.loadInfoPageSuccess, (state, { payload }) => {
        state.loading = false;
        state.resource = payload;
        return state;
    }),
    on(infoPageActions.loadInfoPageFail, (state, { payload }) => {
        state.loading = false;
        return state;
    })
);

export function infoPageReducer(state: InfoPageStoreState | undefined, action: Action){
    return reducer(state, action);
}