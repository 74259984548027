import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { verifyJobseekerEmail } from 'src/app/root-store/jobseeker-store/jobseeker-registration/actions';
import { selectEmailVerificationComplete, selectEmailVeririficationFailed } from 'src/app/root-store/jobseeker-store/jobseeker-registration/selectors';
import { AppState } from 'src/app/root-store/state';

@Component({
  selector: 'app-jobseeker-email-verification',
  templateUrl: './jobseeker-email-verification.component.html',
  styleUrls: ['./jobseeker-email-verification.component.scss']
})
export class JobseekerEmailVerificationComponent implements OnInit {

  public emailVerificationComplete$: Observable<boolean>;
  public emailVerificationFailed$: Observable<boolean>;

  constructor(private store: Store<AppState>, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(p => {
      let verificationCode = p.get('code');
      this.store.dispatch(verifyJobseekerEmail({payload: { verificationCode: verificationCode }}));
    });

    this.emailVerificationComplete$ = this.store.pipe(select(selectEmailVerificationComplete));
    this.emailVerificationFailed$ = this.store.pipe(select(selectEmailVeririficationFailed));
  }

}
