import { createAction, props } from '@ngrx/store';
import { Announcement } from 'src/app/announcement/shared/models/announcement-model';
import { FrontPageViewModel } from 'src/app/announcement/shared/services/announcement.service';
import { Banner } from 'src/app/banner/shared/models/banner-model';
import { ApiError } from '@hrra/core';
import { ActionPayload } from "@hrra/core";

export enum FrontPageActionTypes{
  loadFrontPageAnnouncements = '[Front page] Load front page announcements',
  loadFrontPageAnnouncementsSuccess = '[Front page] Load front page announcements success',
  loadFrontPageAnnouncementsFail = '[Front page] Load front page announcements fail',

  GetFrontPageBannerList = '[Front page] Get front page banner list',
  GetFrontPageBannerListSucess = '[Front page] Get front page banner list success',
  GetFrontPageBannerListFailed = '[Front page] Get front page banner list faled',

  AddAnnouncementsInVipSectionList = '[Front page] Add announcements in vip section list',
  AddAnnouncementsInMergedSectionList = '[Front page] Add announcements in merged section list',
  ResetAnnouncementListCounts = '[Front page] Reset announcement list counts',

  ShowCvBoxSlider = '[Front page] Show cv box slider',
}

export enum FavoriteActions {
  LoadFavoriteAnnouncements = '[Favorite announcements page] Load favorite announcements',
  LoadFavoriteAnnouncementsSuccess = '[Favorite announcements page] Load favorite announcements success',
  LoadFavoriteAnnouncementsFail = '[Favorite announcements page] Load favorite announcements fail'
}

// export const addToFavorites = createAction(
//   '[Front Page] Annoucement added to favorites',
//   props<{ payload: { announcementId: number }}>()
// );

export const loadFrontPageAnnouncements = createAction(
  FrontPageActionTypes.loadFrontPageAnnouncements,
);

export const loadFrontPageAnnouncementsSuccess = createAction(
  FrontPageActionTypes.loadFrontPageAnnouncementsSuccess,
  props<ActionPayload<FrontPageViewModel>>()
);

export const loadFrontPageAnnouncementsFail = createAction(
  FrontPageActionTypes.loadFrontPageAnnouncementsFail,
  props<{ payload: {error: ApiError} }>()
);

export const addAnnouncementsInVipSectionList = createAction(
  FrontPageActionTypes.AddAnnouncementsInVipSectionList
);

export const addAnnouncementsInMergedSectionList = createAction(
  FrontPageActionTypes.AddAnnouncementsInMergedSectionList
);

export const resetAnnouncementListCounts = createAction(
  FrontPageActionTypes.ResetAnnouncementListCounts
);

export const loadFavoriteAnnouncements = createAction(
  FavoriteActions.LoadFavoriteAnnouncements
);

export const showCvBoxSlider = createAction(
  FrontPageActionTypes.ShowCvBoxSlider,
  props<ActionPayload<boolean>>()
);

export const loadFavoriteAnnouncementsSuccess = createAction(
  FavoriteActions.LoadFavoriteAnnouncementsSuccess,
  props<ActionPayload<Announcement[]>>()
);

export const loadFavoriteAnnouncementsFail = createAction(
  FavoriteActions.LoadFavoriteAnnouncementsFail,
  props<ActionPayload<{ error: ApiError }>>()
);

//banners
export const getFrontPageBannerList = createAction(
  FrontPageActionTypes.GetFrontPageBannerList,
  props<ActionPayload<{ targetId: number, bannerHeadPositionId: number }>>()
);
export const getFrontPageBannerListSuccess = createAction(
  FrontPageActionTypes.GetFrontPageBannerListSucess,
  props<ActionPayload<Banner[]>>()
);
export const getFrontPageBannerListFailed = createAction(
  FrontPageActionTypes.GetFrontPageBannerListFailed,
  props<ActionPayload<ApiError>>()
);
