import { Component, OnInit, Input } from '@angular/core';
import { IconButtonTypeEnum } from '../shared/models/enums/icon-button-type-enum';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent implements OnInit {
  
  public iconType: any;
  public iconTypeMap : {[index: number]: {src: string, svgClass: string}}

  @Input() iconButtonType;
  @Input() text;
   
  constructor() {this.iconType = IconButtonTypeEnum }

  ngOnInit(): void {
    this.iconTypeMap = {
      [IconButtonTypeEnum.edit]: {src: '/assets/icons/edit.svg', svgClass: 'edit'},
      [IconButtonTypeEnum.delete]: {src: '/assets/icons/delete.svg', svgClass: 'delete'},
      [IconButtonTypeEnum.attachment]: {src: '/assets/icons/attachment.svg', svgClass: 'attachment'},
      [IconButtonTypeEnum.add]: {src: '/assets/icons/add.svg', svgClass: 'add'}
    }
  }
}
