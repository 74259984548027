import { createAction, props } from '@ngrx/store';
import { WebsiteStatsModel } from 'src/app/announcement/shared/models/website-stats';
import { ActionPayload } from "@hrra/core";

export enum SearchFieldActionTypes {
  loadSearchFieldInitData = '[Front page] Load search field data',
  loadSearchFieldInitDataSuccess = '[Front page] Load search field data success',
  loadSearchFieldInitDataFail = '[Front page] Load search field data fail',
}

export const loadSearchFieldInitData = createAction(
  SearchFieldActionTypes.loadSearchFieldInitData
);

export const loadSearchFieldInitDataSuccess = createAction(
  SearchFieldActionTypes.loadSearchFieldInitDataSuccess,
  props<ActionPayload<WebsiteStatsModel>>()
);

export const loadSearchFieldInitDataFail = createAction(
  SearchFieldActionTypes.loadSearchFieldInitDataFail,
  props<{ payload: { error: any } }>()
);
