import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerComponent } from './banner/banner.component';
import { BannerInsertScriptComponent } from './banner-insert-script/banner-insert-script.component';
import { BannerRoutingModule } from './banner-routing.module';



@NgModule({
  declarations: [BannerComponent, BannerInsertScriptComponent],
  imports: [
    CommonModule,
    BannerRoutingModule
  ],
  exports:[
    BannerComponent,
    BannerInsertScriptComponent
  ]
})
export class BannerModule { }
