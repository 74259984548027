import { AnnouncementStoreState } from './state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AnnouncementListingSectionEnum } from '@hrra/common-models';
import { Announcement } from 'src/app/announcement/shared/models/announcement-model';
import {
  selectCurrentWebsiteNameForSeo,
  selectFavoriteAnnouncementsMap,
} from '../selectors';
import { BannerPositionEnum } from 'src/app/banner/shared/models/enums/banner-position-enum';
import { Banner } from 'src/app/banner/shared/models/banner-model';

export const getAnnouncementState =
  createFeatureSelector<AnnouncementStoreState>('announcement');

export const selectCvBoxCustomerMap = createSelector(
  getAnnouncementState,
  (s) => {
    return s.frontpage.cvBoxDecorateAnnouncementCustomerIds.reduce((a, b) => {
      a[b] = true;
      return a;
    }, {});
  }
);

export const selectAnnouncements = createSelector(
  getAnnouncementState,
  selectFavoriteAnnouncementsMap,
  selectCvBoxCustomerMap,
  (s, fav, cm) => {
    let announcements = s.frontpage.announcements.map((c) =>
      Object.assign({}, c, {
        isFavorite: fav[c.announcementId] === true,
        isWithCvBox: cm[c.customerId] === true,
      })
    );

    return announcements;
  }
);

export const getAnnouncements = createSelector(selectAnnouncements, (s) => s);

export const selectVipCvAnnouncements = createSelector(
  getAnnouncements,
  (state: Announcement[]) => {
    let result = state.filter(
      (a) => a.listingSection == AnnouncementListingSectionEnum.VipCV
    );
    return result.sort(
      (a, b) =>
        new Date(b.renewalDate || b.publishDate).valueOf() -
        new Date(a.renewalDate || a.publishDate).valueOf()
    );
  }
);

export const selectVipAnnouncements = createSelector(
  getAnnouncements,
  (state: Announcement[]) => {
    let result = state.filter(
      (a) => a.listingSection == AnnouncementListingSectionEnum.Vip
    );

    return result.sort(
      (a, b) =>
        new Date(b.renewalDate || b.publishDate).valueOf() -
        new Date(a.renewalDate || a.publishDate).valueOf()
    );
  }
);

export const selectVipAnnouncementsInfiniteScoll = createSelector(
  selectVipAnnouncements,
  getAnnouncementState,
  (va, as) => {
    return va.slice(0, as.frontpage.vipAnnouncementListCount);
  }
);

export const selectExclusiveAnnouncements = createSelector(
  getAnnouncements,
  (state: Announcement[]) => {
    let result = state.filter(
      (a) => a.listingSection == AnnouncementListingSectionEnum.Exclusive
    );

    return result.sort(
      (a, b) =>
        new Date(b.renewalDate || b.publishDate).valueOf() -
        new Date(a.renewalDate || a.publishDate).valueOf()
    );
  }
);

export const selectPrioStandardAnnouncements = createSelector(
  getAnnouncements,
  (state: Announcement[]) => {
    let result = state.filter(
      (a) => a.listingSection == AnnouncementListingSectionEnum.P2
    );

    return result.sort(
      (a, b) =>
        new Date(b.renewalDate || b.publishDate).valueOf() -
        new Date(a.renewalDate || a.publishDate).valueOf()
    );
  }
);

export const selectPrioStartAnnouncements = createSelector(
  getAnnouncements,
  (state: Announcement[]) => {
    let result = state.filter(
      (a) => a.listingSection == AnnouncementListingSectionEnum.P1
    );

    return result.sort(
      (a, b) =>
        new Date(b.renewalDate || b.publishDate).valueOf() -
        new Date(a.renewalDate || a.publishDate).valueOf()
    );
  }
);

export const mergedAnnouncements = createSelector(
  selectExclusiveAnnouncements,
  selectPrioStandardAnnouncements,
  selectPrioStartAnnouncements,
  (ea, sa, psa) => {
    return ea.concat(sa, psa);
  }
);

export const selectMergedAnnouncements = createSelector(
  mergedAnnouncements,
  getAnnouncementState,
  (ma, as) => {
    return ma.slice(0, as.frontpage.mergedAnnouncementListCount);
  }
);

export const orderedAnnouncementListIds = createSelector(
  selectVipCvAnnouncements,
  selectVipAnnouncements,
  selectExclusiveAnnouncements,
  selectPrioStandardAnnouncements,
  selectPrioStartAnnouncements,
  (vc, v, e, p2, p1) => {
    let result: Announcement[] = [];

    return result.concat(vc, v, e, p2, p1).map((e) => {
      return { announcementId: e.announcementId, announcementTitle: e.title };
    });
  }
);

export const selectFavoriteAnnouncements = createSelector(
  getAnnouncementState,
  selectFavoriteAnnouncementsMap,
  selectCvBoxCustomerMap,
  (s, fav, cm) => {
    let announcements = s.frontpage.favoriteAnnouncements.map((c) =>
      Object.assign({}, c, {
        isFavorite: fav[c.announcementId] === true,
        isWithCvBox: cm[c.customerId] === true,
      })
    );
    return announcements;
  }
);

export const selectIsLoading = createSelector(
  getAnnouncementState,
  (s) => s.frontpage.loading
);

export const selectFrontPageMetaData = createSelector(
  getAnnouncementState,
  selectCurrentWebsiteNameForSeo,
  (s, w) => {
    return {
      website: w,
      metaData: s.frontpage.metaData,
    };
  }
);

export const selectFrontPageCityLinks = createSelector(
  getAnnouncementState,
  (s) => {
    return s.frontpage.frontPageCityLinks?.map((c) =>
      Object.assign({}, c, {
        queryParams: { l: JSON.stringify([c.localityId]) },
      })
    );
  }
);

export const selectIsFrontPageLinkVisible = createSelector(
  getAnnouncementState,
  (s) => s.frontpage.frontPageFailureDetails == null && !s.frontpage.loading
);

export const selectFavoriteIds = createSelector(getAnnouncementState, (s) =>
  s.frontpage.favoriteAnnouncements.map((e) => {
    return { announcementId: e.announcementId, announcementTitle: e.title };
  })
);

export const selectIsMainPaigCvBoxSliderVisible = createSelector(
  getAnnouncementState,
  (s) => s.frontpage.isCvBoxSliderVisible
);

//Banners
export const selectFrontPageBanners = createSelector(
  getAnnouncementState,
  (s) => s.frontpage.bannerList
);

export const selectFrontPageBannerIds = createSelector(
  getAnnouncementState,
  (e) => {
    return {
      bannerIdList: e.frontpage.bannerList?.map((v) => v.bannerId) || [],
      targetId: e.frontpage.bannerList &&  e.frontpage.bannerList.length > 0 && e.frontpage.bannerList[0].targetId,
    };
  }
)

export const selectFrontPageBannerMap = createSelector(
  selectFrontPageBanners,
  (s) => {
    let map: { [index: number]: Banner } = {};
    s?.reduce((a, c) => {
      a[c.positionId] = c;
      return a;
    }, map);

    return map;
  }
);

export const selectFrontPageTopBanner = createSelector(
  selectFrontPageBannerMap,
  (s) => {
    return s[BannerPositionEnum.Main_page_Top];
  }
);

export const selectFrontPageMidBanner = createSelector(
  selectFrontPageBannerMap,
  (s) => {
    return s[BannerPositionEnum.Main_page_Mid];
  }
);

export const selectFrontPageMidBanner2 = createSelector(
  selectFrontPageBannerMap,
  (s) => {
    return s[BannerPositionEnum.Main_page_Mid2];
  }
);

export const selectFrontPageBottomBanner = createSelector(
  selectFrontPageBannerMap,
  (s) => {
    return s[BannerPositionEnum.Main_page_bottom];
  }
);

export const selectFavoritePageBannerIds = createSelector(
  selectFrontPageTopBanner,
  selectFrontPageBottomBanner,
  (t,b) => {return {bannerIdList:t && b ? [t.bannerId,b.bannerId]: [], targetId:t && t.targetId}}
)
