<ng-container *ngIf="{ 
    filter: filter$ | async,
    salaryOptions: salaryOptions$ | async,
    publishDateOptions: publishDateOptions$ | async,
    publishDefaultDateOption: publishDefaultDateOption$ | async,
    isFilterExpanded: isFilterExpanded$ | async,
    locationCount: locationCount$ | async,
    experienceCount: experienceCount$ | async,
    categoryTree: categoryTree$ | async,
    categoryIds: categoryIds$ | async,
    experienceFilter: experienceFilter$ | async,
    localityTree: localityTree$ | async,
    localityIds: localityIds$ | async} as data">
   
    <div (click)="onMobileFilterExpandToggle()" [ngClass]="{'expand-filter': !data.isFilterExpanded, 'expand-filter--hidden': data.isFilterExpanded}" >
        <div class="expand-filter__text">{{'label.DetailedSearch' | translate}}</div>
        <div class="expand-filter__icon">
            <svg-icon src="../../../assets/img/common/expand-filter.svg"></svg-icon>
        </div>
    </div>
    <div class="ann-filter" [ngClass]="{'mobile-hidden': !data.isFilterExpanded }">
        <div class="filter-container">

            <div class="actual-filters" [ngClass]="{'show-clear-filter': !data.isFilterExpanded, 'show-clear-filter--hidden':data.isFilterExpanded}">
                <div class="clear-search-filter">
                    <div class="clear-search-filter__clear-filters">
                        <span class="clear-search-filter__label">{{"label.SearchParameters" | translate}}</span>
                        <span class="clear-search-filter__btn" (click)="onResetFilter()">{{'label.ClearFilter' | translate}}</span>
                    </div>
                </div>

                <div class="ann-filters">
                    <h6 class="header--bold">{{'label.AnnouncementType' | translate}}</h6>
                    <app-ann-type-filter
                    [selectedTypeOption]="data.filter.announcementTypeId"
                    ></app-ann-type-filter>
                </div>

                <div style="position: relative;" class="search-field">
                    <input id="lbl-search-query" type="text" class="search-query" [placeholder]="'label.SearchKeyword' | translate"
                        (keyup.enter)="onSearch(data.filter, data.salaryOptions, data.publishDateOptions)"
                        (input)="onKeywordFilterChange(q.value)" 
                        [ngModel]="data.filter.query" 
                        #q>
                    <!-- <label class="search-icon" for="lbl-search-query"></label> -->
                    <label for="lbl-search-query" style="position: absolute; top: calc(50% - 0.4rem); right: 1.7rem;">
                        <svg-icon title="{{'text.KeywordFilterInfo' | translate}}" [src]="'assets/icons/info.svg'" ></svg-icon>
                    </label>
                </div>
                <!-- <svg-icon src="/assets/icons/double-chevron-down.svg" [svgStyle]="{ 'width.rem':0.81, 'stroke':'red' }"></svg-icon> -->


                <!-- locations -->
                <div class="ann-filters">
                    <h6 class="header--bold">{{'label.Location' | translate}}</h6>
                    <div  class="localities">
                        <hrra-location-tree-select #locationTree
                    (updateTreeValue)="onToggleLocality($event)"
                    [isLarge]="true"
                    [PlaceholderText]="'label.Location'"
                    [SelectItemCountText]="'label.SelectedCityCount'"
                    [selectedNodes]="data.localityIds"
                    [enableVirtualScroll]="true"
                    [compressValues]="true"
                    ></hrra-location-tree-select>
                </div>
                </div>

                <!-- <app-ann-filter-section [title]="'label.Categories' | translate" [isExpanded]="isCategoryFilterExpanded$ | async"
                    [isChanged]="isCategoryFilterActive$ | async" [selectedItemsCount]="data.categoryCount" (onExpandToggle)="onCategoryExpandToggle()">
                    <app-ann-category-filter></app-ann-category-filter>
                </app-ann-filter-section> -->

                <!-- categories -->
                <div class="ann-filters">
                    <h6 class="header--bold">{{'label.Categories' | translate}}</h6>
                    <div *ngIf="data.categoryTree.length > 0" class="categories" id="categories">
                        <hrra-tree-select #tree
                        (updateTreeValue)="onToggleCategory($event)"
                        [treeNodes]="data.categoryTree" 
                        [isLarge]="true"
                        [PlaceholderText]="'label.Categories'"
                        [SelectItemCountText]="'label.SelectedCategoryCount'"
                        [selectedNodes]="data.categoryIds"></hrra-tree-select>
                    </div>
                </div>

                <!-- work from home -->
                <div class="ann-filters">
                    <hrra-work-from-home
                    #workFromHome
                    [filter]="data.filter"
                    (workFromHome)="onWorkFromHomeChange($event)">
                    </hrra-work-from-home>
                </div>

                <!-- salary range -->
                <div class="ann-filters">
                    <hrra-salary 
                    #salaryDropdown
                    [selectedSalaryOption]="data.filter.salaryRangeOptionId"
                    [salaryOptions]="data.salaryOptions" 
                    [filter]="data.filter"
                    (changeSalaryRangeOption)="onChangeSalaryRangeOption($event)"
                    (onlySelectedSalary)="onOnlySelectedSalary($event)">
                </hrra-salary>
                </div>

                <!-- work experience -->
                <div class="ann-filters">
                    <hrra-work-experience
                    [experienceFilter]="data.experienceFilter" 
                    (onExperienceFilter)="onToggleExperienceRangeOption($event)"></hrra-work-experience>
                </div>

                <!-- published -->
                <div class="ann-filters ann-filters-last">
                    <h6 class="header--bold">{{'label.Published' | translate}}</h6>
                    <div class="publish-date">
                        <p-dropdown
                        [ngModel]="data.filter.publishDateRangeOptionId"
                        [optionValue]="'key'"
                        [styleClass]="'publishDateDropdown'"
                        [panelStyleClass]="'dropdown-panel'"
                        [dataKey]="'key'"
                        #publishDateDropdown
                        [options]="data.publishDateOptions" 
                        optionLabel="name" 
                        [showClear]="data.filter.publishDateRangeOptionId != data.publishDefaultDateOption?.key"
                        (onChange)="onChangePublishDateRangeOption($event)"
                        [placeholder]="'label.Published' | translate"
                        [appendTo]="'body'"
                        (onClear)="onClearPublishDateRange()">
                        
                        <ng-template let-item pTemplate="item">
                            <span class="dropdown-item">{{ item.name }}</span>
                        </ng-template>
                        <ng-template pTemplate="dropdownicon">
                            <svg-icon src="/assets/icons/arrow-down.svg" [svgStyle]="{'display':'flex', 'justify-content':'center', 'align-items':'center'}"></svg-icon>
                        </ng-template>
                    </p-dropdown>
                    </div>
                </div>



                <div class="search-buttons">
                    <button class="search-btn"
                    (click)="onSearch(data.filter, data.salaryOptions, data.publishDateOptions)">{{'label.Search' | translate}}</button>
                    <div class="search-buttons__clear-filters">
                        <span class="search-buttons__label">{{"label.SearchParameters" | translate}}</span>
                        <span class="search-buttons__btn" (click)="onResetFilter()">{{'label.ClearFilter' | translate}}</span>
                    </div>
                </div>

            </div>
        </div>



        <!-- <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
        <mat-panel-title>
            Location
        </mat-panel-title>
        </mat-expansion-panel-header>
    
        <p>This is the primary content of the panel.</p>
    
    </mat-expansion-panel> -->


    </div>
</ng-container>