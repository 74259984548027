import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { HrTabGroupComponent } from './hr-tab-group/hr-tab-group.component';
import { HrTabComponent } from './hr-tab/hr-tab.component';
import { TestDashboardComponent } from './test-dashboard/test-dashboard.component';
import { AdvancedButtonComponent } from './advanced-button/advanced-button.component';
import { IconComponent } from './icon/icon.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { InfoPopupComponent } from './info-popup/info-popup.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { PagingComponent } from './paging/paging.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ExceptionDialogComponent } from './exception-dialog/exception-dialog.component';
import { RouterModule } from '@angular/router';
import { AdvancedTabComponent } from './advanced-tab/advanced-tab.component';
import { AdvancedTabGroupComponent } from './advanced-tab-group/advanced-tab-group.component';
import { HrFancyCheckboxComponent } from './hr-fancy-checkbox/hr-fancy-checkbox.component';
import { ConnectFormDirective } from './directives/connect-form.directive';
import { RoutablePagingComponent } from './routable-paging/routable-paging.component';
import { AlertPopupComponent } from './alert-popup/alert-popup.component';

@NgModule({
  declarations: [
    ButtonComponent, IconButtonComponent, HrTabGroupComponent, 
    HrTabComponent, TestDashboardComponent, AdvancedButtonComponent, 
    IconComponent, ConfirmationDialogComponent, InfoPopupComponent, AlertPopupComponent,
    SpinnerComponent,
    PagingComponent,
    SafeHtmlPipe,
    ErrorMessageComponent,
    HrFancyCheckboxComponent,
    ConnectFormDirective,
    ExceptionDialogComponent,
    AdvancedTabComponent,
    AdvancedTabGroupComponent,
    RoutablePagingComponent
  ],
  imports: [
    CommonModule,
    AngularSvgIconModule,
    MatDialogModule,
    MatSnackBarModule,
    TranslateModule,
    RouterModule
  ],
  exports: [
    ButtonComponent,
    IconButtonComponent,
    IconComponent,
    HrTabGroupComponent,
    HrTabComponent,
    AdvancedButtonComponent,
    SpinnerComponent,
    PagingComponent,
    SafeHtmlPipe,
    ErrorMessageComponent,
    HrFancyCheckboxComponent,
    ConnectFormDirective,
    ConfirmationDialogComponent,
    ExceptionDialogComponent,
    AdvancedTabComponent,
    AdvancedTabGroupComponent,
    RoutablePagingComponent
  ]
})
export class UiModule { }
