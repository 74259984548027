import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { subscriberReducer } from './reducer';
import { EffectsModule } from '@ngrx/effects';
import { SubscriberEffects } from './effects';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('subscriber',  subscriberReducer),
    EffectsModule.forFeature([
        SubscriberEffects
    ])
  ]
})
export class SubscriberStoreModule { }
