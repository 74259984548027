<form [formGroup]="workExperienceForm" #ngForm="ngForm" class="form">
    <div class="header">{{'label.WorkExperienceYears' | translate}}</div>

    <div style="display: flex; gap: 0.938rem; align-items: center;">
        <input min="0" placeholder="{{'label.From' | translate}}" (input)="onWorkExperienceFilter()" class="input-text" formControlName="workExperienceFrom" type="number">
        <span>-</span>
        <input placeholder="{{'label.To' | translate}}" (input)="onWorkExperienceFilter()" class="input-text" formControlName="workExperienceTo" type="number">
    </div>

    <div class="checkbox-container">
        <input (change)="onWorkExperienceFilter()" formControlName="withoutWorkExperience" type="checkbox">
        <label class="label">{{'label.WithoutWorkExperience' | translate}}</label>
    </div>

    <div class="checkbox-container">
        <input (change)="onWorkExperienceFilter()" formControlName="anyExperience" type="checkbox">
        <label class="label">{{'label.WithAnyWorkExperience' | translate}}</label>
    </div>
</form>