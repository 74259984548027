import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ConnectFormDirective } from './connect-form.directive';
import { ClickOutsideDirective } from './click-outside.directive';



@NgModule({
  declarations: [SafeHtmlPipe, ConnectFormDirective, ClickOutsideDirective],
  imports: [
    CommonModule
  ],
  exports:[
    TranslateModule,
    SafeHtmlPipe,
    ConnectFormDirective,
    ClickOutsideDirective
  ]
})
export class SharedModule { }
