import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvService {

  //TODO old
  public apiUrl = 'EMPTY_URL_PLACEHOLDER';
  public analyticsApi = 'EMPTY_ANALYTICS_URL_PLACEHOLDER'

  apiConfig: { [key: string]: string };

  public enableDebug = false;
  public logLevel = 'info'
  public topGeOn = "true";
  constructor() { }
}
