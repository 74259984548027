import { Injectable } from '@angular/core';
import { SmartHttpService } from '@hrra/core';
import { UrlConfigService } from '@hrra/core';
import { UrlConfig, UrlConfigContainer } from 'src/app/core/url-config';
import { StaticPageViewModel } from 'src/app/shared/models/static-page-model';

@Injectable({
  providedIn: 'root'
})
export class InfoPageService {

  public getInfoPageContent(alias: string) {
    return this.smartHttp.get<StaticPageViewModel>(
      this.urlService.format(this.urlService.urlConfig.api.data.infoPage.get, {
        alias: alias,
      }),
      {}
    ); 
  }

  constructor(private smartHttp: SmartHttpService, private urlService: UrlConfigService<UrlConfigContainer>) { }
}
