import { HRRAExceptionCode } from "@hrra/common-models";
import { createSelector } from '@ngrx/store';
import { JobseekerBlacklistUpdateViewModel } from 'src/app/jobseeker/shared/services/jobseeker-settings.service';
import { selectJobseekerStoreState } from '../jobseeker-profile/selectors';

export const selectJobseekerPrivacyList = createSelector(
    selectJobseekerStoreState,
    s => s.jobseekerLookup.lookup?.jobseekerPrivacyList
);

export const selectSelectedJobseekerPrivacy = createSelector(
    selectJobseekerStoreState,
    s => s.jobseekerSettings.selectedPrivacy
);

export const selectJobseekerCurrentPasswordIsIncorrect = createSelector(
  selectJobseekerStoreState,
  (e) => {
    return e.jobseekerSettings.jobseekerChangePasswordFailureDetails?.errorCode == HRRAExceptionCode.JobseekerCurrentPasswordIsIncorrect;
  }
);

export const selectJobseekerPasswordMismatch = createSelector(
    selectJobseekerStoreState,  
    (e) => { 
        return e.jobseekerSettings.jobseekerChangePasswordFailureDetails?.errorCode == HRRAExceptionCode.PasswordMismatch;
    }
);

export const selectJobseekerIgnoredCustomerList = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerSettings.blacklist.jobseekerIgnoredCustomerCurrentList
);

export const selectCustomerList = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerSettings.blacklist.jobseekerCustomerCurrentList
);

export const searchedCustomerList = (searchValue: string = "") => createSelector(
    selectCustomerList,
    e => e.filter(e => e.name.toLowerCase().includes(searchValue.toLowerCase()))
);

export const selectCurrentBlacklist = createSelector(
    selectJobseekerStoreState,
    (e) => {
        let result: JobseekerBlacklistUpdateViewModel = <any>{};
        result.ignoredCustomerList = e.jobseekerSettings.blacklist.jobseekerIgnoredCustomerCurrentList?.map(e => e.value);

        return result;
    }
);

export const selectIsJobseekerSettingsLoading = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerSettings.loadingPrivacy || e.jobseekerSettings.blacklist.loadJobseekerBlacklist
);

export const selectIsJobseekerSettingUpdating = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerSettings.updateLoading
);