import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { changeLanguage } from 'src/app/root-store/actions';
import { selectCustomerPortalUrl, selectFavoriteAnnouncementsCount } from 'src/app/root-store/selectors';
import { AppState } from 'src/app/root-store/state';

@Component({
  selector: 'app-header-public',
  templateUrl: './header-public.component.html',
  styleUrls: ['./header-public.component.scss']
})
export class HeaderPublicComponent implements OnInit {

  @Input() currentLanguage: string;
  @Input() alternativeLanguage: string;
  @Input() defaultLanguage: string

  isBurgerMenuOpen = false;
  
  favCount$: Observable<number>;
  customerPortalUrl$: Observable<string>;

  public isActive(url: string){
    return this.router.isActive(url, { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'});
  }

  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit(): void {
    this.favCount$ = this.store.pipe(select(selectFavoriteAnnouncementsCount));
    this.customerPortalUrl$ = this.store.pipe(select(selectCustomerPortalUrl));
  }

  closeDropdown(){
    if(this.isBurgerMenuOpen){}
    this.isBurgerMenuOpen = false;
 }

  toggleLanguage() {
    let lang = this.currentLanguage == this.defaultLanguage ? this.alternativeLanguage : this.defaultLanguage;
    this.store.dispatch(changeLanguage({ payload: { languageId: lang}}));
  }

  toggleBurgerMenu() {
    this.isBurgerMenuOpen = !this.isBurgerMenuOpen;
  }
}