import { Component, OnInit, Input } from '@angular/core';
import { SimilarAnnouncement } from '../shared/models/similar-announcement-model';
import { theme } from 'src/environments/theme';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/root-store/state';
import { setIsSimilarAnnouncement } from 'src/app/root-store/announcement-store/ann-details-page/actions';

@Component({
  selector: 'app-similar-ann-item',
  templateUrl: `./${theme.name}/similar-ann-item.component.html`,
  styleUrls: [`./${theme.name}/similar-ann-item.component.scss`]
})
export class SimilarAnnItemComponent implements OnInit {

  @Input() similarAnnouncement: SimilarAnnouncement;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
  }

  onSimilarAnnouncement(){
      this.store.dispatch(setIsSimilarAnnouncement());
  }
}