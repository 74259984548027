import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnnSearchFilterComponent } from './ann-search-filter/ann-search-filter.component';
import { AnnSearchComponent } from './ann-search/ann-search.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyInputModule as MatInputModule, MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AnnFilterSectionComponent } from './ann-filter-section/ann-filter-section.component';
import { AnnLocationFilterComponent } from './ann-location-filter/ann-location-filter.component';
import { AnnTypeFilterComponent } from './ann-type-filter/ann-type-filter.component';
import { AnnSalaryFilterComponent } from './ann-salary-filter/ann-salary-filter.component';
import { AnnWorkExperienceFilterComponent } from './ann-work-experience-filter/ann-work-experience-filter.component';
import { AnnCategoryFilterComponent } from './ann-category-filter/ann-category-filter.component';
import { AnnPublishDateFilterComponent } from './ann-publish-date-filter/ann-publish-date-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnnPagingComponent } from './ann-paging/ann-paging.component';
import { AnnouncementItemComponent } from './announcement-item/announcement-item.component';
import { MainPageComponent } from './front-page/front-page.component';
import { CvBoxModule } from '../cv-box/cv-box.module';
import { SharedModule } from '../shared/shared.module';
import { AnnouncementRoutingModule } from './announcement-routing.module';
import { CustomerModule } from '../customer/customer.module';
import { SearchAndFilterComponent } from './search-and-filter/search-and-filter.component';

import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { AnnDetailsPageComponent } from './ann-details-page/ann-details-page.component';
import { SimilarAnnItemComponent } from './similar-ann-item/similar-ann-item.component';
import { AnnFavoritesComponent } from './ann-favorites/ann-favorites.component';
import { HrUiModule } from '../hr-ui/hr-ui.module';
import { UiModule } from '@hrra/ui';
import { BannerModule } from '../banner/banner.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CommonComponentsModule } from '@hrra/common-components';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [
    SearchAndFilterComponent,
    AnnSearchFilterComponent,
    AnnSearchComponent,
    AnnFilterSectionComponent,
    AnnLocationFilterComponent,
    AnnTypeFilterComponent,
    AnnSalaryFilterComponent,
    AnnWorkExperienceFilterComponent,
    AnnCategoryFilterComponent,
    AnnPublishDateFilterComponent,
    AnnPagingComponent,
    AnnouncementItemComponent,
    MainPageComponent,
    AnnDetailsPageComponent,
    SimilarAnnItemComponent,
    AnnFavoritesComponent
  ],
  imports: [
    CommonModule,
    AnnouncementRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CvBoxModule,
    SharedModule,
    MatExpansionModule,
    AngularSvgIconModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    HrUiModule,
    UiModule,
    BannerModule,
    InfiniteScrollModule,
    CarouselModule,
    CommonComponentsModule
  ],
  exports: [AnnSearchComponent, AnnouncementItemComponent],
})
export class AnnouncementModule {}
