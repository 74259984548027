import { CustomerStoreState } from "./state";
import { on, Action } from '@ngrx/store';
import * as CustomerPublicPageActions from './public-page-actions';
import { createImmerReducer } from "ngrx-immer/store";

let initialState: CustomerStoreState = {
    customer: null,
    loading: false,
    customerPageFailureDetails: null,
    isWithFullDescription: false,

    customerListPageState: {
        customerList: null,
        pageLinkCount: 5,
        currentPage: 1,
        loading: false,
        customerListPageFailureDetails: null
    },

    cvBoxApplyLoading: false,
    cvBoxApplyFailureDetails: null,

    isCustomerLoaded: false
}

const reducer = createImmerReducer(
    initialState,
    on(CustomerPublicPageActions.loadCustomer, (state, { payload }) =>{
        state.loading = true;
        return state;
    }),
    on(CustomerPublicPageActions.loadCustomerSuccess, (state, { payload }) => {
        state.customer = payload;
        state.loading = false;
        state.isCustomerLoaded = true;
        return state;
    }),
    on(CustomerPublicPageActions.loadCustomerFail, (state, { payload }) => {
        state.customerPageFailureDetails = payload;
        state.loading = false;
        return state;
    }),
    on(CustomerPublicPageActions.changeFullDescriptionMode, (state) => {
        state.isWithFullDescription = !state.isWithFullDescription;
        return state;
    }),
    on(CustomerPublicPageActions.loadCustomerList, (state, {payload}) => {
        state.customerListPageState.currentPage = payload.page;
        state.customerListPageState.loading = true;
        return state;
    }),
    on(CustomerPublicPageActions.loadCustomerListSuccess, (state, { payload }) => {
        state.customerListPageState.customerList = payload;
        state.customerListPageState.loading = false;
        state.customerListPageState.customerListPageFailureDetails = null;
        return state;
    }),
    on(CustomerPublicPageActions.loadCustomerListFail, (state, { payload }) => {
        state.customerListPageState.loading = false;
        state.customerListPageState.customerListPageFailureDetails = payload;
        return state;
    }),
    on(CustomerPublicPageActions.cvBoxApply, (state, { payload }) => {
        state.cvBoxApplyLoading = true;
        return state;
    }),
    on(CustomerPublicPageActions.cvBoxApplySuccess, (state, { payload }) => {
        state.customer = payload.customer;
        state.cvBoxApplyLoading = false;
        return state;
    }),
    on(CustomerPublicPageActions.cvBoxApplyFailed, (state, { payload }) => {
        state.cvBoxApplyLoading = false;
        return state;
    })
)


export function customerReducer(state: CustomerStoreState | undefined, action: Action) {
    return reducer(state, action);
}