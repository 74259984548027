import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  navigationSubs: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private router: Router, public dialogRef: MatDialogRef<ConfirmationDialogComponent>) { 
    this.navigationSubs = this.router.events.subscribe(e => {
      if(e instanceof NavigationEnd){
        this.dialogRef.close();
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.navigationSubs.unsubscribe();
  }
}
