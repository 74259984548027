import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { combineLatest, of } from "rxjs";
import { catchError, filter, map, mergeMap, take, withLatestFrom } from "rxjs/operators";
import { JobseekerAppliesService } from "src/app/jobseeker/shared/services/jobseeker-applies.service";
import { selectGeneralDefaultNumberOfListItemsOnPage } from "../../selectors";
import { AppState } from "../../state";
import * as JobseekerAppliesActions from './actions';

@Injectable()
export class JobseekerAppliesEffects{

    loadAnnouncementApplications$ = createEffect(() =>
    combineLatest(
        [
            this.actions$.pipe(
                ofType(JobseekerAppliesActions.loadApplications)
            ),
            this.store.pipe(
                select(selectGeneralDefaultNumberOfListItemsOnPage),
                filter(c => c !== undefined),
                take(1)
            )
        ]).pipe(
        mergeMap(([action, pageSize]) => this.service.getApplicationList(action.payload.page, pageSize)
            .pipe(
                map(resp => JobseekerAppliesActions.loadApplicationsSuccess({payload: {application: resp.data, page: action.payload.page}})),
                catchError(response => of(JobseekerAppliesActions.loadApplicationsFail({payload: response.error})))
            ))
        )
    );


    loadCvBoxApplications$ = createEffect(() =>
    combineLatest(
        [
            this.actions$.pipe(
                ofType(JobseekerAppliesActions.loadCvBoxApplications)
            ),
            this.store.pipe(
                select(selectGeneralDefaultNumberOfListItemsOnPage),
                filter(c => c !== undefined),
                take(1)
            )
        ]).pipe(
        mergeMap(([action, pageSize]) => this.service.getCvBoxApplicationList(action.payload.page, pageSize)
            .pipe(
                map(resp => JobseekerAppliesActions.loadCvBoxApplicationsSuccess({payload: {application: resp.data, page: action.payload.page}})),
                catchError(response => of(JobseekerAppliesActions.loadCvBoxApplicationsFail({payload: response.error})))
            ))
        )
    );


    constructor(private actions$: Actions, private service: JobseekerAppliesService, private store: Store<AppState>){}
}