import { createAction, props } from '@ngrx/store';
import { ApiError } from '@hrra/core';
import { CustomerListViewModel } from 'src/app/customer/shared/models/customer-list-view-model';
import { Customer } from 'src/app/customer/shared/models/customer-model';
import { ActionPayload } from "@hrra/core";

export enum CustomerPublicPageActionTypes{
    LoadCustomer = '[Customer public page] load customer',
    LoadCustomerSuccess = '[Customer public page] Load customer success',
    LoadCustomerFail = '[Customer public page] Load customer fail',
    ChangeFullDescriptionMode = '[Customer public page] change description mode',

    LoadCustomerList = '[Customer list page] Load customer list',
    LoadCustomerListSuccess = '[Customer list page] Load customer list success',
    LoadCustomerListFail = '[Customer list page] Load customer list fail',

    UpdateCurrentPage = '[Customer list page] Update current page',

    CvBoxApply = '[Customer public page] Cv box apply',
    CvBoxApplySuccess = '[Customer public page] Cv box apply success',
    CvBoxApplyFailed = '[Customer public page] Cv box apply failed',
  }

  export const loadCustomer = createAction(
    CustomerPublicPageActionTypes.LoadCustomer,
    props<{payload: { customerId: number, urlTitle: string }}>()
  );

  export const loadCustomerSuccess = createAction(
    CustomerPublicPageActionTypes.LoadCustomerSuccess,
    props<{ payload: Customer}>()
  );

  export const loadCustomerFail = createAction(
      CustomerPublicPageActionTypes.LoadCustomerFail,
      props<ActionPayload<ApiError>>()
  );

  export const changeFullDescriptionMode = createAction(
    CustomerPublicPageActionTypes.ChangeFullDescriptionMode
  );

  export const loadCustomerList = createAction(
    CustomerPublicPageActionTypes.LoadCustomerList,
    props<ActionPayload<{page: number}>>()
  );

  export const loadCustomerListSuccess = createAction(
    CustomerPublicPageActionTypes.LoadCustomerListSuccess,
    props<ActionPayload<CustomerListViewModel>>()
  );

  export const loadCustomerListFail = createAction(
    CustomerPublicPageActionTypes.LoadCustomerListFail,
    props<ActionPayload<ApiError>>()
  );

  export const updateCurrentPage = createAction(
    CustomerPublicPageActionTypes.UpdateCurrentPage,
    props<ActionPayload<{page: number}>>()
  );

  export const cvBoxApply = createAction(
    CustomerPublicPageActionTypes.CvBoxApply,
    props<ActionPayload<{ customerId: number}>>()
  );
  
  export const cvBoxApplySuccess = createAction(
    CustomerPublicPageActionTypes.CvBoxApplySuccess,
    props<ActionPayload<{ customer: Customer}>>()
  );
  
  export const cvBoxApplyFailed = createAction(
    CustomerPublicPageActionTypes.CvBoxApplyFailed,
    props<ActionPayload<ApiError>>()
  );