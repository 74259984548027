<header class="header">
    <nav class="navigation">
        <ul class="navigation-list">
            <li class="list-item logo">
                <a *ngIf="!isActive('/')" class="logo__link" [routerLink]="['/']">
                    <img class="logo__img" src="assets/img/common/logo.svg" alt="" />
                </a>
                <a *ngIf="isActive('/')" class="logo__link" [routerLink]="['/']">
                    <img class="logo__img" src="assets/img/common/logo.svg" alt="" />
                </a>
            </li>
    
            <ul class="header-items header-items--small-size">
                <li *ngIf="!isBurgerMenuOpen" class="profile profile--small-size">
                    <a [routerLink]="['/jobseeker/profile']">
                        <img class="profile__icon--mobile" src="assets/img/header/jobseeker-profile-icon.svg" alt="" />
                    </a>
                </li>
                <li *ngIf="!isBurgerMenuOpen" class="favorites favorites--mobile">
                    <a [routerLink]="['/announcement/favorites']">
                        <div class="favorites--mobile__text">
                            <img class="favorites--mobile__icon" src="assets/img/common/heart-icon.svg" alt="" />
                            <div *ngIf="(favCount$ | async) > 0" class="favorites--mobile__count">{{ favCount$ | async }}</div>
                        </div>
                    </a>
                </li>
                <li class="search" [ngClass]="{'search--open-menu': isBurgerMenuOpen}">
                    <a [routerLink]="['/search-posting']">
                        <img class="search__icon" src="assets/img/common/search-icon.svg" alt="" />
                    </a>
                </li>
                <li class="burger-menu">
                    <button class="menu burger-menu__btn" (click)="toggleBurgerMenu()" >
                        <img class="burger-menu__icon" src="assets/img/header/burger-menu-icon.svg" alt="" />
                    </button>
                </li>
            </ul>

            <ul [ngClass]="{'burger-menu--opened': isBurgerMenuOpen}" class="header-items basic-list" appClickOutside (clickOutside)="closeDropdown()" menuClass="menu">
                <li class="basic-list__item vacancy">
                    <a class="vacancy__link" [routerLink]="['/']" menuNavItem>
                        <img class="vacancy__icon" src="assets/img/header/vacancy-icon.svg" alt="" />
                        <div class="vacancy__text">{{ "label.Announcements" | translate }}</div>
                    </a>
                </li>
                <li class="basic-list__item cv-box">
                    <a class="cv-box__link" [routerLink]="['/cvbox']" menuNavItem>
                        <img class="cv-box__icon" src="assets/img/cv-box/cv-box-icon.svg" alt="" />
                        <div class="cv-box__text">{{ 'label.CvBoxes' | translate }}</div>
                    </a>
                </li>
                <li class="basic-list__item favorites">
                    <a class="favorites__link" [routerLink]="['/announcement/favorites']" menuNavItem>
                        <img class="favorites__icon" src="assets/img/common/heart-icon.svg" alt="" />
                        <div class="favorites__text">
                            <div>{{ 'label.Favorites' | translate }}</div>
                            <div *ngIf="(favCount$ | async) > 0" class="favorites__count">{{ favCount$ | async }}</div>
                        </div>
                    </a>
                </li>
                <li class="basic-list__item subscription">
                    <a class="subscription__link" [routerLink]="['/jobseeker/subscription']" menuNavItem>
                        <img class="subscription__icon" src="assets/img/header/subscription-icon.svg" alt="" />
                        <div class="subscription__text">{{ 'label.Subscribe' | translate }}</div>
                    </a>
                </li>
                <li class="basic-list__item profile profile--desktop">
                    <div (click)="toggleProfile()" class="menu">
                        <div class="text profile__jobseeker-name">
                          {{ user?.name }}
                        </div>
                        <img class="profile__icon" src="assets/img/header/jobseeker-profile-icon.svg" alt="" />
                        <img class="profile__down-arrow-icon" src="assets/img/common/down-arrow-icon.svg" alt="" />
                      </div>
                </li>

                <ul *ngIf="isProfileOpen" class="profile-list">
                    <li class="profile-list__item language" *ngIf="defaultLanguage !== null && alternativeLanguage !== null">
                        <div (click)="toggleLanguage()" class="profile-list__text" menuNavItem>
                            {{ (currentLanguage == defaultLanguage ? ('label.lang-'+ alternativeLanguage) : ('label.lang-'+ defaultLanguage)) | translate }}
                        </div>
                    </li>
                    <li class="profile-list__item">
                        <a class="profile-list__link" [routerLink]="['/jobseeker/profile']" menuNavItem>
                            <div class="profile-list__text" menuNavItem>{{ 'label.Profile' | translate }}</div>
                        </a>
                    </li>
                    <li class="profile-list__item">
                        <a [routerLink]="['/jobseeker/applies']" menuNavItem>
                            <div class="profile-list__text">{{ 'label.Applications' | translate }}</div>
                        </a>
                    </li>
                    <li class="profile-list__item">
                        <a [routerLink]="['/jobseeker/settings']" menuNavItem>
                            <div class="profile-list__text">{{ 'label.MySettings' | translate }}</div>
                        </a>
                    </li>
                    <li class="profile-list__item sign-out">
                        <a (click)="onSignOutClick()" style="cursor: pointer;" menuNavItem>
                            <div class="profile-list__text">{{ 'label.SignOut' | translate }}</div>
                        </a>
                    </li>
                </ul>
            </ul>
        </ul>
    </nav>
</header>
