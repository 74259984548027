import { createAction, props } from '@ngrx/store';
import { ApiError } from '@hrra/core';
import { ActionPayload } from "@hrra/core";
import { ResetPasswordModel } from '@hrra/common-models';

export enum Actions{
    ResetPassword = '[Password reset] Reset password',
    ResetPasswordSuccess = '[Password reset] Reset password success',
    ResetPasswordFail = '[Password reset] Reset password fail',

    ClearResetPasswordPageInfo = '[Password reset] Clear reset password page info',
}

export const resetPassword = createAction(Actions.ResetPassword, props<ActionPayload<ResetPasswordModel>>());
export const resetPasswordSuccess = createAction(Actions.ResetPasswordSuccess);
export const resetPasswordFail = createAction(Actions.ResetPasswordFail, props<ActionPayload<ApiError>>());
export const clearResetPasswordPageInfo = createAction(Actions.ClearResetPasswordPageInfo);