import { OwlOptions } from 'ngx-owl-carousel-o';

export function getOwlOptions(){
    let cvBoxSliderCustomOptions: OwlOptions = {
        "autoplay": true, 
        "autoplaySpeed": 4000,
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: false,
        dots: false,
        navSpeed: 4000,
        navText: ['', ''],
        responsive: {
          0: {
            items: 1
          },
          450: {
            items: 2
          },
          650: {
            items: 3
          },
          850: {
            items: 4
          },
          1050: {
            items: 3
          },
          1150: {
            items: 4
          },
          1350: {
            items: 5
          },
          1450: {
            items: 6
          }
          ,
          1650: {
            items: 7
          }
        },
        nav: true
      }

    return cvBoxSliderCustomOptions;
}