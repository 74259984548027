
function getDateKey(date: Date): string {
    let result = date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
    return result;
}

export function announcementPublishTodayHelper(renewalDate, publishDate, currentDate): boolean{
    let rawActualPublishDate = renewalDate || publishDate;
    let actualPublishDate = new Date(rawActualPublishDate);
    
    let publishDateKey = getDateKey(actualPublishDate);
    let currentDateKey = getDateKey(currentDate);

    return publishDateKey == currentDateKey;
}

export function announcementDeadlineTodayHelper(deadlineDate, currentDate): boolean {
    let annDeadlineDate = new Date(deadlineDate);

    let deadlineDateKey = getDateKey(annDeadlineDate)
    let currentDateKey = getDateKey(currentDate);

    return deadlineDateKey == currentDateKey;
}
