import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Params } from '@angular/router';

@Component({
  selector: 'hrra-routable-paging',
  templateUrl: './routable-paging.component.html',
  styleUrls: ['./routable-paging.component.scss']
})
export class RoutablePagingComponent implements OnInit {

  @Input() currentPage: number;
  
  @Input() pagingNavItems: RoutablePagingNavItem[];
  @Input() previousePageQueryParams: any;
  @Input() nextPageQueryParams: any;

  @Input() pageCount: number;

  @Input() queryParams: any;

  constructor() { }

  ngOnInit(): void {
  }

}

export interface RoutablePagingNavItem{
  page: number;
  isElipsis: boolean;
  isCurrent: boolean;
  queryParams: Params;
}
