import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'hrra-advanced-tab',
  templateUrl: './advanced-tab.component.html',
  styleUrls: ['./advanced-tab.component.scss']
})
export class AdvancedTabComponent implements OnInit {

  @Input()
  public label: string;

  public isActive: boolean;

  @ViewChild(TemplateRef)
  public tabContent: TemplateRef<any>


  constructor() { }

  ngOnInit(): void {
  }

}
