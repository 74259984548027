import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { cvBoxReducer } from './reducer';
import { EffectsModule } from '@ngrx/effects';
import { CvBoxSectionsEffects } from './effects';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('cvBox', cvBoxReducer),
    EffectsModule.forFeature([CvBoxSectionsEffects])
  ]
})
export class CvBoxStoreModule { }
