import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { InfoPageEffects } from './effects';
import { infoPageReducer } from './reducer';

@NgModule({
    declarations: [],
    imports: [
      CommonModule,
      StoreModule.forFeature('infoPage',  infoPageReducer),
      EffectsModule.forFeature([InfoPageEffects])
    ]
  })
  export class InfoPageStoreModule { }