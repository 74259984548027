import { createAction, props } from '@ngrx/store';
import { ApiError } from '@hrra/core';
import { MetaDataModel } from 'src/app/shared/models/meta-model';
import { SubscriberRegistrationRequestViewModel, SubscriberRegistrationResponseViewModel, SubscriptionUpdateViewModel, SubscriptionViewModel } from 'src/app/subscriber/shared/services/subsriber.service';
import { ActionPayload } from "@hrra/core";

export enum Actions {
    CreateNewSubscription = '[Subscription page] Create new subscription',
    CreateNewSubscriptionSuccess = '[Subscription page] Create new subscription success',
    CreateNewSubscriptionFail = '[Subscription page] Create new subscription fail',
    

    ResendActivationEmail = '[Subscription complete page] Resend activation email',
    ResendActivationEmailSuccess = '[Subscription complete page] Resend activation email success',
    ResendActivationEmailFail = '[Subscription complete page] Resend activation email fail',

    VerifyEmail = '[Subscriber email verification page] Verify email',
    VerifyEmailSuccess = '[Subscriber email verification page] Verify email success',
    VerifyEmailFail = '[Subscriber email verification page] Verify email fail',

    LoadSubscription = '[Subscription page] Load subscription',
    LoadSubscriptionSuccess = '[Subscription page] Load subscription success',
    LoadSubscriptionFail = '[Subscription page] Load subscription fail',

    UpdateSubscription = '[Edit subscription page] Update subscription',
    UpdateSubscriptionSuccess = '[Edit subscription page] Update subscription success',
    UpdateSubscriptionFail = '[Edit subscription page] Update subscription fail',

    CancelSubscription = '[Cancel subscription page] Cancel subscription',
    CancelSubscriptionSuccess = '[Cancel subscription page] Cancel subscription success',
    CancelSubscriptionFail = '[Cancel subscription page] Cancel subscription fail',

    UpdateSelectedCategories = '[Subscription page] Update selected categories',

    ActivateEditMode = '[Subscription page] Activate edit mode',
    ActivateEditModeFail = '[Subscription page] Activate edit mode fail',

    SetEmailValidateLoading = '[Subscription page] Set email validate loading',

    GetSubscriptionPageSeoData = '[Subscription page] Get subscription page seo data',
    GetSubscriptionPageSeoDataSuccess = '[Subscription page] Get subscription page seo data success',
    GetSubscriptionPageSeoDataFail = '[Subscription page] Get subscription page seo data fail',

    ResetSubscriptionCategories = '[Subscription page] Reset subscription page',

}

export const createNewSubscription = createAction(
    Actions.CreateNewSubscription,
    props<ActionPayload<SubscriberRegistrationRequestViewModel>>()
);

export const createNewSubscriptionSuccess = createAction(
    Actions.CreateNewSubscriptionSuccess,
    props<ActionPayload<SubscriberRegistrationResponseViewModel>>()
);

export const createNewSubscriptionFail = createAction(
    Actions.CreateNewSubscriptionFail,
    props<ActionPayload<ApiError>>()
);



export const resendActivationEmail = createAction(
    Actions.ResendActivationEmail,
    props<ActionPayload<{emailVerificationResendToken: string}>>()
);

export const resendActivationEmailSuccess = createAction(Actions.ResendActivationEmail);

export const ResendActivationEmailFail = createAction(
    Actions.ResendActivationEmailFail,
    props<ActionPayload<ApiError>>()
);

export const verifySubscriberEmail = createAction(
    Actions.VerifyEmail,
    props<ActionPayload<{verificationCode: string}>>()
);

export const verifySubscriberEmailSuccess = createAction(Actions.VerifyEmailSuccess);

export const verifySubscriberEmailFail = createAction(
    Actions.VerifyEmailFail,
    props<ActionPayload<ApiError>>()
);


export const loadSubscription = createAction(
    Actions.LoadSubscription,
    props<ActionPayload<{subscriberCode: string}>>()
);

export const loadSubscriptionSuccess = createAction(
    Actions.LoadSubscriptionSuccess,
    props<ActionPayload<SubscriptionViewModel>>()
);

export const loadSubscriptionFail = createAction(
    Actions.LoadSubscriptionFail,
    props<ActionPayload<ApiError>>()
);

export const updateSubscription = createAction(
    Actions.UpdateSubscription,
    props<ActionPayload<SubscriptionUpdateViewModel>>()
);

export const updateSubscriptionSuccess = createAction(
    Actions.UpdateSubscriptionSuccess,
    props<ActionPayload<SubscriptionViewModel>>()
);

export const updateSubscriptionFail = createAction(
    Actions.UpdateSubscriptionFail,
    props<ActionPayload<ApiError>>()
);


export const cancelSubscription = createAction(
    Actions.CancelSubscription,
    props<ActionPayload<{subscriberCode: string}>>()
);

export const cancelSubscriptionSuccess = createAction(
    Actions.CancelSubscriptionSuccess
);

export const cancelSubscriptionFail = createAction(
    Actions.CancelSubscriptionFail,
    props<ActionPayload<ApiError>>()
);

export const updateSelectedCategories = createAction(
    Actions.UpdateSelectedCategories,
    props<ActionPayload<{ categoryId: number, checked: boolean}>>()
);

export const activateEditMode = createAction(
    Actions.ActivateEditMode
);

export const activateEditModeFail = createAction(
    Actions.ActivateEditModeFail
);

export const setEmailValidateLoading = createAction(
    Actions.SetEmailValidateLoading,
    props<ActionPayload<{isValidateLoading: boolean}>>()
);

export const getSubscriptionPageSeoData = createAction(
    Actions.GetSubscriptionPageSeoData
);
export const getSubscriptionPageSeoDataSuccess = createAction(
    Actions.GetSubscriptionPageSeoDataSuccess,
    props<ActionPayload<MetaDataModel>>()
);
export const getSubscriptionPageSeoDataFail = createAction(
    Actions.GetSubscriptionPageSeoDataFail,
    props<ActionPayload<ApiError>>()
);

export const resetSubscriptionCategories = createAction(
    Actions.ResetSubscriptionCategories
);