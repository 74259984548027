import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { selectAlternativeLanguage, selectCurrentLanguage, selectDefaultLanguage, selectIsSignedIn, selectUser } from 'src/app/root-store/selectors';
import { AppState } from 'src/app/root-store/state';
import { UserTypeEnum } from 'src/app/user/shared/models/enums/user-type-enum';
import { User } from 'src/app/user/shared/models/user-model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isSignedIn$: Observable<boolean>;
  user$: Observable<User>;
  currentLanguage$: Observable<string>;
  alternativeLanguage$: Observable<string>;
  defaultLanguage$: Observable<string>;
  
  userType: any;
  
  constructor(private translate: TranslateService, private store: Store<AppState>) { 
    this.userType = UserTypeEnum
  }

  ngOnInit(): void {
    this.isSignedIn$ = this.store.pipe(select(selectIsSignedIn));
    this.user$ = this.store.pipe(select(selectUser));
    this.currentLanguage$ = this.store.pipe(select(selectCurrentLanguage));
    this.alternativeLanguage$ = this.store.pipe(select(selectAlternativeLanguage));
    this.defaultLanguage$ = this.store.pipe(select(selectDefaultLanguage));
  }
}