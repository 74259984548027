import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { RESPONSE, REQUEST } from '@nguniversal/express-engine/tokens';
import { Request, Response } from 'express'

@Injectable({
  providedIn: 'root'
})
export class SsrService {

  setStatusNotFound(){
    this.setStatus(404);
  }

  setStatusNotAvailable(){
    this.setStatus(410);
  }

  isPlatformServer(){
    return isPlatformServer(this.platformId);
  }

  isPlatformBrowser(){
    return isPlatformBrowser(this.platformId);
  }

  private setStatus(code: number){
    if(isPlatformServer(this.platformId)){
      
      this.response.status(code);
    }
  }

  constructor(@Optional() @Inject(RESPONSE) private response: Response, @Inject(PLATFORM_ID) private platformId: any) { }
}
