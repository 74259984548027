import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { JobseejerLanguageComponent } from './jobseejer-language/jobseejer-language.component';
import { UiModule } from '@hrra/ui';
import { JobseekerDocumentComponent } from './jobseeker-document/jobseeker-document.component';
import { JobseekerEducationComponent } from './jobseeker-education/jobseeker-education.component';
import { JobseekerPersonalInfoComponent } from './jobseeker-personal-info/jobseeker-personal-info.component';
import { JobseekerPhotoComponent } from './jobseeker-photo/jobseeker-photo.component';
import { JobseekerWorkExperienceComponent } from './jobseeker-work-experience/jobseeker-work-experience.component';
import { JobseekerProfileTileComponent } from './jobseeker-profile-tile/jobseeker-profile-tile.component';
import { AnnouncementLocationComponent } from './announcement-location/announcement-location.component';
import { CommonModelsModule } from '@hrra/common-models';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { LocationPickerComponent } from './location-picker/location-picker.component';
import { TreeSelectComponent } from './tree-select/tree-select.component';
import { TreeSelectModule } from 'primeng/treeselect';
import { LocationTreeSelectComponent } from './location-tree-select/location-tree-select.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { FiltersModule } from './filters/filters.module';

@NgModule({
  declarations: [
    JobseejerLanguageComponent,
    JobseekerDocumentComponent,
    JobseekerEducationComponent,
    JobseekerPersonalInfoComponent,
    JobseekerPhotoComponent,
    JobseekerWorkExperienceComponent,
    JobseekerProfileTileComponent,
    LocationPickerComponent,
    AnnouncementLocationComponent,
    TreeSelectComponent,
    LocationTreeSelectComponent
  ],
  imports: [
    CommonModule,
    AngularSvgIconModule,
    MatDialogModule,
    TranslateModule,
    UiModule,
    CommonModelsModule,
    MatAutocompleteModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    TreeSelectModule,
    ProgressSpinnerModule,
    DropdownModule,
    FiltersModule,
  ],
  exports: [
    JobseejerLanguageComponent,
    JobseekerDocumentComponent,
    JobseekerEducationComponent,
    JobseekerPersonalInfoComponent,
    JobseekerPhotoComponent,
    JobseekerWorkExperienceComponent,
    JobseekerProfileTileComponent,
    LocationPickerComponent,
    AnnouncementLocationComponent,
    TreeSelectComponent,
    LocationTreeSelectComponent,
    DropdownModule,
    FiltersModule,
  ],
})
export class CommonComponentsModule {}
