import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CvBoxStoreState } from './state';
import { CvBox } from 'src/app/cv-box/shared/models/cv-box-model';
import { CvBoxSectionEnum } from 'src/app/cv-box/shared/models/enums/cv-box-section-enum';
import { selectCurrentWebsiteNameForSeo } from '../selectors';
import { BannerPositionEnum } from 'src/app/banner/shared/models/enums/banner-position-enum';
import { Banner } from 'src/app/banner/shared/models/banner-model';


export const getCvBoxesState = createFeatureSelector<CvBoxStoreState>('cvBox');

export const selectFilteredCvBoxes = (state: CvBoxStoreState) => state.cvBoxes.filter(c => state.searchTerm == '' || c.customerName.indexOf(state.searchTerm)!=-1);

export const selectCvBoxes = createSelector(getCvBoxesState, selectFilteredCvBoxes);



export const selectVipCvBoxes = createSelector(
    selectCvBoxes,
    (state:  CvBox[]) => {
       let result = state.filter(c => c.cvBoxPageSection == CvBoxSectionEnum.Vip);
       return result.sort((a, b) => 
           b.activationDate.valueOf() - a.activationDate.valueOf() 
       );
    }
);

export const selectPremiumCvBoxes = createSelector(
    selectCvBoxes,
    (state:  CvBox[]) => {
       let result = state.filter(c => c.cvBoxPageSection == CvBoxSectionEnum.Premium);
       return result.sort((a, b) => 
           b.activationDate.valueOf() - a.activationDate.valueOf() 
       );
    }
);

export const selectStandardCvBoxes = createSelector(
    selectCvBoxes,
    (state:  CvBox[]) => {
       let result = state.filter(c => c.cvBoxPageSection == CvBoxSectionEnum.Standard);
       return result.sort((a, b) => 
           b.activationDate.valueOf() - a.activationDate.valueOf() 
       );
    }
);

export const selectAllVipCvBoxes = createSelector(
    getCvBoxesState,
    (state: CvBoxStoreState) => {
        let vipCvBoxes = state.cvBoxes.filter(c => c.cvBoxPageSection == CvBoxSectionEnum.Vip);
        let count = vipCvBoxes.length;
        let result: CvBox[] = [];

        for (let i = count; i > 0; i--) {
            let randomIndex = Math.floor(Math.random() * vipCvBoxes.length);
            result.push(vipCvBoxes[randomIndex]);

            vipCvBoxes.splice(randomIndex, 1);
        }

        return result;
    }
);

export const selectAllPremiumCvBoxes = createSelector(
    getCvBoxesState,
    (state: CvBoxStoreState) => {
        let premiumCvBoxes = state.cvBoxes.filter(c => c.cvBoxPageSection == CvBoxSectionEnum.Premium);
        let vipCvBoxes = state.cvBoxes.filter(c => c.cvBoxPageSection == CvBoxSectionEnum.Vip);
        let cvBoxes= premiumCvBoxes.concat(vipCvBoxes);
       
        let count = cvBoxes.length;
        let result: CvBox[] = [];

        for (let i = count; i > 0; i--) {
            let randomIndex = Math.floor(Math.random() * cvBoxes.length);
            result.push(cvBoxes[randomIndex]);

            cvBoxes.splice(randomIndex, 1);
        }

        return result;
    }
);

export const selectShuffledPremiumCvBoxes = createSelector(
    getCvBoxesState,
    (state: CvBoxStoreState) => {
        let cvBoxes = state.cvBoxes.filter(c => c.cvBoxPageSection == CvBoxSectionEnum.Premium);
       
        let count = cvBoxes.length;
        let result: CvBox[] = [];

        for (let i = count; i > 0; i--) {
            let randomIndex = Math.floor(Math.random() * cvBoxes.length);
            result.push(cvBoxes[randomIndex]);

            cvBoxes.splice(randomIndex, 1);
        }

        return result;
    }
);

export const selectIsCvBoxPageLoading = createSelector(
    getCvBoxesState,
    c => c.loading
);

export const selectCvBoxPageMetaData = createSelector(
    getCvBoxesState,
    selectCurrentWebsiteNameForSeo,
    (c, w) => {
        return {
            website: w,
            metaData: c.metaData
        };
    }
);


//Banners
export const selectCvBoxPageBanners = createSelector(
    getCvBoxesState,
    s => s.bannerList
);

export const selectCvBoxPageBannerIds = createSelector(
    getCvBoxesState,
    (e) => {
        return {
          bannerIdList: e.bannerList?.map((v) => v.bannerId) || [],
          targetId: e.bannerList && e.bannerList.length > 0 && e?.bannerList[0].targetId,
        };
    }
)
export const selectCvBoxPageBannerMap = createSelector(
    selectCvBoxPageBanners,
    s => {
        let map: { [index: number]: Banner } = {};
        s?.reduce((a, c) => {
            a[c.positionId] = c;
            return a;
        }, map);

        return map;
    }
);

export const selectCvBoxVipBanner = createSelector(
    selectCvBoxPageBannerMap,
    (s) => {
        return s[BannerPositionEnum.Cvbox_page_Vip];
    }
);

export const selectCvBoxPremiumBanner = createSelector(
    selectCvBoxPageBannerMap,
    (s) => {
        return s[BannerPositionEnum.Cvbox_page_Premium];
    }
);

export const selectCvBoxRegularBanner = createSelector(
    selectCvBoxPageBannerMap,
    (s) => {
        return s[BannerPositionEnum.Cvbox_page_Regular];
    }
);
