import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse, EmptyResponse } from '@hrra/core';
import { SmartHttpService } from '@hrra/core';
import { UrlConfigService } from '@hrra/core';
import { JobseekerRegistrationValidationMessage, ServerValidationResult } from 'src/app/jobseeker/shared/services/jobseeker.service';
import { UrlConfigContainer } from 'src/app/core/url-config';

@Injectable({
  providedIn: 'root'
})
export class SubscriberService {

  public validateEmail(email: string): Observable<ApiResponse<ServerValidationResult<SubscriberRegistrationValidationMessage>>> {
    return this.smartHttp.get(this.urlService.urlConfig.api.data.subscribers.validateEmail, { email: email });
  }

  public createSubscription(registrationRequest: SubscriberRegistrationRequestViewModel) {
    return this.smartHttp.post<SubscriberRegistrationResponseViewModel>(this.urlService.urlConfig.api.data.subscribers.subscribe, registrationRequest);
  }

  public resendVerificationEmail(emailVerificationResendToken: string) {
    let data = { EmailVerificationResendToken: emailVerificationResendToken };

    return this.smartHttp.post(this.urlService.urlConfig.api.data.subscribers.resendActivationEmail, data);
  }

  public verifyEmail(verificationCode: string) {
    let data = { VerificationCode: verificationCode };

    return this.smartHttp.post(this.urlService.urlConfig.api.data.subscribers.verifyEmail, data);
  }

  public updateSubscription(subscription: SubscriptionUpdateViewModel){
    return this.smartHttp.post<SubscriptionViewModel>(this.urlService.urlConfig.api.data.subscribers.edit, subscription);
  }

  public cancelSubscription(subscriberCode: string){
    return this.smartHttp.post<EmptyResponse>(this.urlService.urlConfig.api.data.subscribers.unsubscribe, { Code: subscriberCode });
  }

  public getSubscription(subscriberCode: string){
    return this.smartHttp.get<SubscriptionViewModel>(this.urlService.urlConfig.api.data.subscribers.get, { subscriberCode: subscriberCode});
  }

  constructor(private smartHttp: SmartHttpService, private urlService: UrlConfigService<UrlConfigContainer>) { }
}


export enum SubscriberRegistrationValidationMessage {
  EmailRegisteredToSubscriber=1,
  EmailRegisteredToJobseekerToAccessYourAccountPleaseLogin=2,
  CannotConnectToServer=-1
}

export interface SubscriberRegistrationRequestViewModel {
  Name: string;
  Email: string;
  DailyDigestOn: boolean;
  OccupationCategoryIds: number[];
  LocalityIds: number[];
}

export interface SubscriptionViewModel {
  name: string;
  code: string;
  email: string;
  dailyDigestOn: boolean;
  subscibedCategoryIds: number[];
  selectedLocalityIds: number[];
}

export interface SubscriberRegistrationResponseViewModel {
  emailVerificationResendToken: string;
}

export interface SubscriptionUpdateViewModel {
  //Name: string;
  Code: string;
  DailyDigestOn: boolean;
  OccupationCategoryIds: number[];
  LocalityIds: number[];
}