import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { JobseekerService } from 'src/app/jobseeker/shared/services/jobseeker.service';
import * as actions from './actions';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { FileDownloadService } from '@hrra/core';

@Injectable()
export class JobseekerEffects{

    loadJobseekerLookups$ = createEffect(() => this.actions$.pipe(
        ofType(actions.loadJobseekerLookups),
        mergeMap(() => this.jobseekerService.getJobseekerLookup()
        .pipe(
            map(response => actions.loadJobseekerLookupsSuccess({payload: response.data})),
            catchError(response => of(actions.loadJobseekersLookupsFailed({payload: response.error})))
        ))
    ));


    constructor(private actions$: Actions, private jobseekerService: JobseekerService, private fileDownloadService: FileDownloadService,){}
}