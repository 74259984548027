import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../state';
import { JobseekerStoreState } from '../state';
import { selectCurrentWebsiteNameForSeo } from '../../selectors';


export const selectJobseekerStoreState = createFeatureSelector<JobseekerStoreState>('jobseeker');

export const selectRegistrationState = createSelector(
    selectJobseekerStoreState,
    j => j.jobseekerRegistration
);


export const selectRegistrationFailed = createSelector(
    selectRegistrationState,
    s => s.regsitrationRequestFailed
);

export const selectResendMaxAttemptsReached = createSelector(
    selectRegistrationState,
    s => s.activationEmailResendMaxAttemptsReached
);

export const selectEmailVerificationResendToken = createSelector(
    selectRegistrationState,
    s => s.emailResendToken
);

export const selectEmailVerificationComplete = createSelector(
    selectRegistrationState,
    s => s.emailVerificationComplete
);

export const selectEmailVeririficationFailed = createSelector(
    selectRegistrationState,
    s => s.emailVerificationError
);

export const selectIsRegistrationLoadign = createSelector(
    selectRegistrationState,
    s => s.loading
);

export const selectCvRegistrationPageMetaData = createSelector(
    selectRegistrationState,
    selectCurrentWebsiteNameForSeo,
    (s, w) => {
        return {
            website: w,
            metaData: s.metaData
        };
    }
);