<div class="container">
    <div class="ann-title" (click)="onSimilarAnnouncement()">
        <a [routerLink]="['/announcement', similarAnnouncement.announcementId, similarAnnouncement.urlFriendlyTitle]">
            <div #hovarableTitle class="text-line ann-title__text">
                {{similarAnnouncement.title}}
            </div>
            <div *ngIf="hovarableTitle?.offsetWidth < hovarableTitle?.scrollWidth" class="ann-title__text tooltip ann-title__tooltip">
                {{similarAnnouncement.title}}
            </div>
        </a>
    </div>

    <div class="location">
        <div #hovarableLocation  class="text-line text-line--visibility">
            <svg-icon src="assets/icons/pin.svg" [svgClass]="'location__icon'"></svg-icon>
            <span *ngFor="let location of similarAnnouncement.locations, let last = last" class="location__text">
                {{location}}{{last ? '' : ','}}
            </span>
        </div>
        <div class="location__text location__text--visibility">
            <div *ngIf="hovarableLocation?.offsetWidth < hovarableLocation?.scrollWidth" class="tooltip location__tooltip">
                <span *ngFor="let location of similarAnnouncement.locations, let last = last">
                    {{location}}{{last ? '' : ','}}
                </span>
            </div>
        </div>
    </div>

    <div class="customer">
        <a *ngIf="!similarAnnouncement.isAnonymous; else elseBlock" [routerLink]="['/customer', similarAnnouncement.customerId, similarAnnouncement.urlFriendlyCustomerName]">
            <div #hovarableCustomer class="text-line customer__name">{{similarAnnouncement.customerName}}</div>
            <div *ngIf="hovarableCustomer?.offsetWidth < hovarableCustomer?.scrollWidth" class="customer__name tooltip customer__tooltip">{{similarAnnouncement.customerName}}</div>
        </a>
        <ng-template #elseBlock>
            <div #hovarableCustomer class="text-line customer__name">{{similarAnnouncement.customerName}}</div>
            <div *ngIf="hovarableCustomer?.offsetWidth < hovarableCustomer?.scrollWidth" class="customer__name tooltip customer__tooltip">{{similarAnnouncement.customerName}}</div>
        </ng-template>
    </div>
</div>