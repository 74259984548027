import { createAction, props } from '@ngrx/store';
import { ApiError } from '@hrra/core';
import { ActionPayload } from "@hrra/core";
import { JobseekerRegistrationRequestViewModel, JobseekerRegsitrationResponseViewModel } from 'src/app/jobseeker/shared/services/jobseeker.service';
import { MetaDataModel } from 'src/app/shared/models/meta-model';

export enum Actions{
    RegisterJobseeker = '[Jobseeker registration page] Regsiter jobseeker',
    RegisterJobseekerSuccess = '[Jobseeker registration page] Regsiter jobseeker success',
    RegisterJobseekerFail = '[Jobseeker registration page] Regsiter jobseeker fail',
    ResendActivationEmail = '[Jobseeker regsitration complete page] Resend activation email',
    ResendActivationEmailSuccess = '[Jobseeker regsitration complete page] Resend activation email success',
    ResendActivationEmailFail = '[Jobseeker regsitration complete page] Resend activation email fail',
    VerifyEmail = '[Jobseeker email verification page] Verify email',
    VerifyEmailSuccess = '[Jobseeker email verification page] Verify email success',
    VerifyEmailFail = '[Jobseeker email verification page] Verify email fail',

    GetCvRegistrationPageSeoData = '[Jobseeker registration page] Get cv registration page seo data',
    GetCvRegistrationPageSeoDataSuccess = '[Jobseeker registration page] Get cv registration page seo data success',
    GetCvRegistrationPageSeoDataFail = '[Jobseeker registration page] Get cv registration page seo data fail',
}


export const registerJobseeker = createAction(
    Actions.RegisterJobseeker, 
    props<ActionPayload<JobseekerRegistrationRequestViewModel>>()
);

export const registerJobseekerSuccess = createAction(
    Actions.RegisterJobseekerSuccess,
    props<ActionPayload<JobseekerRegsitrationResponseViewModel>>()
);

export const registerJobseekerFail = createAction(
    Actions.RegisterJobseekerFail,
    props<ActionPayload<ApiError>>()
);

export const resendActivationEmail = createAction(
    Actions.ResendActivationEmail,
    props<ActionPayload<{emailVerificationResendToken: string}>>()
);

export const resendActivationEmailSuccess = createAction(Actions.ResendActivationEmail);

export const ResendActivationEmailFail = createAction(
    Actions.ResendActivationEmailFail,
    props<ActionPayload<ApiError>>()
);

export const verifyJobseekerEmail = createAction(
    Actions.VerifyEmail,
    props<ActionPayload<{verificationCode: string}>>()
);

export const verifyJobseekerEmailSuccess = createAction(Actions.VerifyEmailSuccess);

export const verifyJobseekerEmailFail = createAction(
    Actions.VerifyEmailFail,
    props<ActionPayload<ApiError>>()
);

export const getCvRegistrationPageSeoData = createAction(
    Actions.GetCvRegistrationPageSeoData
);
export const getCvRegistrationPageSeoDataSuccess = createAction(
    Actions.GetCvRegistrationPageSeoDataSuccess,
    props<ActionPayload<MetaDataModel>>()
);
export const getCvRegistrationPageSeoDataFail = createAction(
    Actions.GetCvRegistrationPageSeoDataFail,
    props<ActionPayload<ApiError>>()
);