import { CvBoxStoreState } from "./state";
import { Action, createReducer, on } from '@ngrx/store';
import * as CvBoxPageActions from './actions/cv-box-page-actions';
import * as FrontPageCvBoActions from './actions/cv-box-front-page-actions';

let initialState: CvBoxStoreState = {
    cvBoxes: [],
    loading: false,
    searchTerm: '',
    metaData: null,
    bannerList: null,
    bannerListLoading: false
}

const reducer = createReducer(
    initialState,
    on(CvBoxPageActions.loadCvBoxes, state => ({
        ...state,
        loading: true
    })),
    on(CvBoxPageActions.loadCvBoxesSuccess, (state, { payload }) => ({
        ...state,
        cvBoxes: payload.cvBoxes,
        metaData: payload.metaData,
        loading: false
    })),
    on(CvBoxPageActions.loadCvBoxesFail, (state, { payload }) => ({
        ...state,
        loading: false
    })),
    on(CvBoxPageActions.SearchCvBox, (state, { payload }) => ({
        ...state,
        searchTerm: payload
    })),
    on(FrontPageCvBoActions.loadFrontPageCvBoxes, state => ({
        ...state,
        loading: true
    })),
    on(FrontPageCvBoActions.loadFrontPageCvBoxesSuccess, (state, { payload }) => ({
        ...state,
        cvBoxes: payload.cvBoxes,
        loading: false
    })),
    on(FrontPageCvBoActions.loadFrontPageCvBoxesFail, (state, { payload }) => ({
        ...state,
        loading: false
    })),
    on(
        CvBoxPageActions.getCvBoxPageBannerList,
        (state, { payload }) => ({
          ...state,
          bannerListLoading: true
        })
      ),
      on(
        CvBoxPageActions.getCvBoxPageBannerListSuccess,
        (state, { payload }) => ({
          ...state,
          bannerList: payload,
          bannerListLoading: false
        })
      ),
      on(
        CvBoxPageActions.getCvBoxPageBannerListFailed,
        (state, { payload }) => ({
          ...state,
          bannerListLoading: false
        })
      ),
)


export function cvBoxReducer(state: CvBoxStoreState | undefined, action: Action) {
    return reducer(state, action);
}