<div class="container">
    <a class="item" *ngIf="currentPage!=1" [routerLink]="['./']"  [queryParams]="previousePageQueryParams">
        <svg-icon class="chevron chevron--left" src="/assets/icons/chevron-left.svg" [svgClass]="'chevron'"></svg-icon>
    </a>
    <div class="paging-container">
        <div *ngFor="let item of pagingNavItems">
            <a *ngIf="!item.isElipsis" [ngClass]="{'item--current' : item.isCurrent, 'item' : true}" [routerLink]="['./']"  [queryParams]="item.queryParams">
                {{ item.page }}
            </a>
            <div *ngIf="item.isElipsis"  [ngClass]="{'item--current' : item.isCurrent, 'item' : true}">
                ...
            </div>
        </div>     
    </div>
    <a class="item"  *ngIf="currentPage!=pageCount" [routerLink]="['./']"  [queryParams]="nextPageQueryParams">
        <svg-icon class="chevron--left" src="assets/icons/chevron-right.svg"  [svgClass]="'chevron'"></svg-icon>
    </a>
</div>