import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { loadInfoPage } from 'src/app/root-store/info-pages-store/actions';
import { selectInfoPageContent, selectInfoPageMetaData, selectIsInfoPageLoading } from 'src/app/root-store/info-pages-store/selectors';
import { AppState } from 'src/app/root-store/state';
import { MetaDataModel } from 'src/app/shared/models/meta-model';
import { StaticPageViewModel } from 'src/app/shared/models/static-page-model';
import { MetaService } from 'src/app/shared/services/meta.service';


@Component({
  selector: 'app-info-page',
  templateUrl: './info-page.component.html',
  styleUrls: ['./info-page.component.scss']
})
export class InfoPageComponent implements OnInit {

  metaDataSubscription: Subscription;

  resource$: Observable<StaticPageViewModel>;
  isLoading$: Observable<boolean>;

  constructor(private store: Store<AppState>,
              private route: ActivatedRoute,
              private metaService: MetaService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((p) => {
      let alias = p.get('alias');
      this.store.dispatch(loadInfoPage({payload: {alias: alias}}));
      this.isLoading$ = this.store.pipe(select(selectIsInfoPageLoading));
    });

    
    this.metaDataSubscription = this.store.pipe(select(selectInfoPageMetaData)).subscribe(
      (data) => {
        this.metaService.updateMetaData(data.website, data.metaData);
      }
    )

    this.resource$ = this.store.pipe(select(selectInfoPageContent));
  }

  ngOnDestroy() {
    this.metaDataSubscription.unsubscribe()
  }
}