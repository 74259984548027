import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SalaryComponent } from './salary/salary.component';
import { DropdownModule } from 'primeng/dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { WorkExperienceComponent } from './work-experience/work-experience.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { WorkFromHomeComponent } from './work-from-home/work-from-home.component';

@NgModule({
  declarations: [
    SalaryComponent,
    WorkExperienceComponent,
    WorkFromHomeComponent,
  ],
  imports: [
    CommonModule,
    AngularSvgIconModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    TranslateModule,
  ],
  exports: [SalaryComponent, WorkExperienceComponent,    WorkFromHomeComponent,
  ],
})
export class FiltersModule {}
