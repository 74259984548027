import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AppState } from 'src/app/root-store/state';
import { Store, select } from '@ngrx/store';
import { toggleExpandLocationFilter, toggleExpandCategoryFilter, toggleExpandSalaryFilter, toggleExpandExperienceFilter, toggleExpandAnnouncementTypeFilter, toggleExpandPublishDateFilter, updateFilter, announcementSearch, resetFilter, updateKeywordFilter, toggleExpandFilter, updateSubmittedFilter, updateCategoryilter, updatePublishDateFilter, updateExperienceFilter, updateSalaryFilter, updateOnlySelectedSalaryFilter, updateLocalityFilter, updateWorkFromHomeFilter } from 'src/app/root-store/announcement-store/search-page/actions';
import { combineLatest, Observable } from 'rxjs';
import { selectLocationExpanded, selectLocationFilterActive, selectCategoriesExpanded, selectCategoryFilterActive, selectSalaryExpanded, selectSalaryFilterActive, selectExperienceExpanded, selectExperienceFilterActive, selectAnnouncementTypeExpanded, selectAnnouncementTypeFilterActive, selectPublishDateExpanded, selectPublishDateFilterActive, selectFilter, selectFilterExpanded, selectLocationCount, selectExperienceCount, selectCategoryCount, selectCategoryIds, selectExperienceFilter, selectLocalityIds } from 'src/app/root-store/announcement-store/search-page/selectors';
import { SearchPageActions } from 'src/app/root-store/announcement-store/search-page';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AnnouncementFilter } from 'src/app/shared/models/announcement-filter';
import { selectSalaryOptions, selectPublishDateOptions, selectSearchResultsStateInfo, selectCategoriesTree, selectDefaultPublishDateOption} from 'src/app/root-store/selectors';
import { AnnouncementService } from 'src/app/shared/services/announcement.service';
import { OptionItem, TreeSelectModel, WorkExperienceFilterModel } from '@hrra/common-models';
import { Range } from '@hrra/core';
import { PageNameEnum } from 'src/app/shared/models/enums/page-names-enum';

@Component({
  selector: 'app-ann-search-filter',
  templateUrl: './ann-search-filter.component.html',
  styleUrls: ['./ann-search-filter.component.scss'],
})
export class AnnSearchFilterComponent implements OnInit {

  @ViewChild('tree')
  public tree!: any;

  @ViewChild('locationTree')
  public locationTree!: any;

  @ViewChild('publishDateDropdown')
  publishDateDropdown!: any;

  @ViewChild('salaryDropdown')
  salaryDropdown!: any

  public localityTree$: Observable<TreeSelectModel<number>[]>

  //locations
  public isLocationFilterExpanded$: Observable<boolean>;
  public isLocationFilterActive$: Observable<boolean>;

  public localityIds$: Observable<number[]>;

  //categories 
  public categoryTree$: Observable<TreeSelectModel<number>[]>;
  public categoryIds$: Observable<number[]>;

  //Todo: remove
  // public isCategoryFilterExpanded$: Observable<boolean>;
  // public isCategoryFilterActive$: Observable<boolean>;


  //salary
  public isSalaryFilterExpanded$: Observable<boolean>;
  public isSalaryFilterActive$: Observable<boolean>;

  //experience
  public isExperienceFilterExpanded$: Observable<boolean>;
  public isExperienceFilterActive$: Observable<boolean>;

  public experienceFilter$: Observable<WorkExperienceFilterModel>;

  //announcement type
  public isAnnouncementTypeFilterExpanded$: Observable<boolean>;
  public isAnnouncementTypeFilterActive$: Observable<boolean>;

  //publish date
  public isPublishDateFilterExpanded$: Observable<boolean>;
  public isPublishDateFilterActive$: Observable<boolean>;

  //filter
  public filter$: Observable<AnnouncementFilter>;
  
  public isFilterExpanded$: Observable<boolean>;

  public salaryOptions$: Observable<OptionItem<Range<number>>[]>;
  public publishDateOptions$: Observable<OptionItem<Range<number>>[]>;
  public publishDefaultDateOption$: Observable<OptionItem<Range<number>>>;

  public locationCount$: Observable<number>;
  public experienceCount$: Observable<number>;
 
  //TODO: remove
  // public categoryCount$: Observable<number>;

  constructor(private store: Store<AppState>, private router: Router, private route: ActivatedRoute, private announcementService: AnnouncementService) { 
  }

  onLocationExpandToggle(){
    this.store.dispatch(toggleExpandLocationFilter());
  }

  onCategoryExpandToggle(){
    this.store.dispatch(toggleExpandCategoryFilter());
  }

  onSalaryExpandToggle(){
    this.store.dispatch(toggleExpandSalaryFilter());
  }

  onExperienceExpandToggle(){
    this.store.dispatch(toggleExpandExperienceFilter());
  }

  onAnnouncementTypeExpandToggle(){
    this.store.dispatch(toggleExpandAnnouncementTypeFilter());
  }

  onPublishDateExpandToggle(){
    this.store.dispatch(toggleExpandPublishDateFilter());
  }

  onMobileFilterExpandToggle(){
    this.store.dispatch(toggleExpandFilter());
  }

  onSearch(filter: AnnouncementFilter, salaryOptions: OptionItem<Range<number>>[], publishDateOptions: OptionItem<Range<number>>[]){
    this.store.dispatch(updateSubmittedFilter());
    
    let q = this.announcementService.convertFilterToQueryParams(filter, salaryOptions, publishDateOptions);
    q['pg'] = 1;
    
    this.router.navigate(['search-posting'], {
      queryParams: q
    });
  }

  onResetFilter(){
    this.store.dispatch(resetFilter());
  }

  onKeywordFilterChange(value: string){
    this.store.dispatch(updateKeywordFilter({ payload: { query: value } }));
  }

  // public convertQueryParamsToFilter(queryParams: ParamMap): AnnouncementFilter{
  //   let result = <AnnouncementFilter>{};

  //   if(queryParams.has(AnnouncementFilterQueryParams.AnnouncementType)){
  //     result.announcementTypeId = +queryParams.get(AnnouncementFilterQueryParams.AnnouncementType);
  //   }

  //   if(queryParams.has(AnnouncementFilterQueryParams.Categories)){
  //     result.categoryIds = JSON.parse(queryParams.get(AnnouncementFilterQueryParams.Categories));
  //   }

  //   if(queryParams.has(AnnouncementFilterQueryParams.Experience)){
  //     result.experienceRangeOptionIds = JSON.parse(queryParams.get(AnnouncementFilterQueryParams.Experience));
  //   }

  //   if(queryParams.has(AnnouncementFilterQueryParams.Locality)){
  //     result.localityIds = JSON.parse(queryParams.get(AnnouncementFilterQueryParams.Locality));
  //   }

  //   if(queryParams.has(AnnouncementFilterQueryParams.PublishDate)){
  //     result.publishDateRangeOptionId = +queryParams.get(AnnouncementFilterQueryParams.PublishDate);
  //   }

  //   if(queryParams.has(AnnouncementFilterQueryParams.Query)){
  //     result.query = queryParams.get(AnnouncementFilterQueryParams.Query);
  //   }

  //   if(queryParams.has(AnnouncementFilterQueryParams.Salary)){
  //     result.salaryRangeOptionId = +queryParams.get(AnnouncementFilterQueryParams.Salary);
  //   }

  //   return result;
  // }

  // public convertFilterToFullFilter(filter: AnnouncementFilter, state: AppState): AnnouncementFullFilter{
  //   let result = <AnnouncementFullFilter>{};
    
  //   let lookup = state.lookup;

  //   if(filter.query !== undefined){
  //     result.query = filter.query;
  //   }

  //   if(filter.announcementTypeId !== undefined){
  //     result.announcementTypeId = filter.announcementTypeId;
  //   }

  //   if(filter.localityIds !== undefined && filter.localityIds.length>0){
  //     result.localityIds = filter.localityIds;
  //   }

  //   if(filter.categoryIds !== undefined && filter.categoryIds.length>0){
  //     result.categoryIds = filter.categoryIds;
  //   }

  //   if(filter.salaryRangeOptionId !== undefined){
  //     let salary = lookup.salaryFilterOptions[filter.salaryRangeOptionId]
  //     result.salary = {
  //       from: salary.value.from,
  //       to: salary.value.to
  //     };
  //   }

  //   if(filter.publishDateRangeOptionId !== undefined){
  //     let publishDate = lookup.publishDateFilterOptions[filter.publishDateRangeOptionId];
  //     result.publishDate = {
  //       from: publishDate.value.from,
  //       to: publishDate.value.to
  //     };
  //   }

  //   if(filter.experienceRangeOptionIds !== undefined && filter.experienceRangeOptionIds.length > 0){
  //     let experienceOptions = filter.experienceRangeOptionIds.map(c => lookup.workExperienceFilterOptions[c]);
  //     result.experience = experienceOptions.map(c => ({ from: c.value.from, to: c.value.to}));
  //   }

  //   return result;
  // }

  // public convertFilterToQueryParams(filter: AnnouncementFilter, salaryOptions: OptionItem<Range<number>>[], publishDateOptions: OptionItem<Range<number>>[]): Params{
  //   let result = <Params> {};

  //   if(filter.query !== undefined){
  //     result[AnnouncementFilterQueryParams.Query] = filter.query;
  //   }

  //   if(filter.announcementTypeId !== undefined){
  //     result[AnnouncementFilterQueryParams.AnnouncementType] = filter.announcementTypeId;
  //   }

  //   if(filter.localityIds !== undefined && filter.localityIds.length>0){
  //     result[AnnouncementFilterQueryParams.Locality] = JSON.stringify(filter.localityIds);
  //   }

  //   if(filter.categoryIds !== undefined && filter.categoryIds.length>0){
  //     result[AnnouncementFilterQueryParams.Categories] = JSON.stringify(filter.categoryIds);
  //   }

  //   if(filter.salaryRangeOptionId !== undefined && salaryOptions[filter.salaryRangeOptionId].value !== undefined){
  //     result[AnnouncementFilterQueryParams.Salary] = filter.salaryRangeOptionId;
  //   }

  //   if(filter.publishDateRangeOptionId !== undefined && publishDateOptions[filter.publishDateRangeOptionId].value !== undefined){
  //     result[AnnouncementFilterQueryParams.PublishDate] = filter.publishDateRangeOptionId;
  //   }

  //   if(filter.experienceRangeOptionIds !== undefined && filter.experienceRangeOptionIds.length > 0){
  //     result[AnnouncementFilterQueryParams.Experience] = JSON.stringify(filter.experienceRangeOptionIds);
  //   }

  //   return result;
  // }

  
  ngOnInit(): void {

    //locations
    this.isLocationFilterExpanded$ = this.store.pipe(select(selectLocationExpanded));
    this.isLocationFilterActive$ = this.store.pipe(select(selectLocationFilterActive));

    //TODO: remove
    //categories
    // this.isCategoryFilterExpanded$ = this.store.pipe(select(selectCategoriesExpanded));
    // this.isCategoryFilterActive$ = this.store.pipe(select(selectCategoryFilterActive));

    //salary
    this.isSalaryFilterExpanded$ = this.store.pipe(select(selectSalaryExpanded));
    this.isSalaryFilterActive$ = this.store.pipe(select(selectSalaryFilterActive));

    // this.localityTree$ = this.store.pipe(select(selectLocalitiesTree))
    this.localityIds$ = this.store.pipe(select(selectLocalityIds))

    //work experience
    this.isExperienceFilterExpanded$ = this.store.pipe(select(selectExperienceExpanded));
    this.isExperienceFilterActive$ = this.store.pipe(select(selectExperienceFilterActive));

    this.experienceFilter$ = this.store.pipe(select(selectExperienceFilter));

    //announcement type
    this.isAnnouncementTypeFilterExpanded$ = this.store.pipe(select(selectAnnouncementTypeExpanded));
    this.isAnnouncementTypeFilterActive$ = this.store.pipe(select(selectAnnouncementTypeFilterActive));

    //publish date
    this.isPublishDateFilterExpanded$ = this.store.pipe(select(selectPublishDateExpanded));
    this.isPublishDateFilterActive$ = this.store.pipe(select(selectPublishDateFilterActive));

    //filter
    this.filter$ = this.store.pipe(select(selectFilter));
    this.salaryOptions$ = this.store.pipe(select(selectSalaryOptions({ emptyItemName: 'All'})));
    this.publishDateOptions$ = this.store.pipe(select(selectPublishDateOptions));
    this.publishDefaultDateOption$ = this.store.pipe(select(selectDefaultPublishDateOption));

    this.isFilterExpanded$ = this.store.pipe(select(selectFilterExpanded));

    this.locationCount$ = this.store.pipe(select(selectLocationCount));
    this.experienceCount$ = this.store.pipe(select(selectExperienceCount));
    
    //remove
    // this.categoryCount$ = this.store.pipe(select(selectCategoryCount));

    this.categoryTree$ = this.store.pipe(select(selectCategoriesTree));
    this.categoryIds$ = this.store.pipe(select(selectCategoryIds));

    this.store.dispatch(SearchPageActions.loadFilterStats());

    let queryParamMap$ = this.route.queryParamMap;
    let lookup$ = this.store.pipe(select(selectSearchResultsStateInfo), filter(c => c.pageSize !== undefined && c.isLookupLoaded));

    combineLatest([queryParamMap$, lookup$])
      .subscribe(([p, l]) => {
        let filter = this.announcementService.convertQueryParamsToFilter(p);
        let fullFilter = this.announcementService.convertFilterToFullFilter(filter, l.lookup, l.pageSize);

        this.store.dispatch(updateFilter({ payload: filter }));
        this.store.dispatch(announcementSearch({payload: fullFilter}));//TODO bad design, read in effect directly from store?
    });
  }

  onToggleCategory(event: number[]){
    this.store.dispatch(updateCategoryilter( { payload: { categoryIds: event } }));
  }

  onToggleLocality(event:number[]){
    this.store.dispatch(updateLocalityFilter({ payload: {localityIds: event}}))
  }

  onChangePublishDateRangeOption(event){
    this.store.dispatch(updatePublishDateFilter( { payload: { publishDateRangeOptionId: event.value } }));
  }

  onClearPublishDateRange(){
    this.store.dispatch(updatePublishDateFilter( { payload: { publishDateRangeOptionId: 0 } }));
  }

  onToggleExperienceRangeOption(event){
    this.store.dispatch(updateExperienceFilter( { payload: { experienceFilter: event } }));
  }

  onChangeSalaryRangeOption(event){
    this.store.dispatch(updateSalaryFilter( { payload: { salaryRangeOptionId: event } }));
  }

  onOnlySelectedSalary(event){
    this.store.dispatch(updateOnlySelectedSalaryFilter ( { payload:  event }));
  }
  onWorkFromHomeChange(event){
    this.store.dispatch(updateWorkFromHomeFilter({ payload: {isWorkFromHome:event}}))
  }
}
