import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AppState, LookupState } from './state';
import { WebsiteInfoState, CurrentInfoState } from './state';
import { UserTypeEnum } from '../user/shared/models/enums/user-type-enum';
import {
  HRRAExceptionCode,
  Locality,
  OccupationCategory,
  TreeSelectModel,
} from '@hrra/common-models';

const selectLookups = createFeatureSelector<LookupState>('lookupMy');

export const selectLookupMy = createSelector(selectLookups, (s) => s);

export const selectCommonLookupsLoaded = createSelector(
  selectLookups,
  (s) => s.commonLookupsLoaded
);

export const selectLocalities = createSelector(
  selectLookups,
  (s) => s.localities
);

export const selectLocalitiesMap = createSelector(selectLocalities, (s) => {
  let map: { [index: number]: Locality } = {};
  s.reduce((a, c) => {
    a[c.localityId] = c;
    return a;
  }, map);

  return map;
});

export const selectLocalitiesMapNotEmpty = createSelector(
  selectLocalities,
  (s) => {
    if (s === null || s === undefined || s.length == 0) {
      return null;
    }
    let map: { [index: number]: Locality } = {};
    s.reduce((a, c) => {
      a[c.localityId] = c;
      return a;
    }, map);

    return map;
  }
);

export const selectCategories = createSelector(
  selectLookups,
  (s) => s.occupationCategories
);

export const selectCategoriesTree = createSelector(selectCategories, (s) => {
  let categoryTree = [];
  let categoryDictionary: { [index: number]: TreeSelectModel<number> } = {};
  let headCategories = s.filter((c) => c.isHead);
  let categories = s.filter((c) => !c.isHead);

  headCategories.forEach((headCategory) => {
    categoryDictionary[headCategory.id] = {
      key: headCategory.id.toString(),
      label: headCategory.name,
      data: headCategory.id,
      date: headCategory.id,
      children: [],
      leaf: false,
      icon: null,
      collapsedIcon: null,
      expandedIcon: null,
      parent: null,
      partialSelected: false,
      expanded:false
    };
  });

  categories.forEach((category) => {
    categoryDictionary[category.headCategoryId].children.push({
      key: category.id.toString(),
      label: category.name,
      data: category.id,
      date: category.id,
      children: null,
      leaf: true,
      icon: null,
      collapsedIcon: null,
      expandedIcon: null,
      parent: categoryDictionary[category.headCategoryId],
      partialSelected: false,
      expanded: false,
    });
  });

  for (var key in categoryDictionary) {
    var children = categoryDictionary[key].children;

    if (children != null) {
      children.sort((a, b) => a.label.localeCompare(b.label));
    }

    categoryDictionary[key].children = children;
    categoryTree.push(categoryDictionary[key]);
  }

  categoryTree.sort((a, b) => a.label.localeCompare(b.label));

  return categoryTree;
});

export const selectHeadCategories = createSelector(selectCategories, (s) =>
  s.filter((c) => c.isHead)
);

export const selectChildCategories = createSelector(selectCategories, (s) =>
  s.filter((c) => !c.isHead)
);

export const selectCategoriesMap = createSelector(selectCategories, (s) => {
  let map: { [index: number]: OccupationCategory } = {};
  s.reduce((a, c) => {
    a[c.categoryId] = c;
    return a;
  }, map);

  return map;
});

export const selectCategoriesMapNotEmpty = createSelector(
  selectCategories,
  (s) => {
    if ((s === null && s === undefined) || s.length == 0) {
      return null;
    }

    let map: { [index: number]: OccupationCategory } = {};
    s.reduce((a, c) => {
      a[c.categoryId] = c;
      return a;
    }, map);

    return map;
  }
);

export const selectAnnouncementTypes = createSelector(
  selectLookups,
  (s) => s.announcementTypes
);

export const selectAnnouncementTypeOptions = createSelector(
  selectLookups,
  (s) => s.announcementTypeOptions
);

export const selectSalaryOptions = (props: any) =>
  createSelector(selectLookups, (s) => {
    return [
      // {
      //     indexNumber: 0,
      //     name: props.emptyItemName,
      //     value: undefined,
      //     isDefault: false,
      //     isEmpty: true
      // },
      ...s.salaryFilterOptions,
    ];
  });

export const selectSalaryOptionsNew = createSelector(selectLookups, (s) => {
  return [
    // {
    //     indexNumber: 0,
    //     name: props.emptyItemName,
    //     value: undefined,
    //     isDefault: false,
    //     isEmpty: true
    // },
    ...s.salaryFilterOptions,
  ];
});

export const selectPublishDateOptions = createSelector(
  selectLookups,
  (s) => s.publishDateFilterOptions
);

export const selectDefaultPublishDateOption = createSelector(
  selectLookups,
  (s) => {
    const defaultPublishDateOptions = s.publishDateFilterOptions.filter(
      (f) => f.key == 0
    );

    if (defaultPublishDateOptions.length == 0) {
      return null;
    } else if (defaultPublishDateOptions.length == 1) {
      return defaultPublishDateOptions[0];
    } else {
      throw Error('More than one default publish date filter option!');
    }
  }
);

export const selectTodayPublishDateOption = createSelector(
  selectPublishDateOptions,
  (s) => s.find((e) => e.value?.from == 0 && e.value.to == 0)
);

export const selectWorkExperienceOptions = createSelector(
  selectLookups,
  (s) => s.workExperienceFilterOptions
);

export const getLookupState = createFeatureSelector<WebsiteInfoState>('lookup');

export const selectPartnerSitesForFooter = createSelector(
  getLookupState,
  (state: WebsiteInfoState) => state.partnerSitesForFooter
);

export const ProviderWebsiteUrlMap = createSelector(
  getLookupState,
  (state: WebsiteInfoState) => {
    let map: { [index: number]: string } = {};
    state.partnerSitesForFooter?.reduce((a, c) => {
      a[c.id] = c.link;
      return a;
    }, map);
    return map;
  }
);

export const selectSystemConfigs = createSelector(
  getLookupState,
  (state: WebsiteInfoState) => state.configs?.systemConfigs
);

export const selectCustomersNumberOfItemsOnPage = createSelector(
  getLookupState,
  (state: WebsiteInfoState) =>
    state.configs?.uxSettings.websiteSettings.customersNumberOfItemsOnPage
);

export const selectGeneralDefaultNumberOfListItemsOnPage = createSelector(
  getLookupState,
  (state: WebsiteInfoState) =>
    state.configs?.uxSettings.websiteSettings
      .generalDefaultNumberOfListItemsOnPage
);

export const selectFrontPageNumberOfItemsOnPage = createSelector(
  getLookupState,
  (state: WebsiteInfoState) =>
    state.configs?.uxSettings.websiteSettings.frontPageNumberOfItemsOnPage
);

export const selectSearchResultsStateInfo = createSelector(
  selectLookups,
  selectFrontPageNumberOfItemsOnPage,
  selectCommonLookupsLoaded,
  (l, p, ll) => {
    return {
      lookup: l,
      isLookupLoaded: ll,
      pageSize: p,
    };
  }
);

export const selectLocationLevelCount = createSelector(
  getLookupState,
  (state: WebsiteInfoState) =>
    state.configs?.systemConfigs.common.locationLevelCount
);

export const selectLocationRequiredLevelCount = createSelector(
  getLookupState,
  (state: WebsiteInfoState) =>
    state.configs?.systemConfigs.common.locationRequiredLevelCount
);

export const selectLocationLastHiddenLevelCount = createSelector(
  getLookupState,
  (state: WebsiteInfoState) =>
    state.configs?.systemConfigs.common.locationLastHiddenLevelCount
);

export const selectLocationFirstLevelId = createSelector(
  getLookupState,
  (s) => s.configs?.systemConfigs.common.locationFirstLevelId
);

export const selectTimeZone = createSelector(
  selectSystemConfigs,
  (s) => s?.common.timeZone
);

export const selectRecaptchaSiteKey = createSelector(
  selectSystemConfigs,
  (s) => s?.common.recaptchaSiteKey
);

export const selectGoogleAnalyticsCode = createSelector(
  selectSystemConfigs,
  (s) => s?.common.googleAnalyticsCode
);

export const selectGoogleTagManagerCode = createSelector(
  selectSystemConfigs,
  (s) => s?.common.googleTagManagerCode
);

export const selectAnnViewCountIgnorePeriod = createSelector(
  selectSystemConfigs,
  (s) => s?.announcement.viewCountIgnorePeriod
);

export const selectIsTopGeOn = createSelector(
  selectSystemConfigs,
  (s) => s?.common.isTopGeOn
);

export const selectTopGeCode = createSelector(
  selectSystemConfigs,
  (s) => s?.common.topGeCode
);

export const selectFacebookPageId = createSelector(
  selectSystemConfigs,
  (s) => s?.common.facebookPageId
);

export const selectCurrency = createSelector(
  selectSystemConfigs,
  (s) => s?.common.currency
);

export const selectAnnouncementLocationRequiredLevelCount = createSelector(
  selectSystemConfigs,
  (s) => s?.announcement.locationRequiredLevelCount
);

//current info
export const getCurrentInfoState =
  createFeatureSelector<CurrentInfoState>('currentInfo');

export const selectCurrentDate = createSelector(
  getCurrentInfoState,
  (s) => s.currentDate
);

export const selectCurrentLanguage = createSelector(
  getCurrentInfoState,
  (state: CurrentInfoState) => state.currentLanguage
);

export const selectDefaultLanguage = createSelector(
  getCurrentInfoState,
  (state: CurrentInfoState) => state.defaultLanguage
);

export const selectAlternativeLanguage = createSelector(
  getCurrentInfoState,
  (state: CurrentInfoState) => state.alternativeLanguage
);

export const selectCurrentWebsite = createSelector(
  getCurrentInfoState,
  (state: CurrentInfoState) => state.currenWbeiste
);

export const selectCurrentWebsiteDisplayName = createSelector(
  getCurrentInfoState,
  (state: CurrentInfoState) =>
    state.currenWbeiste?.displayName.replace('.', '-')
);

export const selectCurrentWebsiteShortName = createSelector(
  getCurrentInfoState,
  (state: CurrentInfoState) => state.currenWbeiste?.displayName.split('.')[0]
);

export const selectCurrentWebsiteNameForSeo = createSelector(
  getCurrentInfoState,
  (state: CurrentInfoState) => {
    return '| ' + state.currenWbeiste?.name;
  }
);

export const selectLastListingPage = createSelector(
  getCurrentInfoState,
  (state: CurrentInfoState) => state.lastListingPage
);

export const selectIsSignedIn = createSelector(
  getCurrentInfoState,
  (state: CurrentInfoState) => state.isSignedIn
);

export const selectIsJobseekerSignedIn = createSelector(
  getCurrentInfoState,
  (state: CurrentInfoState) =>
    state.isSignedIn && state.user?.userType == UserTypeEnum.Jobseeker
);

export const selectIsCustomerSignedIn = createSelector(
  getCurrentInfoState,
  (state: CurrentInfoState) =>
    state.isSignedIn && state.user?.userType == UserTypeEnum.CustomerAgent
);

export const selectUser = createSelector(
  getCurrentInfoState,
  (state: CurrentInfoState) => state.user
);

export const selectCurrentUserType = createSelector(
  getCurrentInfoState,
  (s) => s.user?.userType
);

export const selectIsPasswordChangeRequired = createSelector(
  selectUser,
  (u) => u?.isPasswordChangeRequired
);

export const selectIsError = createSelector(
  getCurrentInfoState,
  (state: CurrentInfoState) => state.isSignInError
);

export const selectFavorites = (s: AppState) => {
  return s.favorites;
};

export const selectFavoriteAnnouncementsMap = createSelector(
  selectFavorites,
  (s) => {
    return s.favoriteAnnouncementIds.reduce((a, b) => {
      a[b] = true;
      return a;
    }, {});
  }
);

export const selectFavoriteAnnouncementsCount = createSelector(
  selectFavorites,
  (s) => s.favoriteAnnouncementIds.length
);

export const selectIsSignInLoading = createSelector(
  getCurrentInfoState,
  (c) => c.loading
);

export const selectCustomerPortalUrl = createSelector(
  selectSystemConfigs,
  (s) => s?.common.customerPortalUrl
);

export const selectUserDoesNotExist = createSelector(
  getCurrentInfoState,
  (s) => {
    return (
      s.signInFailureDetails?.errorCode == HRRAExceptionCode.UserDoesNotExist
    );
  }
);

export const selectIsSignInError = createSelector(
  getCurrentInfoState,
  (s) => s.isSignInError
);
