import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { DictionaryStr } from './models';
import { CookieOptions, CookieService } from 'ngx-cookie';
import * as dayjs from 'dayjs';
import { SsrService } from './ssr.service';
import { LogginService } from './logging/logging-provider';

export enum AnonymousUserGuid {
  Anonymous = 'Anonymous',
}

@Injectable({
  providedIn: 'root',
})
export class LocalDataStorageService {
  private supportedUiCultures: DictionaryStr<string> = {
    ka: 'ka-GE',
    en: 'en-US',
    ru: 'ru-RU',
  };

  private localStorageMemoryFallBack = new Map();

  private store(key: string, value: any) {
    if (this.ssr.isPlatformBrowser()) {
      this.storage.store(key, value);
    } else {
      this.localStorageMemoryFallBack.set(key, value);
    }
  }

  private retrieve(key: string) {
    if (this.ssr.isPlatformBrowser()) {
      return this.storage.retrieve(key);
    } else {
      return this.localStorageMemoryFallBack.get(key);
    }
  }

  private clear(key: string) {
    if (this.ssr.isPlatformBrowser()) {
      this.storage.clear(key);
    } else {
      this.localStorageMemoryFallBack.delete(key);
    }
  }

  private put(key: string, value: string, options?: CookieOptions) {
    this.cookie.put(key, value, options);
  }

  private get(key: string) {
    return this.cookie.get(key);
  }

  constructor(
    private storage: LocalStorageService,
    private cookie: CookieService,
    private ssr: SsrService,
    private logService: LogginService
  ) {}

  public storeAccessToken(accessToken: string) {
    this.store(DataStorageKey.AccessToken, accessToken);
  }

  public storeAnonymousGuid(uniqueGuide: string) {
    this.store(AnonymousUserGuid.Anonymous, uniqueGuide);
  }

  public getAnonymousGuid() {
    return this.retrieve(AnonymousUserGuid.Anonymous);
  }

  public getAccessToken(): string {
    return this.retrieve(DataStorageKey.AccessToken);
  }

  public destroyAccessToken() {
    this.clear(DataStorageKey.AccessToken);
  }

  public hasAccessToken() {
    return (
      this.getAccessToken() !== null && this.getAccessToken() !== undefined
    );
  }

  public isUiLanguageSet() {
    return this.getUiLanguage() !== null && this.getUiLanguage() !== undefined;
  }

  public getUiLanguage(): string {
    return this.get(DataStorageKey.UiLanguage);
  }

  public setUiLanguage(language: string) {
    const date = dayjs(new Date()).add(1, 'y').toDate();
    return this.put(DataStorageKey.UiLanguage, language, {
      expires: date,
      path: '/',
    });
  }

  public getUiCulture() {
    const language = this.getUiLanguage();
    if (this.supportedUiCultures[language] == null) {
      throw Error('Unsupported language');
    }

    return this.supportedUiCultures[language];
  }
}

export enum DataStorageKey {
  AccessToken = 'core_accessToken',
  UiLanguage = 'culture',
}
