import { createAction, props } from '@ngrx/store';
import { ActionPayload, PagingWrapper } from "@hrra/core";
import { AnnouncementFullFilter } from 'src/app/shared/models/announcement-filter';
import { ApiError } from '@hrra/core';
import { AnnouncementDetailsViewModel } from 'src/app/announcement/shared/services/announcement.service';
import { Banner } from 'src/app/banner/shared/models/banner-model';
import { AnnouncementViewRequestModel } from 'src/app/announcement/shared/models/announcement-view-request.model';
import { CurrentNavigation } from './state';
import { create } from 'domain';
import { AnnouncementDetails } from 'src/app/announcement/shared/models/announcement-details-model';

export enum AnnouncementDetailsActions{
    LoadAnnouncementDetails = '[Announcement details page] Load annoncement details',
    LoadAnnouncementDetailsSuccess = '[Announcement details page] Load annoncement details success',
    LoadAnnouncementDetailsFail = '[Announcement details page] Load annoncement details fail',

    LoadNavigationIds = '[Announcement details page] Load current nvaigation ids',
    LoadNavigationIdsSuccess = '[Announcement details page] Load current nvaigation ids success',
    LoadNavigationIdsFail = '[Announcement details page] Load current nvaigation ids fail',

    SendAnnouncementViewToAnalytic = '[Announcement details page] send announcement to analytic',
    sendAnnouncementViewToAnalyticSuccess = '[Announcement details page] send announcement to analytic success',
    sendAnnouncementViewToAnalyticFail = '[Announcement details page] send announcement to analytic fail',

    SetPreviouseAnnouncementId = '[Announcement details page] Set previouse announcement id',
    SetIsSimilarAnnouncement = '[Announcement details page] Set is similar announcement',

    GetAnnDetailsPageBannerList = '[Announcement details page] Get ann details banner list',
    GetAnnDetailsPageBannerListSucess = '[Announcement details page] Get ann details banner list success',
    GetAnnDetailsPageBannerListFailed = '[Announcement details page] Get ann details banner list faled',

    Apply = '[Announcement details page] Apply',
    ApplySuccess = '[Announcement details page] Apply success',
    ApplyFailed = '[Announcement details page] Apply failed',

    ViewAnnouncement = '[Announcement details page] View announcement',
    ViewAnnouncementSuccess = '[Announcement details page] View announcement success',
    ViewAnnouncementFailed = '[Announcement details page] View announcement failed',

    ShowCvBoxSlider = '[Announcement details page] Show cv box slider',
}

//announcement details
export const loadAnnouncementDetails = createAction(
    AnnouncementDetailsActions.LoadAnnouncementDetails,
    props<{payload: {announcementId: number, urlTitle: string, nav?: any}}>()
);

export const loadAnnouncementDetailsSuccess = createAction(
    AnnouncementDetailsActions.LoadAnnouncementDetailsSuccess,
    props<{payload: AnnouncementDetailsViewModel}>()
);

export const loadAnnouncementDetailsFail = createAction(
    AnnouncementDetailsActions.LoadAnnouncementDetailsFail,
    props<ActionPayload<ApiError>>()
);

export const sendAnnouncementViewToAnalytic = createAction(
AnnouncementDetailsActions.SendAnnouncementViewToAnalytic,
props<{payload:{ announcement: AnnouncementDetails, providerWebsiteId: number}}>()
)

export const sendAnnouncementViewToAnalyticSuccess = createAction(
    AnnouncementDetailsActions.sendAnnouncementViewToAnalyticSuccess,
    props<{payload: AnnouncementDetailsViewModel}>()
);

export const sendAnnouncementViewToAnalyticFail = createAction(
    AnnouncementDetailsActions.sendAnnouncementViewToAnalyticFail,
    props<ActionPayload<ApiError>>()
);

//navigation ids
export const loadNavigationIds = createAction(
    AnnouncementDetailsActions.LoadNavigationIds,
    props<{payload: {filter: AnnouncementFullFilter, start: number}}>()
);

export const loadNavigationIdsSuccess = createAction(
    AnnouncementDetailsActions.LoadNavigationIdsSuccess,
    props<{payload: {announcementIdsWithTitles: CurrentNavigation[], start: number}}>()
);

export const loadNavigationIdsFail = createAction(
    AnnouncementDetailsActions.LoadNavigationIdsFail,
    props<{payload: {error: any}}>()
);

export const setPreviouseAnnouncementId = createAction(
    AnnouncementDetailsActions.SetPreviouseAnnouncementId
);

export const setIsSimilarAnnouncement = createAction(
    AnnouncementDetailsActions.SetIsSimilarAnnouncement
);

export const apply = createAction(
    AnnouncementDetailsActions.Apply,
    props<ActionPayload<{ announcementId: number}>>()
);

export const applySuccess = createAction(
    AnnouncementDetailsActions.ApplySuccess,
    props<ActionPayload<{ announcement: AnnouncementDetailsViewModel}>>()
);

export const applyFailed = createAction(
    AnnouncementDetailsActions.ApplyFailed,
    props<ActionPayload<ApiError>>()
);

export const viewAnnouncement = createAction(
    AnnouncementDetailsActions.ViewAnnouncement,
    props<ActionPayload<AnnouncementViewRequestModel>>()
);

export const viewAnnouncementSuccess = createAction(
    AnnouncementDetailsActions.ViewAnnouncementSuccess
);

export const ViewAnnouncementFailed = createAction(
    AnnouncementDetailsActions.ViewAnnouncementFailed,
    props<ActionPayload<ApiError>>()
);

export const showCvBoxSlider = createAction(
    AnnouncementDetailsActions.ShowCvBoxSlider,
    props<ActionPayload<boolean>>()
);

//banners
export const getAnnDetailsPageBannerList = createAction(
    AnnouncementDetailsActions.GetAnnDetailsPageBannerList,
    props<ActionPayload<{ targetId: number, bannerHeadId: number, }>>()
);
export const getAnnDetailsPageBannerListSuccess = createAction(
    AnnouncementDetailsActions.GetAnnDetailsPageBannerListSucess,
    props<ActionPayload<Banner[]>>()
);
export const getAnnDetailsPageBannerListFailed = createAction(
    AnnouncementDetailsActions.GetAnnDetailsPageBannerListFailed,
    props<ActionPayload<ApiError>>()
);
