import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-info-popup',
  templateUrl: './info-popup.component.html',
  styleUrls: ['./info-popup.component.scss']
})
export class InfoPopupComponent implements OnInit {
  
  navigationSubs: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private router: Router, public dialogRef: MatDialogRef<InfoPopupComponent>) { 
    this.navigationSubs = this.router.events.subscribe(e => {
      if(e instanceof NavigationEnd){
        this.dialogRef.close();
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.navigationSubs.unsubscribe();
  }
}
