import { Injectable } from '@angular/core';
import { DictionaryStr } from '@hrra/core';

@Injectable({
  providedIn: 'root'
})
export class TranslitService {
  private generalMap: DictionaryStr<string> = {
    'ქ':'q', 'წ':'w', 'ჭ':'W', 'ე':'e', 'რ':'r', 'ღ':'R', 'ტ':'t', 'თ':'T', 'ყ':'y', 'უ':'u', 'ი':'i', 'ო':'o', 'პ':'p', 'ა':'a', 'ს':'s', 'შ':'S', 'დ':'d', 'ფ':'f', 'გ':'g', 'ჰ':'h', 'ჯ':'j', 'ჟ':'J', 'კ':'k', 'ლ':'l', 'ზ':'z', 'ძ':'Z', 'ხ':'x', 'ც':'c', 'ჩ':'C', 'ვ':'v', 'ბ':'b', 'ნ':'n', 'მ':'m',
    'щ':'s', 'ш':'s', 'е':'e', 'р':'r', 'т':'t', 'й':'i', 'у':'u', 'и':'i', 'о':'o', 'п':'p', 'ч':'c', 'ъ':'i', 'а':'a', 'с':'s', 'д':'d', 'ф':'f', 'г':'g', 'ы':'h', 'ж':'j', 'к':'k', 'л':'l', 'э':'e', 'ь':'i', 'з':'z', 'х':'x', 'ц':'c', 'в':'v', 'б':'b', 'н':'n', 'м':'m', 'я':'a', 'ю':'u', 'ё':'e',
    'Ç':'c', 'ç':'c', 'Ə':'e', 'ə':'e', 'Ğ':'g', 'ğ':'g', 'İ':'i', 'Ö':'o', 'ö':'o', 'Ş':'s', 'ş':'s', 'Ü':'u', 'ü':'u', 'I':'i', 'ı':'i',
  }

  private urlMap: DictionaryStr<string> = {
    ' ': '-',
    '-': '-',
    'a': 'a', 'b': 'b', 'c': 'c', 'd': 'd', 'e': 'e', 'f': 'f', 'g': 'g', 'h': 'h', 'i': 'i', 'j': 'j', 'k': 'k', 'l': 'l', 'm': 'm', 'n': 'n', 'o': 'o', 'p': 'p', 'q': 'q', 'r': 'r', 's': 's', 't': 't', 'u': 'u', 'v': 'v', 'w': 'w', 'x': 'x', 'y': 'y', 'z': 'z',
    'A': 'A', 'B': 'B', 'C': 'C', 'D': 'D', 'E': 'E', 'F': 'F', 'G': 'G', 'H': 'H', 'I': 'I', 'J': 'J', 'K': 'K', 'L': 'L', 'M': 'M', 'N': 'N', 'O': 'O', 'P': 'P', 'Q': 'Q', 'R': 'R', 'S': 'S', 'T': 'T', 'U': 'U', 'V': 'V', 'W': 'W', 'X': 'X', 'Y': 'Y', 'Z': 'Z'

  }

  private urlFriendlyMap: DictionaryStr<string>;

  private getMappedString(original: string, map: DictionaryStr<string>, cleanUnrecognized: boolean){
    let result = [];
    for(let c of original){
      result.push(map[c] || (cleanUnrecognized ? '' : c));
    }

    return result.join('');
  }

  public getString(original: string){
    return this.getMappedString(original, this.generalMap, false);
  }

  public getUrlFriendlyString(original: string){
    return this.getMappedString(original, this.urlFriendlyMap, true);
  }

  constructor() {
    this.urlFriendlyMap = Object.assign({}, this.generalMap, this.urlMap);
  }
}
