import { createAction, props } from '@ngrx/store'
import { ActionPayload, PagingWrapper, Range } from "@hrra/core";
import { AnnouncementFilter, AnnouncementFullFilter } from 'src/app/shared/models/announcement-filter'
import { ApiError } from '@hrra/core'
import { FilterStats } from './state'
import { SearchResultModel } from 'src/app/shared/services/announcement.service'
import { Banner } from 'src/app/banner/shared/models/banner-model'
import { WorkExperienceFilterModel } from '@hrra/common-models';
import { OptionItem } from '@hrra/common-models';
import { BannerHeaderPositionId } from 'src/app/shared/models/enums/banner-header-position-id-enum';


export enum Actions {
    LoadFilterStats = '[Announcement search page] Initialize search filters',
    LoadFilterStatsComplete = '[Announcement search page] Initiazlize search filters complete',
    LoadFilterStatsFailed = '[Announcement search page] Initialize search filters failed',

    Search = '[Announcement search page] Search announcements',
    CancelSearch = '[Announcement search page] Cancel search',
    //SearchStart = '[Announcement search page] Search started',
    SearchComplete = '[Announcement search page] Search success',
    SearchFailed = '[Announcement search page] Search failed',
    PagingNavigation = '[Announcement search page] Navigate to page',
    PagingNavivationComplete = '[Announcement search page] Navigation success',
    PagingNavigationFailed = '[Announcement search page] Navigation failed',

    ToggleExpandLocationFilter = '[Announcement search page] Toggle expand locality filter',
    ToggleExpandExperienceFilter = '[Announcement search page] Expand experience filter',
    ToggleExpandSalaryFilter = '[Announcement search page] Expand salary filter',
    ToggleExpandAnnouncementTypeFilter = '[Announcement search page] Expand announcement type filter',
    ToggleExpandPublishDateFilter = '[Announcement search page] Expand publish date filter',
    ToggleExpandCategoryFilter = '[Announcement search page] Toggle expand category filter',

    //ShowAllLocations = '[Announcement search page] Show all locations',
    //ShowTopLocationsOnly = '[Announcement search page] Show top locations only',
    ToggleShowAllLocations = '[Announcement search page] Toggle show all locations',
    ToggleShowAllCategories = '[Announcement search page] Show all categories',
    //ShowTopCategoriesOnly = '[Announcement search page] Show all categories',

    ToggleExpandHeadCategory= '[Announcement search page] Toggle expand head category',

    UpdateLocationFilter = '[Announcement search page] Update location filter',
    UpdateCategoryFilter = '[Announcement search page] Update category filter',

    UpdateWorkFromHomeFilter = '[Announcement search page] Update work from home filter',
   
    UpdateSalaryFilter = '[Announcement search page] Update salary filter',
    UpdateOnlySelectedSalaryFilter = '[Announcement search page] Update only selected salary filter',
    
    UpdateExperienceFilter = '[Announcement search page] Update experience filter',
    UpdateAnnouncementTypeFilter = '[Announcement search page] Update announcement type filter',
    UpdatePublishDateFilter = '[Announcement search page] Update publish date filter',
    UpdateKeywordFilter = '[Announcement search page] Update keyword filter',

    UpdateFilter = '[Announcement search page] Update filters',
    ResetFilter = '[Announcement search page] Reset filter',

    ToggleExpandFilter = '[Announcement search page] Toggle hide filter',

    GetSearchPageBannerList = '[Announcement search page] Get banner list',
    GetSearchPageBannerListSucess = '[Announcement search page] Get banner list success',
    GetSearchPageBannerListFailed = '[Announcement search page] Get banner list faled',

    SendSearchPageBannerViewToAnalytic = '[Announcement search page] send banner view to analytics',

    UpdateSubmittedFilter = '[Announcement search page] Update submitted filter',
}


//Locations

export const toggleExpandLocationFilter = createAction(
    Actions.ToggleExpandLocationFilter
);

export const toggleShowAllLocations = createAction(
    Actions.ToggleShowAllLocations
);

export const updateLocationFilter = createAction(
    Actions.UpdateLocationFilter,
    props<ActionPayload<{ localityId: number, checked: boolean }>>()
)

export const updateLocalityFilter = createAction(
    Actions.UpdateLocationFilter,
    props<ActionPayload<{ localityIds: number[] }>>()
);

//Categories

export const toggleExpandCategoryFilter = createAction(
    Actions.ToggleExpandCategoryFilter
);

export const toggleShowAllCategories = createAction(
    Actions.ToggleShowAllCategories
);

export const toggleExpandHeadCategory = createAction(
    Actions.ToggleExpandHeadCategory,
    props<ActionPayload<{categoryId: number}>>()
);

export const updateCategoryilter = createAction(
    Actions.UpdateCategoryFilter,
    props<ActionPayload<{ categoryIds: number[] }>>()
);


//Salary

export const toggleExpandPublishDateFilter = createAction(
    Actions.ToggleExpandPublishDateFilter
);

export const updatePublishDateFilter = createAction(
    Actions.UpdatePublishDateFilter,
    props<ActionPayload< { publishDateRangeOptionId: number }>>()
);

//Work experience

export const toggleExpandExperienceFilter = createAction(
    Actions.ToggleExpandExperienceFilter
);

export const updateExperienceFilter = createAction(
    Actions.UpdateExperienceFilter,
    props<ActionPayload< { experienceFilter: WorkExperienceFilterModel }>>()
)

// Work from home 
export const updateWorkFromHomeFilter = createAction(
    Actions.UpdateWorkFromHomeFilter,
    props<ActionPayload< { isWorkFromHome: boolean }>>()
)

//Announcement type

export const toggleExpandAnnouncementTypeFilter = createAction(
    Actions.ToggleExpandAnnouncementTypeFilter
);

export const updateAnnouncementTypeFilter = createAction(
    Actions.UpdateAnnouncementTypeFilter,
    props<ActionPayload< { announcementTypeId: number }>>()
);

//publish date

export const toggleExpandSalaryFilter = createAction(
    Actions.ToggleExpandSalaryFilter
);

export const updateSalaryFilter = createAction(
    Actions.UpdateSalaryFilter,
    props<ActionPayload< { salaryRangeOptionId: number }>>()
);

export const updateOnlySelectedSalaryFilter = createAction(
    Actions.UpdateOnlySelectedSalaryFilter,
    props<ActionPayload<boolean>>()
);

//keyword

export const updateKeywordFilter = createAction(
    Actions.UpdateKeywordFilter,
    props<ActionPayload<{ query: string}>>()
);

//full filter

export const updateFilter = createAction(
    Actions.UpdateFilter,
    props<ActionPayload<AnnouncementFilter>>()
);

export const resetFilter = createAction(
    Actions.ResetFilter
);

export const toggleExpandFilter = createAction(
    Actions.ToggleExpandFilter
);

//search

export const loadFilterStats = createAction(
    Actions.LoadFilterStats
)

export const loadFilterStatsComplete = createAction(
    Actions.LoadFilterStatsComplete,
    props<ActionPayload<FilterStats>>()
)

export const loadFilterStatsFailed = createAction(
    Actions.LoadFilterStatsFailed,
    props<ActionPayload<ApiError>>()
)

export const announcementSearch = createAction(
    Actions.Search,
    props<ActionPayload<AnnouncementFullFilter>>()
);

export const searchComplete = createAction(
    Actions.SearchComplete,
    props<ActionPayload<SearchResultModel>>()
);

export const searchFail = createAction(
    Actions.SearchFailed,
    props<ActionPayload<ApiError>>()
);

export const updateSubmittedFilter = createAction(
    Actions.UpdateSubmittedFilter
);

//banners
export const getSearchPageBannerList = createAction(
    Actions.GetSearchPageBannerList,
    props<ActionPayload<{ targetId: number, bannerHeadId: number, }>>()
);
export const getSearchPageBannerListSuccess = createAction(
    Actions.GetSearchPageBannerListSucess,
    props<ActionPayload<Banner[]>>()
);
export const getSearchPageBannerListFailed = createAction(
    Actions.GetSearchPageBannerListFailed,
    props<ActionPayload<ApiError>>()
);

export const sendSearchPageBannerViewToAnalytic = createAction(
    Actions.SendSearchPageBannerViewToAnalytic,
    props<ActionPayload<{bannerIdList: number[], providerWebsiteId:number, pageId: BannerHeaderPositionId}>>()
)