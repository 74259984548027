import { Inject, InjectionToken, inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { EnvService } from './env.service';
import * as P from 'pino';
import { LogginService } from './logging/logging-provider';

export let URL_CONFIG_TOKEN = new InjectionToken('url.config');

//let apiBase = '/../api/v2/';

export interface ApiEndpoint {
  url: string;
}





export interface ApiUrlConfig<T>
{
  apiType: string;//public-web-api, analytics-api  { "public-web-api": "api.hr.ge", "analytics-api": "analytics.hr.ge" }
  data: T;
}


export interface ApiEndpointConfig
{
  [key: string]: ApiUrlConfig<any>;
}

@Injectable({
  providedIn: 'root',
})
export class UrlConfigService<T extends ApiEndpointConfig> {

  // public urlConfig: T;

  private _logger?: P.Logger;

  public urlConfig: T;

  get logger() {
    return this._logger;
  }

  constructor(
    private env: EnvService,
    private loggingService: LogginService,
    @Inject(URL_CONFIG_TOKEN) urlConfig: T
  ) {

    this._logger = this.loggingService.logger.child({ source: 'url-config' });

    let ownUrlConfig = <T>JSON.parse(JSON.stringify(urlConfig));

    for(let key in ownUrlConfig){
      this._logger.debug(
        { baseUrl: this.getBaseUrl(ownUrlConfig[key].apiType) },
        'Base API URL: %s' + this.getBaseUrl(ownUrlConfig[key].apiType)
      );
    }


    for (let key in ownUrlConfig){
      this.applyApiUrl(ownUrlConfig[key].data, this.getBaseUrl(ownUrlConfig[key].apiType));
    }

    this.urlConfig = ownUrlConfig;
  }

  getBaseUrl(apiType: string){
    return this.env.apiConfig[apiType];
  }

  applyApiUrl(obj: any, baseUrl: string) {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (typeof obj[prop] === 'string') {
          obj[prop] = this.getUrl(obj[prop], baseUrl);
        } else if (typeof obj[prop] === 'object' && obj[prop] != null) {
          this.applyApiUrl(obj[prop], baseUrl);
        } else {
          throw new Error('Invalid url config');
        }
      }
    } 
  }

  getUrl(relativePath: string, baseUrl: string) {
    return baseUrl + relativePath;
  }

  format(template: string, values: { [index: string]: string | number }) {
    let result = template;

    for (let prop in values) {
      if (values.hasOwnProperty(prop)) {
        let regex = new RegExp(`{${prop}}`);
        result = result.replace(regex, values[prop].toString());
      }
    }

    return result;
  }
}

