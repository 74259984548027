import { ComponentStore, tapResponse } from "@ngrx/component-store";
import { LocationService } from "../services/location.service";
import { Injectable } from "@angular/core";
import { Observable, from, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { TreeSelectModel } from "@hrra/common-models";

export interface LocationTreeSelectState {
    loading: boolean;
    locationTree: TreeSelectModel<string>[];
    overlayVisible: boolean;
    //locationTreeDictionary: { [index: string]: TreeSelectModel<string> };
}

export const initialState: LocationTreeSelectState = {
    loading: false,
    locationTree: [],
    overlayVisible: false
    //locationTreeDictionary: {},
}

export interface TreeInputUpdateCommand
{
    newValues: string[];
    existingValues: string[];
    additionalCheckRequired: boolean;
}

@Injectable()
export class LocationTreeSelectStore extends ComponentStore<LocationTreeSelectState> {

    constructor(private locationService: LocationService) {
        super(initialState)
    }

    loading$ = this.select(s => s.loading);
    locationTree$ = this.select(s => s.locationTree);
    overlayVisible$ = this.select(s => s.overlayVisible);

    //updates

    readonly updateLoading = this.updater((state, loading: boolean) => {
        return {
            ...state,
            loading: loading
        }
    });

    readonly updateOverlayVisible = this.updater((state, overlayVisible: boolean) => {
        return {
            ... state,
            overlayVisible: overlayVisible
        }
    });

    readonly updateLocationTree = this.updater((state, locationTree: TreeSelectModel<string>[]) => {
        //const locationTreeDictionary: { [index: string]: TreeSelectModel<string> } = {};

        // locationTree.forEach((node) => {
        //     locationTreeDictionary[node.data] = node;
        // });

        //this.walker(undefined, locationTree);

        return {
            ...state,
            locationTree: locationTree,
            loading: false,
            //locationTreeDictionary: locationTreeDictionary
        }
    });

    // walker(parent: TreeSelectModel<string> | undefined, nodes: TreeSelectModel<string>[]) {
    //     if (nodes === undefined || nodes === null || nodes.length == 0)
    //         return;

    //     for (let node of nodes) {
    //         if (parent !== undefined) {
    //             node.parent = { ...parent };
    //         }
    //         this.walker(node, node.children);
    //     }
    // }

    readonly loadLocationTree = this.effect((parentId$) => {
        return parentId$.pipe(
            switchMap(() => {
                this.updateLoading(true);
                return this.locationService.getTreeSelectLocations().pipe(
                    tapResponse({
                        next: (response) => {
                            this.updateLocationTree(response.data);
                        },
                        error: error => {
                            this.updateLoading(false)
                        }
                    })
                );
            })
        );
    });

}