import { SearchFieldStoreState, SearchFieldState } from './state';
import { Action, createReducer, on, ActionReducerMap } from '@ngrx/store';
import * as SearchFieldActons from './search-field-actions';

let initialState: SearchFieldState = {
  websiteStats: null,
  loading: false,
};

const reducer = createReducer(
  initialState,
  on(SearchFieldActons.loadSearchFieldInitData, (State) => ({
    ...State,
    loading: true,
  })),
  on(
    SearchFieldActons.loadSearchFieldInitDataSuccess,
    (state, { payload }) => ({
      ...state,
      websiteStats: payload,
      loading: false,
    })
  ),
  on(SearchFieldActons.loadSearchFieldInitDataFail, (state, { payload }) => ({
    ...state,
    loading: false,
  }))
);

export function searchFieldReducer(
  state: SearchFieldState | undefined,
  action: Action
) {
  return reducer(state, action);
}

export const reducers: ActionReducerMap<SearchFieldStoreState> = {
  searchField: searchFieldReducer,
};
