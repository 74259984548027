import { Component, OnInit, Input } from '@angular/core';
import { ButtonTypeAttributeEnum } from '../shared/models/enums/button-type-attribute-enum';
import { ButtonTypeEnum } from '../shared/models/enums/button-type-enum';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  public buttonType: any;
  public buttonTypeAttribute: any;
  public buttonTypeMap :  {[index: number]: string}

  @Input() type;
  @Input() text;
  @Input() typeAttribute;
  @Input() disabled: boolean;
  
  constructor() { 
    this.buttonType = ButtonTypeEnum;
    this.buttonTypeAttribute = ButtonTypeAttributeEnum;
    this.disabled = false;
  }

  ngOnInit(): void {
    this.buttonTypeMap = {
      [ButtonTypeEnum.coloured]: 'coloured',
      [ButtonTypeEnum.uncoloured]: 'uncoloured'
    }
  }
}
