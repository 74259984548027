import { SubscriberState } from "./state";
import { createImmerReducer } from 'ngrx-immer/store';
import * as SubscriberActions from './actions';
import { on, Action } from '@ngrx/store';
import { SubscriptionViewModel } from 'src/app/subscriber/shared/services/subsriber.service';
import { state } from "@angular/animations";

let initialState: SubscriberState = {
    loading: false,
    subscription: null,
    isEditMode: false,
    selectedCategories: [],
    categoriesTouched: false,
    registrationFailureDetails: null,
    regsitrationRequestFailed: false,
    emailResendToken: null,
    activationEmailResendMaxAttemptsReached: false,
    emailVerificationLoading: false,
    emailVerificationComplete: false,
    emailVerificationError: false,
    emailValidateLoading: false,
    metaData: null,
    metaDataLoading: false
}

const reducer = createImmerReducer(
    initialState,

    on(SubscriberActions.updateSelectedCategories, (state, { payload }) => {
        state.selectedCategories = state.selectedCategories.filter(c => c != payload.categoryId);
        if (payload.checked) {
            state.selectedCategories = [...state.selectedCategories, payload.categoryId];
        }

        if (!state.categoriesTouched) {
            state.categoriesTouched = true;
        }

        return state;
    }),

    on(SubscriberActions.createNewSubscription, state => {
        state.loading = true;
        state.regsitrationRequestFailed = false;
        state.registrationFailureDetails = null;
        return state;
    }),
    on(SubscriberActions.createNewSubscriptionSuccess, (state, { payload }) => {
        state.loading = false;
        state.emailResendToken = payload.emailVerificationResendToken;
        return state;
    }),
    on(SubscriberActions.createNewSubscriptionFail, (state, { payload }) => {
        state.loading = false;
        state.regsitrationRequestFailed = true;
        state.registrationFailureDetails = payload;
        return state;
    }),
    on(SubscriberActions.verifySubscriberEmail, (state) => {
        state.emailVerificationLoading = true;

        return state;
    }),
    on(SubscriberActions.verifySubscriberEmailSuccess, (state) => {
        state.emailVerificationLoading = false;
        state.emailVerificationComplete = true;
        return state;
    }),
    on(SubscriberActions.verifySubscriberEmailFail, (state) => {
        state.emailVerificationLoading = false;
        state.emailVerificationError = true;
        return state;
    }),

    on(SubscriberActions.loadSubscription, state => {
        state.loading = true;

        return state;
    }),

    on(SubscriberActions.loadSubscriptionSuccess, (state, { payload }) => {
        state.loading = false;
        state.subscription = payload;
        state.selectedCategories = payload.subscibedCategoryIds;

        return state;
    }),

    on(SubscriberActions.loadSubscriptionFail, (state, { payload }) => {
        state.loading = false;
        state.registrationFailureDetails = payload;

        return state;
    }),

    on(SubscriberActions.updateSubscription, state => {
        state.loading = true;
        return state;
    }),

    on(SubscriberActions.updateSubscriptionSuccess, (state, { payload }) => {
        state.loading = false;
        state.subscription = payload;

        return state;
    }),

    on(SubscriberActions.updateSubscriptionFail, (state, { payload }) => {
        state.loading = false;
        state.registrationFailureDetails = payload;

        return state;
    }),

    on(SubscriberActions.cancelSubscription, (state) => {
        state.loading = true;

        return state;
    }),

    on(SubscriberActions.cancelSubscriptionSuccess, (state) => {
        state.loading = false;

        return state;
    }),

    on(SubscriberActions.cancelSubscriptionFail, (state, { payload }) => {
        state.loading = false;
        state.registrationFailureDetails = payload;
        return state;
    }),

    on(SubscriberActions.activateEditMode, (state) => {
        state.isEditMode = true;

        return state;
    }),
    
    on(SubscriberActions.setEmailValidateLoading, (state, { payload }) => {
        state.emailValidateLoading = payload.isValidateLoading;
        return state;
    }),
    
    on(SubscriberActions.getSubscriptionPageSeoData, (state) => {
        state.metaDataLoading = true;
        return state;
    }),
    on(SubscriberActions.getSubscriptionPageSeoDataSuccess, (state, { payload }) => {
        state.metaData = payload;
        state.metaDataLoading = false;
        return state;
    }),
    on(SubscriberActions.getSubscriptionPageSeoDataFail, (state, { payload }) => {
        state.metaDataLoading = false;
        return state;
    }),
    on(SubscriberActions.resetSubscriptionCategories, state => {
        state.selectedCategories = [];
        state.categoriesTouched = false;
        return state;
    })
);

export function subscriberReducer(state: SubscriberState | undefined, action: Action) {
    return reducer(state, action);
}
