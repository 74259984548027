import { Component, OnInit, Input } from '@angular/core';
import { theme } from 'src/environments/theme';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-jobseeker-error-message',
  templateUrl: `jobseeker-error-message.component.html`,
  styleUrls: [`jobseeker-error-message.component.scss`],
})
export class JobseekerErrorMessageComponent implements OnInit {
  @Input()
  messageHeading: string;
  @Input()
  message: string;

  constructor(private translateSerivce: TranslateService) {}

  ngOnInit() {}
}
