import { Component, OnInit, Input } from '@angular/core';
import { IconButtonTypeEnum } from '../shared/models/enums/icon-button-type-enum';

@Component({
  selector: 'app-advanced-button',
  templateUrl: './advanced-button.component.html',
  styleUrls: ['./advanced-button.component.scss']
})
export class AdvancedButtonComponent implements OnInit {

  public iconButtonType: any;
  
  @Input() text;
  @Input() iconType;
  @Input() type;
  
  constructor() { this.iconButtonType = IconButtonTypeEnum}

  ngOnInit(): void {
  }

}
