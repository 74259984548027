
<app-layout-main></app-layout-main>
<!-- <router-outlet></router-outlet> -->
<ng-container *ngIf="{
    page_id: facebookPageId$ | async,
    greeting: 'label.HowCanIHelpYou' | translate
} as data">
    <div *ngIf="data.page_id" class="fb-customerchat"
    [attr.page_id]="data.page_id"
    theme_color="#495678"
    [attr.logged_in_greeting]="data.greeting"
    [attr.logged_out_greeting]="data.greeting">
    </div>
</ng-container>