
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { map, mergeMap, catchError, switchMap } from 'rxjs/operators';
import * as SearchActions from './actions';
import { AnnouncementService } from 'src/app/shared/services/announcement.service';
import { BannerService } from 'src/app/banner/shared/services/banner.service';


@Injectable()
export class AnnouncementSearchEffects {

  loadFilterStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.Actions.LoadFilterStats),
      mergeMap(() => this.announcementService.getFilterStats()
        .pipe(
          map(data => SearchActions.loadFilterStatsComplete({ payload: data })),
          catchError(err => of(SearchActions.loadFilterStatsFailed({ payload: err })))
        )
      )
    )
  );

  searchAnnouncements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.announcementSearch),
      mergeMap(action => this.announcementService.searchAnnouncements(action.payload)
        .pipe(
          map(response => SearchActions.searchComplete({ payload: response.data })),
          catchError(response => of(SearchActions.searchFail({ payload: response.error })))
        )
      )
    )
  );

  getSearchPageBannerList$ = createEffect(() => this.actions$.pipe(
    ofType(SearchActions.getSearchPageBannerList),
    mergeMap(action => this.bannerService.getBannerList(action.payload.targetId, action.payload.bannerHeadId)
    .pipe(
        map(response => SearchActions.getSearchPageBannerListSuccess({payload: response.data})),
        catchError(error => of(SearchActions.getSearchPageBannerListFailed({payload: error.error})))
    ))
));

  constructor(
    private actions$: Actions,
    private announcementService: AnnouncementService, 
    private bannerService: BannerService
  ) { }
}