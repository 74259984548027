import { JobseekerSubscriptionState } from "./state";
import { createImmerReducer } from 'ngrx-immer/store';
import * as JobseekerSubscriptionActions from './actions';
import { on, Action } from '@ngrx/store';
import { SubscriptionViewModel } from 'src/app/subscriber/shared/services/subsriber.service';

let initialState: JobseekerSubscriptionState = {
    loading: false,
    subscription: null,
    isEditMode: false,
    selectedCategories: [],
    categoriesTouched: false,
    subscriptionLoadFailureDetails: null,
    subscriptionUpdateFailureDetails: null
}

const reducer = createImmerReducer(
    initialState,

    on(JobseekerSubscriptionActions.updateSelectedCategories, (state, { payload }) => {
        state.selectedCategories = state.selectedCategories.filter(c => c != payload.categoryId);
        if (payload.checked) {
            state.selectedCategories = [...state.selectedCategories, payload.categoryId];
        }

        if (!state.categoriesTouched) {
            state.categoriesTouched = true;
        }

        return state;
    }),

    on(JobseekerSubscriptionActions.loadSubscription, state => {
        state.loading = true;

        return state;
    }),

    on(JobseekerSubscriptionActions.loadSubscriptionSuccess, (state, { payload }) => {
        state.loading = false;
        state.subscription = payload;
        state.selectedCategories = payload.subscibedCategoryIds;

        return state;
    }),

    on(JobseekerSubscriptionActions.loadSubscriptionFail, (state, { payload }) => {
        state.loading = false;
        state.subscriptionLoadFailureDetails = payload;

        return state;
    }),

    on(JobseekerSubscriptionActions.updateSubscription, state => {
        state.loading = true;
        return state;
    }),

    on(JobseekerSubscriptionActions.updateSubscriptionSuccess, (state, { payload }) => {
        state.loading = false;
        state.subscription = payload;

        return state;
    }),

    on(JobseekerSubscriptionActions.updateSubscriptionFail, (state, { payload }) => {
        state.loading = false;
        state.subscriptionUpdateFailureDetails = payload;

        return state;
    }),

    on(JobseekerSubscriptionActions.activateEditMode, (state) => {
        state.isEditMode = true;

        return state;
    })

);

export function jobseekerSubscriptionReducer(state: JobseekerSubscriptionState | undefined, action: Action) {
    return reducer(state, action);
}
