import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { CustomerPublicPageComponent } from './customer-public-page/customer-public-page.component';
import { CustomerListPageComponent } from './customer-list-page/customer-list-page.component';

const customerRoutes: Routes = [
  {path: 'customer/:id/:title', component: CustomerPublicPageComponent },
  {path: 'customer/:id', component: CustomerPublicPageComponent },
  {path: 'companies', component: CustomerListPageComponent}
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(customerRoutes)
  ]
})
export class CustomerRoutingModule { }
