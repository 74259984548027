import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { response } from 'express';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { BannerService } from 'src/app/banner/shared/services/banner.service';
import { AppState } from '../state';
import * as BannerActions from './actions';
import { AnalyticViewBanner, AnalyticClickBanner } from 'src/app/banner/shared/models/banner-model';
import { AuthService } from 'src/app/core/auth.service';
import { BannerHeaderPositionId } from 'src/app/shared/models/enums/banner-header-position-id-enum';

export {};

@Injectable()
export class BannerEffects {
  insertBannerClick$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BannerActions.insertBannerClick),
        map((action) => {
          
          const analyticBanner: AnalyticClickBanner = {
            bannerId: action.payload.bannerId,
            isAnonymous: !this.authService.isSignedIn(),
            providerWebsiteId: action.payload.providerWebsiteId,
            userId: this.authService.getUserId(),
            targetId: action.payload.targetId,
            pageId: action.payload.pageId
          };
          
          return this.bannerService.sendBannerClick(analyticBanner);
        }),
        tap((success) => {
          if (!success) {
            throw Error('error sending beacon');
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private bannerService: BannerService,
    private store: Store<AppState>,
    private router: Router,
    private authService: AuthService
  ) {}
}
