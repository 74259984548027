import { PagingNavItem } from "@hrra/ui";


export function displayPages(current: number, pageLinkCount: number, pageCount: number){
    let result: PagingNavItem[] = [];
    let halfNavCount = (pageLinkCount - 3) / 2; //nav lnks count before and after current page when it's in the middle and does nto interfer with forst and last page
    let fullNavCount = pageLinkCount - 2; //nav links count except first and last page
    let currentPage = current; //s.filter.currentPage; 

    if (pageCount <= pageLinkCount) {
      //if page numebr is less tha totoal nav links just display all pages
      for (let i = 1; i <= pageCount; i++) {
        result.push({ page: i, isElipsis: false, isCurrent: i == currentPage });
      }
    } else {
      result.push({ page: 1, isElipsis: false, isCurrent: currentPage == 1 }); //display forst page

      if (currentPage == 1 || currentPage - halfNavCount <= 2) {
        //if current page's distance from first page is less then halfNvacount just display all links starting from 2 and then elispsi and last page
        for (let i = 1; i <= fullNavCount; i++) {
          result.push({
            page: i + 1,
            isElipsis: false,
            isCurrent: i + 1 == currentPage,
          });
        }
        result.push({ page: -1, isElipsis: true, isCurrent: false });
        result.push({
          page: pageCount,
          isElipsis: false,
          isCurrent: pageCount == currentPage,
        });
      } else if (
        currentPage - halfNavCount > 2 &&
        currentPage + halfNavCount >= pageCount
      ) {
        //if current page's distance from last page is less than halfNavcount display all linkes starting from last page - fullNavCount -1, and then last page
        result.push({ page: -1, isElipsis: true, isCurrent: false });

        for (let i = 0; i < fullNavCount; i++) {
          result.push({
            page: pageCount - fullNavCount + i,
            isElipsis: false,
            isCurrent: pageCount - fullNavCount + i == currentPage,
          });
        }

        result.push({
          page: pageCount,
          isElipsis: false,
          isCurrent: pageCount == currentPage,
        });
      } else if (
        currentPage - halfNavCount > 2 &&
        currentPage + halfNavCount < pageCount
      ) {
        //if current page is in th emiddle display first page, elispsis, halfnavcoutn pages before current page, current page and half nav coutn after current page and finally last page
        result.push({ page: -1, isElipsis: true, isCurrent: false });
        for (let i = 0; i < fullNavCount; i++) {
          result.push({
            page: currentPage - halfNavCount + i,
            isElipsis: false,
            isCurrent: currentPage - halfNavCount + i == currentPage,
          });
        }

        if (currentPage - halfNavCount + fullNavCount - 1 < pageCount) {
          result.push({ page: -1, isElipsis: true, isCurrent: false });
        }

        result.push({
          page: pageCount,
          isElipsis: false,
          isCurrent: pageCount == currentPage,
        });
      }
    }
    return result;
  }
