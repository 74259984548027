import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, pipe } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { InfoPageService } from 'src/app/info-pages/shared/services/info-page.service';
import * as actions from './actions';

@Injectable()
export class InfoPageEffects{

    loadInfoPage$ = createEffect(() => this.actions$.pipe(
        ofType(actions.loadInfoPage),
        mergeMap((action) => this.infoPageService.getInfoPageContent(action.payload.alias)
        .pipe(
            map(response => actions.loadInfoPageSuccess({payload: response.data})),
            catchError(response => of(actions.loadInfoPageFail({payload: response.error})))
        ))
    ));

    constructor(private actions$: Actions, private infoPageService: InfoPageService){}
}