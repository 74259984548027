import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { SmartHttpService } from '@hrra/core';
import { UrlConfigService } from '@hrra/core';
import { MetaDataModel } from 'src/app/shared/models/meta-model';
import { TranslitService } from 'src/app/shared/services/translit.service';
import { CvBox } from '../models/cv-box-model';
import { UrlConfig, UrlConfigContainer } from 'src/app/core/url-config';


@Injectable({
  providedIn: 'root'
})
export class CvBoxService {

  public getCvBoxPageSections(){
     return this.smartHttp.get<CvBoxPageViewModel>(this.urlService.format(this.urlService.urlConfig.api.data.cvBox.list, {}), {})
     .pipe(map(c => {
       c.data.cvBoxes.forEach(a => {
          a.urlFriendlyCustomerName = this.translit.getUrlFriendlyString(a.customerName);
          a.activationDate = new Date(a.activationDate);
       });

       return c;
     }));
  }

  constructor(private smartHttp: SmartHttpService, private urlService: UrlConfigService<UrlConfigContainer>, private translit: TranslitService) { }
}


export class CvBoxPageViewModel{
  cvBoxes: CvBox[];
  metaData: MetaDataModel;
}