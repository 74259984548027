import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  LocalDataStorageService,
  SmartHttpService,
  UrlConfigService,
} from '@hrra/core';
import { UrlConfigContainer } from 'src/app/core/url-config';
import { TranslitService } from 'src/app/shared/services/translit.service';
import { AnalyticViewBanner, AnalyticClickBanner, Banner } from '../models/banner-model';
import { AuthService } from 'src/app/core/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/root-store/state';
import { insertBannerClick } from 'src/app/root-store/banner-store/actions';

export enum BannerRequests {
  BannerIdList = 'bannerIdList',
  BannerId = 'bannerId',
  IsAnonymous = 'isAnonymous',
  ProviderWebsiteId = 'providerWebsiteId',
  UserId = 'userId',
  PageId = 'pageId',
  BannerPositionIdList = 'bannerPositionIdList',
  TargetId = 'targetId'
}
@Injectable({
  providedIn: 'root',
})
export class BannerService {
  // public inseretBannerClick(bannerId: number){
  //   return this.smartHttp.post(this.urlService.urlConfig.analytics.data.banner.insertClick, bannerId);
  // }

  public sendBannerClick(analyticBanner: AnalyticClickBanner) {
    const data = new FormData();

    data.append(BannerRequests.BannerId, (analyticBanner.bannerId).toString())
    data.append(BannerRequests.IsAnonymous, (analyticBanner.isAnonymous).toString());
    data.append(BannerRequests.PageId, (analyticBanner.pageId).toString());
    data.append(BannerRequests.ProviderWebsiteId, (analyticBanner.providerWebsiteId).toString());
    data.append(BannerRequests.UserId, (analyticBanner.userId).toString());
    data.append(BannerRequests.TargetId, (analyticBanner.targetId).toString())

    return this.smartHttp.sendBeacon(
      this.urlService.urlConfig.analytics.data.banner.insertClick,
      data
    );
  }

  public sendBannerView(analyticBanner:AnalyticViewBanner){
    const data = new FormData();
    
    data.append(BannerRequests.BannerIdList, analyticBanner.bannerIdList);
    data.append(BannerRequests.IsAnonymous, (analyticBanner.isAnonymous).toString());
    data.append(BannerRequests.PageId, (analyticBanner.pageId).toString());
    data.append(BannerRequests.ProviderWebsiteId, (analyticBanner.providerWebsiteId).toString());
    data.append(BannerRequests.UserId, (analyticBanner.userId).toString());
    data.append(BannerRequests.TargetId, (analyticBanner.targetId).toString());

    return this.smartHttp.sendBeacon(
      this.urlService.urlConfig.analytics.data.banner.bannerView, 
      data
    )
  }

  public getBannerList(targetId: number, bannerHeadPositionId: number) {
    return this.smartHttp.get<Banner[]>(
      this.urlService.urlConfig.api.data.banner.getList,
      { targetId: targetId, bannerHeadId: bannerHeadPositionId }
    );
  }

  constructor(
    private smartHttp: SmartHttpService,
    private urlService: UrlConfigService<UrlConfigContainer>,
    private translit: TranslitService,
    private storage: LocalDataStorageService,
    private authService: AuthService,
    private store: Store<AppState>
  ) {}
}
