import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SmartHttpService } from '@hrra/core';
import { UrlConfigService } from '@hrra/core';
import { UrlConfig, UrlConfigContainer } from 'src/app/core/url-config';
import { MetaDataModel } from '../models/meta-model';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  public getCvRegistrationPageSeoData(){
    return this.smartHttp.get<MetaDataModel>(this.urlService.urlConfig.api.data.seoData.cvRegistrationPage, {});
  }

  public getSubscriptionPageSeoData(){
    return this.smartHttp.get<MetaDataModel>(this.urlService.urlConfig.api.data.seoData.subscriptionPage, {});
  }

  public updateMetaData(website: string, data: MetaDataModel = null){

    let title: string;
    let description: string;
    let keywords: string;
    
    if(data !== null){
      title = data.seoData.title == null ? website : data.seoData.title + ' ' + website;
      description = data.seoData.description == null ? '' : data.seoData.description;
      keywords = data.seoData.keywords == null ? '' : data.seoData.keywords;      
    }
    else{
      title = website;
      description = '';
      keywords = '';
    }

    this.titleService.setTitle(title);

    this.metaTagService.updateTag(
      {name: 'description', content: description}
    );
    this.metaTagService.updateTag(
      {name: 'keywords', content: keywords}
    );


    //openGraph
    if(data !== null && data.openGraphData !== null){
      this.metaTagService.updateTag(
        {property: 'og:type', content: data.openGraphData.type}
      );

      this.metaTagService.updateTag(
        {property: 'og:title', content: data.openGraphData.title}
      )
      this.metaTagService.updateTag(
        {property: 'og:description', content: data.openGraphData.description}
      )
      this.metaTagService.updateTag(
        {property: 'og:image', content: data.openGraphData.image}
      )
    }
    else{
      this.metaTagService.removeTag('property = "og:type"');
      this.metaTagService.removeTag('property = "og:title"');
      this.metaTagService.removeTag('property = "og:description"');
      this.metaTagService.removeTag('property = "og:image"');
    }
  }

  constructor(  private smartHttp: SmartHttpService,
    private urlService: UrlConfigService<UrlConfigContainer>,
    private titleService: Title,
    private metaTagService: Meta,) { }
  }