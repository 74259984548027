import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MainPageComponent } from './front-page/front-page.component';
import { AnnSearchComponent } from './ann-search/ann-search.component';
import { AnnDetailsPageComponent } from './ann-details-page/ann-details-page.component';
import { AnnFavoritesComponent } from './ann-favorites/ann-favorites.component';

const announcementRoutes: Routes = [
  { path: '', component: MainPageComponent },
  {
    path: 'announcement/favorites',
    component: AnnFavoritesComponent,
  },
  {
    path: 'search-posting',
    pathMatch: 'full',
    component: AnnSearchComponent,
    data: {
      reuse: true
    }
  },
  {
    path: 'announcement/:id/:title',
    component: AnnDetailsPageComponent,
  },
  { path: 'announcement/:id', component: AnnDetailsPageComponent },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(announcementRoutes)],
  exports: [RouterModule],
})
export class AnnouncementRoutingModule {}
