import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AnnouncementType } from 'src/app/root-store/state';
import { Range } from '@hrra/core';
import { UrlConfigService } from '@hrra/core';
import { SmartHttpService } from '@hrra/core';
import { tap, map, concatMap, flatMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ProviderWebsite } from '../models/provider-website';
import { UrlConfig, UrlConfigContainer } from 'src/app/core/url-config';
import { PartnerSitesModel } from '../models/partner-sites-mode';
import { Locality, OccupationCategory, OptionItem } from '@hrra/common-models';



// let mockLocalities: Locality[] = [
//   {
//     indexNumber: 1,
//     localityId: 1,
//     name: 'Tbilisi'
//   },
//   {
//     indexNumber: 2,
//     localityId: 2,
//     name: 'Kutaisi'
//   },
//   {
//     indexNumber: 3,
//     localityId: 3,
//     name: 'Rustavi'
//   },
//   {
//     indexNumber: 4,
//     localityId: 4,
//     name: 'Gori'
//   },
//   {
//     indexNumber: 5,
//     localityId: 5,
//     name: 'City 5'
//   },
//   {
//     indexNumber: 6,
//     localityId: 6,
//     name: 'City 6'
//   },
//   {
//     indexNumber: 7,
//     localityId: 7,
//     name: 'City 7'
//   },
//   {
//     indexNumber: 8,
//     localityId: 8,
//     name: 'City 8'
//   },
//   {
//     indexNumber: 9,
//     localityId: 9,
//     name: 'City 9'
//   }
// ];

// let mockCategories: OccupationCategory[] = [
//   {
//     categoryId: 1,
//     childCategoryIds: [2, 3],
//     headCategoryId: 1,
//     isHead: true,
//     name: 'IT'
//   },
//   {
//     categoryId: 2,
//     childCategoryIds: [],
//     headCategoryId: 1,
//     isHead: false,
//     name: 'Developer'
//   },
//   {
//     categoryId: 3,
//     childCategoryIds: [],
//     headCategoryId: 1,
//     isHead: false,
//     name: 'System administrator'
//   },

//   {
//     categoryId: 4,
//     childCategoryIds: [5, 6],
//     headCategoryId: 4,
//     isHead: true,
//     name: 'Sales'
//   },
//   {
//     categoryId: 5,
//     childCategoryIds: [],
//     headCategoryId: 4,
//     isHead: false,
//     name: 'Sales manager'
//   },
//   {
//     categoryId: 6,
//     childCategoryIds: [],
//     headCategoryId: 4,
//     isHead: false,
//     name: 'Corporative sales manager'
//   }
// ];

// let mockAnnouncementTypes: AnnouncementType[] = [
//   {
//     announcementTypeId: 1,
//     indexNumber: 1,
//     name: 'Vacancy'
//   },
//   {
//     announcementTypeId: 2,
//     indexNumber: 2,
//     name: 'Training'
//   },
//   {
//     announcementTypeId: 3,
//     indexNumber: 3,
//     name: 'Tender'
//   },
//   {
//     announcementTypeId: 4,
//     indexNumber: 4,
//     name: 'Other'
//   }
// ];

// let mockSalaryFilterOptions: OptionItem<Range<number>>[] = [
//   {
//     indexNumber: 0,
//     name: 'All',
//     value: undefined,
//     isDefault: false,
//     isEmpty: true
//   },
//   {
//     indexNumber: 1,
//     name: '500+',
//     value: { from: 500, to: 1000 },
//     isDefault: false,
//     isEmpty: false
//   },
//   {
//     indexNumber: 1,
//     name: '1000+',
//     value: { from: 1000, to: 1500 },
//     isDefault: false,
//     isEmpty: false
//   },
//   {
//     indexNumber: 1,
//     name: '1500+',
//     value: { from: 1500, to: 2000 },
//     isDefault: false,
//     isEmpty: false
//   },
//   {
//     indexNumber: 1,
//     name: '2000+',
//     value: { from: 2000, to: 2500 },
//     isDefault: false,
//     isEmpty: false
//   },
//   {
//     indexNumber: 1,
//     name: '2500+',
//     value: { from: 2500, to: 3000 },
//     isDefault: false,
//     isEmpty: false
//   },
//   {
//     indexNumber: 1,
//     name: '3000+',
//     value: { from: 3000, to: 5000 },
//     isDefault: false,
//     isEmpty: false
//   },
//   {
//     indexNumber: 1,
//     name: '5000+',
//     value: { from: 5000, to: undefined },
//     isDefault: false,
//     isEmpty: false
//   }
// ];

// let mockWorkExperienceFilterOptions: OptionItem<Range<number>>[] = [
//   {
//     indexNumber: 0,
//     name: 'Without experience',
//     value: { from: 0, to: 0 },
//     isDefault: false,
//     isEmpty: false
//   },
//   {
//     indexNumber: 1,
//     name: 'Less then 1 year',
//     value: { from: 0, to: 1 },
//     isDefault: false,
//     isEmpty: false
//   },
//   {
//     indexNumber: 1,
//     name: '1-2 years',
//     value: { from: 1, to: 2 },
//     isDefault: false,
//     isEmpty: false
//   },
//   {
//     indexNumber: 1,
//     name: '2-3 years',
//     value: { from: 2, to: 3 },
//     isDefault: false,
//     isEmpty: false
//   },
//   {
//     indexNumber: 1,
//     name: '3-5 years',
//     value: { from: 3, to: 5 },
//     isDefault: false,
//     isEmpty: false
//   },
//   {
//     indexNumber: 1,
//     name: '5-7 years',
//     value: { from: 5, to: 7 },
//     isDefault: false,
//     isEmpty: false
//   },
//   {
//     indexNumber: 1,
//     name: '7+ years',
//     value: { from: 0, to: 1 },
//     isDefault: false,
//     isEmpty: false
//   }
// ];

let mockPublishDateFilterOptions: OptionItem<Range<number>>[] = [
  {
    indexNumber: 0,
    name: 'All',
    value: undefined,
    isDefault: false,
    isEmpty: true
  },
  {
    indexNumber: 1,
    name: 'Today',
    value: { from: 0, to: 0 },
    isDefault: false,
    isEmpty: false
  },
  {
    indexNumber: 2,
    name: 'Last 3 days',
    value: { from: -3, to: 0 },
    isDefault: false,
    isEmpty: false
  },
  {
    indexNumber: 3,
    name: 'Last week',
    value: { from: -7, to: 0 },
    isDefault: false,
    isEmpty: false
  },
  {
    indexNumber: 4,
    name: 'Last month',
    value: { from: -7, to: 0 },
    isDefault: false,
    isEmpty: false
  }
];

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  public processData(lookupData: CommonLookupData) {

    lookupData.occupationCategories.forEach(c => {
      c.isHead = c.id == c.head.id;
      c.categoryId = c.id;//TODO remove categoryId
      c.headCategoryId = c.head.id;
      c.childCategoryIds = lookupData.occupationCategories.filter(cc => cc.headCategoryId == c.id && cc.id != c.id).map(cc => cc.id);
    });

  }

  getAll(): Observable<CommonLookupData> {

    return this.translateService.get(['label.Any'])
    .pipe(
      flatMap(t =>
        this.http.get<CommonLookupData>(this.urlService.urlConfig.api.data.lookup.common, {})
        .pipe(
          map(c => c.data),
          tap(c => {
            //c.salaryFilterOptions = mockSalaryFilterOptions;
            //c.workExperienceFilterOptions = mockWorkExperienceFilterOptions;
            c.salaryFilterOptions = [
              {
                indexNumber: 0,
                name: t['label.Any'],
                value: undefined,
                isDefault: false,
                isEmpty: true
              },
              ...c.salaryFilterOptions
            ];
            c.publishDateFilterOptions = [
              {
                indexNumber: 0,
                name:  t['label.Any'],
                value: undefined,
                isDefault: false,
                isEmpty: true
              },
              ...c.publishDateFilterOptions
            ];
            c.announcementTypeOptions = [
              {
                announcementTypeId: undefined,
                name: t['label.Any'],
                indexNumber: 0,
              },
              ...c.announcementTypes
            ];
          }),
          tap(c => this.processData(c))
        )
      )
    );

    // return of({ 
    //   localities: mockLocalities, 
    //   occupationCategories: mockCategories, 
    //   announcementTypes: mockAnnouncementTypes,
    //   salaryFilterOptions: mockSalaryFilterOptions,
    //   workExperienceFilterOptions: mockWorkExperienceFilterOptions,
    //   publishDateFilterOptions: mockPublishDateFilterOptions,

    // });
  }

  public getPartnerSites() {
    return this.http.get<PartnerSitesModel[]>(this.urlService.format(this.urlService.urlConfig.api.data.lookup.partnerSites, {}), {});
  }

  public getProviderWebsite() {
    return this.http.get<ProviderWebsite>(this.urlService.format(this.urlService.urlConfig.api.data.lookup.providerWebsite, {}), {});
  }

  constructor(private urlService: UrlConfigService<UrlConfigContainer>, private http: SmartHttpService, private translateService: TranslateService) { }
}



export interface CommonLookupData {
  localities: Locality[];
  occupationCategories: OccupationCategory[];
  announcementTypes: AnnouncementType[];
  announcementTypeOptions: AnnouncementType[];
  salaryFilterOptions: OptionItem<Range<number>>[];
  workExperienceFilterOptions: OptionItem<Range<number>>[];
  publishDateFilterOptions: OptionItem<Range<number>>[];
}
