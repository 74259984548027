import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectCurrentWebsiteNameForSeo } from '../selectors';
import { AppState } from '../state';
import { InfoPageStoreState } from './state';

export const selectInfoPageStoreState = createFeatureSelector<InfoPageStoreState>('infoPage');

export const selectInfoPageContent = createSelector(
    selectInfoPageStoreState,
    s => s.resource   
);

export const selectInfoPageMetaData = createSelector(
    selectInfoPageStoreState,
    selectCurrentWebsiteNameForSeo,
    (s, w) => {
        return {
            website: w,
            metaData: s.resource?.metaData
        };
    }
);

export const selectIsInfoPageLoading = createSelector(
    selectInfoPageStoreState,
    e => e.loading
);