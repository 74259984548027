import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { JobseekerService } from 'src/app/jobseeker/shared/services/jobseeker.service';
import * as SubscriberActions from './actions';
import { mergeMap, catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { SubscriberService } from 'src/app/subscriber/shared/services/subsriber.service';
import { MetaService } from 'src/app/shared/services/meta.service';

@Injectable()
export class SubscriberEffects{

    createSubscription$ = createEffect(() => this.actions$.pipe(
        ofType(SubscriberActions.createNewSubscription),
        mergeMap((action) => this.subscriberService.createSubscription(action.payload)
        .pipe(
            map(response => SubscriberActions.createNewSubscriptionSuccess({payload: response.data})),
            catchError(response => of(SubscriberActions.createNewSubscriptionFail({payload: response.error})))
        ))
    ));

    createSubscriptionSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(SubscriberActions.createNewSubscriptionSuccess),
        tap(() => this.router.navigate(['/subscriber/subscription-complete'])),
        catchError(response => of(SubscriberActions.createNewSubscriptionFail({payload: response.error})))//todo remove bacuase it's not dispatched
    ), { dispatch: false});

    resendActivationEmail$ = createEffect(() => this.actions$.pipe(
        ofType(SubscriberActions.resendActivationEmail),
        mergeMap((action) => this.subscriberService.resendVerificationEmail(action.payload.emailVerificationResendToken)
        .pipe(
            map(() => SubscriberActions.resendActivationEmailSuccess()),
            catchError(response => of(SubscriberActions.ResendActivationEmailFail({payload: response.error})))
        ))
    ));

    verifyJobseekerEmail$ = createEffect(() => this.actions$.pipe(
        ofType(SubscriberActions.verifySubscriberEmail),
        mergeMap((action) => this.subscriberService.verifyEmail(action.payload.verificationCode)
        .pipe(
            map(response => SubscriberActions.verifySubscriberEmailSuccess()),
            catchError(response => of(SubscriberActions.verifySubscriberEmailFail({payload: response.error})))
        ))
    ));
    

    updateSubscription$ = createEffect(() => this.actions$.pipe(
        ofType(SubscriberActions.updateSubscription),
        mergeMap((action) => this.subscriberService.updateSubscription(action.payload)
        .pipe(
            map(response => SubscriberActions.updateSubscriptionSuccess({payload: response.data})),
            catchError(response => of(SubscriberActions.updateSubscriptionFail({payload: response.error})))
        ))
    ));

    updateSubscriptionSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(SubscriberActions.updateSubscriptionSuccess),
        tap((action) => this.router.navigate([`/subscriber/${action.payload.code}/subscription`])),
    ), { dispatch: false});
    
    cancelSubscription$ = createEffect(() => this.actions$.pipe(
        ofType(SubscriberActions.cancelSubscription),
        mergeMap((action) => this.subscriberService.cancelSubscription(action.payload.subscriberCode)
        .pipe(
            map(response => SubscriberActions.cancelSubscriptionSuccess()),
            catchError(response => of(SubscriberActions.cancelSubscriptionFail({payload: response.error})))
        ))
    ));

    cancelSubscriptionSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(SubscriberActions.cancelSubscriptionSuccess),
        tap(() => this.router.navigate(['/subscriber/unsubscribe-complete'])),
        catchError(response => of(SubscriberActions.cancelSubscriptionFail({payload: response.error})))//todo remove bacuase it's not dispatched
    ), { dispatch: false});



    loadSubscription$ = createEffect(() => this.actions$.pipe(
        ofType(SubscriberActions.loadSubscription),
        mergeMap((action) => this.subscriberService.getSubscription(action.payload.subscriberCode)
        .pipe(
            map(response => SubscriberActions.loadSubscriptionSuccess({payload: response.data})),
            catchError(response => of(SubscriberActions.loadSubscriptionFail({payload: response.error})))
        ))
    ));

    activateEditMode$ = createEffect(() => this.actions$.pipe(
        ofType(SubscriberActions.activateEditMode),
        tap(() => this.router.navigate(['subscriber/subscription'])),
        catchError(response => of(SubscriberActions.activateEditModeFail()))//todo remove bacuase it's not dispatched
    ), { dispatch: false});


    getSubscriptionPageSeoData$ = createEffect(() => this.actions$.pipe(
        ofType(SubscriberActions.getSubscriptionPageSeoData),
        mergeMap(() => this.metaService.getSubscriptionPageSeoData()
        .pipe(
            map(response => SubscriberActions.getSubscriptionPageSeoDataSuccess({payload: response.data})),
            catchError(response => of(SubscriberActions.getSubscriptionPageSeoDataFail({payload: response.error})))
        ))
    ));

    
    constructor(private actions$: Actions, private subscriberService: SubscriberService, private router: Router, private metaService: MetaService){}
}