import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { CvBoxPageComponent } from './cv-box-page/cv-box-page.component';


const cvBoxRoutes: Routes =[

  {path: 'cvbox', component: CvBoxPageComponent}
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(cvBoxRoutes)
  ],
  exports: [RouterModule]
})
export class CvBoxRoutingModule { }
