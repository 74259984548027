import { Injectable } from '@angular/core';
import { LocationsViewModel, TreeSelectModel } from '@hrra/common-models';
import { ApiResponse, SmartHttpService, UrlConfigService } from '@hrra/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UrlConfig } from 'src/app/core/url-config';

@Injectable()
export abstract class LocationService {

  public abstract getLocations(locationParentIds: string[]): Observable<ApiResponse<LocationsViewModel[], {}>>;

  public abstract getTreeSelectLocations() : Observable<ApiResponse<TreeSelectModel<string>[], {}>>;
}
