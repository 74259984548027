<div class="tab" (window:resize)="onResizeWindowWidth()">
    <div class="header tab__header">
        <div  *ngFor="let tabItem of tabItems$ | async" 
              (click)="selectTab(tabItem)"
              [ngClass]="{'tab-button-active': activeTabsSet.has(tabItem)}" 
              class="tab-button">
            {{tabItem.label}}
        </div>
        <div class="default-fill"></div>
    </div>
    <div *ngFor="let item of (tabItems$ | async)">
        <div (click)="selectTab(item)" class="header header--responsive">
            <div [ngClass]="{'active-tab-label': activeTabsSet.has(item)}">{{item.label}}</div>
            <svg-icon src="/assets/icons/double-chevron-down.svg" class="chevron" [ngClass]="{'active-tab': activeTabsSet.has(item)}"></svg-icon>
        </div>
        <div class="tab-item-container" *ngIf="activeTabsSet.has(item)">
            <ng-container  *ngTemplateOutlet="item?.tabContent">
            
            </ng-container>
        </div>
    </div> 
</div>
