import { Injectable } from '@angular/core';
import { EmptyResponse, UrlConfigService } from '@hrra/core';
import { Announcement } from '../models/announcement-model';
import { AnnouncementDetails, AnnouncementViewEvent } from '../models/announcement-details-model';
import { WebsiteStatsModel } from '../models/website-stats';
import { FavoritesService } from 'src/app/shared/services/favorites.service';
import { TranslitService } from 'src/app/shared/services/translit.service';
import { map, tap } from 'rxjs/operators';
import { ApiResponse } from '@hrra/core';
import { MetaDataModel } from 'src/app/shared/models/meta-model';
import { SmartHttpService } from '@hrra/core';
import { UrlConfig, UrlConfigContainer } from 'src/app/core/url-config';
import { CustomLocalDataStorageService } from 'src/app/core/local-data-storage.service';
import { announcementDeadlineTodayHelper, announcementPublishTodayHelper } from 'src/app/shared/helpers/announcement-publish-date-helper';
import { Params } from '@angular/router';
import { AnnouncementViewRequestModel } from '../models/announcement-view-request.model';

export enum AnnouncementBeaconField {
  AnnouncementId = 'announcementId',
  AnnouncementStatId = 'announcementStatId',
  AnnouncementRenewalStatId = 'announcementRenewalStatId',
  IsAnonymous = 'isAnonymous',
  UserId = 'userId',
  IsUnique = 'isUnique',
  ProviderWebsiteId = 'providerWebsiteId',
}

@Injectable({
  providedIn: 'root',
})
export class AnnouncementService {
  public getFrontPageModel() {
    return this.smartHttp.get<FrontPageViewModel>(
      this.urlService.urlConfig.api.data.announcements.list,
      {}
    ).pipe(map((d) => this.addUrlFriendlyMainPageTitles(d)));    
  }

  public getFavoriteAnnouncements() {
    let announcementIds = this.favorites.getFavorites();
    let data = { announcementIds: announcementIds };
    return this.smartHttp.get<Announcement[]>(this.urlService.urlConfig.api.data.announcements.favorites, data)
    .pipe(map((d)=>this.addUrlFriendlyTitles(d)));
  }

  public getRefreshedFavoriteIds(){
    let announcementIds = this.favorites.getFavorites();
    return this.smartHttp.get<number[]>(this.urlService.urlConfig.api.data.announcements.getRefreshedFavoriteIds, { announcementIds: announcementIds});
  }

  public getAnnouncement(id: number) {
    return this.smartHttp.get<AnnouncementDetailsViewModel>(
      this.urlService.format(this.urlService.urlConfig.api.data.announcements.get, {
        id: id,
      }),
      {}
    ).pipe(map(c => {
      
      let result = JSON.parse(JSON.stringify(c));

      if(!c.data.announcement.expired){
        let currentDate = new Date();

        result.data.announcement.urlFriendlyTitle = this.translit.getUrlFriendlyString(c.data.announcement.title);
        result.data.announcement.urlFriendlyCustomerName = this.translit.getUrlFriendlyString(c.data.announcement.customerName);
        result.data.announcement.isPublishedToday = announcementPublishTodayHelper(c.data.announcement.renewalDate, c.data.announcement.publishDate, currentDate);
        result.data.announcement.isDeadLineToday = announcementDeadlineTodayHelper(c.data.announcement.deadlineDate, currentDate);
        result.data.announcement.similarAnnouncements.forEach(a => {
          a.urlFriendlyTitle = this.translit.getUrlFriendlyString(a.title);
          a.urlFriendlyCustomerName = this.translit.getUrlFriendlyString(a.customerName);
        })
      }
      return result;
    }));
  }

  public sendAnnouncementViewToAnalytic(announcementAnalytic:any){
    const data = new FormData();
    data.append(AnnouncementBeaconField.AnnouncementId, announcementAnalytic.announcementId);
    data.append(AnnouncementBeaconField.AnnouncementStatId, announcementAnalytic.announcementStatId);
    if(announcementAnalytic.announcementRenewalStatId){
      data.append(AnnouncementBeaconField.AnnouncementRenewalStatId, announcementAnalytic.announcementRenewalStatId);
    };
    data.append(AnnouncementBeaconField.IsAnonymous, announcementAnalytic.isAnonymous);
    data.append(AnnouncementBeaconField.UserId, announcementAnalytic.userId);
    data.append(AnnouncementBeaconField.IsUnique, announcementAnalytic.isUnique);
    data.append(AnnouncementBeaconField.ProviderWebsiteId, announcementAnalytic.providerWebsiteId);
    return this.smartHttp.sendBeacon(
      this.urlService.urlConfig.analytics.data.announcement.announcementView,
      data
    );
  };

  public getSearchFieldInitialData() {
    return this.smartHttp.get<WebsiteStatsModel>(
      this.urlService.urlConfig.api.data.announcements.getSearchField,
      {}
    );
  }

  private addUrlFriendlyTitles(response: ApiResponse<Announcement[]>){
    let currentDate = new Date();
    response.data.forEach(a => {
      a.urlFriendlyTitle = this.translit.getUrlFriendlyString(a.title);
      a.urlFriendlyCustomerName = this.translit.getUrlFriendlyString(a.customerName);

      a.isPublishedToday = announcementPublishTodayHelper(a.renewalDate, a.publishDate, currentDate);
      a.isDeadLineToday = announcementDeadlineTodayHelper(a.deadlineDate, currentDate);
    });

    return response;
  }

  private addUrlFriendlyMainPageTitles(response: ApiResponse<FrontPageViewModel>){
    let currentDate = new Date();
    response.data.announcementList.forEach(a => {
      a.urlFriendlyTitle = this.translit.getUrlFriendlyString(a.title);
      a.urlFriendlyCustomerName = this.translit.getUrlFriendlyString(a.customerName);

      a.isPublishedToday = announcementPublishTodayHelper(a.renewalDate, a.publishDate, currentDate);
      a.isDeadLineToday = announcementDeadlineTodayHelper(a.deadlineDate, currentDate);
    });

    return response;
  }

  public announcementView(request: AnnouncementViewRequestModel) {
    return this.smartHttp.get<number>(this.urlService.urlConfig.api.data.announcements.announcementView, request);
  }

  public getAnnouncementViews(): ViewAnnouncementModel[] {
    return this.storage.getAnnouncementViews() ?? [];
  }

  public addAnnouncementView(announcementView: ViewAnnouncementModel){
    let announcementViews = this.getAnnouncementViews();
    let currentDate = new Date();
    let newAnnouncementViews = [ ... announcementViews.filter(p => p.announcementId != announcementView.announcementId && p.expireDate > currentDate), announcementView ];

    this.storage.storeAnnouncementViews(newAnnouncementViews);

    return newAnnouncementViews;
  }

  public isAnnouncementViewCountable(announcementId: number){
    let announcementViews = this.getAnnouncementViews();
    let announcement = announcementViews.find(p => p.announcementId == announcementId);

    let result =  announcement == null || new Date(announcement.expireDate).getTime() < new Date().getTime();

    return result;
  }

  public apply(id: number) {
    return this.smartHttp.post<AnnouncementDetailsViewModel>(this.urlService.urlConfig.api.data.announcements.apply, id);
  }

  
  constructor(
    private smartHttp: SmartHttpService,
    private urlService: UrlConfigService<UrlConfigContainer>,
    private favorites: FavoritesService,
    private translit: TranslitService,
    private storage: CustomLocalDataStorageService
  ) {}
}

export interface FrontPageViewModel{
  announcementList: Announcement[];
  metaData: MetaDataModel;
  frontPageCityLinks: FrontPageCityLink[];
  cvBoxDecorateAnnouncementCustomerIds: number[];
}

export interface AnnouncementDetailsViewModel{
  announcement: AnnouncementDetails;
  metaData: MetaDataModel;
}

export interface FrontPageCityLink{
  localityId: number;
  name: string;
  queryParams: Params;
}

export interface ViewAnnouncementModel{
  announcementId: number;
  expireDate: Date;
}
