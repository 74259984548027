import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { HRRAExceptionCode } from "@hrra/common-models";
import { TranslateService } from '@ngx-translate/core';
import { InfoPopupComponent } from '@hrra/ui';
import { ConfirmationDialogComponent } from 'libs/ui/src/lib/confirmation-dialog/confirmation-dialog.component';
import { ExceptionDialogComponent } from 'libs/ui/src/lib/exception-dialog/exception-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog,  private translate: TranslateService) { }

  openConfirmDialog(msg){
    return this.dialog.open(ConfirmationDialogComponent, {
      // disableClose: true,
      panelClass: 'confirm-dialog-container',
      data:{
        message : msg
      },
      id: 'confirm'
    });
  }

  infoPopup(text, title){
    return this.dialog.open(InfoPopupComponent, {
      panelClass: 'info-dialog-container',
      autoFocus: false,
      data:{
        text : text,
        title: title
      },
      maxHeight: '100vh',
      id: 'info'
    });
  }

  openExeptionDialog(text, routerText, routerLink){
    return this.dialog.open(ExceptionDialogComponent, {
      panelClass: 'exeption-dialog-container',
      data:{
        message : text,
        routerText: routerText,
        routerLink: routerLink
      },
      id: 'exeption',
    });
  }

  getApplyFailedDialog(code){
    let routerText = this.translate.instant('label.GoToProfile');
    let routerLink = '/jobseeker/profile';
    let text: string;

    if(code == HRRAExceptionCode.ProfileIsIncomplete){
        text = this.translate.instant('message.ProfileIsIncomplete');
    }

    if(code == HRRAExceptionCode.ProfileIsPrivate){
        text = this.translate.instant('message.ProfileIsPrivate')
    }
    
    if(code == HRRAExceptionCode.CustomerIsBlackListed){
        text =  this.translate.instant('message.CannotApplyBlacklistedCompany')
    }

    return this.openExeptionDialog(text,  routerText,  routerLink)
  }
}
