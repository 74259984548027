import { createAction, props } from '@ngrx/store';
import { ApiError } from '@hrra/core';
import { JobseekerSubscriptionUpdateViewModel, JobseekerSubscriptionViewModel } from 'src/app/jobseeker/shared/services/jobseeker.service';
import { SubscriberRegistrationRequestViewModel, SubscriberRegistrationResponseViewModel, SubscriptionUpdateViewModel, SubscriptionViewModel } from 'src/app/subscriber/shared/services/subsriber.service';
import { ActionPayload } from "@hrra/core";

export enum Actions {

    LoadSubscription = '[Jobseeker subscription page] Load subscription',
    LoadSubscriptionSuccess = '[Jobseeker subscription page] Load subscription success',
    LoadSubscriptionFail = '[Jobseeker subscription page] Load subscription fail',

    UpdateSubscription = '[Edit jobseeker subscription page] Update subscription',
    UpdateSubscriptionSuccess = '[Edit jobseeker subscription page] Update subscription success',
    UpdateSubscriptionFail = '[Edit jobseeker subscription page] Update subscription fail',

    UpdateSelectedCategories = '[Jobseeker subscription page] Update selected categories',

    ActivateEditMode = '[Jobseeker subscription page] Activate edit mode'
}

export const loadSubscription = createAction(
    Actions.LoadSubscription
);

export const loadSubscriptionSuccess = createAction(
    Actions.LoadSubscriptionSuccess,
    props<ActionPayload<JobseekerSubscriptionViewModel>>()
);

export const loadSubscriptionFail = createAction(
    Actions.LoadSubscriptionFail,
    props<ActionPayload<ApiError>>()
);

export const updateSubscription = createAction(
    Actions.UpdateSubscription,
    props<ActionPayload<JobseekerSubscriptionUpdateViewModel>>()
);

export const updateSubscriptionSuccess = createAction(
    Actions.UpdateSubscriptionSuccess,
    props<ActionPayload<JobseekerSubscriptionViewModel>>()
);

export const updateSubscriptionFail = createAction(
    Actions.UpdateSubscriptionFail,
    props<ActionPayload<ApiError>>()
);

export const updateSelectedCategories = createAction(
    Actions.UpdateSelectedCategories,
    props<ActionPayload<{ categoryId: number, checked: boolean}>>()
);

export const activateEditMode = createAction(
    Actions.ActivateEditMode
);
