<div *ngIf="banner != null">
  <div
    class="banner"
    *ngIf="providerWebsite$ | async as data"
    [ngClass]="{
      'banner--main-page-top':
        banner.positionId == position.Main_page_top ||
        banner.positionId == position.Main_page_Top,
      'banner--auto':
        banner.positionId == position.Main_page_bottom ||
        banner.positionId == position.Announcement_details_Bottom,
      'banner-ann-details-right':
        banner.positionId == position.Announcement_details_Right_Banner ||
        banner.positionId == position.Announcement_details_Right_Banner2,
      'banner-ann-details-right3':
        banner.positionId == position.Announcement_details_Right_Banner3,
      'banner-ann-details-square':
        banner.positionId == position.Announcement_details_Right_Square,
      'banner-ann-search':
        banner.positionId == position.Search_results_Top ||
        banner.positionId == position.Search_results_Left_Sidebar ||
        banner.positionId == position.Search_results_List,
      'banner-ann-search-list':
        banner.positionId == position.Search_results_List,
      'banner-ann-search-sidebar':
        banner.positionId == position.Search_results_Left_Sidebar
    }"
  >
    <a
      *ngIf="banner.typeId == bannerType.Image"
      [href]="!banner?.link.startsWith('https://') &&
      !banner?.link.startsWith('http://')
        ? 'http://' + banner.link
        : banner.link"

      (click)="
        insertBannerClick(banner.bannerId, banner.targetId, data.providerWebsiteId)
      "
      target="{{ banner.isOpenNewTab ? '_blank' : '_self' }}"
    >
      <img class="banner__img" [src]="banner.imageFileUrl" alt="" />
    </a>

    <div *ngIf="banner.typeId == bannerType.Html">
      <app-banner-insert-script
        [banner]="banner.code"
      ></app-banner-insert-script>
      <app-banner-insert-script
        [banner]="banner.code"
      ></app-banner-insert-script>
    </div>
  </div>
</div>
