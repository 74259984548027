import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AnnouncementStoreState } from '../state';
import { AppState } from '../../state';
import { PageNameEnum } from 'src/app/shared/models/enums/page-names-enum';
import {
  getCurrentInfoState,
  selectCommonLookupsLoaded,
  selectCurrentWebsiteNameForSeo,
  selectCurrentWebsiteShortName,
  selectFrontPageNumberOfItemsOnPage,
  selectLookupMy,
} from '../../selectors';
import { selectQueryParams } from '../search-page/selectors';
import { orderedAnnouncementListIds, selectFavoriteIds } from '../selectors';
import { getCustomerState } from '../../customer-store/selectors';
import {
  MetaDataModel,
  OpenGraphModel,
} from 'src/app/shared/models/meta-model';
import { BannerPositionEnum } from 'src/app/banner/shared/models/enums/banner-position-enum';
import { Banner } from 'src/app/banner/shared/models/banner-model';
import { Announcement } from 'src/app/announcement/shared/models/announcement-model';
import { HRRAExceptionCode } from '@hrra/common-models';

export const selectAnnouncementState =
  createFeatureSelector<AnnouncementStoreState>('announcement');

export const selectCurrentNvaigationIds = createSelector(
  selectAnnouncementState,
  (s) => s.announcementDetails.currentNvaigationIds
);

export const selectLastListingPage = createSelector(
  getCurrentInfoState,
  (s) => s.lastListingPage
);

export const selectAnnouncementDetails = createSelector(
  selectAnnouncementState,
  (ann) => ann.announcementDetails.announcementDetails
);

export const selectPreviouseAnnouncementId = createSelector(
  selectAnnouncementState,
  (e) => e.announcementDetails?.previouseAnnouncementId
);

export const selectIsFirstOpenedAnnouncement = createSelector(
  selectAnnouncementState,
  (e) =>
    e.announcementDetails?.previouseAnnouncementId ==
    e.announcementDetails?.announcementDetails?.announcementId
);

export const selectBackToSearchResultRouterLink = createSelector(
  //When the first announcement is opened, back to the page this one is opened from..
  selectLastListingPage,
  selectAnnouncementDetails,
  selectPreviouseAnnouncementId,
  selectAnnouncementState,
  selectIsFirstOpenedAnnouncement,
  (l, a, p, s, f) => {
    if (l == PageNameEnum.SearchResultsPage) {
      return '/search-posting';
    }

    if ((l == PageNameEnum.FrontPage && f) || l == null) {
      return '';
    }

    if (l == PageNameEnum.Favorites && f) {
      return `/announcement/favorites`;
    }

    if (!f) {
      return `/announcement/${p}`;
    }

    if (l == PageNameEnum.CustomerPublicPage && f) {
      let customerId = a?.customerId;
      return `/customer/${customerId}`;
    }
  }
);

export const selectBackToSearchResultQueryParams = createSelector(
  selectLastListingPage,
  selectQueryParams,
  (l, q) => {
    if (l == PageNameEnum.SearchResultsPage) {
      return q;
    }

    if (
      l == PageNameEnum.FrontPage ||
      l == PageNameEnum.CustomerPublicPage ||
      l == PageNameEnum.AnnouncementDetails ||
      l == PageNameEnum.Favorites
    ) {
      return null;
    }
  }
);

export const getNextAnnouncementId = createSelector(
  selectCurrentNvaigationIds,
  selectAnnouncementDetails,
  orderedAnnouncementListIds,
  selectLastListingPage,
  getCustomerState,
  selectAnnouncementState,
  selectIsFirstOpenedAnnouncement,
  selectFavoriteIds,
  (a, ad, al, lp, c, as, f, fav) => {
    if (ad == null) return undefined; //TODO: doesn't work when null is returned

    if (lp == PageNameEnum.SearchResultsPage && f && a != null) {
      if (ad.announcementId != a[a.length - 1].announcementId) {
        let currentAnnouncementIndex = a.findIndex(
          (obj) => obj['announcementId'] == ad.announcementId
        );
        return a[currentAnnouncementIndex + 1];
      }
    }
    if (
      lp == PageNameEnum.FrontPage &&
      f &&
      ad.announcementId != al[al.length - 1].announcementId
    ) {
      let currentAnnouncementIndex = al.findIndex(
        (e) => ad.announcementId == e.announcementId
      );
      return al[currentAnnouncementIndex + 1];
    }

    if (
      lp == PageNameEnum.Favorites &&
      f &&
      ad.announcementId != fav[fav.length - 1].announcementId
    ) {
      let currentAnnouncementIndex = fav.findIndex(
        (i) => ad.announcementId == i.announcementId
      );
      return fav[currentAnnouncementIndex + 1];
    }

    let customerAnnouncementIds = c.customer?.announcementList.map((e) => {
      return { announcementId: e.announcementId, announcementTitle: e.title };
    });
    if (
      lp == PageNameEnum.CustomerPublicPage &&
      f &&
      ad.announcementId !=
        customerAnnouncementIds[customerAnnouncementIds.length - 1]
          .announcementId
    ) {
      let currentAnnouncementIndex = customerAnnouncementIds.findIndex(
        (val) => ad.announcementId == val.announcementId
      );
      return customerAnnouncementIds[currentAnnouncementIndex + 1];
    }

    let similarAnnouncementIds =
      as.announcementDetails.firstOpenedAnnouncementSimilarIds;
    if (
      !f &&
      similarAnnouncementIds != null &&
      ad.announcementId !=
        similarAnnouncementIds[similarAnnouncementIds.length - 1].announcementId
    ) {
      let currentAnnouncementIndex = similarAnnouncementIds?.findIndex(
        (e) => ad.announcementId == e.announcementId
      );
      return similarAnnouncementIds[currentAnnouncementIndex + 1];
    } else {
      return null;
    }
  }
);

export const getPreviousAnnouncementId = createSelector(
  selectCurrentNvaigationIds,
  selectAnnouncementDetails,
  orderedAnnouncementListIds,
  selectLastListingPage,
  getCustomerState,
  selectAnnouncementState,
  selectIsFirstOpenedAnnouncement,
  selectFavoriteIds,
  (a, ad, al, lp, c, as, f, fav) => {
    if (ad == null) return undefined; //TODO: doesn't work when null is returned

    if (lp == PageNameEnum.SearchResultsPage && f && a != null) {
      if (ad.announcementId != a[0].announcementId) {
        let currentAnnouncementIndex = a.findIndex(
          (e) => e.announcementId == ad.announcementId
        );
        return a[currentAnnouncementIndex - 1];
      }
    }
    if (
      lp == PageNameEnum.FrontPage &&
      f &&
      ad.announcementId != al[0].announcementId
    ) {
      let currentAnnouncementIndex = al.findIndex(
        (e) => ad.announcementId == e.announcementId
      );
      return al[currentAnnouncementIndex - 1];
    }

    if (
      lp == PageNameEnum.Favorites &&
      f &&
      ad.announcementId != fav[0].announcementId
    ) {
      let currentAnnouncementIndex = fav.findIndex(
        (i) => ad.announcementId == i.announcementId
      );
      return fav[currentAnnouncementIndex - 1];
    }

    let customerAnnouncementIds = c.customer?.announcementList.map((e) => {
      return { announcementId: e.announcementId, announcementTitle: e.title };
    });
    if (
      lp == PageNameEnum.CustomerPublicPage &&
      f &&
      ad.announcementId != customerAnnouncementIds[0].announcementId
    ) {
      let currentAnnouncementIndex = customerAnnouncementIds.findIndex(
        (e) => ad.announcementId == e.announcementId
      );
      return customerAnnouncementIds[currentAnnouncementIndex - 1];
    }

    let similarAnnouncementIds =
      as.announcementDetails.firstOpenedAnnouncementSimilarIds;
    if (
      !f &&
      similarAnnouncementIds != null &&
      ad.announcementId != similarAnnouncementIds[0].announcementId
    ) {
      let currentAnnouncementIndex = similarAnnouncementIds?.findIndex(
        (e) => ad.announcementId == e.announcementId
      );
      return similarAnnouncementIds[currentAnnouncementIndex - 1];
    } else {
      return null;
    }
  }
);

export const selectAnnouncementPublishDate = createSelector(
  selectAnnouncementDetails,
  (e) => {
    return e?.renewalDate == null ? e?.publishDate : e?.renewalDate;
  }
);

export const selectSimilarAnnouncements = createSelector(
  selectAnnouncementDetails,
  (e) => {
    return e?.isWithBranding
      ? e?.similarAnnouncements.slice(0, 3)
      : e?.similarAnnouncements;
  }
);

export const ownAnnouncements = createSelector(
  selectAnnouncementDetails,
  (e) => {
    let announcements = [...(e?.ownAnnouncements || [])];
    let result = announcements
      .sort(() => Math.random())
      .filter((d) => d.announcementId != e.announcementId);

    return result.slice(0, 3);
  }
);

export const selectAnnouncementNotFound = createSelector(
  selectAnnouncementState,
  (e) => {
    return (
      e.announcementDetails.announcementFailureDetails?.errorCode ==
      HRRAExceptionCode.AnnouncementNotFound
    );
  }
);

export const selectIsAnnouncementDetailsLoading = createSelector(
  selectAnnouncementState,
  (s) => s.announcementDetails.loading || s.announcementDetails.applyLoading
);

export const selectAnnouncementDetailsMetaData = createSelector(
  selectAnnouncementState,
  selectCurrentWebsiteNameForSeo,
  selectCurrentWebsiteShortName,
  (s, w, wn) => {
    let existingMetadata = s.announcementDetails.metaData;
    if (existingMetadata == null) return null;

    let metadata: MetaDataModel = <MetaDataModel>{};
    let og: OpenGraphModel = <OpenGraphModel>{};

    og.description = existingMetadata.openGraphData.description;
    og.isAvailable = existingMetadata.openGraphData.isAvailable;
    og.title = existingMetadata.openGraphData.title;
    og.type = existingMetadata.openGraphData.type;
    og.image = '/assets/img/local/social-media/' + wn + '.png';

    metadata.openGraphData = og;
    metadata.seoData = existingMetadata.seoData;

    return {
      website: w,
      metaData: metadata,
    };
  }
);

export const selectAnnouncementDesktopImage = createSelector(
  selectAnnouncementDetails,
  (e) => {
    let result = e?.customerBranding?.announcementDetailsDesktopBgImage;
    return result;
  }
);

export const selectAnnouncementMobileImage = createSelector(
  selectAnnouncementDetails,
  (e) => {
    let result = e?.customerBranding?.announcementDetailsMobileBgImage;
    return result;
  }
);

//Banners
export const selectAnnDetailsPageBanners = createSelector(
  selectAnnouncementState,
  (e) => e.announcementDetails?.bannerList
);

export const selectAnnDetailsPageBannerIds = createSelector(
  selectAnnouncementState,
  (e) => {
    return {
      bannerIdList: e.announcementDetails.bannerList?.map((v) => v.bannerId) || [],
      targetId: e.announcementDetails.bannerList &&  e.frontpage.bannerList.length > 0 &&  e.frontpage.bannerList[0].targetId,
    };
  }
);

export const selectAnnDetailsBannerMap = createSelector(
  selectAnnDetailsPageBanners,
  (s) => {
    let map: { [index: number]: Banner } = {};
    s?.reduce((a, c) => {
      a[c.positionId] = c;
      return a;
    }, map);

    return map;
  }
);

export const selectAnnDetailsTopBanner = createSelector(
  selectAnnDetailsBannerMap,
  (s) => {
    return s[BannerPositionEnum.Announcement_details_Top];
  }
);

export const selectAnnDetailsRightBanner = createSelector(
  selectAnnDetailsBannerMap,
  (s) => {
    return s[BannerPositionEnum.Announcement_details_Right_Banner];
  }
);

export const selectAnnDetailsRightBanner2 = createSelector(
  selectAnnDetailsBannerMap,
  (s) => {
    return s[BannerPositionEnum.Announcement_details_Right_Banner2];
  }
);

export const selectAnnDetailsRightBanner3 = createSelector(
  selectAnnDetailsBannerMap,
  (s) => {
    return s[BannerPositionEnum.Announcement_details_Right_Banner3];
  }
);

export const selectAnnDetailsSquareBanner = createSelector(
  selectAnnDetailsBannerMap,
  (s) => {
    return s[BannerPositionEnum.Announcement_details_Right_Square];
  }
);

export const selectAnnDetailsMiddleBanner = createSelector(
  selectAnnDetailsBannerMap,
  (s) => {
    return s[BannerPositionEnum.Announcement_details_Middle];
  }
);

export const selectAnnDetailsBottomBanner = createSelector(
  selectAnnDetailsBannerMap,
  (s) => {
    return s[BannerPositionEnum.Announcement_details_Bottom];
  }
);

export const selectAnnDetailsStateInfo = createSelector(
  selectLookupMy,
  selectFrontPageNumberOfItemsOnPage,
  selectCommonLookupsLoaded,
  selectAnnouncementState,
  selectLastListingPage,
  (l, p, ll, as, lp) => {
    return {
      lookup: l,
      isLookupLoaded: ll,
      pageSize: p,
      search: as.search,
      annDetails: as.announcementDetails,
      lastListingPage: lp,
    };
  }
);

export const selectIsAnnDetailsCvBoxSliderVisible = createSelector(
  selectAnnouncementState,
  (e) => e.announcementDetails.isCvBoxSliderVisible
);
