import { Action, on } from "@ngrx/store";
import { JobseekerApplicationState } from "./state";
import * as Actions from './actions';
import { createImmerReducer } from "ngrx-immer/store";

let initialState: JobseekerApplicationState = {
    vacancyApplications: {
        applicationModel: null,
        loading: null,
        isActiveTab: null,

        pageState: {        
            pageLinkCount: 5,
            currentPage: 1,
        },
    },

    cvBoxApplications:{
        applicationModel: null,
        loading: null,
        isActiveTab: null,

        pageState: {        
            pageLinkCount: 5,
            currentPage: 1,
        },
    }
}

const reducer = createImmerReducer(
    initialState,

    on(Actions.loadApplications, (state, { payload }) => {
        state.vacancyApplications.loading = true;
        return state;
    }),
    on(Actions.loadApplicationsSuccess, (state, { payload }) => {
        state.vacancyApplications.applicationModel = payload.application;
        state.vacancyApplications.pageState.currentPage = payload.page;
        state.vacancyApplications.loading = false;
        return state;
    }),
    on(Actions.loadApplicationsFail, (state, { payload }) => {
        state.vacancyApplications.loading = false;
        return state;
    }),
    on(Actions.loadCvBoxApplications, (state, { payload }) => {
        state.cvBoxApplications.loading = true;
        return state;
    }),
    on(Actions.loadCvBoxApplicationsSuccess, (state, { payload }) => {
        state.cvBoxApplications.applicationModel = payload.application;
        state.cvBoxApplications.pageState.currentPage = payload.page;
        state.cvBoxApplications.loading = false;
        return state;
    }),
    on(Actions.loadCvBoxApplicationsFail, (state, { payload }) => {
        state.cvBoxApplications.loading = false;
        return state;
    })
)

export function jobseekerApplicationsReducer(state: JobseekerApplicationState | undefined, action: Action) {
    return reducer(state, action);
}