import { createAction, props } from "@ngrx/store";
import { ApiError } from "@hrra/core";
import { ApplicationViewModel, CvBoxApplicationViewModel } from "src/app/jobseeker/shared/services/jobseeker-applies.service";
import { ActionPayload } from "@hrra/core";

export enum Actions{
    LoadApplications = '[Jobseeker applies] Load applications',
    LoadApplicationsSuccess = '[Jobseeker applies] Load applications success',
    LoadApplicationsFail = '[Jobseeker applies] Load applications fail',

    LoadCvBoxApplications = '[Jobseeker applies] Load cv box application',
    LoadCvBoxApplicationsSuccess = '[Jobseeker applies] Load cv box application success',
    LoadCvBoxApplicationsFail = '[Jobseeker applies] Load cv box application fail',

    ResetPage = '[Jobseeker applies] Reset page',
}

export const loadApplications = createAction(
    Actions.LoadApplications,
    props<ActionPayload<{page: number}>>()
);
export const loadApplicationsSuccess = createAction(
    Actions.LoadApplicationsSuccess,
    props<ActionPayload<{application: ApplicationViewModel, page: number}>>()
);
export const loadApplicationsFail = createAction(
    Actions.LoadApplicationsFail,
    props<ActionPayload<ApiError>>()
);

export const loadCvBoxApplications = createAction(
    Actions.LoadCvBoxApplications,
    props<ActionPayload<{page: number}>>()
);
export const loadCvBoxApplicationsSuccess = createAction(
    Actions.LoadCvBoxApplicationsSuccess,
    props<ActionPayload<{application: CvBoxApplicationViewModel, page: number}>>()
);
export const loadCvBoxApplicationsFail = createAction(
    Actions.LoadCvBoxApplicationsFail,
    props<ActionPayload<ApiError>>()
);
