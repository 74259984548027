import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoPageComponent } from './info-page/info-page.component';
import { InfoPageRoutingModule } from './info-page-routing.module';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [InfoPageComponent],
  imports: [
    InfoPageRoutingModule,
    CommonModule,
    SharedModule,
  ],
  exports: [
    InfoPageComponent
  ]
})
export class InfoPagesModule { }
