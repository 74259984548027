import { Inject, Injectable, InjectionToken } from '@angular/core';
import * as pino from 'pino';
import { EnvService } from '../env.service';

import { EXTERNAL_LOGGER, LogginService } from './logging-provider';

@Injectable({
  providedIn: 'root'
})
export class ServerLoggingService extends LogginService {

  _logger: pino.Logger;

  get logger() {
    return this._logger;
  }

  constructor(private env: EnvService, @Inject(EXTERNAL_LOGGER) externalLogger: pino.Logger) {
    super();
    this._logger = externalLogger;
  }
}