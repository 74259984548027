import { Component, ContentChildren, Inject, Input, OnInit, QueryList } from '@angular/core';
import { Observable } from 'rxjs';
import { AdvancedTabComponent } from '../advanced-tab/advanced-tab.component';
import { startWith, delay, map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { Breakpoints } from '@hrra/common-models';

@Component({
  selector: 'hrra-advanced-tab-group',
  templateUrl: './advanced-tab-group.component.html',
  styleUrls: ['./advanced-tab-group.component.scss']
})
export class AdvancedTabGroupComponent implements OnInit {
  
  ngAfterContentChecked(): void {

    if (!this.initialized) {
      Promise.resolve().then(() => {
        const tabsArray = this.tabs.toArray();
        const activeTab = tabsArray[this.activeTabIndex || 0];

        this.activeTabs = [... this.activeTabs, activeTab];

        this.initialized  = true;
      });
    }
  }

  ngAfterContentInit(): void {
    this.tabItems$ = this.tabs.changes
      .pipe(startWith(""))
      .pipe(delay(0))
      .pipe(map(() => this.tabs.toArray()));
  }

  constructor(@Inject(DOCUMENT) private _document) { }

  public initialized: boolean = false;

  @ContentChildren(AdvancedTabComponent)
  public tabs: QueryList<AdvancedTabComponent>;

  public tabItems$: Observable<AdvancedTabComponent[]>;

  public _activeTabs: AdvancedTabComponent[] = [];


  public activeTabsSet: Set<AdvancedTabComponent>;

  public get activeTabs(){
    return this._activeTabs;
  }

  public set activeTabs(value: AdvancedTabComponent[]){

    this.activeTabsSet = new Set(value);
    this._activeTabs = value;
  }

  public isDesktop: boolean = false;

  @Input()
  public activeTabIndex: number;

  ngOnInit(): void {
    this.isDesktop = window.innerWidth > Breakpoints.medium;
  }

  selectTab(tabItem: AdvancedTabComponent) {
    if(!this.isDesktop){
      let itemIsActive = this.activeTabs.find(e => e == tabItem);
      if(!itemIsActive){
        this.activeTabs = [... this.activeTabs, tabItem];
      }
      else{
        this.activeTabs = this.activeTabs.filter(e => e != tabItem);
      }
    }
    else{
      let itemIsActive = this.activeTabs.find(e => e == tabItem);

      if(!itemIsActive){
        this.activeTabs = [tabItem];
      }
    }
  }

  onResizeWindowWidth() {
    if(!this.isDesktop && window.innerWidth > Breakpoints.medium){
      this.activeTabs = [this.tabs.toArray()[this.activeTabIndex]];
    }
    
    if(this.isDesktop && window.innerWidth < Breakpoints.medium){
      this.isDesktop = !this.isDesktop;
    }
  }
}
