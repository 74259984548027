export enum BannerPositionEnum{
    Main_page = 1,
    Search_results = 2,
    Announcement_details = 3,
    Cvbox_page = 4,
    Main_page_Top = 5,
    Main_page_Mid = 6,
    Main_page_Mid2 = 7,
    Main_page_bottom = 8,
    Search_results_Top = 9,
    Search_results_Left_Sidebar = 10,
    Search_results_List = 11,
    Announcement_details_Top = 12,
    Announcement_details_Right_Banner = 13,
    Announcement_details_Right_Banner2 = 14,
    Announcement_details_Right_Square = 15,
    Announcement_details_Middle = 16,
    Announcement_details_Bottom = 17,
    Cvbox_page_Vip = 18,
    Cvbox_page_Premium = 19,
    Cvbox_page_Regular = 20,
    Announcement_details_Right_Banner3 = 21
}
