import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { OptionItem } from '@hrra/common-models';
import { Range } from '@hrra/core';
import { Dropdown } from 'primeng/dropdown';

@Component({
  selector: 'hrra-salary',
  templateUrl: './salary.component.html',
  styleUrls: ['./salary.component.scss'],
})
export class SalaryComponent implements OnInit, ControlValueAccessor, OnChanges {
 
  onChange = (value) => {};
  onTouched = () => {};

  @ViewChild('dropdown')
  dropdown!: Dropdown;

  @Input() salaryOptions: OptionItem<Range<number>>[];
  @Input() filter: any;

  private _selectedSalaryOption: number = 0;

  @Input()
  get selectedSalaryOption(): number { return this._selectedSalaryOption; }
  set selectedSalaryOption(selectedSalaryOption: any){
    /**
     * When click clear button, selectedSalaryOption value become OptionItem<Range<number>>{  }, other cases - option key
     */
    this._selectedSalaryOption = typeof(selectedSalaryOption) == "number" ? selectedSalaryOption : selectedSalaryOption?.key;
  }

  @Output() changeSalaryRangeOption: EventEmitter<number> = new EventEmitter<number>();
  @Output() onlySelectedSalary: EventEmitter<boolean> = new EventEmitter<boolean>();

  salaryForm: FormGroup;

  constructor(private fb: FormBuilder) {}

 
  ngOnInit(): void {
    this.salaryForm = this.fb.group({
      salaryRangeOption: new FormControl(undefined),
      onlySelectedSalary: new FormControl(undefined),
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filter'] ){
      if(this.salaryForm?.controls['salaryRangeOption'].value == undefined){
        this.salaryForm?.controls['salaryRangeOption'].setValue(this.salaryOptions[this.filter.salaryRangeOptionId]);
      }
      if(this.salaryForm?.controls['onlySelectedSalary'].value !== undefined){
        this.salaryForm?.controls['onlySelectedSalary'].setValue(this.filter.onlySelectedSalary);
      }
    }
  }

  writeValue(value: any) {}

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }
  
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  onChangeSalaryRangeOption(event){
    if(event == null){
      this.selectedSalaryOption = 0;
      this.changeSalaryRangeOption.emit(0);
      return;
    }
    this.changeSalaryRangeOption.emit(event);
  }

  onOnlySelectedSalary(){
    this.onlySelectedSalary.emit(this.salaryForm.value.onlySelectedSalary);
  }
}
