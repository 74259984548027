<div style="padding: 1rem;">
    <div *ngIf="emailVerificationComplete$ | async">
    {{ 'message.AccountIsActivated' | translate}}
    </div>
    <div *ngIf="emailVerificationFailed$ | async">
        <app-jobseeker-error-message
        [messageHeading]="'message.CommonServerErrorSummary' | translate"
        [message]="'message.CommonServerErrorDetail' | translate">
    </app-jobseeker-error-message>
    </div>
</div>
