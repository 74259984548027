import { AnnouncementStoreState, FrontPageState } from './state';
import { Action, createReducer, on, ActionReducerMap, State } from '@ngrx/store';
import * as FrontPageAnnouncementActions from './announcement-front-page-actions';
import { SearchPageReducer } from './search-page';
import { AnnouncementDetailsReducer } from './ann-details-page';
import { searchFieldReducer } from './front-page/search-field/reducer';
import { selectVipCvAnnouncements } from './selectors';
import { state } from '@angular/animations';

const announcementCountForInfiniteScroll = 50;

let initialState: FrontPageState = {
  announcements: [],
  loading: false,
  metaData: null,
  frontPageCityLinks: null,
  favoriteAnnouncements: [],
  cvBoxDecorateAnnouncementCustomerIds: [],
  frontPageFailureDetails: null,
  bannerList: [],
  bannerListLoading: false,
  vipAnnouncementListCount: announcementCountForInfiniteScroll,
  mergedAnnouncementListCount: announcementCountForInfiniteScroll,
  isCvBoxSliderVisible: false
};

const reducer = createReducer(
  initialState,
  on(FrontPageAnnouncementActions.loadFrontPageAnnouncements, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    FrontPageAnnouncementActions.loadFrontPageAnnouncementsSuccess,
    (state, { payload }) => ({
      ...state,
      announcements: payload.announcementList,
      metaData: payload.metaData,
      cvBoxDecorateAnnouncementCustomerIds: payload.cvBoxDecorateAnnouncementCustomerIds,
      frontPageCityLinks: payload.frontPageCityLinks,
      loading: false,
      frontPageFailureDetails: null
    })
  ),
  on(
    FrontPageAnnouncementActions.loadFrontPageAnnouncementsFail,
    (state, { payload }) => ({
      ...state,
      loading: false,
      frontPageFailureDetails: payload.error
    })
  ),
  on(
    FrontPageAnnouncementActions.showCvBoxSlider,
    (state, { payload }) => ({
      ...state,
      isCvBoxSliderVisible: payload
    })
  ),
  on(
    FrontPageAnnouncementActions.loadFavoriteAnnouncements, (state) => ({
      ...state,
      loading: true
    })
  ),
  on(
    FrontPageAnnouncementActions.loadFavoriteAnnouncementsSuccess,
    (state, { payload }) => ({
      ...state,
      loading: false,
      favoriteAnnouncements: payload
    })
  ),
  on(
    FrontPageAnnouncementActions.loadFavoriteAnnouncementsFail,
    (state, { payload }) => ({
      ...state,
      loading: false
    })
  ),
  on(
    FrontPageAnnouncementActions.getFrontPageBannerList,
    (state, { payload }) => ({
      ...state,
      bannerListLoading: true
    })
  ),
  on(
    FrontPageAnnouncementActions.getFrontPageBannerListSuccess,
    (state, { payload }) => ({
      ...state,
      bannerList: payload,
      bannerListLoading: false
    })
  ),
  on(
    FrontPageAnnouncementActions.getFrontPageBannerListFailed,
    (state, { payload }) => ({
      ...state,
      bannerListLoading: false
    })
  ),
  on(FrontPageAnnouncementActions.addAnnouncementsInVipSectionList,
    (state) => ({
      ...state,
      vipAnnouncementListCount: state.vipAnnouncementListCount + announcementCountForInfiniteScroll
    })
  ),
  on(FrontPageAnnouncementActions.addAnnouncementsInMergedSectionList,
    (state) => ({
      ...state,
      mergedAnnouncementListCount: state.mergedAnnouncementListCount + announcementCountForInfiniteScroll
    })
  ),
  on(FrontPageAnnouncementActions.resetAnnouncementListCounts,
    (state) => ({
      ...state,
      vipAnnouncementListCount: announcementCountForInfiniteScroll,
      mergedAnnouncementListCount: announcementCountForInfiniteScroll
    })
  ),
);

export function announcementReducer(
  state: FrontPageState | undefined,
  action: Action
) {
  return reducer(state, action);
}
export const reducers: ActionReducerMap<AnnouncementStoreState> = {
  search: SearchPageReducer.announcementReducer,
  frontpage: announcementReducer,
  announcementDetails: AnnouncementDetailsReducer.announcementDetailsReducer,
  searchFieldState: searchFieldReducer,
  //lookup: SearchPageReducer.lookupReducer
};
