import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  Renderer2,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from './root-store/state';
import {
  loadCommonLookups,
  PartnerSitesLookupActions,
  getNewAccessToken,
  loadLanguage,
  loadFavorites,
  loadProviderWebsiteInfo,
  ConfigsActions,
  facebookSdkInit,
  setCurrentDate,
  getGuid,
} from './root-store/actions';
import { DOCUMENT, registerLocaleData } from '@angular/common';
import localeGeo from '@angular/common/locales/ka';
import { combineLatest, Observable } from 'rxjs';
import {
  selectFacebookPageId,
} from './root-store/selectors';
import { NavigationEnd, Router } from '@angular/router';
import { filter, skip, take } from 'rxjs/operators';

import { SsrService } from './core/ssr.service';
import { TranslateService } from '@ngx-translate/core';
import { FacebookService } from '@hrra/integrations';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { EnvService, LocalDataStorageService, LogginService } from '@hrra/core';
import { UniqueGuidService } from './shared/services/unique-guid.service';

registerLocaleData(localeGeo, 'ka');

@Component({
  selector: 'app-root',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'public-web-app';

  date: Date;

  facebookPageId$: Observable<string>;

  constructor(
    private gtmService: GoogleTagManagerService,
    private translate: TranslateService,
    private store: Store<AppState>,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document,
    private router: Router,
    private ssr: SsrService,
    private loggingService: LogginService,
    private envService: EnvService,
  ) {
    this.date = new Date();
  }

  ngAfterViewInit(): void {
    if (this.ssr.isPlatformBrowser() && this.envService.topGeOn == 'true') {
      let topGeHeadTag = this._document.getElementById('top-ge');

      let topGeBlock = this._document.getElementById(
        'top-ge-counter-container'
      );

      topGeHeadTag.appendChild(topGeBlock);
    }
  }

  ngOnInit(): void {
    this.store.dispatch(setCurrentDate({ payload: { date: this.date } }));
    this.store.dispatch(getGuid())

    this.store.dispatch(loadLanguage());
    this.store.dispatch(loadProviderWebsiteInfo());
    this.store.dispatch(loadCommonLookups());
    this.store.dispatch({
      type: PartnerSitesLookupActions.LoadPartnerSitesLookup,
    });
    //this.store.dispatch({type: CurrentInfoActions.LoadCurrentInfo});
    this.store.dispatch(getNewAccessToken());
    this.store.dispatch({ type: ConfigsActions.LoadConfigs });

    if (this.ssr.isPlatformBrowser()) {
      this.store.dispatch(loadFavorites());
    }

    this.facebookPageId$ = this.store.pipe(select(selectFacebookPageId));

    if (this.ssr.isPlatformBrowser()) {
      this.store.dispatch(facebookSdkInit());
    }
  }

  ngOnDestroy() {
  }
}
