export enum NecessaryField{
    Name = 1,
    Surname = 2,
    CV = 3,
    Phone = 4,
    City = 5,
    Gender = 6,
    BirthDate = 7,
    WorkExperience = 8,
    Education = 9,
    Language = 10
}