import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { InfoPageComponent } from './info-page/info-page.component';

const infoPageRoutes: Routes =[

  {
    path: 'info/:alias',
    pathMatch: 'full',
    component: InfoPageComponent,
  }
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(infoPageRoutes)
  ],
  exports: [RouterModule]
})
export class InfoPageRoutingModule { }
