import { createAction, props } from '@ngrx/store';
import { ApiError } from '@hrra/core';
import { JobseekerLookupData } from 'src/app/jobseeker/shared/services/jobseeker.service';
import { ActionPayload } from "@hrra/core";

export enum Actions {
    JobseekerLookups = '[jobseeker] Load jobseeker lookups',
    JobseekerLookupsSuccess = '[jobseeker] Load jobseeker lookups Success',
    JobseekerLookupsFailed = '[jobseeker] Load jobseeker lookups failed'
}

export const loadJobseekerLookups = createAction(
    Actions.JobseekerLookups
);

export const loadJobseekerLookupsSuccess = createAction(
    Actions.JobseekerLookupsSuccess,
    props<{payload: JobseekerLookupData}>()
);

export const loadJobseekersLookupsFailed = createAction(
    Actions.JobseekerLookupsFailed,
    props<ActionPayload<ApiError>>()
);