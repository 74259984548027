import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class DefaultRouteReuseStrategy implements RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
  }
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle|null {
    return null;
  }
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    // return true;
    let result = this.deepCompare(future, curr) && this.deepCheck(curr);
    if(result)
      console.log("reusing route ");

    return result;
  }


  deepCompare(a: ActivatedRouteSnapshot, b: ActivatedRouteSnapshot){
    if(a.routeConfig !== b.routeConfig)
      return false;
    let aChildEmpty = a.firstChild === null || a.firstChild === undefined;
    let bChildEmtpy = b.firstChild === null || b.firstChild === undefined;
    
    if(aChildEmpty != bChildEmtpy)
      return false;

    if(aChildEmpty && bChildEmtpy)
      return true;

    return this.deepCompare(a.firstChild, b.firstChild);
  }

  deepCheck(route: ActivatedRouteSnapshot){
    if(route === null || route === undefined)
      return false;
    if(route.routeConfig && route.routeConfig.data && route.routeConfig.data['reuse'] && route.routeConfig.data['reuse'] === true)
      return true;
    return this.deepCheck(route.firstChild);
  }


}